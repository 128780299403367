import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import { caloTheme } from '../../assets/themes/calo';

interface CustomFoodsFilterProps {
	isCustomFoodHidden: boolean;
	setIsCustomFoodHidden: (isCustomFoodHidden: boolean) => void;
}

const CustomFoodsFilter = ({ isCustomFoodHidden, setIsCustomFoodHidden }: CustomFoodsFilterProps) => (
	<FormGroup>
		<FormControlLabel
			sx={{ fontSize: '21px', fontFamily: caloTheme.typography.fontFamily, textTransform: 'uppercase', ml: 0, mr: 0 }}
			control={
				<Switch
					checked={isCustomFoodHidden}
					onChange={() => setIsCustomFoodHidden(!isCustomFoodHidden)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			}
			disableTypography={true}
			label="Hide custom meals"
			labelPlacement="start"
		/>
	</FormGroup>
);
export default CustomFoodsFilter;

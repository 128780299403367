import { uniqBy } from 'lodash-es';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { ComplaintsFiltersInput } from '../libs';
import { GraphQLClient, LIST_COMPLAINTS_QUERY, SUBSCRIBE_TO_RESOLVE_COMPLAINT_SUBSCRIPTION } from '../libs/graphQL';

const useListCustomerComplaints = (filters: ComplaintsFiltersInput) => {
	const [complaintsList, setComplaints] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState<number>(0);
	const limit = 50;
	const hasNext = (page + 1) * limit < total;

	useSubscription(SUBSCRIBE_TO_RESOLVE_COMPLAINT_SUBSCRIPTION);
	const { loading, refetch, fetchMore } = useQuery(LIST_COMPLAINTS_QUERY, {
		variables: {
			query: {
				filters: {
					...filters
				},
				limit: limit,
				page: 0
			}
		},
		onCompleted: (data) => {
			if (data?.listComplaints?.data) {
				setComplaints(data?.listComplaints?.data);
			}
			if (data?.listComplaints?.total) {
				setTotal(data?.listComplaints?.total);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const handleFetchMore = () => {
		if (hasNext) {
			const pageNum = page + 1;
			fetchMore({
				variables: {
					query: {
						filters: { ...filters },
						limit: limit,
						page: pageNum
					}
				},
				updateQuery: (prev: any, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setPage(pageNum + 1);
						const prevComplaints = Array.isArray(prev?.listComplaints?.data) ? prev.listComplaints.data : [];
						const moreComplaints = Array.isArray(fetchMoreResult?.listComplaints?.data)
							? fetchMoreResult.listComplaints.data
							: [];
						const newComplaints = uniqBy([...prevComplaints, ...moreComplaints], 'id');

						return {
							...prev,
							listComplaints: {
								...prev.listComplaints,
								data: newComplaints,
								total: prev?.listComplaints?.total
							}
						};
					}

					return prev;
				}
			});
		}
	};

	const clearData = () => {
		setPage(0);
		setComplaints([]);
		// eslint-disable-next-line unicorn/no-useless-undefined
		GraphQLClient.clearStore();
	};

	return {
		complaintsLoading: loading,
		complaintsList,
		hasNext: hasNext,
		handleFetchMoreComplaints: handleFetchMore,
		clearComplaintsData: clearData,
		refetchComplaints: refetch
	};
};

export default useListCustomerComplaints;

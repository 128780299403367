import { useContext } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button, Checkbox, Stack, Typography, useTheme } from '@mui/material';

import { AppContext } from '../../../../../App';
import { CombinedShortages, FoodComponent, FoodComponentStationOperation, FoodComponentStationTitle } from '../../../../../libs';
import {
	BATCH_UPDATE_FOODS_PORTIONING_STATUS_MUTATION,
	UPDATE_FOOD_COMPONENT_STATUS_MUTATION
} from '../../../../../libs/graphQL';

type ActionButtonsProps = {
	foodComponent: FoodComponent;
	tableName: FoodComponentStationTitle;
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
} & (
	| {
			combinedShortages: CombinedShortages;
			isChecked: boolean;
			setIsCheckedState: React.Dispatch<React.SetStateAction<boolean>>;
	  }
	| { combinedShortages: undefined }
);

const ActionButtons = ({ foodComponent, tableName, statusAttributes, ...props }: ActionButtonsProps) => {
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [mutateFoodComponent, { loading }] = useMutation(UPDATE_FOOD_COMPONENT_STATUS_MUTATION);
	const [mutateFoodsPortioningStatus] = useMutation(BATCH_UPDATE_FOODS_PORTIONING_STATUS_MUTATION);

	const cookingStation = foodComponent.cookingStations?.find(
		(station) => FoodComponentStationTitle[station.name as keyof typeof FoodComponentStationTitle] === tableName
	);
	const allIntervals = cookingStation?.processTime?.intervals || [];

	const canStart = !cookingStation?.processTime && !cookingStation?.endTime;
	const canResume =
		cookingStation?.processTime?.startedAt && allIntervals.length > 0 && !allIntervals[allIntervals.length - 1].resumedAt;
	const canPause =
		cookingStation?.processTime?.startedAt && (allIntervals.length === 0 || allIntervals[allIntervals.length - 1].resumedAt);

	const handleFoodComponentStationAction = async (operation: FoodComponentStationOperation) => {
		await mutateFoodComponent({
			variables: {
				id: foodComponent.id,
				kitchen: foodComponent.kitchen,
				station: tableName,
				operation: operation,
				shortagesIds: props.combinedShortages?.ids || []
			},
			onCompleted: async () => {
				const lastStationName = foodComponent.cookingStations?.at(-1)?.name;
				if (
					FoodComponentStationTitle[lastStationName as keyof typeof FoodComponentStationTitle] === tableName &&
					operation === FoodComponentStationOperation.end
				) {
					await mutateFoodsPortioningStatus({
						variables: {
							kitchen: foodComponent.kitchen,
							foodsIds: foodComponent.foodsIds || []
						}
					});
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	if (props.combinedShortages) {
		return (
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'column',
					my: '-4px',
					alignItems: 'flex-end',
					width: '100%',
					justifyContent: 'flex-end'
				}}
			>
				<Checkbox
					checked={props.isChecked}
					disabled={!statusAttributes.isCheckable || loading || appContext.isOffline || props.isChecked}
					onChange={(event) => {
						if (!props.isChecked) {
							props.setIsCheckedState(event.target.checked);
							handleFoodComponentStationAction(FoodComponentStationOperation.end);
						}
					}}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</Stack>
		);
	}

	if (canStart) {
		return (
			<Stack sx={{ display: 'flex', flexDirection: 'column', my: '-4px', alignItems: 'flex-end', width: '100%' }}>
				<Button
					onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.start)}
					disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
					sx={{
						width: '100%',
						height: '100%',
						color: theme.palette.primary500,
						border: '1px solid ' + theme.palette.primary500,
						borderRadius: '8px',
						textTransform: 'capitalize',
						'&:disabled': {
							borderColor: theme.palette.neutral200
						},
						'&:hover': {}
					}}
				>
					<Typography fontWeight="600" fontSize="16px">
						Start Now
					</Typography>
				</Button>
			</Stack>
		);
	}

	return (
		<Stack sx={{ my: '-4px', display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'flex-end' }}>
			<Button
				variant="outlined"
				onClick={() =>
					handleFoodComponentStationAction(canPause ? FoodComponentStationOperation.pause : FoodComponentStationOperation.resume)
				}
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				startIcon={canResume ? <PlayArrowIcon /> : <PauseCircleIcon sx={{ color: theme.palette.black }} />}
				sx={{
					width: '110px',
					height: '100%',
					textTransform: 'capitalize',
					color: theme.palette.black,
					backgroundColor: theme.palette.white,
					borderRadius: '5px'
				}}
				style={{
					borderColor: theme.palette.black
				}}
			>
				<Typography fontWeight="500" fontSize="13px">
					{canPause ? 'PAUSE' : 'RESUME'}
				</Typography>
			</Button>
			<Button
				variant="outlined"
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.end)}
				sx={{
					backgroundColor: theme.palette.white,
					borderColor: theme.palette.black,
					borderRadius: '5px',
					padding: 0,
					width: '38px',
					minWidth: '48px',
					'&:hover': {
						backgroundColor: theme.palette.primary500
					},
					'&:disabled': {
						backgroundColor: theme.palette.white
					}
				}}
			>
				<CheckRoundedIcon sx={{ color: theme.palette.black }} />
			</Button>
		</Stack>
	);
};

export default ActionButtons;

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
};

export type Address = {
	address?: Maybe<Scalars['String']['output']>;
	apartment?: Maybe<Scalars['String']['output']>;
	building?: Maybe<Scalars['String']['output']>;
	city?: Maybe<Scalars['String']['output']>;
	country: Country;
	customDeliveryTime?: Maybe<Scalars['String']['output']>;
	district?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	lat: Scalars['Float']['output'];
	lng: Scalars['Float']['output'];
	name?: Maybe<Scalars['String']['output']>;
	office?: Maybe<Scalars['String']['output']>;
	region?: Maybe<Scalars['String']['output']>;
	street?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
	address?: InputMaybe<Scalars['String']['input']>;
	apartment?: InputMaybe<Scalars['String']['input']>;
	building?: InputMaybe<Scalars['String']['input']>;
	city?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Country>;
	customDeliveryTime?: InputMaybe<Scalars['String']['input']>;
	district?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	lat?: InputMaybe<Scalars['Float']['input']>;
	lng?: InputMaybe<Scalars['Float']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	office?: InputMaybe<Scalars['String']['input']>;
	region?: InputMaybe<Scalars['String']['input']>;
	street?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<Scalars['String']['input']>;
};

export type AddressTicketData = {
	newAddress?: Maybe<Address>;
	newDeliveryTime?: Maybe<Scalars['String']['output']>;
	newDriver?: Maybe<Driver>;
	oldAddress?: Maybe<Address>;
	oldDriver?: Maybe<Driver>;
};

export type AddressTicketDataInput = {
	newAddress?: InputMaybe<AddressInput>;
	newDeliveryTime?: InputMaybe<Scalars['String']['input']>;
	newDriver?: InputMaybe<DriverInput>;
	oldAddress?: InputMaybe<AddressInput>;
	oldDriver?: InputMaybe<DriverInput>;
};

export type BaggingDispatch = {
	action?: Maybe<Scalars['String']['output']>;
	actual?: Maybe<Scalars['Int']['output']>;
	endTemperature?: Maybe<Scalars['Float']['output']>;
	extra?: Maybe<Scalars['Int']['output']>;
	foodCost?: Maybe<Scalars['Float']['output']>;
	foodId: Scalars['String']['output'];
	foodName: Localized;
	foodSize: Scalars['String']['output'];
	foodType?: Maybe<Array<Maybe<FoodType>>>;
	id: Scalars['String']['output'];
	isCustomFood?: Maybe<Scalars['Boolean']['output']>;
	leftovers?: Maybe<Scalars['Int']['output']>;
	modified?: Maybe<Scalars['Int']['output']>;
	packages?: Maybe<Array<Package>>;
	purchasingCost?: Maybe<Scalars['Float']['output']>;
	session?: Maybe<Session>;
	startTemperature?: Maybe<Scalars['Float']['output']>;
	tk?: Maybe<Scalars['String']['output']>;
	total?: Maybe<Scalars['Int']['output']>;
};

export type BaggingDispatchInput = {
	action?: InputMaybe<Scalars['String']['input']>;
	actual?: InputMaybe<Scalars['Int']['input']>;
	date?: InputMaybe<Scalars['String']['input']>;
	endTemperature?: InputMaybe<Scalars['Float']['input']>;
	extra?: InputMaybe<Scalars['Int']['input']>;
	foodId?: InputMaybe<Scalars['String']['input']>;
	foodName?: InputMaybe<LocalizedInput>;
	foodSize?: InputMaybe<Scalars['String']['input']>;
	foodType?: InputMaybe<Array<InputMaybe<FoodType>>>;
	isCustomFood?: InputMaybe<Scalars['Boolean']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	leftovers?: InputMaybe<Scalars['Int']['input']>;
	modified?: InputMaybe<Scalars['Int']['input']>;
	purchasingCost?: InputMaybe<Scalars['Float']['input']>;
	session?: InputMaybe<Session>;
	startTemperature?: InputMaybe<Scalars['Float']['input']>;
	total?: InputMaybe<Scalars['Int']['input']>;
};

export type BaggingSession = {
	date: Scalars['String']['output'];
	dispatches?: Maybe<Array<BaggingDispatch>>;
	endTime?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	roomTemperature?: Maybe<Scalars['Float']['output']>;
	session?: Maybe<Scalars['String']['output']>;
	startTime?: Maybe<Scalars['String']['output']>;
	status?: Maybe<SessionStatus>;
	tk?: Maybe<Scalars['String']['output']>;
};

export type BaggingSessionInput = {
	date?: InputMaybe<Scalars['String']['input']>;
	endTime?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	roomTemperature?: InputMaybe<Scalars['Float']['input']>;
	session?: InputMaybe<Scalars['String']['input']>;
	startTime?: InputMaybe<Scalars['String']['input']>;
	status?: InputMaybe<SessionStatus>;
};

export enum Brand {
	CALO = 'CALO',
	MEALO = 'MEALO'
}

export type Chef = {
	brand: Brand;
	country: Country;
	deletedAt?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	name: Scalars['String']['output'];
	session: Session;
	stations?: Maybe<Array<Maybe<FoodComponentStation>>>;
};

export type ChefInput = {
	brand?: InputMaybe<Brand>;
	country?: InputMaybe<Country>;
	kitchen?: InputMaybe<Kitchen>;
	name?: InputMaybe<Scalars['String']['input']>;
	session?: InputMaybe<Session>;
	stations?: InputMaybe<Array<InputMaybe<FoodComponentStation>>>;
};

export type Child = {
	count?: Maybe<Scalars['Float']['output']>;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type Complaint = {
	attachment?: Maybe<Scalars['Boolean']['output']>;
	attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	category: Scalars['String']['output'];
	comments?: Maybe<Array<Maybe<ComplaintComment>>>;
	createdAt?: Maybe<Scalars['String']['output']>;
	createdBy?: Maybe<User>;
	createdDate?: Maybe<Scalars['String']['output']>;
	date: Scalars['String']['output'];
	deliveryId: Scalars['String']['output'];
	deliveryTime?: Maybe<Scalars['String']['output']>;
	driver?: Maybe<Driver>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	meal?: Maybe<ComplaintMeal>;
	operationsId?: Maybe<Scalars['String']['output']>;
	resolvedAt?: Maybe<Scalars['String']['output']>;
	resolvedBy?: Maybe<User>;
	reviewedAt?: Maybe<Scalars['String']['output']>;
	reviewedBy?: Maybe<User>;
	status?: Maybe<ComplaintStatus>;
	type: Scalars['String']['output'];
	user: ComplaintUser;
};

export type ComplaintComment = {
	id: Scalars['String']['output'];
	name?: Maybe<Scalars['String']['output']>;
	text?: Maybe<Scalars['String']['output']>;
};

export type ComplaintMeal = {
	id?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	size?: Maybe<Scalars['String']['output']>;
	slug?: Maybe<Scalars['String']['output']>;
};

export enum ComplaintStatus {
	approved = 'approved',
	pending = 'pending',
	rejected = 'rejected',
	review = 'review'
}

export type ComplaintUser = {
	email?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	phoneNumber?: Maybe<Scalars['String']['output']>;
	userId: Scalars['String']['output'];
};

export type ComplaintsFiltersInput = {
	category?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<RangeInput>;
	kitchen: Kitchen;
	type?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentBatch = {
	batchNumber: Scalars['Int']['output'];
	brand: Brand;
	chef?: Maybe<Scalars['String']['output']>;
	country: Country;
	date: Scalars['String']['output'];
	deletedAt?: Maybe<Scalars['String']['output']>;
	deletedBy?: Maybe<User>;
	foodComponentId: Scalars['String']['output'];
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	measurementUnit: MeasurementUnit;
	quality?: Maybe<Array<Maybe<QualityChecks>>>;
	session: Session;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type ComponentBatchInput = {
	batchNumber?: InputMaybe<Scalars['Int']['input']>;
	brand?: InputMaybe<Scalars['String']['input']>;
	chef?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['String']['input']>;
	deletedAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	deletedBy?: InputMaybe<UserInput>;
	foodComponentId?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Scalars['String']['input']>;
	measurementUnit?: InputMaybe<MeasurementUnit>;
	quality?: InputMaybe<Array<InputMaybe<QualityChecksInput>>>;
	session?: InputMaybe<Session>;
	weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ComponentShortage = {
	cookingStations?: Maybe<Array<Maybe<CookingStations>>>;
	createdAt?: Maybe<Scalars['String']['output']>;
	foodId: Scalars['String']['output'];
	foodName: Localized;
	foodSize: Scalars['String']['output'];
	id: Scalars['String']['output'];
	weight?: Maybe<Scalars['Float']['output']>;
};

export type ComponentShortageInput = {
	componentId: Scalars['String']['input'];
	foodId: Scalars['String']['input'];
	foodName: LocalizedInput;
	foodSize: Scalars['String']['input'];
	weight: Scalars['Float']['input'];
};

export type ComponentsQuantity = {
	id: Scalars['String']['output'];
	quantity?: Maybe<Scalars['Float']['output']>;
};

export type ConfigurationValuesInput = {
	heatingInstructions?: InputMaybe<Scalars['String']['input']>;
	language?: InputMaybe<Scalars['String']['input']>;
	shelfLife?: InputMaybe<Scalars['Int']['input']>;
	shelfLifeType?: InputMaybe<Scalars['String']['input']>;
	template?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<Scalars['String']['input']>;
};

export enum CookingStationName {
	bakery = 'bakery',
	bakeryAndPastry = 'bakeryAndPastry',
	butchery = 'butchery',
	commissary = 'commissary',
	gardeManger = 'gardeManger',
	hot = 'hot',
	merchandise = 'merchandise',
	other = 'other',
	portioning = 'portioning',
	roasting = 'roasting',
	sauce = 'sauce',
	saucier = 'saucier',
	vegetable = 'vegetable'
}

export type CookingStations = {
	endTime?: Maybe<Scalars['String']['output']>;
	name: CookingStationName;
	processTime?: Maybe<ProcessTime>;
	status?: Maybe<FCStatus>;
};

export enum Country {
	AE = 'AE',
	BH = 'BH',
	GB = 'GB',
	KW = 'KW',
	OM = 'OM',
	QA = 'QA',
	SA = 'SA',
	US = 'US'
}

export type CupSizes = {
	size: Scalars['Float']['output'];
	value: Scalars['Float']['output'];
};

export type CupsQuantity = {
	cup: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
};

export type DeleteComponentBatchResponse = {
	message?: Maybe<Scalars['String']['output']>;
};

export type DeliveriesFiltersInput = {
	brand: Brand;
	country: Scalars['String']['input'];
	day: RangeInput;
	deliveryTime?: InputMaybe<DeliveryTime>;
	driverId?: InputMaybe<Scalars['String']['input']>;
	kitchen: Kitchen;
	name?: InputMaybe<Scalars['String']['input']>;
	phoneNumber?: InputMaybe<Scalars['String']['input']>;
	shortId?: InputMaybe<Scalars['String']['input']>;
	skipped?: InputMaybe<Scalars['Boolean']['input']>;
	status: Array<DeliveryStatus>;
	userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Delivery = {
	brand?: Maybe<Brand>;
	customDeliveryTime?: Maybe<Scalars['String']['output']>;
	day: Scalars['String']['output'];
	downloadedDocs?: Maybe<Array<Maybe<DocsType>>>;
	driver?: Maybe<Driver>;
	food?: Maybe<Array<Maybe<DeliveryFood>>>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	name: Scalars['String']['output'];
	phoneNumber: Scalars['String']['output'];
	pickupTime?: Maybe<Scalars['String']['output']>;
	shortId?: Maybe<Scalars['String']['output']>;
	skipped?: Maybe<Scalars['Boolean']['output']>;
	status: DeliveryStatus;
	time?: Maybe<DeliveryTime>;
	userId: Scalars['String']['output'];
};

export type DeliveryFood = {
	id: Scalars['String']['output'];
	isCustom?: Maybe<Scalars['Boolean']['output']>;
	isSwapped?: Maybe<Scalars['Boolean']['output']>;
	name: Localized;
	size: Scalars['String']['output'];
	skipped?: Maybe<Scalars['Boolean']['output']>;
	tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	type?: Maybe<Array<Maybe<FoodType>>>;
};

export enum DeliveryStatus {
	cancelled = 'cancelled',
	paused = 'paused',
	paymentRequired = 'paymentRequired',
	suspended = 'suspended',
	upcoming = 'upcoming'
}

export type DeliveryTicket = {
	brand: Brand;
	country: Country;
	createdAt?: Maybe<Scalars['String']['output']>;
	createdBy?: Maybe<User>;
	data?: Maybe<DeliveryTicketData>;
	date?: Maybe<Scalars['String']['output']>;
	downloadedAt?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	resolvedAt?: Maybe<Scalars['String']['output']>;
	resolvedBy?: Maybe<User>;
	status?: Maybe<DeliveryTicketStatus>;
	zone?: Maybe<Scalars['String']['output']>;
};

export type DeliveryTicketData = {
	addressTicketData?: Maybe<AddressTicketData>;
	autoApprove?: Maybe<Scalars['Boolean']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	deliveryId: Scalars['String']['output'];
	deliveryOldShortId?: Maybe<Scalars['String']['output']>;
	deliveryShortId: Scalars['String']['output'];
	deliveryTime?: Maybe<Scalars['String']['output']>;
	driver?: Maybe<Driver>;
	manualTicketData?: Maybe<ManualTicketData>;
	mealTicketData?: Maybe<Array<Maybe<MealTicketData>>>;
	otherTicketData?: Maybe<OtherTicketData>;
	phoneNumber?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Scalars['String']['output']>;
	userId: Scalars['String']['output'];
	userName?: Maybe<Scalars['String']['output']>;
};

export type DeliveryTicketDataInput = {
	addressTicketData?: InputMaybe<AddressTicketDataInput>;
	autoApprove?: InputMaybe<Scalars['Boolean']['input']>;
	comment?: InputMaybe<Scalars['String']['input']>;
	deliveryId?: InputMaybe<Scalars['String']['input']>;
	deliveryOldShortId?: InputMaybe<Scalars['String']['input']>;
	deliveryShortId?: InputMaybe<Scalars['String']['input']>;
	deliveryTime?: InputMaybe<Scalars['String']['input']>;
	driver?: InputMaybe<DriverInput>;
	manualTicketData?: InputMaybe<ManualTicketDataInput>;
	mealTicketData?: InputMaybe<Array<InputMaybe<MealTicketDataInput>>>;
	otherTicketData?: InputMaybe<OtherTicketDataInput>;
	phoneNumber?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<Scalars['String']['input']>;
	userId?: InputMaybe<Scalars['String']['input']>;
	userName?: InputMaybe<Scalars['String']['input']>;
};

export enum DeliveryTicketStatus {
	approved = 'approved',
	pending = 'pending',
	rejected = 'rejected'
}

export enum DeliveryTicketType {
	address = 'address',
	cancel = 'cancel',
	manual = 'manual',
	meal = 'meal',
	other = 'other'
}

export type DeliveryTicketsFiltersInput = {
	date?: InputMaybe<RangeInput>;
	kitchen?: InputMaybe<Kitchen>;
	status?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<Scalars['String']['input']>;
	userId?: InputMaybe<Scalars['String']['input']>;
};

export enum DeliveryTime {
	evening = 'evening',
	morning = 'morning'
}

export enum DocsType {
	backLabel = 'backLabel',
	frontLabel = 'frontLabel',
	invoice = 'invoice',
	portioningGuidelines = 'portioningGuidelines'
}

export type Driver = {
	code?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
};

export type DriverDispatch = {
	caloBags?: Maybe<Scalars['Int']['output']>;
	departureTime?: Maybe<Scalars['String']['output']>;
	driver: RoutPlanDriver;
	id: Scalars['String']['output'];
	mealoBags?: Maybe<Scalars['Int']['output']>;
	vanTemperature?: Maybe<Scalars['Float']['output']>;
};

export type DriverDispatchInput = {
	caloBags?: InputMaybe<Scalars['Int']['input']>;
	departureTime?: InputMaybe<Scalars['String']['input']>;
	mealoBags?: InputMaybe<Scalars['Int']['input']>;
	vanTemperature?: InputMaybe<Scalars['String']['input']>;
};

export type DriverInput = {
	code?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDocumentsResponse = {
	data?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ExportFileResponse = {
	data?: Maybe<Scalars['String']['output']>;
};

export type ExportFoodLabelsDataInput = {
	amount?: InputMaybe<Scalars['Int']['input']>;
	backLabels?: InputMaybe<Scalars['Boolean']['input']>;
	frontLabels?: InputMaybe<Scalars['Boolean']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
};

export type ExportFoodLabelsReq = {
	configurations?: InputMaybe<ConfigurationValuesInput>;
	data?: InputMaybe<Array<InputMaybe<ExportFoodLabelsDataInput>>>;
	day?: InputMaybe<Scalars['String']['input']>;
};

export enum ExportMetricsTypeEnum {
	components = 'components',
	meals = 'meals'
}

export type ExtraMealsObjectInput = {
	extraMeals?: InputMaybe<Scalars['Int']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	size?: InputMaybe<Scalars['String']['input']>;
};

export enum FCStatus {
	completed = 'completed',
	pending = 'pending'
}

export type FCWastageInputs = {
	action?: InputMaybe<Scalars['String']['input']>;
	chilledWeight?: InputMaybe<Scalars['Float']['input']>;
	preppedWeight?: InputMaybe<Scalars['Float']['input']>;
	wastage?: InputMaybe<Scalars['Float']['input']>;
};

export type Food = {
	assemblyURL?: Maybe<Scalars['String']['output']>;
	brand?: Maybe<Brand>;
	componentIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	count?: Maybe<Scalars['Int']['output']>;
	country?: Maybe<Country>;
	date?: Maybe<Scalars['String']['output']>;
	deliveryTime?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	id: Scalars['String']['output'];
	isAssemblyRequired?: Maybe<Scalars['Boolean']['output']>;
	isCustom?: Maybe<Scalars['Boolean']['output']>;
	kitchen?: Maybe<Kitchen>;
	name: Localized;
	portioningStatus?: Maybe<Array<Maybe<PortioningStatus>>>;
	portioningURL?: Maybe<Scalars['String']['output']>;
	size?: Maybe<Scalars['String']['output']>;
	sizes?: Maybe<Array<Maybe<FoodSize>>>;
	slug?: Maybe<Scalars['String']['output']>;
	tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	tk?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Array<Maybe<FoodType>>>;
};

export type FoodAction = {
	componentId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	type?: Maybe<FoodActionType>;
};

export enum FoodActionType {
	add = 'add',
	remove = 'remove',
	swap = 'swap'
}

export type FoodComponent = {
	brand?: Maybe<Brand>;
	child?: Maybe<Child>;
	childComponents?: Maybe<Array<Maybe<LinkedComponent>>>;
	cookedWeight?: Maybe<Scalars['Float']['output']>;
	cookingStations?: Maybe<Array<Maybe<CookingStations>>>;
	cost?: Maybe<Scalars['Float']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	country?: Maybe<Country>;
	cups?: Maybe<Array<Maybe<CupSizes>>>;
	cupsQuantity?: Maybe<Array<Maybe<CupsQuantity>>>;
	date?: Maybe<Scalars['String']['output']>;
	foodsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	id: Scalars['String']['output'];
	ingredients?: Maybe<Array<Maybe<fcIngredient>>>;
	isVerified?: Maybe<Scalars['Boolean']['output']>;
	kitchen?: Maybe<Kitchen>;
	kitchenWastageInputs?: Maybe<KitchenWastageInputs>;
	measurementUnit: MeasurementUnit;
	method?: Maybe<Array<Maybe<FoodComponentMethodStep>>>;
	name: Localized;
	purchasingCost?: Maybe<Scalars['Float']['output']>;
	quantities?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
	quantity?: Maybe<Scalars['Float']['output']>;
	shortages?: Maybe<Array<Maybe<ComponentShortage>>>;
	standards?: Maybe<Array<Maybe<FoodComponentQualityStandard>>>;
	tk?: Maybe<Scalars['String']['output']>;
	verifiedBy?: Maybe<User>;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type FoodComponentMethodStep = {
	attachment?: Maybe<Scalars['String']['output']>;
	description?: Maybe<Scalars['String']['output']>;
	station?: Maybe<Scalars['String']['output']>;
};

export type FoodComponentQualityStandard = {
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	imageURLs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	name: Scalars['String']['output'];
};

export enum FoodComponentStation {
	bakeryAndPastry = 'bakeryAndPastry',
	butchery = 'butchery',
	commissary = 'commissary',
	gardeManger = 'gardeManger',
	hot = 'hot',
	merchandise = 'merchandise',
	other = 'other',
	portioning = 'portioning',
	roasting = 'roasting',
	saucier = 'saucier'
}

export enum FoodComponentStationOperation {
	end = 'end',
	pause = 'pause',
	resume = 'resume',
	start = 'start'
}

export type FoodFiltersInput = {
	foodType?: InputMaybe<Array<FoodType>>;
	ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	kitchen?: InputMaybe<Kitchen>;
	name?: InputMaybe<Scalars['String']['input']>;
	sizes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	usedOnMenu?: InputMaybe<Array<InputMaybe<FoodUsedOnMenusInput>>>;
};

export type FoodMetrics = {
	cacheKey?: Maybe<Scalars['String']['output']>;
	numberOfFoods?: Maybe<Scalars['Float']['output']>;
	numberOfModified?: Maybe<Scalars['Float']['output']>;
	portioningTime?: Maybe<Scalars['Float']['output']>;
	predictedPortioningTimePerMeal?: Maybe<Scalars['Float']['output']>;
	shift: Session;
};

export type FoodSize = {
	components?: Maybe<Array<Maybe<ComponentsQuantity>>>;
	componentsCost?: Maybe<Scalars['Float']['output']>;
	extraMeals?: Maybe<Scalars['Int']['output']>;
	id: Scalars['String']['output'];
	purchasingCost?: Maybe<Scalars['Float']['output']>;
	quantity?: Maybe<Scalars['Float']['output']>;
	size: Scalars['String']['output'];
};

export enum FoodType {
	breakfast = 'breakfast',
	caloKids = 'caloKids',
	coffee = 'coffee',
	dessert = 'dessert',
	dinner = 'dinner',
	juice = 'juice',
	lunch = 'lunch',
	salad = 'salad',
	snack = 'snack'
}

export type FoodUsedOnMenusInput = {
	date?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<FoodUsedOnMenusTypeEnum>;
	week?: InputMaybe<Scalars['String']['input']>;
};

export enum FoodUsedOnMenusTypeEnum {
	addonsMenu = 'addonsMenu',
	mainMenu = 'mainMenu'
}

export type Gift = {
	attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	comment?: Maybe<Scalars['String']['output']>;
	createdAt?: Maybe<Scalars['String']['output']>;
	createdBy?: Maybe<User>;
	date?: Maybe<Scalars['String']['output']>;
	deliveryId: Scalars['String']['output'];
	deliveryTime?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	items?: Maybe<Array<Maybe<GiftItem>>>;
	kitchen: Kitchen;
	phoneNumber?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Array<Maybe<GiftItemType>>>;
	updatedAt?: Maybe<Scalars['String']['output']>;
	userId: Scalars['String']['output'];
	userName?: Maybe<Scalars['String']['output']>;
	zone?: Maybe<Scalars['String']['output']>;
};

export type GiftItem = {
	amount?: Maybe<Scalars['Int']['output']>;
	id: Scalars['String']['output'];
	item?: Maybe<GiftItemData>;
	type?: Maybe<GiftItemType>;
};

export type GiftItemData = {
	id: Scalars['String']['output'];
	name?: Maybe<Localized>;
	size?: Maybe<Scalars['String']['output']>;
	slug?: Maybe<Scalars['String']['output']>;
};

export type GiftItemDataInput = {
	id?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<LocalizedInput>;
	size?: InputMaybe<Scalars['String']['input']>;
	slug?: InputMaybe<Scalars['String']['input']>;
};

export type GiftItemInput = {
	amount?: InputMaybe<Scalars['Int']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	item?: InputMaybe<GiftItemDataInput>;
	type?: InputMaybe<GiftItemType>;
};

export enum GiftItemType {
	cake = 'cake',
	flowers = 'flowers',
	meal = 'meal',
	merch = 'merch',
	other = 'other'
}

export type Ingredient = {
	brand?: Maybe<Brand>;
	date?: Maybe<Scalars['String']['output']>;
	header?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	internalName?: Maybe<Scalars['String']['output']>;
	kitchen?: Maybe<Kitchen>;
	measurementUnit: MeasurementUnit;
	name: Localized;
	purchasingCost?: Maybe<Scalars['Float']['output']>;
	quantity?: Maybe<Scalars['Float']['output']>;
	wastage?: Maybe<Scalars['Float']['output']>;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type KDSKitchen = {
	id: Scalars['String']['output'];
	sessions?: Maybe<Scalars['String']['output']>;
};

export enum Kitchen {
	AE001 = 'AE001',
	BH001 = 'BH001',
	GB000 = 'GB000',
	GB001 = 'GB001',
	KW001 = 'KW001',
	NA000 = 'NA000',
	NA001 = 'NA001',
	OM001 = 'OM001',
	QA001 = 'QA001',
	SA001 = 'SA001',
	SA002 = 'SA002'
}

export type KitchenWastageInputs = {
	action?: Maybe<Scalars['String']['output']>;
	chilledWeight?: Maybe<Scalars['Float']['output']>;
	preppedWeight?: Maybe<Scalars['Float']['output']>;
	wastage?: Maybe<Scalars['Float']['output']>;
};

export type LinkedComponent = {
	id: Scalars['String']['output'];
	quantity?: Maybe<Scalars['Float']['output']>;
};

export type ListChefs = {
	data?: Maybe<Array<Maybe<Chef>>>;
};

export type ListComplaintInput = {
	filters?: InputMaybe<ComplaintsFiltersInput>;
	limit?: InputMaybe<Scalars['Float']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type ListComplaintsResponse = {
	data?: Maybe<Array<Maybe<Complaint>>>;
	total?: Maybe<Scalars['Int']['output']>;
};

export type ListComponentBatchesResponse = {
	data?: Maybe<Array<Maybe<ComponentBatch>>>;
	total?: Maybe<Scalars['Int']['output']>;
};

export type ListDeliveriesInput = {
	filters?: InputMaybe<DeliveriesFiltersInput>;
	limit: Scalars['Float']['input'];
	page: Scalars['Int']['input'];
};

export type ListDeliveriesResponse = {
	data?: Maybe<Array<Maybe<Delivery>>>;
	total?: Maybe<Scalars['Int']['output']>;
};

export type ListDeliveryTicketsResponse = {
	data?: Maybe<Array<Maybe<DeliveryTicket>>>;
};

export type ListFoodComponentByRangeFilters = {
	shortages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListFoodComponentByRangeResponse = {
	components?: Maybe<Array<Maybe<FoodComponent>>>;
	next?: Maybe<Scalars['String']['output']>;
};

export type ListFoodComponentsResponse = {
	data?: Maybe<Array<Maybe<FoodComponent>>>;
};

export type ListFoodsByFiltersResponse = {
	data?: Maybe<Array<Maybe<Food>>>;
	meta?: Maybe<Meta>;
};

export type ListFoodsResponse = {
	data?: Maybe<Array<Maybe<Food>>>;
};

export type ListGiftsFiltersInput = {
	date?: InputMaybe<RangeInput>;
	deliveryIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	kitchen?: InputMaybe<Kitchen>;
};

export type ListGiftsReqInput = {
	cursor?: InputMaybe<Scalars['String']['input']>;
	filters?: InputMaybe<ListGiftsFiltersInput>;
	limit?: InputMaybe<Scalars['Float']['input']>;
};

export type ListGiftsResponse = {
	data?: Maybe<Array<Maybe<Gift>>>;
	next?: Maybe<Scalars['String']['output']>;
};

export type ListKitchensResponse = {
	data?: Maybe<Array<Maybe<KDSKitchen>>>;
};

export type ListProductivityMetricsResponse = {
	data?: Maybe<Array<Maybe<ProductivityMetrics>>>;
};

export type ListQualityMetricsResponse = {
	data?: Maybe<Array<Maybe<QualityMetric>>>;
};

export type ListResponseMeta = {
	total?: Maybe<Scalars['Int']['output']>;
};

export type Localized = {
	ar: Scalars['String']['output'];
	en: Scalars['String']['output'];
};

export type LocalizedInput = {
	ar?: InputMaybe<Scalars['String']['input']>;
	en?: InputMaybe<Scalars['String']['input']>;
};

export type ManualTicketData = {
	address?: Maybe<Address>;
	coolerBag?: Maybe<Scalars['Boolean']['output']>;
	cutlery?: Maybe<Scalars['Boolean']['output']>;
	foods?: Maybe<Array<Maybe<MealTicketFood>>>;
};

export type ManualTicketDataInput = {
	address?: InputMaybe<AddressInput>;
	coolerBag?: InputMaybe<Scalars['Boolean']['input']>;
	cutlery?: InputMaybe<Scalars['Boolean']['input']>;
	foods?: InputMaybe<Array<InputMaybe<MealTicketFoodInput>>>;
};

export type MealTicketData = {
	newFood?: Maybe<MealTicketFood>;
	oldFood?: Maybe<MealTicketFood>;
};

export type MealTicketDataInput = {
	newFood?: InputMaybe<MealTicketFoodInput>;
	oldFood?: InputMaybe<MealTicketFoodInput>;
};

export type MealTicketFood = {
	actions?: Maybe<Array<Maybe<FoodAction>>>;
	foodType?: Maybe<Array<Maybe<FoodType>>>;
	id: Scalars['String']['output'];
	name: Localized;
	positionIndex?: Maybe<Scalars['Int']['output']>;
	size: Scalars['String']['output'];
	slug?: Maybe<Scalars['String']['output']>;
};

export type MealTicketFoodInput = {
	foodType?: InputMaybe<Array<InputMaybe<FoodType>>>;
	id?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<LocalizedInput>;
	positionIndex?: InputMaybe<Scalars['Int']['input']>;
	size?: InputMaybe<Scalars['String']['input']>;
	slug?: InputMaybe<Scalars['String']['input']>;
};

export enum MeasurementUnit {
	cup = 'cup',
	g = 'g',
	ml = 'ml',
	piece = 'piece',
	pinch = 'pinch',
	teaspoon = 'teaspoon',
	wedge = 'wedge'
}

export type Meta = {
	limit?: Maybe<Scalars['Int']['output']>;
	page?: Maybe<Scalars['Int']['output']>;
	total?: Maybe<Scalars['Int']['output']>;
};

export type ModifiedDeliveryIds = {
	deliveryIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	downloadSequence?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
	addBaggingDispatch?: Maybe<BaggingDispatch>;
	approveDeliveryTicket?: Maybe<DeliveryTicket>;
	batchCreateComponentShortage?: Maybe<Array<Maybe<FoodComponent>>>;
	batchUpdateFoodsPortioningStatus?: Maybe<Array<Maybe<Food>>>;
	createChef?: Maybe<Chef>;
	createComponentBatch?: Maybe<ComponentBatch>;
	deleteChef?: Maybe<Chef>;
	deleteComponentBatch?: Maybe<DeleteComponentBatchResponse>;
	deliveryTicketCreated?: Maybe<DeliveryTicket>;
	exportComplaintList?: Maybe<ExportFileResponse>;
	exportComponentBatch?: Maybe<ExportFileResponse>;
	exportDocuments?: Maybe<ExportDocumentsResponse>;
	exportFoodComponentShortage?: Maybe<ExportFileResponse>;
	exportFoodComponentStatus?: Maybe<ExportFileResponse>;
	exportFoodComponentWastage?: Maybe<ExportFileResponse>;
	exportFoodLabels?: Maybe<ExportDocumentsResponse>;
	exportFoodPortioningStatus?: Maybe<ExportFileResponse>;
	exportMealInvoices?: Maybe<ExportDocumentsResponse>;
	exportQualityMetrics?: Maybe<ExportFileResponse>;
	getImageUploadURL?: Maybe<ExportFileResponse>;
	modifyBaggingDispatch?: Maybe<BaggingDispatch>;
	modifyBaggingSession?: Maybe<BaggingSession>;
	rejectDeliveryTicket?: Maybe<DeliveryTicket>;
	resolveComplaint?: Maybe<Complaint>;
	scanShoppingDelivery?: Maybe<ScanShoppingDeliveryResponse>;
	startBaggingSession?: Maybe<BaggingSession>;
	updateChef?: Maybe<Chef>;
	updateComponentBatch?: Maybe<ComponentBatch>;
	updateDeliveryTicketsDownloadedAt?: Maybe<Array<Maybe<DeliveryTicket>>>;
	updateDriverDispatch?: Maybe<DriverDispatch>;
	updateExtraMeals?: Maybe<Array<Maybe<Food>>>;
	updateFCWastageInputs?: Maybe<FoodComponent>;
	updateFoodComponentStatus?: Maybe<FoodComponent>;
	updateFoodPortioningStatus?: Maybe<Food>;
	updateShoppingMenu?: Maybe<ShoppingMenu>;
	verifyComponent?: Maybe<FoodComponent>;
};

export type MutationaddBaggingDispatchArgs = {
	input: BaggingDispatchInput;
};

export type MutationapproveDeliveryTicketArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	type?: InputMaybe<DeliveryTicketType>;
	user?: InputMaybe<UserInput>;
};

export type MutationbatchCreateComponentShortageArgs = {
	input?: InputMaybe<Array<InputMaybe<ComponentShortageInput>>>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
};

export type MutationbatchUpdateFoodsPortioningStatusArgs = {
	foodsIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationcreateChefArgs = {
	data?: InputMaybe<ChefInput>;
};

export type MutationcreateComponentBatchArgs = {
	data?: InputMaybe<ComponentBatchInput>;
};

export type MutationdeleteChefArgs = {
	id: Scalars['String']['input'];
	kitchen: Kitchen;
};

export type MutationdeleteComponentBatchArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Scalars['String']['input']>;
};

export type MutationdeliveryTicketCreatedArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationexportComplaintListArgs = {
	filters?: InputMaybe<ComplaintsFiltersInput>;
};

export type MutationexportComponentBatchArgs = {
	date?: InputMaybe<RangeInput>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportDocumentsArgs = {
	day?: InputMaybe<Scalars['String']['input']>;
	docTypes: Array<InputMaybe<DocsType>>;
	ids: Array<InputMaybe<Scalars['String']['input']>>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportFoodComponentShortageArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportFoodComponentStatusArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportFoodComponentWastageArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportFoodLabelsArgs = {
	input?: InputMaybe<ExportFoodLabelsReq>;
};

export type MutationexportFoodPortioningStatusArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	showCustom?: InputMaybe<Scalars['Boolean']['input']>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportMealInvoicesArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shoppingMenuFoodSlug?: InputMaybe<Scalars['String']['input']>;
};

export type MutationexportQualityMetricsArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Session>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	stations?: InputMaybe<Array<InputMaybe<CookingStationName>>>;
	type?: InputMaybe<ExportMetricsTypeEnum>;
};

export type MutationgetImageUploadURLArgs = {
	path?: InputMaybe<Scalars['String']['input']>;
};

export type MutationmodifyBaggingDispatchArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	input: BaggingDispatchInput;
};

export type MutationmodifyBaggingSessionArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	input: BaggingSessionInput;
};

export type MutationrejectDeliveryTicketArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	type?: InputMaybe<DeliveryTicketType>;
	user?: InputMaybe<UserInput>;
};

export type MutationresolveComplaintArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	status?: InputMaybe<ComplaintStatus>;
	user?: InputMaybe<UserInput>;
};

export type MutationscanShoppingDeliveryArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationstartBaggingSessionArgs = {
	input: BaggingSessionInput;
};

export type MutationupdateChefArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<ChefInput>;
};

export type MutationupdateComponentBatchArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<ComponentBatchInput>;
};

export type MutationupdateDeliveryTicketsDownloadedAtArgs = {
	ids: Array<InputMaybe<Scalars['String']['input']>>;
	kitchen: Kitchen;
};

export type MutationupdateDriverDispatchArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<DriverDispatchInput>;
};

export type MutationupdateExtraMealsArgs = {
	data?: InputMaybe<Array<InputMaybe<UpdateExtraMealsInput>>>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationupdateFCWastageInputsArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	inputs?: InputMaybe<FCWastageInputs>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationupdateFoodComponentStatusArgs = {
	id: Scalars['String']['input'];
	kitchen: Kitchen;
	operation: FoodComponentStationOperation;
	shift?: InputMaybe<Scalars['String']['input']>;
	shortagesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	station: CookingStationName;
};

export type MutationupdateFoodPortioningStatusArgs = {
	id: Scalars['String']['input'];
	kitchen: Kitchen;
	operation: UpdateFoodPortioningStatusOperation;
	session?: InputMaybe<Scalars['String']['input']>;
};

export type MutationupdateShoppingMenuArgs = {
	data?: InputMaybe<UpdateShoppingMenuInput>;
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type MutationverifyComponentArgs = {
	input?: InputMaybe<VerifyComponentInput>;
	kitchen?: InputMaybe<Kitchen>;
};

export type OtherTicketData = {
	coolerBag?: Maybe<Scalars['Boolean']['output']>;
	cutlery?: Maybe<Scalars['Boolean']['output']>;
};

export type OtherTicketDataInput = {
	coolerBag?: InputMaybe<Scalars['Boolean']['input']>;
	cutlery?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Package = {
	id: Scalars['String']['output'];
	name?: Maybe<Scalars['String']['output']>;
};

export type PausedInterval = {
	pausedAt: Scalars['String']['output'];
	resumedAt?: Maybe<Scalars['String']['output']>;
};

export type PortioningStatus = {
	assemblingTime?: Maybe<ProcessTime>;
	endTime?: Maybe<Scalars['String']['output']>;
	portioningTime?: Maybe<ProcessTime>;
	readyForAssemblyTime?: Maybe<Scalars['String']['output']>;
	readyForPortioningTime?: Maybe<Scalars['String']['output']>;
	session?: Maybe<Scalars['String']['output']>;
};

export type ProcessTime = {
	intervals?: Maybe<Array<Maybe<PausedInterval>>>;
	startedAt: Scalars['String']['output'];
};

export type ProductivityMetrics = {
	day: Scalars['String']['output'];
	foods?: Maybe<Array<Maybe<ProductivityMetricsFood>>>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
};

export type ProductivityMetricsFood = {
	cacheKey?: Maybe<Scalars['String']['output']>;
	id: Scalars['String']['output'];
	isSandwich?: Maybe<Scalars['Boolean']['output']>;
	metrics?: Maybe<Array<Maybe<FoodMetrics>>>;
	name: Localized;
	numberOfComponents?: Maybe<Scalars['Float']['output']>;
	tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	type?: Maybe<Array<Maybe<FoodType>>>;
};

export type QualityCheckRating = {
	id: Scalars['String']['output'];
	name: Scalars['String']['output'];
	rating: QualityRating;
};

export type QualityCheckRatingInput = {
	id: Scalars['String']['input'];
	name: Scalars['String']['input'];
	rating: QualityRating;
};

export enum QualityCheckStatus {
	Accepted = 'Accepted',
	Pending = 'Pending',
	Rejected = 'Rejected'
}

export type QualityChecks = {
	actor?: Maybe<User>;
	comment?: Maybe<Scalars['String']['output']>;
	commentImageURLs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	createdAt?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	standards?: Maybe<Array<Maybe<QualityCheckRating>>>;
	status?: Maybe<QualityCheckStatus>;
};

export type QualityChecksInput = {
	actor?: InputMaybe<UserInput>;
	comment?: InputMaybe<Scalars['String']['input']>;
	commentImageURLs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	createdAt?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	standards?: InputMaybe<Array<InputMaybe<QualityCheckRatingInput>>>;
	status?: InputMaybe<QualityCheckStatus>;
};

export type QualityMetric = {
	components?: Maybe<Array<Maybe<QualityMetricsFoodComponent>>>;
	day?: Maybe<Scalars['String']['output']>;
	food?: Maybe<Array<Maybe<QualityMetricFood>>>;
	id: Scalars['String']['output'];
	isFinalized?: Maybe<Scalars['Boolean']['output']>;
	kitchen: Kitchen;
	shift: Session;
};

export type QualityMetricFood = {
	cacheKey?: Maybe<Scalars['String']['output']>;
	componentsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	id: Scalars['String']['output'];
	name: Localized;
};

export type QualityMetricsFoodComponent = {
	cacheKey?: Maybe<Scalars['String']['output']>;
	consistencyScore?: Maybe<Scalars['Float']['output']>;
	cookedWeight?: Maybe<Scalars['Float']['output']>;
	cost?: Maybe<Scalars['Float']['output']>;
	id: Scalars['String']['output'];
	lastCookingStation?: Maybe<CookingStationName>;
	name: Localized;
	numberOfRejected?: Maybe<Scalars['Float']['output']>;
	totalReviewed?: Maybe<Scalars['Float']['output']>;
	weight?: Maybe<Scalars['Float']['output']>;
	weightOfRejected?: Maybe<Scalars['Float']['output']>;
};

export enum QualityRating {
	bad = 'bad',
	good = 'good',
	neutral = 'neutral'
}

export type Query = {
	baggingStatus?: Maybe<SessionInfo>;
	exportBaggingSession?: Maybe<ExportFileResponse>;
	getFoodComponent?: Maybe<SingleFoodComponent>;
	getShoppingMenu?: Maybe<ShoppingMenu>;
	listBaggingDispatch?: Maybe<Array<Maybe<BaggingDispatch>>>;
	listBaggingSession?: Maybe<SessionListResponse>;
	listChefs?: Maybe<ListChefs>;
	listComplaints?: Maybe<ListComplaintsResponse>;
	listComponentBatches?: Maybe<ListComponentBatchesResponse>;
	listDeliveries?: Maybe<ListDeliveriesResponse>;
	listDeliveryTickets?: Maybe<ListDeliveryTicketsResponse>;
	listDriverDispatches?: Maybe<Array<Maybe<DriverDispatch>>>;
	listFoodComponentByRange?: Maybe<ListFoodComponentByRangeResponse>;
	listFoodComponents?: Maybe<ListFoodComponentsResponse>;
	listFoodComponentsByName?: Maybe<ListFoodComponentsResponse>;
	listFoods?: Maybe<ListFoodsResponse>;
	listFoodsByFilters?: Maybe<ListFoodsByFiltersResponse>;
	listGifts?: Maybe<ListGiftsResponse>;
	listKitchens?: Maybe<ListKitchensResponse>;
	listProductivityMetrics?: Maybe<ListProductivityMetricsResponse>;
	listQualityMetrics?: Maybe<ListQualityMetricsResponse>;
};

export type QuerybaggingStatusArgs = {
	date?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type QueryexportBaggingSessionArgs = {
	actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	fileType?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetFoodComponentArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetShoppingMenuArgs = {
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type QuerylistBaggingDispatchArgs = {
	date?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	session?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistBaggingSessionArgs = {
	actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	next?: InputMaybe<Scalars['String']['input']>;
	shift?: InputMaybe<Scalars['String']['input']>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistChefsArgs = {
	kitchen?: InputMaybe<Kitchen>;
};

export type QuerylistComplaintsArgs = {
	query?: InputMaybe<ListComplaintInput>;
};

export type QuerylistComponentBatchesArgs = {
	date?: InputMaybe<RangeInput>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistDeliveriesArgs = {
	query?: InputMaybe<ListDeliveriesInput>;
};

export type QuerylistDeliveryTicketsArgs = {
	query?: InputMaybe<listDeliveryTicketsInput>;
};

export type QuerylistDriverDispatchesArgs = {
	date?: InputMaybe<Scalars['String']['input']>;
	deliveryTime?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
};

export type QuerylistFoodComponentByRangeArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	filters?: InputMaybe<ListFoodComponentByRangeFilters>;
	kitchen?: InputMaybe<Kitchen>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	next?: InputMaybe<Scalars['String']['input']>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistFoodComponentsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
	station?: InputMaybe<Scalars['String']['input']>;
	withShortages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerylistFoodComponentsByNameArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistFoodsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistFoodsByFiltersArgs = {
	filters?: InputMaybe<FoodFiltersInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerylistGiftsArgs = {
	query?: InputMaybe<ListGiftsReqInput>;
};

export type QuerylistKitchensArgs = {
	kitchen?: InputMaybe<Kitchen>;
};

export type QuerylistProductivityMetricsArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylistQualityMetricsArgs = {
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Session>;
	startDate?: InputMaybe<Scalars['String']['input']>;
};

export type Range = {
	gte?: Maybe<Scalars['String']['output']>;
	lte?: Maybe<Scalars['String']['output']>;
};

export type RangeInput = {
	gte?: InputMaybe<Scalars['String']['input']>;
	lte?: InputMaybe<Scalars['String']['input']>;
};

export type RoutPlanDriver = {
	driverName: Scalars['String']['output'];
	email: Scalars['String']['output'];
	id: Scalars['String']['output'];
	phoneNumber: Scalars['String']['output'];
};

export type ScanShoppingDeliveryResponse = {
	brand?: Maybe<Brand>;
	day?: Maybe<Scalars['String']['output']>;
	food?: Maybe<Array<Maybe<ShoppingMenuFood>>>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
	name?: Maybe<Scalars['String']['output']>;
};

export enum Session {
	all = 'all',
	evening = 'evening',
	morning = 'morning'
}

export type SessionInfo = {
	date: Scalars['String']['output'];
	dispatches?: Maybe<Array<Maybe<BaggingDispatch>>>;
	kitchen: Scalars['String']['output'];
	sessions?: Maybe<Array<Maybe<BaggingSession>>>;
};

export type SessionListResponse = {
	count?: Maybe<Scalars['Int']['output']>;
	next?: Maybe<Scalars['String']['output']>;
	scannedCount?: Maybe<Scalars['Int']['output']>;
	sessions?: Maybe<Array<Maybe<BaggingSession>>>;
};

export enum SessionStatus {
	ended = 'ended',
	pending = 'pending',
	started = 'started'
}

export type ShoppingMenu = {
	country?: Maybe<Country>;
	day?: Maybe<Scalars['String']['output']>;
	food?: Maybe<Array<Maybe<ShoppingMenuFood>>>;
	id: Scalars['String']['output'];
	kitchen: Kitchen;
};

export type ShoppingMenuFood = {
	id: Scalars['String']['output'];
	isCustom?: Maybe<Scalars['Boolean']['output']>;
	isDone?: Maybe<Scalars['Boolean']['output']>;
	modifiedDeliveryIds?: Maybe<ModifiedDeliveryIds>;
	name: Localized;
	order?: Maybe<Scalars['Int']['output']>;
	portioningURL?: Maybe<Scalars['String']['output']>;
	size: Scalars['String']['output'];
	type?: Maybe<Array<Maybe<FoodType>>>;
};

export type SingleFoodComponent = {
	brand?: Maybe<Brand>;
	childComponents?: Maybe<Array<Maybe<FoodComponent>>>;
	cookedRawFactor?: Maybe<Scalars['Float']['output']>;
	cookedWeight?: Maybe<Scalars['Float']['output']>;
	cookingStations?: Maybe<Array<Maybe<CookingStations>>>;
	cost?: Maybe<Scalars['Float']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	country?: Maybe<Country>;
	cups?: Maybe<Array<Maybe<CupSizes>>>;
	cupsQuantity?: Maybe<Array<Maybe<CupsQuantity>>>;
	foodsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
	id: Scalars['String']['output'];
	ingredients?: Maybe<Array<Maybe<Ingredient>>>;
	kitchen?: Maybe<Kitchen>;
	kitchenWastageInputs?: Maybe<KitchenWastageInputs>;
	measurementUnit: MeasurementUnit;
	method?: Maybe<Array<Maybe<FoodComponentMethodStep>>>;
	name: Localized;
	quantities?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
	standards?: Maybe<Array<Maybe<FoodComponentQualityStandard>>>;
	tk?: Maybe<Scalars['String']['output']>;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type Subscription = {
	subscribeToBaggingDispatch?: Maybe<BaggingDispatch>;
	subscribeToBaggingSession?: Maybe<BaggingSession>;
	subscribeToBatchCreateComponentShortage?: Maybe<Array<Maybe<FoodComponent>>>;
	subscribeToBatchUpdateFoodsPortioningStatus?: Maybe<Array<Maybe<Food>>>;
	subscribeToChefChanges?: Maybe<Chef>;
	subscribeToComponentBatchChanges?: Maybe<ComponentBatch>;
	subscribeToDeliveryTicketChanges?: Maybe<DeliveryTicket>;
	subscribeToResolveComplaint?: Maybe<Complaint>;
	subscribeToUpdateDriverDispatch?: Maybe<DriverDispatch>;
	subscribeToUpdateFoodComponentStatus?: Maybe<FoodComponent>;
	subscribeToUpdateFoodPortioning?: Maybe<Food>;
	subscribeToUpdateShoppingMenu?: Maybe<ShoppingMenu>;
};

export type UpdateExtraMealsInput = {
	extraMeals?: InputMaybe<Scalars['Int']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	size?: InputMaybe<Scalars['String']['input']>;
};

export enum UpdateFoodPortioningStatusOperation {
	ended = 'ended',
	notEnded = 'notEnded',
	notReadyToBePortioned = 'notReadyToBePortioned',
	pauseAssembling = 'pauseAssembling',
	pausePortioning = 'pausePortioning',
	readyToBePortioned = 'readyToBePortioned',
	resumeAssembling = 'resumeAssembling',
	resumePortioning = 'resumePortioning',
	startAssembling = 'startAssembling',
	startPortioning = 'startPortioning'
}

export type UpdateShoppingMenuInput = {
	foodId?: InputMaybe<Scalars['String']['input']>;
	operation?: InputMaybe<UpdateShoppingMenuOperation>;
};

export enum UpdateShoppingMenuOperation {
	add = 'add',
	markAsDone = 'markAsDone',
	markAsUndone = 'markAsUndone',
	remove = 'remove'
}

export type User = {
	email: Scalars['String']['output'];
	id?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
};

export type UserInput = {
	email?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyComponentInput = {
	id: Scalars['String']['input'];
	isVerified?: InputMaybe<Scalars['Boolean']['input']>;
	verifiedBy?: InputMaybe<UserInput>;
};

export type fcIngredient = {
	id: Scalars['String']['output'];
	quantity?: Maybe<Scalars['Float']['output']>;
};

export type listDeliveryTicketsInput = {
	cursor?: InputMaybe<Scalars['String']['input']>;
	filters?: InputMaybe<DeliveryTicketsFiltersInput>;
	limit?: InputMaybe<Scalars['Float']['input']>;
};

export type LocalizedFieldsFragment = { ar: string; en: string };

export type PackageFieldsFragment = { id: string; name?: string };

export type BaggingDispatchFieldsFragment = {
	id: string;
	tk?: string;
	session?: Session;
	foodId: string;
	foodSize: string;
	foodType?: Array<FoodType>;
	foodCost?: number;
	total?: number;
	actual?: number;
	extra?: number;
	modified?: number;
	leftovers?: number;
	startTemperature?: number;
	endTemperature?: number;
	action?: string;
	purchasingCost?: number;
	isCustomFood?: boolean;
	foodName: { ar: string; en: string };
	packages?: Array<{ id: string; name?: string }>;
};

export type BaggingSessionFieldsFragment = {
	id: string;
	tk?: string;
	date: string;
	kitchen: Kitchen;
	startTime?: string;
	endTime?: string;
	roomTemperature?: number;
	session?: string;
	status?: SessionStatus;
	dispatches?: Array<{
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	}>;
};

export type SessionInfoFieldsFragment = {
	kitchen: string;
	date: string;
	sessions?: Array<{
		id: string;
		tk?: string;
		date: string;
		kitchen: Kitchen;
		startTime?: string;
		endTime?: string;
		roomTemperature?: number;
		session?: string;
		status?: SessionStatus;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	}>;
	dispatches?: Array<{
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	}>;
};

export type SessionListResponseFieldsFragment = {
	next?: string;
	count?: number;
	scannedCount?: number;
	sessions?: Array<{
		id: string;
		tk?: string;
		date: string;
		kitchen: Kitchen;
		startTime?: string;
		endTime?: string;
		roomTemperature?: number;
		session?: string;
		status?: SessionStatus;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	}>;
};

export type ExportFileResponseFieldsFragment = { data?: string };

export type DeliveryFoodFieldsFragment = {
	id: string;
	size: string;
	tags?: Array<string>;
	type?: Array<FoodType>;
	isCustom?: boolean;
	skipped?: boolean;
	isSwapped?: boolean;
	name: { ar: string; en: string };
};

export type DriverFieldsFragment = { id?: string; name?: string; code?: string };

export type DeliveryFieldsFragment = {
	id: string;
	userId: string;
	name: string;
	phoneNumber: string;
	kitchen: Kitchen;
	day: string;
	status: DeliveryStatus;
	skipped?: boolean;
	shortId?: string;
	brand?: Brand;
	downloadedDocs?: Array<DocsType>;
	time?: DeliveryTime;
	customDeliveryTime?: string;
	pickupTime?: string;
	food?: Array<{
		id: string;
		size: string;
		tags?: Array<string>;
		type?: Array<FoodType>;
		isCustom?: boolean;
		skipped?: boolean;
		isSwapped?: boolean;
		name: { ar: string; en: string };
	}>;
	driver?: { id?: string; name?: string; code?: string };
};

export type ListDeliveriesResponseFieldsFragment = {
	total?: number;
	data?: Array<{
		id: string;
		userId: string;
		name: string;
		phoneNumber: string;
		kitchen: Kitchen;
		day: string;
		status: DeliveryStatus;
		skipped?: boolean;
		shortId?: string;
		brand?: Brand;
		downloadedDocs?: Array<DocsType>;
		time?: DeliveryTime;
		customDeliveryTime?: string;
		pickupTime?: string;
		food?: Array<{
			id: string;
			size: string;
			tags?: Array<string>;
			type?: Array<FoodType>;
			isCustom?: boolean;
			skipped?: boolean;
			isSwapped?: boolean;
			name: { ar: string; en: string };
		}>;
		driver?: { id?: string; name?: string; code?: string };
	}>;
};

export type ChefFieldsFragment = {
	id: string;
	name: string;
	session: Session;
	stations?: Array<FoodComponentStation>;
	brand: Brand;
	kitchen: Kitchen;
	country: Country;
	deletedAt?: string;
};

export type ListChefsFieldsFragment = {
	data?: Array<{
		id: string;
		name: string;
		session: Session;
		stations?: Array<FoodComponentStation>;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		deletedAt?: string;
	}>;
};

export type UserFieldsFragment = { id?: string; name: string; email: string };

export type QualityCheckRatingFieldsFragment = { id: string; name: string; rating: QualityRating };

export type QualityChecksFieldsFragment = {
	id?: string;
	createdAt?: string;
	comment?: string;
	status?: QualityCheckStatus;
	commentImageURLs?: Array<string>;
	actor?: { id?: string; name: string; email: string };
	standards?: Array<{ id: string; name: string; rating: QualityRating }>;
};

export type ComponentBatchFieldsFragment = {
	id: string;
	date: string;
	session: Session;
	batchNumber: number;
	foodComponentId: string;
	chef?: string;
	measurementUnit: MeasurementUnit;
	weight?: number;
	deletedAt?: string;
	brand: Brand;
	kitchen: Kitchen;
	country: Country;
	quality?: Array<{
		id?: string;
		createdAt?: string;
		comment?: string;
		status?: QualityCheckStatus;
		commentImageURLs?: Array<string>;
		actor?: { id?: string; name: string; email: string };
		standards?: Array<{ id: string; name: string; rating: QualityRating }>;
	}>;
	deletedBy?: { id?: string; name: string; email: string };
};

export type ListComponentBatchesResponseFieldsFragment = {
	total?: number;
	data?: Array<{
		id: string;
		date: string;
		session: Session;
		batchNumber: number;
		foodComponentId: string;
		chef?: string;
		measurementUnit: MeasurementUnit;
		weight?: number;
		deletedAt?: string;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		quality?: Array<{
			id?: string;
			createdAt?: string;
			comment?: string;
			status?: QualityCheckStatus;
			commentImageURLs?: Array<string>;
			actor?: { id?: string; name: string; email: string };
			standards?: Array<{ id: string; name: string; rating: QualityRating }>;
		}>;
		deletedBy?: { id?: string; name: string; email: string };
	}>;
};

export type FoodActionFieldsFragment = { type?: FoodActionType; componentId?: Array<string> };

export type MealTicketFoodFieldsFragment = {
	id: string;
	foodType?: Array<FoodType>;
	size: string;
	positionIndex?: number;
	slug?: string;
	name: { ar: string; en: string };
	actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
};

export type MealTicketDataFieldsFragment = {
	oldFood?: {
		id: string;
		foodType?: Array<FoodType>;
		size: string;
		positionIndex?: number;
		slug?: string;
		name: { ar: string; en: string };
		actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
	};
	newFood?: {
		id: string;
		foodType?: Array<FoodType>;
		size: string;
		positionIndex?: number;
		slug?: string;
		name: { ar: string; en: string };
		actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
	};
};

export type AddressFieldsFragment = {
	id: string;
	lat: number;
	lng: number;
	country: Country;
	name?: string;
	region?: string;
	city?: string;
	district?: string;
	street?: string;
	building?: string;
	type?: string;
	apartment?: string;
	office?: string;
	kitchen: Kitchen;
	address?: string;
	customDeliveryTime?: string;
};

export type AddressTicketDataFieldsFragment = {
	newDeliveryTime?: string;
	oldAddress?: {
		id: string;
		lat: number;
		lng: number;
		country: Country;
		name?: string;
		region?: string;
		city?: string;
		district?: string;
		street?: string;
		building?: string;
		type?: string;
		apartment?: string;
		office?: string;
		kitchen: Kitchen;
		address?: string;
		customDeliveryTime?: string;
	};
	oldDriver?: { id?: string; name?: string; code?: string };
	newAddress?: {
		id: string;
		lat: number;
		lng: number;
		country: Country;
		name?: string;
		region?: string;
		city?: string;
		district?: string;
		street?: string;
		building?: string;
		type?: string;
		apartment?: string;
		office?: string;
		kitchen: Kitchen;
		address?: string;
		customDeliveryTime?: string;
	};
	newDriver?: { id?: string; name?: string; code?: string };
};

export type ManualTicketDataFieldsFragment = {
	cutlery?: boolean;
	coolerBag?: boolean;
	foods?: Array<{
		id: string;
		foodType?: Array<FoodType>;
		size: string;
		positionIndex?: number;
		slug?: string;
		name: { ar: string; en: string };
		actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
	}>;
	address?: {
		id: string;
		lat: number;
		lng: number;
		country: Country;
		name?: string;
		region?: string;
		city?: string;
		district?: string;
		street?: string;
		building?: string;
		type?: string;
		apartment?: string;
		office?: string;
		kitchen: Kitchen;
		address?: string;
		customDeliveryTime?: string;
	};
};

export type OtherTicketDataFieldsFragment = { cutlery?: boolean; coolerBag?: boolean };

export type DeliveryTicketDataFieldsFragment = {
	type?: string;
	deliveryId: string;
	deliveryTime?: string;
	deliveryShortId: string;
	deliveryOldShortId?: string;
	userId: string;
	userName?: string;
	phoneNumber?: string;
	comment?: string;
	autoApprove?: boolean;
	mealTicketData?: Array<{
		oldFood?: {
			id: string;
			foodType?: Array<FoodType>;
			size: string;
			positionIndex?: number;
			slug?: string;
			name: { ar: string; en: string };
			actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
		};
		newFood?: {
			id: string;
			foodType?: Array<FoodType>;
			size: string;
			positionIndex?: number;
			slug?: string;
			name: { ar: string; en: string };
			actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
		};
	}>;
	addressTicketData?: {
		newDeliveryTime?: string;
		oldAddress?: {
			id: string;
			lat: number;
			lng: number;
			country: Country;
			name?: string;
			region?: string;
			city?: string;
			district?: string;
			street?: string;
			building?: string;
			type?: string;
			apartment?: string;
			office?: string;
			kitchen: Kitchen;
			address?: string;
			customDeliveryTime?: string;
		};
		oldDriver?: { id?: string; name?: string; code?: string };
		newAddress?: {
			id: string;
			lat: number;
			lng: number;
			country: Country;
			name?: string;
			region?: string;
			city?: string;
			district?: string;
			street?: string;
			building?: string;
			type?: string;
			apartment?: string;
			office?: string;
			kitchen: Kitchen;
			address?: string;
			customDeliveryTime?: string;
		};
		newDriver?: { id?: string; name?: string; code?: string };
	};
	manualTicketData?: {
		cutlery?: boolean;
		coolerBag?: boolean;
		foods?: Array<{
			id: string;
			foodType?: Array<FoodType>;
			size: string;
			positionIndex?: number;
			slug?: string;
			name: { ar: string; en: string };
			actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
		}>;
		address?: {
			id: string;
			lat: number;
			lng: number;
			country: Country;
			name?: string;
			region?: string;
			city?: string;
			district?: string;
			street?: string;
			building?: string;
			type?: string;
			apartment?: string;
			office?: string;
			kitchen: Kitchen;
			address?: string;
			customDeliveryTime?: string;
		};
	};
	otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
	driver?: { id?: string; name?: string; code?: string };
};

export type DeliveryTicketFieldsFragment = {
	id: string;
	date?: string;
	resolvedAt?: string;
	downloadedAt?: string;
	kitchen: Kitchen;
	zone?: string;
	country: Country;
	brand: Brand;
	status?: DeliveryTicketStatus;
	createdAt?: string;
	data?: {
		type?: string;
		deliveryId: string;
		deliveryTime?: string;
		deliveryShortId: string;
		deliveryOldShortId?: string;
		userId: string;
		userName?: string;
		phoneNumber?: string;
		comment?: string;
		autoApprove?: boolean;
		mealTicketData?: Array<{
			oldFood?: {
				id: string;
				foodType?: Array<FoodType>;
				size: string;
				positionIndex?: number;
				slug?: string;
				name: { ar: string; en: string };
				actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
			};
			newFood?: {
				id: string;
				foodType?: Array<FoodType>;
				size: string;
				positionIndex?: number;
				slug?: string;
				name: { ar: string; en: string };
				actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
			};
		}>;
		addressTicketData?: {
			newDeliveryTime?: string;
			oldAddress?: {
				id: string;
				lat: number;
				lng: number;
				country: Country;
				name?: string;
				region?: string;
				city?: string;
				district?: string;
				street?: string;
				building?: string;
				type?: string;
				apartment?: string;
				office?: string;
				kitchen: Kitchen;
				address?: string;
				customDeliveryTime?: string;
			};
			oldDriver?: { id?: string; name?: string; code?: string };
			newAddress?: {
				id: string;
				lat: number;
				lng: number;
				country: Country;
				name?: string;
				region?: string;
				city?: string;
				district?: string;
				street?: string;
				building?: string;
				type?: string;
				apartment?: string;
				office?: string;
				kitchen: Kitchen;
				address?: string;
				customDeliveryTime?: string;
			};
			newDriver?: { id?: string; name?: string; code?: string };
		};
		manualTicketData?: {
			cutlery?: boolean;
			coolerBag?: boolean;
			foods?: Array<{
				id: string;
				foodType?: Array<FoodType>;
				size: string;
				positionIndex?: number;
				slug?: string;
				name: { ar: string; en: string };
				actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
			}>;
			address?: {
				id: string;
				lat: number;
				lng: number;
				country: Country;
				name?: string;
				region?: string;
				city?: string;
				district?: string;
				street?: string;
				building?: string;
				type?: string;
				apartment?: string;
				office?: string;
				kitchen: Kitchen;
				address?: string;
				customDeliveryTime?: string;
			};
		};
		otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
		driver?: { id?: string; name?: string; code?: string };
	};
	createdBy?: { id?: string; name: string; email: string };
	resolvedBy?: { id?: string; name: string; email: string };
};

export type ListDeliveryTicketsResponseFieldsFragment = {
	data?: Array<{
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	}>;
};

export type ComplaintUserFieldsFragment = { userId: string; name?: string; email?: string; phoneNumber?: string };

export type ComplaintMealFieldsFragment = { id?: string; name?: string; size?: string; slug?: string };

export type ComplaintCommentFieldsFragment = { id: string; name?: string; text?: string };

export type ComplaintFieldsFragment = {
	id: string;
	category: string;
	type: string;
	date: string;
	kitchen: Kitchen;
	resolvedAt?: string;
	reviewedAt?: string;
	attachment?: boolean;
	attachments?: Array<string>;
	operationsId?: string;
	createdDate?: string;
	createdAt?: string;
	status?: ComplaintStatus;
	deliveryTime?: string;
	deliveryId: string;
	resolvedBy?: { id?: string; name: string; email: string };
	reviewedBy?: { id?: string; name: string; email: string };
	createdBy?: { id?: string; name: string; email: string };
	user: { userId: string; name?: string; email?: string; phoneNumber?: string };
	meal?: { id?: string; name?: string; size?: string; slug?: string };
	comments?: Array<{ id: string; name?: string; text?: string }>;
	driver?: { id?: string; name?: string; code?: string };
};

export type ListComplaintsResponseFieldsFragment = {
	total?: number;
	data?: Array<{
		id: string;
		category: string;
		type: string;
		date: string;
		kitchen: Kitchen;
		resolvedAt?: string;
		reviewedAt?: string;
		attachment?: boolean;
		attachments?: Array<string>;
		operationsId?: string;
		createdDate?: string;
		createdAt?: string;
		status?: ComplaintStatus;
		deliveryTime?: string;
		deliveryId: string;
		resolvedBy?: { id?: string; name: string; email: string };
		reviewedBy?: { id?: string; name: string; email: string };
		createdBy?: { id?: string; name: string; email: string };
		user: { userId: string; name?: string; email?: string; phoneNumber?: string };
		meal?: { id?: string; name?: string; size?: string; slug?: string };
		comments?: Array<{ id: string; name?: string; text?: string }>;
		driver?: { id?: string; name?: string; code?: string };
	}>;
};

export type GiftItemDataFieldsFragment = { id: string; slug?: string; size?: string; name?: { ar: string; en: string } };

export type GiftItemFieldsFragment = {
	id: string;
	type?: GiftItemType;
	amount?: number;
	item?: { id: string; slug?: string; size?: string; name?: { ar: string; en: string } };
};

export type GiftFieldsFragment = {
	id: string;
	kitchen: Kitchen;
	userId: string;
	userName?: string;
	phoneNumber?: string;
	zone?: string;
	date?: string;
	type?: Array<GiftItemType>;
	deliveryId: string;
	deliveryTime?: string;
	comment?: string;
	attachments?: Array<string>;
	createdAt?: string;
	updatedAt?: string;
	items?: Array<{
		id: string;
		type?: GiftItemType;
		amount?: number;
		item?: { id: string; slug?: string; size?: string; name?: { ar: string; en: string } };
	}>;
	createdBy?: { id?: string; name: string; email: string };
};

export type ListGiftsResponseFieldsFragment = {
	next?: string;
	data?: Array<{
		id: string;
		kitchen: Kitchen;
		userId: string;
		userName?: string;
		phoneNumber?: string;
		zone?: string;
		date?: string;
		type?: Array<GiftItemType>;
		deliveryId: string;
		deliveryTime?: string;
		comment?: string;
		attachments?: Array<string>;
		createdAt?: string;
		updatedAt?: string;
		items?: Array<{
			id: string;
			type?: GiftItemType;
			amount?: number;
			item?: { id: string; slug?: string; size?: string; name?: { ar: string; en: string } };
		}>;
		createdBy?: { id?: string; name: string; email: string };
	}>;
};

export type RoutPlanDriverFieldsFragment = { id: string; driverName: string; phoneNumber: string; email: string };

export type DriverDispatchFieldsFragment = {
	id: string;
	caloBags?: number;
	mealoBags?: number;
	vanTemperature?: number;
	departureTime?: string;
	driver: { id: string; driverName: string; phoneNumber: string; email: string };
};

export type ComponentsQuantityFieldsFragment = { id: string; quantity?: number };

export type FoodSizeFieldsFragment = {
	id: string;
	size: string;
	quantity?: number;
	extraMeals?: number;
	componentsCost?: number;
	purchasingCost?: number;
	components?: Array<{ id: string; quantity?: number }>;
};

export type PausedIntervalFieldsFragment = { pausedAt: string; resumedAt?: string };

export type ProcessTimeFieldsFragment = { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };

export type PortioningStatusFieldsFragment = {
	session?: string;
	readyForAssemblyTime?: string;
	readyForPortioningTime?: string;
	endTime?: string;
	assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
	portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
};

export type FoodFieldsFragment = {
	id: string;
	tk?: string;
	count?: number;
	size?: string;
	componentIds?: Array<string>;
	date?: string;
	country?: Country;
	kitchen?: Kitchen;
	isAssemblyRequired?: boolean;
	isCustom?: boolean;
	type?: Array<FoodType>;
	tags?: Array<string>;
	slug?: string;
	deliveryTime?: Array<string>;
	brand?: Brand;
	portioningURL?: string;
	assemblyURL?: string;
	name: { ar: string; en: string };
	sizes?: Array<{
		id: string;
		size: string;
		quantity?: number;
		extraMeals?: number;
		componentsCost?: number;
		purchasingCost?: number;
		components?: Array<{ id: string; quantity?: number }>;
	}>;
	portioningStatus?: Array<{
		session?: string;
		readyForAssemblyTime?: string;
		readyForPortioningTime?: string;
		endTime?: string;
		assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
	}>;
};

export type ListFoodsResponseFieldsFragment = {
	data?: Array<{
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
};

export type MetaFieldsFragment = { page?: number; limit?: number; total?: number };

export type ListFoodsByFiltersResponseFieldsFragment = {
	data?: Array<{
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
	meta?: { page?: number; limit?: number; total?: number };
};

export type CookingStationsFieldsFragment = {
	name: CookingStationName;
	status?: FCStatus;
	endTime?: string;
	processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
};

export type KitchenWastageInputsFieldsFragment = {
	preppedWeight?: number;
	chilledWeight?: number;
	wastage?: number;
	action?: string;
};

export type fcIngredientFieldsFragment = { id: string; quantity?: number };

export type FoodComponentMethodStepFieldsFragment = { description?: string; attachment?: string; station?: string };

export type LinkedComponentFieldsFragment = { id: string; quantity?: number };

export type CupSizesFieldsFragment = { size: number; value: number };

export type CupsQuantityFieldsFragment = { cup: number; quantity: number };

export type ComponentShortageFieldsFragment = {
	id: string;
	foodId: string;
	foodSize: string;
	weight?: number;
	createdAt?: string;
	foodName: { ar: string; en: string };
	cookingStations?: Array<{
		name: CookingStationName;
		status?: FCStatus;
		endTime?: string;
		processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
	}>;
};

export type ChildFieldsFragment = { count?: number; weight?: number };

export type FoodComponentQualityStandardFieldsFragment = {
	id: string;
	name: string;
	description?: string;
	imageURLs?: Array<string>;
};

export type FoodComponentFieldsFragment = {
	id: string;
	tk?: string;
	count?: number;
	weight?: number;
	cookedWeight?: number;
	measurementUnit: MeasurementUnit;
	date?: string;
	country?: Country;
	kitchen?: Kitchen;
	brand?: Brand;
	foodsIds?: Array<string>;
	cost?: number;
	quantities?: Array<number>;
	quantity?: number;
	purchasingCost?: number;
	isVerified?: boolean;
	name: { ar: string; en: string };
	cookingStations?: Array<{
		name: CookingStationName;
		status?: FCStatus;
		endTime?: string;
		processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
	}>;
	kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
	ingredients?: Array<{ id: string; quantity?: number }>;
	method?: Array<{ description?: string; attachment?: string; station?: string }>;
	childComponents?: Array<{ id: string; quantity?: number }>;
	cups?: Array<{ size: number; value: number }>;
	cupsQuantity?: Array<{ cup: number; quantity: number }>;
	shortages?: Array<{
		id: string;
		foodId: string;
		foodSize: string;
		weight?: number;
		createdAt?: string;
		foodName: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
	child?: { count?: number; weight?: number };
	standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
	verifiedBy?: { id?: string; name: string; email: string };
};

export type ListFoodComponentsResponseFieldsFragment = {
	data?: Array<{
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	}>;
};

export type IngredientFieldsFragment = {
	id: string;
	quantity?: number;
	wastage?: number;
	kitchen?: Kitchen;
	date?: string;
	brand?: Brand;
	measurementUnit: MeasurementUnit;
	internalName?: string;
	purchasingCost?: number;
	header?: string;
	weight?: number;
	name: { ar: string; en: string };
};

export type SingleFoodComponentFieldsFragment = {
	id: string;
	tk?: string;
	count?: number;
	weight?: number;
	cookedWeight?: number;
	cookedRawFactor?: number;
	measurementUnit: MeasurementUnit;
	country?: Country;
	kitchen?: Kitchen;
	brand?: Brand;
	foodsIds?: Array<string>;
	cost?: number;
	quantities?: Array<number>;
	name: { ar: string; en: string };
	cookingStations?: Array<{
		name: CookingStationName;
		status?: FCStatus;
		endTime?: string;
		processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
	}>;
	kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
	ingredients?: Array<{
		id: string;
		quantity?: number;
		wastage?: number;
		kitchen?: Kitchen;
		date?: string;
		brand?: Brand;
		measurementUnit: MeasurementUnit;
		internalName?: string;
		purchasingCost?: number;
		header?: string;
		weight?: number;
		name: { ar: string; en: string };
	}>;
	method?: Array<{ description?: string; attachment?: string; station?: string }>;
	childComponents?: Array<{
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	}>;
	cups?: Array<{ size: number; value: number }>;
	cupsQuantity?: Array<{ cup: number; quantity: number }>;
	standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
};

export type ListFoodComponentByRangeResponseFieldsFragment = {
	next?: string;
	components?: Array<{
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	}>;
};

export type KDSKitchenFieldsFragment = { id: string; sessions?: string };

export type ListKitchensResponseFieldsFragment = { data?: Array<{ id: string; sessions?: string }> };

export type ModifiedDeliveryIdsFieldsFragment = { downloadSequence?: number; deliveryIds?: Array<string> };

export type ShoppingMenuFoodFieldsFragment = {
	id: string;
	order?: number;
	isDone?: boolean;
	isCustom?: boolean;
	type?: Array<FoodType>;
	size: string;
	portioningURL?: string;
	name: { ar: string; en: string };
	modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
};

export type ShoppingMenuFieldsFragment = {
	id: string;
	day?: string;
	country?: Country;
	kitchen: Kitchen;
	food?: Array<{
		id: string;
		order?: number;
		isDone?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		size: string;
		portioningURL?: string;
		name: { ar: string; en: string };
		modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
	}>;
};

export type QualityMetricFoodFieldsFragment = {
	id: string;
	cacheKey?: string;
	componentsIds?: Array<string>;
	name: { ar: string; en: string };
};

export type QualityMetricsFoodComponentFieldsFragment = {
	id: string;
	cacheKey?: string;
	cost?: number;
	weight?: number;
	lastCookingStation?: CookingStationName;
	consistencyScore?: number;
	numberOfRejected?: number;
	totalReviewed?: number;
	weightOfRejected?: number;
	cookedWeight?: number;
	name: { ar: string; en: string };
};

export type QualityMetricFieldsFragment = {
	id: string;
	day?: string;
	kitchen: Kitchen;
	shift: Session;
	isFinalized?: boolean;
	food?: Array<{ id: string; cacheKey?: string; componentsIds?: Array<string>; name: { ar: string; en: string } }>;
	components?: Array<{
		id: string;
		cacheKey?: string;
		cost?: number;
		weight?: number;
		lastCookingStation?: CookingStationName;
		consistencyScore?: number;
		numberOfRejected?: number;
		totalReviewed?: number;
		weightOfRejected?: number;
		cookedWeight?: number;
		name: { ar: string; en: string };
	}>;
};

export type ListQualityMetricsResponseFieldsFragment = {
	data?: Array<{
		id: string;
		day?: string;
		kitchen: Kitchen;
		shift: Session;
		isFinalized?: boolean;
		food?: Array<{ id: string; cacheKey?: string; componentsIds?: Array<string>; name: { ar: string; en: string } }>;
		components?: Array<{
			id: string;
			cacheKey?: string;
			cost?: number;
			weight?: number;
			lastCookingStation?: CookingStationName;
			consistencyScore?: number;
			numberOfRejected?: number;
			totalReviewed?: number;
			weightOfRejected?: number;
			cookedWeight?: number;
			name: { ar: string; en: string };
		}>;
	}>;
};

export type FoodMetricsFieldsFragment = {
	cacheKey?: string;
	shift: Session;
	numberOfFoods?: number;
	numberOfModified?: number;
	portioningTime?: number;
	predictedPortioningTimePerMeal?: number;
};

export type ProductivityMetricsFoodFieldsFragment = {
	id: string;
	cacheKey?: string;
	numberOfComponents?: number;
	type?: Array<FoodType>;
	tags?: Array<string>;
	isSandwich?: boolean;
	name: { ar: string; en: string };
	metrics?: Array<{
		cacheKey?: string;
		shift: Session;
		numberOfFoods?: number;
		numberOfModified?: number;
		portioningTime?: number;
		predictedPortioningTimePerMeal?: number;
	}>;
};

export type ProductivityMetricsFieldsFragment = {
	id: string;
	day: string;
	kitchen: Kitchen;
	foods?: Array<{
		id: string;
		cacheKey?: string;
		numberOfComponents?: number;
		type?: Array<FoodType>;
		tags?: Array<string>;
		isSandwich?: boolean;
		name: { ar: string; en: string };
		metrics?: Array<{
			cacheKey?: string;
			shift: Session;
			numberOfFoods?: number;
			numberOfModified?: number;
			portioningTime?: number;
			predictedPortioningTimePerMeal?: number;
		}>;
	}>;
};

export type ListProductivityMetricsResponseFieldsFragment = {
	data?: Array<{
		id: string;
		day: string;
		kitchen: Kitchen;
		foods?: Array<{
			id: string;
			cacheKey?: string;
			numberOfComponents?: number;
			type?: Array<FoodType>;
			tags?: Array<string>;
			isSandwich?: boolean;
			name: { ar: string; en: string };
			metrics?: Array<{
				cacheKey?: string;
				shift: Session;
				numberOfFoods?: number;
				numberOfModified?: number;
				portioningTime?: number;
				predictedPortioningTimePerMeal?: number;
			}>;
		}>;
	}>;
};

export type DeleteComponentBatchResponseFieldsFragment = { message?: string };

export type ExportDocumentsResponseFieldsFragment = { data?: Array<string> };

export type ScanShoppingDeliveryResponseFieldsFragment = {
	id: string;
	name?: string;
	day?: string;
	kitchen: Kitchen;
	brand?: Brand;
	food?: Array<{
		id: string;
		order?: number;
		isDone?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		size: string;
		portioningURL?: string;
		name: { ar: string; en: string };
		modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
	}>;
};

export type RangeFieldsFragment = { gte?: string; lte?: string };

export type ListResponseMetaFieldsFragment = { total?: number };

export type AddBaggingDispatchMutationVariables = Exact<{
	input: BaggingDispatchInput;
}>;

export type AddBaggingDispatchMutation = {
	addBaggingDispatch?: {
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	};
};

export type ApproveDeliveryTicketMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<DeliveryTicketType>;
	kitchen?: InputMaybe<Kitchen>;
	user?: InputMaybe<UserInput>;
}>;

export type ApproveDeliveryTicketMutation = {
	approveDeliveryTicket?: {
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	};
};

export type BatchCreateComponentShortageMutationVariables = Exact<{
	input?: InputMaybe<Array<InputMaybe<ComponentShortageInput>> | InputMaybe<ComponentShortageInput>>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
}>;

export type BatchCreateComponentShortageMutation = {
	batchCreateComponentShortage?: Array<{
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	}>;
};

export type BatchUpdateFoodsPortioningStatusMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	foodsIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type BatchUpdateFoodsPortioningStatusMutation = {
	batchUpdateFoodsPortioningStatus?: Array<{
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
};

export type CreateChefMutationVariables = Exact<{
	data?: InputMaybe<ChefInput>;
}>;

export type CreateChefMutation = {
	createChef?: {
		id: string;
		name: string;
		session: Session;
		stations?: Array<FoodComponentStation>;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		deletedAt?: string;
	};
};

export type CreateComponentBatchMutationVariables = Exact<{
	data?: InputMaybe<ComponentBatchInput>;
}>;

export type CreateComponentBatchMutation = {
	createComponentBatch?: {
		id: string;
		date: string;
		session: Session;
		batchNumber: number;
		foodComponentId: string;
		chef?: string;
		measurementUnit: MeasurementUnit;
		weight?: number;
		deletedAt?: string;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		quality?: Array<{
			id?: string;
			createdAt?: string;
			comment?: string;
			status?: QualityCheckStatus;
			commentImageURLs?: Array<string>;
			actor?: { id?: string; name: string; email: string };
			standards?: Array<{ id: string; name: string; rating: QualityRating }>;
		}>;
		deletedBy?: { id?: string; name: string; email: string };
	};
};

export type DeleteChefMutationVariables = Exact<{
	id: Scalars['String']['input'];
	kitchen: Kitchen;
}>;

export type DeleteChefMutation = {
	deleteChef?: {
		id: string;
		name: string;
		session: Session;
		stations?: Array<FoodComponentStation>;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		deletedAt?: string;
	};
};

export type DeleteComponentBatchMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteComponentBatchMutation = { deleteComponentBatch?: { message?: string } };

export type DeliveryTicketCreatedMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
}>;

export type DeliveryTicketCreatedMutation = {
	deliveryTicketCreated?: {
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	};
};

export type ExportComplaintListMutationVariables = Exact<{
	filters?: InputMaybe<ComplaintsFiltersInput>;
}>;

export type ExportComplaintListMutation = { exportComplaintList?: { data?: string } };

export type ExportComponentBatchMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<RangeInput>;
	shift?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportComponentBatchMutation = { exportComponentBatch?: { data?: string } };

export type ExportDocumentsMutationVariables = Exact<{
	ids: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
	docTypes: Array<InputMaybe<DocsType>> | InputMaybe<DocsType>;
	day?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportDocumentsMutation = { exportDocuments?: { data?: Array<string> } };

export type ExportFoodComponentShortageMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportFoodComponentShortageMutation = { exportFoodComponentShortage?: { data?: string } };

export type ExportFoodComponentStatusMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportFoodComponentStatusMutation = { exportFoodComponentStatus?: { data?: string } };

export type ExportFoodComponentWastageMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportFoodComponentWastageMutation = { exportFoodComponentWastage?: { data?: string } };

export type ExportFoodLabelsMutationVariables = Exact<{
	input?: InputMaybe<ExportFoodLabelsReq>;
}>;

export type ExportFoodLabelsMutation = { exportFoodLabels?: { data?: Array<string> } };

export type ExportFoodPortioningStatusMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	showCustom?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ExportFoodPortioningStatusMutation = { exportFoodPortioningStatus?: { data?: string } };

export type ExportMealInvoicesMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shoppingMenuFoodSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportMealInvoicesMutation = { exportMealInvoices?: { data?: Array<string> } };

export type ExportQualityMetricsMutationVariables = Exact<{
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Session>;
	type?: InputMaybe<ExportMetricsTypeEnum>;
	stations?: InputMaybe<Array<InputMaybe<CookingStationName>> | InputMaybe<CookingStationName>>;
}>;

export type ExportQualityMetricsMutation = { exportQualityMetrics?: { data?: string } };

export type GetImageUploadURLMutationVariables = Exact<{
	path?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetImageUploadURLMutation = { getImageUploadURL?: { data?: string } };

export type ModifyBaggingDispatchMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	input: BaggingDispatchInput;
}>;

export type ModifyBaggingDispatchMutation = {
	modifyBaggingDispatch?: {
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	};
};

export type ModifyBaggingSessionMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	input: BaggingSessionInput;
}>;

export type ModifyBaggingSessionMutation = {
	modifyBaggingSession?: {
		id: string;
		tk?: string;
		date: string;
		kitchen: Kitchen;
		startTime?: string;
		endTime?: string;
		roomTemperature?: number;
		session?: string;
		status?: SessionStatus;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	};
};

export type RejectDeliveryTicketMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<DeliveryTicketType>;
	kitchen?: InputMaybe<Kitchen>;
	user?: InputMaybe<UserInput>;
}>;

export type RejectDeliveryTicketMutation = {
	rejectDeliveryTicket?: {
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	};
};

export type ResolveComplaintMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	status?: InputMaybe<ComplaintStatus>;
	kitchen?: InputMaybe<Kitchen>;
	user?: InputMaybe<UserInput>;
}>;

export type ResolveComplaintMutation = {
	resolveComplaint?: {
		id: string;
		category: string;
		type: string;
		date: string;
		kitchen: Kitchen;
		resolvedAt?: string;
		reviewedAt?: string;
		attachment?: boolean;
		attachments?: Array<string>;
		operationsId?: string;
		createdDate?: string;
		createdAt?: string;
		status?: ComplaintStatus;
		deliveryTime?: string;
		deliveryId: string;
		resolvedBy?: { id?: string; name: string; email: string };
		reviewedBy?: { id?: string; name: string; email: string };
		createdBy?: { id?: string; name: string; email: string };
		user: { userId: string; name?: string; email?: string; phoneNumber?: string };
		meal?: { id?: string; name?: string; size?: string; slug?: string };
		comments?: Array<{ id: string; name?: string; text?: string }>;
		driver?: { id?: string; name?: string; code?: string };
	};
};

export type ScanShoppingDeliveryMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
}>;

export type ScanShoppingDeliveryMutation = {
	scanShoppingDelivery?: {
		id: string;
		name?: string;
		day?: string;
		kitchen: Kitchen;
		brand?: Brand;
		food?: Array<{
			id: string;
			order?: number;
			isDone?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			size: string;
			portioningURL?: string;
			name: { ar: string; en: string };
			modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
		}>;
	};
};

export type StartBaggingSessionMutationVariables = Exact<{
	input: BaggingSessionInput;
}>;

export type StartBaggingSessionMutation = {
	startBaggingSession?: {
		id: string;
		tk?: string;
		date: string;
		kitchen: Kitchen;
		startTime?: string;
		endTime?: string;
		roomTemperature?: number;
		session?: string;
		status?: SessionStatus;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	};
};

export type UpdateChefMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<ChefInput>;
}>;

export type UpdateChefMutation = {
	updateChef?: {
		id: string;
		name: string;
		session: Session;
		stations?: Array<FoodComponentStation>;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		deletedAt?: string;
	};
};

export type UpdateComponentBatchMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<ComponentBatchInput>;
}>;

export type UpdateComponentBatchMutation = {
	updateComponentBatch?: {
		id: string;
		date: string;
		session: Session;
		batchNumber: number;
		foodComponentId: string;
		chef?: string;
		measurementUnit: MeasurementUnit;
		weight?: number;
		deletedAt?: string;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		quality?: Array<{
			id?: string;
			createdAt?: string;
			comment?: string;
			status?: QualityCheckStatus;
			commentImageURLs?: Array<string>;
			actor?: { id?: string; name: string; email: string };
			standards?: Array<{ id: string; name: string; rating: QualityRating }>;
		}>;
		deletedBy?: { id?: string; name: string; email: string };
	};
};

export type UpdateDeliveryTicketsDownloadedAtMutationVariables = Exact<{
	ids: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
	kitchen: Kitchen;
}>;

export type UpdateDeliveryTicketsDownloadedAtMutation = {
	updateDeliveryTicketsDownloadedAt?: Array<{
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	}>;
};

export type UpdateDriverDispatchMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	input?: InputMaybe<DriverDispatchInput>;
}>;

export type UpdateDriverDispatchMutation = {
	updateDriverDispatch?: {
		id: string;
		caloBags?: number;
		mealoBags?: number;
		vanTemperature?: number;
		departureTime?: string;
		driver: { id: string; driverName: string; phoneNumber: string; email: string };
	};
};

export type UpdateExtraMealsMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	data?: InputMaybe<Array<InputMaybe<UpdateExtraMealsInput>> | InputMaybe<UpdateExtraMealsInput>>;
}>;

export type UpdateExtraMealsMutation = {
	updateExtraMeals?: Array<{
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
};

export type UpdateFCWastageInputsMutationVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	id?: InputMaybe<Scalars['String']['input']>;
	inputs?: InputMaybe<FCWastageInputs>;
}>;

export type UpdateFCWastageInputsMutation = {
	updateFCWastageInputs?: {
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	};
};

export type UpdateFoodComponentStatusMutationVariables = Exact<{
	kitchen: Kitchen;
	id: Scalars['String']['input'];
	station: CookingStationName;
	operation: FoodComponentStationOperation;
	shortagesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	shift?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateFoodComponentStatusMutation = {
	updateFoodComponentStatus?: {
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	};
};

export type UpdateFoodPortioningStatusMutationVariables = Exact<{
	kitchen: Kitchen;
	id: Scalars['String']['input'];
	session?: InputMaybe<Scalars['String']['input']>;
	operation: UpdateFoodPortioningStatusOperation;
}>;

export type UpdateFoodPortioningStatusMutation = {
	updateFoodPortioningStatus?: {
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	};
};

export type UpdateShoppingMenuMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	data?: InputMaybe<UpdateShoppingMenuInput>;
}>;

export type UpdateShoppingMenuMutation = {
	updateShoppingMenu?: {
		id: string;
		day?: string;
		country?: Country;
		kitchen: Kitchen;
		food?: Array<{
			id: string;
			order?: number;
			isDone?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			size: string;
			portioningURL?: string;
			name: { ar: string; en: string };
			modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
		}>;
	};
};

export type VerifyComponentMutationVariables = Exact<{
	input?: InputMaybe<VerifyComponentInput>;
	kitchen?: InputMaybe<Kitchen>;
}>;

export type VerifyComponentMutation = {
	verifyComponent?: {
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	};
};

export type BaggingStatusQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<Scalars['String']['input']>;
}>;

export type BaggingStatusQuery = {
	baggingStatus?: {
		kitchen: string;
		date: string;
		sessions?: Array<{
			id: string;
			tk?: string;
			date: string;
			kitchen: Kitchen;
			startTime?: string;
			endTime?: string;
			roomTemperature?: number;
			session?: string;
			status?: SessionStatus;
			dispatches?: Array<{
				id: string;
				tk?: string;
				session?: Session;
				foodId: string;
				foodSize: string;
				foodType?: Array<FoodType>;
				foodCost?: number;
				total?: number;
				actual?: number;
				extra?: number;
				modified?: number;
				leftovers?: number;
				startTemperature?: number;
				endTemperature?: number;
				action?: string;
				purchasingCost?: number;
				isCustomFood?: boolean;
				foodName: { ar: string; en: string };
				packages?: Array<{ id: string; name?: string }>;
			}>;
		}>;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	};
};

export type ExportBaggingSessionQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	shift?: InputMaybe<Scalars['String']['input']>;
	actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	fileType?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportBaggingSessionQuery = { exportBaggingSession?: { data?: string } };

export type GetFoodComponentQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFoodComponentQuery = {
	getFoodComponent?: {
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		cookedRawFactor?: number;
		measurementUnit: MeasurementUnit;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{
			id: string;
			quantity?: number;
			wastage?: number;
			kitchen?: Kitchen;
			date?: string;
			brand?: Brand;
			measurementUnit: MeasurementUnit;
			internalName?: string;
			purchasingCost?: number;
			header?: string;
			weight?: number;
			name: { ar: string; en: string };
		}>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{
			id: string;
			tk?: string;
			count?: number;
			weight?: number;
			cookedWeight?: number;
			measurementUnit: MeasurementUnit;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			brand?: Brand;
			foodsIds?: Array<string>;
			cost?: number;
			quantities?: Array<number>;
			quantity?: number;
			purchasingCost?: number;
			isVerified?: boolean;
			name: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
			kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
			ingredients?: Array<{ id: string; quantity?: number }>;
			method?: Array<{ description?: string; attachment?: string; station?: string }>;
			childComponents?: Array<{ id: string; quantity?: number }>;
			cups?: Array<{ size: number; value: number }>;
			cupsQuantity?: Array<{ cup: number; quantity: number }>;
			shortages?: Array<{
				id: string;
				foodId: string;
				foodSize: string;
				weight?: number;
				createdAt?: string;
				foodName: { ar: string; en: string };
				cookingStations?: Array<{
					name: CookingStationName;
					status?: FCStatus;
					endTime?: string;
					processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				}>;
			}>;
			child?: { count?: number; weight?: number };
			standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
			verifiedBy?: { id?: string; name: string; email: string };
		}>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
	};
};

export type GetShoppingMenuQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
}>;

export type GetShoppingMenuQuery = {
	getShoppingMenu?: {
		id: string;
		day?: string;
		country?: Country;
		kitchen: Kitchen;
		food?: Array<{
			id: string;
			order?: number;
			isDone?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			size: string;
			portioningURL?: string;
			name: { ar: string; en: string };
			modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
		}>;
	};
};

export type ListBaggingDispatchQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<Scalars['String']['input']>;
	session?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListBaggingDispatchQuery = {
	listBaggingDispatch?: Array<{
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	}>;
};

export type ListBaggingSessionQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	shift?: InputMaybe<Scalars['String']['input']>;
	actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	next?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListBaggingSessionQuery = {
	listBaggingSession?: {
		next?: string;
		count?: number;
		scannedCount?: number;
		sessions?: Array<{
			id: string;
			tk?: string;
			date: string;
			kitchen: Kitchen;
			startTime?: string;
			endTime?: string;
			roomTemperature?: number;
			session?: string;
			status?: SessionStatus;
			dispatches?: Array<{
				id: string;
				tk?: string;
				session?: Session;
				foodId: string;
				foodSize: string;
				foodType?: Array<FoodType>;
				foodCost?: number;
				total?: number;
				actual?: number;
				extra?: number;
				modified?: number;
				leftovers?: number;
				startTemperature?: number;
				endTemperature?: number;
				action?: string;
				purchasingCost?: number;
				isCustomFood?: boolean;
				foodName: { ar: string; en: string };
				packages?: Array<{ id: string; name?: string }>;
			}>;
		}>;
	};
};

export type ListChefsQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
}>;

export type ListChefsQuery = {
	listChefs?: {
		data?: Array<{
			id: string;
			name: string;
			session: Session;
			stations?: Array<FoodComponentStation>;
			brand: Brand;
			kitchen: Kitchen;
			country: Country;
			deletedAt?: string;
		}>;
	};
};

export type ListComplaintsQueryVariables = Exact<{
	query?: InputMaybe<ListComplaintInput>;
}>;

export type ListComplaintsQuery = {
	listComplaints?: {
		total?: number;
		data?: Array<{
			id: string;
			category: string;
			type: string;
			date: string;
			kitchen: Kitchen;
			resolvedAt?: string;
			reviewedAt?: string;
			attachment?: boolean;
			attachments?: Array<string>;
			operationsId?: string;
			createdDate?: string;
			createdAt?: string;
			status?: ComplaintStatus;
			deliveryTime?: string;
			deliveryId: string;
			resolvedBy?: { id?: string; name: string; email: string };
			reviewedBy?: { id?: string; name: string; email: string };
			createdBy?: { id?: string; name: string; email: string };
			user: { userId: string; name?: string; email?: string; phoneNumber?: string };
			meal?: { id?: string; name?: string; size?: string; slug?: string };
			comments?: Array<{ id: string; name?: string; text?: string }>;
			driver?: { id?: string; name?: string; code?: string };
		}>;
	};
};

export type ListComponentBatchesQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<RangeInput>;
	shift?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListComponentBatchesQuery = {
	listComponentBatches?: {
		total?: number;
		data?: Array<{
			id: string;
			date: string;
			session: Session;
			batchNumber: number;
			foodComponentId: string;
			chef?: string;
			measurementUnit: MeasurementUnit;
			weight?: number;
			deletedAt?: string;
			brand: Brand;
			kitchen: Kitchen;
			country: Country;
			quality?: Array<{
				id?: string;
				createdAt?: string;
				comment?: string;
				status?: QualityCheckStatus;
				commentImageURLs?: Array<string>;
				actor?: { id?: string; name: string; email: string };
				standards?: Array<{ id: string; name: string; rating: QualityRating }>;
			}>;
			deletedBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListDeliveriesQueryVariables = Exact<{
	query?: InputMaybe<ListDeliveriesInput>;
}>;

export type ListDeliveriesQuery = {
	listDeliveries?: {
		total?: number;
		data?: Array<{
			id: string;
			userId: string;
			name: string;
			phoneNumber: string;
			kitchen: Kitchen;
			day: string;
			status: DeliveryStatus;
			skipped?: boolean;
			shortId?: string;
			brand?: Brand;
			downloadedDocs?: Array<DocsType>;
			time?: DeliveryTime;
			customDeliveryTime?: string;
			pickupTime?: string;
			food?: Array<{
				id: string;
				size: string;
				tags?: Array<string>;
				type?: Array<FoodType>;
				isCustom?: boolean;
				skipped?: boolean;
				isSwapped?: boolean;
				name: { ar: string; en: string };
			}>;
			driver?: { id?: string; name?: string; code?: string };
		}>;
	};
};

export type ListDeliveryTicketsQueryVariables = Exact<{
	query?: InputMaybe<listDeliveryTicketsInput>;
}>;

export type ListDeliveryTicketsQuery = {
	listDeliveryTickets?: {
		data?: Array<{
			id: string;
			date?: string;
			resolvedAt?: string;
			downloadedAt?: string;
			kitchen: Kitchen;
			zone?: string;
			country: Country;
			brand: Brand;
			status?: DeliveryTicketStatus;
			createdAt?: string;
			data?: {
				type?: string;
				deliveryId: string;
				deliveryTime?: string;
				deliveryShortId: string;
				deliveryOldShortId?: string;
				userId: string;
				userName?: string;
				phoneNumber?: string;
				comment?: string;
				autoApprove?: boolean;
				mealTicketData?: Array<{
					oldFood?: {
						id: string;
						foodType?: Array<FoodType>;
						size: string;
						positionIndex?: number;
						slug?: string;
						name: { ar: string; en: string };
						actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
					};
					newFood?: {
						id: string;
						foodType?: Array<FoodType>;
						size: string;
						positionIndex?: number;
						slug?: string;
						name: { ar: string; en: string };
						actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
					};
				}>;
				addressTicketData?: {
					newDeliveryTime?: string;
					oldAddress?: {
						id: string;
						lat: number;
						lng: number;
						country: Country;
						name?: string;
						region?: string;
						city?: string;
						district?: string;
						street?: string;
						building?: string;
						type?: string;
						apartment?: string;
						office?: string;
						kitchen: Kitchen;
						address?: string;
						customDeliveryTime?: string;
					};
					oldDriver?: { id?: string; name?: string; code?: string };
					newAddress?: {
						id: string;
						lat: number;
						lng: number;
						country: Country;
						name?: string;
						region?: string;
						city?: string;
						district?: string;
						street?: string;
						building?: string;
						type?: string;
						apartment?: string;
						office?: string;
						kitchen: Kitchen;
						address?: string;
						customDeliveryTime?: string;
					};
					newDriver?: { id?: string; name?: string; code?: string };
				};
				manualTicketData?: {
					cutlery?: boolean;
					coolerBag?: boolean;
					foods?: Array<{
						id: string;
						foodType?: Array<FoodType>;
						size: string;
						positionIndex?: number;
						slug?: string;
						name: { ar: string; en: string };
						actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
					}>;
					address?: {
						id: string;
						lat: number;
						lng: number;
						country: Country;
						name?: string;
						region?: string;
						city?: string;
						district?: string;
						street?: string;
						building?: string;
						type?: string;
						apartment?: string;
						office?: string;
						kitchen: Kitchen;
						address?: string;
						customDeliveryTime?: string;
					};
				};
				otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
				driver?: { id?: string; name?: string; code?: string };
			};
			createdBy?: { id?: string; name: string; email: string };
			resolvedBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListDriverDispatchesQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<Scalars['String']['input']>;
	deliveryTime?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListDriverDispatchesQuery = {
	listDriverDispatches?: Array<{
		id: string;
		caloBags?: number;
		mealoBags?: number;
		vanTemperature?: number;
		departureTime?: string;
		driver: { id: string; driverName: string; phoneNumber: string; email: string };
	}>;
};

export type ListFoodComponentByRangeQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	next?: InputMaybe<Scalars['String']['input']>;
	filters?: InputMaybe<ListFoodComponentByRangeFilters>;
}>;

export type ListFoodComponentByRangeQuery = {
	listFoodComponentByRange?: {
		next?: string;
		components?: Array<{
			id: string;
			tk?: string;
			count?: number;
			weight?: number;
			cookedWeight?: number;
			measurementUnit: MeasurementUnit;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			brand?: Brand;
			foodsIds?: Array<string>;
			cost?: number;
			quantities?: Array<number>;
			quantity?: number;
			purchasingCost?: number;
			isVerified?: boolean;
			name: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
			kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
			ingredients?: Array<{ id: string; quantity?: number }>;
			method?: Array<{ description?: string; attachment?: string; station?: string }>;
			childComponents?: Array<{ id: string; quantity?: number }>;
			cups?: Array<{ size: number; value: number }>;
			cupsQuantity?: Array<{ cup: number; quantity: number }>;
			shortages?: Array<{
				id: string;
				foodId: string;
				foodSize: string;
				weight?: number;
				createdAt?: string;
				foodName: { ar: string; en: string };
				cookingStations?: Array<{
					name: CookingStationName;
					status?: FCStatus;
					endTime?: string;
					processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				}>;
			}>;
			child?: { count?: number; weight?: number };
			standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
			verifiedBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListFoodComponentsByNameQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	brand?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListFoodComponentsByNameQuery = {
	listFoodComponentsByName?: {
		data?: Array<{
			id: string;
			tk?: string;
			count?: number;
			weight?: number;
			cookedWeight?: number;
			measurementUnit: MeasurementUnit;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			brand?: Brand;
			foodsIds?: Array<string>;
			cost?: number;
			quantities?: Array<number>;
			quantity?: number;
			purchasingCost?: number;
			isVerified?: boolean;
			name: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
			kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
			ingredients?: Array<{ id: string; quantity?: number }>;
			method?: Array<{ description?: string; attachment?: string; station?: string }>;
			childComponents?: Array<{ id: string; quantity?: number }>;
			cups?: Array<{ size: number; value: number }>;
			cupsQuantity?: Array<{ cup: number; quantity: number }>;
			shortages?: Array<{
				id: string;
				foodId: string;
				foodSize: string;
				weight?: number;
				createdAt?: string;
				foodName: { ar: string; en: string };
				cookingStations?: Array<{
					name: CookingStationName;
					status?: FCStatus;
					endTime?: string;
					processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				}>;
			}>;
			child?: { count?: number; weight?: number };
			standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
			verifiedBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListFoodComponentsQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<Scalars['String']['input']>;
	station?: InputMaybe<Scalars['String']['input']>;
	shift?: InputMaybe<Scalars['String']['input']>;
	brand?: InputMaybe<Scalars['String']['input']>;
	withShortages?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListFoodComponentsQuery = {
	listFoodComponents?: {
		data?: Array<{
			id: string;
			tk?: string;
			count?: number;
			weight?: number;
			cookedWeight?: number;
			measurementUnit: MeasurementUnit;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			brand?: Brand;
			foodsIds?: Array<string>;
			cost?: number;
			quantities?: Array<number>;
			quantity?: number;
			purchasingCost?: number;
			isVerified?: boolean;
			name: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
			kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
			ingredients?: Array<{ id: string; quantity?: number }>;
			method?: Array<{ description?: string; attachment?: string; station?: string }>;
			childComponents?: Array<{ id: string; quantity?: number }>;
			cups?: Array<{ size: number; value: number }>;
			cupsQuantity?: Array<{ cup: number; quantity: number }>;
			shortages?: Array<{
				id: string;
				foodId: string;
				foodSize: string;
				weight?: number;
				createdAt?: string;
				foodName: { ar: string; en: string };
				cookingStations?: Array<{
					name: CookingStationName;
					status?: FCStatus;
					endTime?: string;
					processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				}>;
			}>;
			child?: { count?: number; weight?: number };
			standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
			verifiedBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListFoodsByFiltersQueryVariables = Exact<{
	filters?: InputMaybe<FoodFiltersInput>;
	page?: InputMaybe<Scalars['Int']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ListFoodsByFiltersQuery = {
	listFoodsByFilters?: {
		data?: Array<{
			id: string;
			tk?: string;
			count?: number;
			size?: string;
			componentIds?: Array<string>;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			isAssemblyRequired?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			tags?: Array<string>;
			slug?: string;
			deliveryTime?: Array<string>;
			brand?: Brand;
			portioningURL?: string;
			assemblyURL?: string;
			name: { ar: string; en: string };
			sizes?: Array<{
				id: string;
				size: string;
				quantity?: number;
				extraMeals?: number;
				componentsCost?: number;
				purchasingCost?: number;
				components?: Array<{ id: string; quantity?: number }>;
			}>;
			portioningStatus?: Array<{
				session?: string;
				readyForAssemblyTime?: string;
				readyForPortioningTime?: string;
				endTime?: string;
				assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		meta?: { page?: number; limit?: number; total?: number };
	};
};

export type ListFoodsQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
	date?: InputMaybe<Scalars['String']['input']>;
	shift?: InputMaybe<Scalars['String']['input']>;
	brand?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListFoodsQuery = {
	listFoods?: {
		data?: Array<{
			id: string;
			tk?: string;
			count?: number;
			size?: string;
			componentIds?: Array<string>;
			date?: string;
			country?: Country;
			kitchen?: Kitchen;
			isAssemblyRequired?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			tags?: Array<string>;
			slug?: string;
			deliveryTime?: Array<string>;
			brand?: Brand;
			portioningURL?: string;
			assemblyURL?: string;
			name: { ar: string; en: string };
			sizes?: Array<{
				id: string;
				size: string;
				quantity?: number;
				extraMeals?: number;
				componentsCost?: number;
				purchasingCost?: number;
				components?: Array<{ id: string; quantity?: number }>;
			}>;
			portioningStatus?: Array<{
				session?: string;
				readyForAssemblyTime?: string;
				readyForPortioningTime?: string;
				endTime?: string;
				assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
				portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
	};
};

export type ListGiftsQueryVariables = Exact<{
	query?: InputMaybe<ListGiftsReqInput>;
}>;

export type ListGiftsQuery = {
	listGifts?: {
		next?: string;
		data?: Array<{
			id: string;
			kitchen: Kitchen;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			zone?: string;
			date?: string;
			type?: Array<GiftItemType>;
			deliveryId: string;
			deliveryTime?: string;
			comment?: string;
			attachments?: Array<string>;
			createdAt?: string;
			updatedAt?: string;
			items?: Array<{
				id: string;
				type?: GiftItemType;
				amount?: number;
				item?: { id: string; slug?: string; size?: string; name?: { ar: string; en: string } };
			}>;
			createdBy?: { id?: string; name: string; email: string };
		}>;
	};
};

export type ListKitchensQueryVariables = Exact<{
	kitchen?: InputMaybe<Kitchen>;
}>;

export type ListKitchensQuery = { listKitchens?: { data?: Array<{ id: string; sessions?: string }> } };

export type ListProductivityMetricsQueryVariables = Exact<{
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
}>;

export type ListProductivityMetricsQuery = {
	listProductivityMetrics?: {
		data?: Array<{
			id: string;
			day: string;
			kitchen: Kitchen;
			foods?: Array<{
				id: string;
				cacheKey?: string;
				numberOfComponents?: number;
				type?: Array<FoodType>;
				tags?: Array<string>;
				isSandwich?: boolean;
				name: { ar: string; en: string };
				metrics?: Array<{
					cacheKey?: string;
					shift: Session;
					numberOfFoods?: number;
					numberOfModified?: number;
					portioningTime?: number;
					predictedPortioningTimePerMeal?: number;
				}>;
			}>;
		}>;
	};
};

export type ListQualityMetricsQueryVariables = Exact<{
	startDate?: InputMaybe<Scalars['String']['input']>;
	endDate?: InputMaybe<Scalars['String']['input']>;
	kitchen?: InputMaybe<Kitchen>;
	shift?: InputMaybe<Session>;
}>;

export type ListQualityMetricsQuery = {
	listQualityMetrics?: {
		data?: Array<{
			id: string;
			day?: string;
			kitchen: Kitchen;
			shift: Session;
			isFinalized?: boolean;
			food?: Array<{ id: string; cacheKey?: string; componentsIds?: Array<string>; name: { ar: string; en: string } }>;
			components?: Array<{
				id: string;
				cacheKey?: string;
				cost?: number;
				weight?: number;
				lastCookingStation?: CookingStationName;
				consistencyScore?: number;
				numberOfRejected?: number;
				totalReviewed?: number;
				weightOfRejected?: number;
				cookedWeight?: number;
				name: { ar: string; en: string };
			}>;
		}>;
	};
};

export type SubscribeToBaggingDispatchSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToBaggingDispatchSubscription = {
	subscribeToBaggingDispatch?: {
		id: string;
		tk?: string;
		session?: Session;
		foodId: string;
		foodSize: string;
		foodType?: Array<FoodType>;
		foodCost?: number;
		total?: number;
		actual?: number;
		extra?: number;
		modified?: number;
		leftovers?: number;
		startTemperature?: number;
		endTemperature?: number;
		action?: string;
		purchasingCost?: number;
		isCustomFood?: boolean;
		foodName: { ar: string; en: string };
		packages?: Array<{ id: string; name?: string }>;
	};
};

export type SubscribeToBaggingSessionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToBaggingSessionSubscription = {
	subscribeToBaggingSession?: {
		id: string;
		tk?: string;
		date: string;
		kitchen: Kitchen;
		startTime?: string;
		endTime?: string;
		roomTemperature?: number;
		session?: string;
		status?: SessionStatus;
		dispatches?: Array<{
			id: string;
			tk?: string;
			session?: Session;
			foodId: string;
			foodSize: string;
			foodType?: Array<FoodType>;
			foodCost?: number;
			total?: number;
			actual?: number;
			extra?: number;
			modified?: number;
			leftovers?: number;
			startTemperature?: number;
			endTemperature?: number;
			action?: string;
			purchasingCost?: number;
			isCustomFood?: boolean;
			foodName: { ar: string; en: string };
			packages?: Array<{ id: string; name?: string }>;
		}>;
	};
};

export type SubscribeToBatchCreateComponentShortageSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToBatchCreateComponentShortageSubscription = {
	subscribeToBatchCreateComponentShortage?: Array<{
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	}>;
};

export type SubscribeToBatchUpdateFoodsPortioningStatusSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToBatchUpdateFoodsPortioningStatusSubscription = {
	subscribeToBatchUpdateFoodsPortioningStatus?: Array<{
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	}>;
};

export type SubscribeToChefChangesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToChefChangesSubscription = {
	subscribeToChefChanges?: {
		id: string;
		name: string;
		session: Session;
		stations?: Array<FoodComponentStation>;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		deletedAt?: string;
	};
};

export type SubscribeToComponentBatchChangesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToComponentBatchChangesSubscription = {
	subscribeToComponentBatchChanges?: {
		id: string;
		date: string;
		session: Session;
		batchNumber: number;
		foodComponentId: string;
		chef?: string;
		measurementUnit: MeasurementUnit;
		weight?: number;
		deletedAt?: string;
		brand: Brand;
		kitchen: Kitchen;
		country: Country;
		quality?: Array<{
			id?: string;
			createdAt?: string;
			comment?: string;
			status?: QualityCheckStatus;
			commentImageURLs?: Array<string>;
			actor?: { id?: string; name: string; email: string };
			standards?: Array<{ id: string; name: string; rating: QualityRating }>;
		}>;
		deletedBy?: { id?: string; name: string; email: string };
	};
};

export type SubscribeToDeliveryTicketChangesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToDeliveryTicketChangesSubscription = {
	subscribeToDeliveryTicketChanges?: {
		id: string;
		date?: string;
		resolvedAt?: string;
		downloadedAt?: string;
		kitchen: Kitchen;
		zone?: string;
		country: Country;
		brand: Brand;
		status?: DeliveryTicketStatus;
		createdAt?: string;
		data?: {
			type?: string;
			deliveryId: string;
			deliveryTime?: string;
			deliveryShortId: string;
			deliveryOldShortId?: string;
			userId: string;
			userName?: string;
			phoneNumber?: string;
			comment?: string;
			autoApprove?: boolean;
			mealTicketData?: Array<{
				oldFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
				newFood?: {
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				};
			}>;
			addressTicketData?: {
				newDeliveryTime?: string;
				oldAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				oldDriver?: { id?: string; name?: string; code?: string };
				newAddress?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
				newDriver?: { id?: string; name?: string; code?: string };
			};
			manualTicketData?: {
				cutlery?: boolean;
				coolerBag?: boolean;
				foods?: Array<{
					id: string;
					foodType?: Array<FoodType>;
					size: string;
					positionIndex?: number;
					slug?: string;
					name: { ar: string; en: string };
					actions?: Array<{ type?: FoodActionType; componentId?: Array<string> }>;
				}>;
				address?: {
					id: string;
					lat: number;
					lng: number;
					country: Country;
					name?: string;
					region?: string;
					city?: string;
					district?: string;
					street?: string;
					building?: string;
					type?: string;
					apartment?: string;
					office?: string;
					kitchen: Kitchen;
					address?: string;
					customDeliveryTime?: string;
				};
			};
			otherTicketData?: { cutlery?: boolean; coolerBag?: boolean };
			driver?: { id?: string; name?: string; code?: string };
		};
		createdBy?: { id?: string; name: string; email: string };
		resolvedBy?: { id?: string; name: string; email: string };
	};
};

export type SubscribeToResolveComplaintSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToResolveComplaintSubscription = {
	subscribeToResolveComplaint?: {
		id: string;
		category: string;
		type: string;
		date: string;
		kitchen: Kitchen;
		resolvedAt?: string;
		reviewedAt?: string;
		attachment?: boolean;
		attachments?: Array<string>;
		operationsId?: string;
		createdDate?: string;
		createdAt?: string;
		status?: ComplaintStatus;
		deliveryTime?: string;
		deliveryId: string;
		resolvedBy?: { id?: string; name: string; email: string };
		reviewedBy?: { id?: string; name: string; email: string };
		createdBy?: { id?: string; name: string; email: string };
		user: { userId: string; name?: string; email?: string; phoneNumber?: string };
		meal?: { id?: string; name?: string; size?: string; slug?: string };
		comments?: Array<{ id: string; name?: string; text?: string }>;
		driver?: { id?: string; name?: string; code?: string };
	};
};

export type SubscribeToUpdateDriverDispatchSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToUpdateDriverDispatchSubscription = {
	subscribeToUpdateDriverDispatch?: {
		id: string;
		caloBags?: number;
		mealoBags?: number;
		vanTemperature?: number;
		departureTime?: string;
		driver: { id: string; driverName: string; phoneNumber: string; email: string };
	};
};

export type SubscribeToUpdateFoodComponentStatusSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToUpdateFoodComponentStatusSubscription = {
	subscribeToUpdateFoodComponentStatus?: {
		id: string;
		tk?: string;
		count?: number;
		weight?: number;
		cookedWeight?: number;
		measurementUnit: MeasurementUnit;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		brand?: Brand;
		foodsIds?: Array<string>;
		cost?: number;
		quantities?: Array<number>;
		quantity?: number;
		purchasingCost?: number;
		isVerified?: boolean;
		name: { ar: string; en: string };
		cookingStations?: Array<{
			name: CookingStationName;
			status?: FCStatus;
			endTime?: string;
			processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
		kitchenWastageInputs?: { preppedWeight?: number; chilledWeight?: number; wastage?: number; action?: string };
		ingredients?: Array<{ id: string; quantity?: number }>;
		method?: Array<{ description?: string; attachment?: string; station?: string }>;
		childComponents?: Array<{ id: string; quantity?: number }>;
		cups?: Array<{ size: number; value: number }>;
		cupsQuantity?: Array<{ cup: number; quantity: number }>;
		shortages?: Array<{
			id: string;
			foodId: string;
			foodSize: string;
			weight?: number;
			createdAt?: string;
			foodName: { ar: string; en: string };
			cookingStations?: Array<{
				name: CookingStationName;
				status?: FCStatus;
				endTime?: string;
				processTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			}>;
		}>;
		child?: { count?: number; weight?: number };
		standards?: Array<{ id: string; name: string; description?: string; imageURLs?: Array<string> }>;
		verifiedBy?: { id?: string; name: string; email: string };
	};
};

export type SubscribeToUpdateFoodPortioningSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToUpdateFoodPortioningSubscription = {
	subscribeToUpdateFoodPortioning?: {
		id: string;
		tk?: string;
		count?: number;
		size?: string;
		componentIds?: Array<string>;
		date?: string;
		country?: Country;
		kitchen?: Kitchen;
		isAssemblyRequired?: boolean;
		isCustom?: boolean;
		type?: Array<FoodType>;
		tags?: Array<string>;
		slug?: string;
		deliveryTime?: Array<string>;
		brand?: Brand;
		portioningURL?: string;
		assemblyURL?: string;
		name: { ar: string; en: string };
		sizes?: Array<{
			id: string;
			size: string;
			quantity?: number;
			extraMeals?: number;
			componentsCost?: number;
			purchasingCost?: number;
			components?: Array<{ id: string; quantity?: number }>;
		}>;
		portioningStatus?: Array<{
			session?: string;
			readyForAssemblyTime?: string;
			readyForPortioningTime?: string;
			endTime?: string;
			assemblingTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
			portioningTime?: { startedAt: string; intervals?: Array<{ pausedAt: string; resumedAt?: string }> };
		}>;
	};
};

export type SubscribeToUpdateShoppingMenuSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToUpdateShoppingMenuSubscription = {
	subscribeToUpdateShoppingMenu?: {
		id: string;
		day?: string;
		country?: Country;
		kitchen: Kitchen;
		food?: Array<{
			id: string;
			order?: number;
			isDone?: boolean;
			isCustom?: boolean;
			type?: Array<FoodType>;
			size: string;
			portioningURL?: string;
			name: { ar: string; en: string };
			modifiedDeliveryIds?: { downloadSequence?: number; deliveryIds?: Array<string> };
		}>;
	};
};

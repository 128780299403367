import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Collapse, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { caloTheme } from '../../assets/themes/calo';
import RouteLink, { Route } from './RouteLink';
import { NotificationReq } from './Sidebar';

interface GroupSectionProps {
	notificationReq: NotificationReq;
	routeGroup: {
		title: string;
		icon: JSX.Element;
		routes: Route[];
	};
}

const GroupSection = ({ routeGroup, notificationReq }: GroupSectionProps) => {
	const [isExpended, setIsExpended] = useState(true);
	return (
		<Box>
			<Button
				onClick={() => setIsExpended(!isExpended)}
				variant="text"
				sx={{
					color: routeGroup.routes.some((r) => r.isActive) ? caloTheme.palette.primary500 : '#000000B8',
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
					textTransform: 'none',
					paddingY: '16px',
					paddingX: '15px'
				}}
			>
				<Box display={'flex'} justifyContent={'row'}>
					{routeGroup.icon}
					<Typography sx={{ fontSize: '16px', width: '80%', paddingLeft: '10px', fontFamily: caloTheme.typography.fontFamily }}>
						{routeGroup.title}
					</Typography>
				</Box>
				{isExpended ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</Button>

			<Collapse in={isExpended} timeout="auto" unmountOnExit>
				{routeGroup.routes.map((route, index) => (
					<RouteLink key={index} route={route} notificationReq={notificationReq} isIndent />
				))}
			</Collapse>
		</Box>
	);
};

export default GroupSection;

import ScheduleIcon from '@mui/icons-material/Schedule';
import { alpha, Divider, Menu, MenuItem, MenuProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Session } from '../../libs';

interface ShiftSelectorProps {
	shift: string;
	allSessions: boolean;
	kitchen: string;
	isShiftMenuOpened: boolean;
	shiftMenuAnchorEl: HTMLElement | null;
	handleShiftChange: (value: Session) => void;
	setShiftMenuAnchorEl: (value: HTMLElement | null) => void;
	showJustIcon?: boolean;
}
const ShiftSelector = ({
	allSessions,
	shift,
	handleShiftChange,
	isShiftMenuOpened,
	shiftMenuAnchorEl,
	setShiftMenuAnchorEl,
	showJustIcon
}: ShiftSelectorProps) => {
	const StyledMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(5),
			minWidth: 110,
			color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			'& .MuiMenu-list': {
				padding: '4px 0'
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(2)
				},
				'&:active': {
					backgroundColor: alpha(theme.palette.primary500, theme.palette.action.selectedOpacity)
				}
			}
		}
	}));

	return (
		<>
			<Button
				id="menu-button"
				aria-controls={isShiftMenuOpened ? 'menu-button' : undefined}
				aria-haspopup="true"
				aria-expanded={isShiftMenuOpened ? 'true' : undefined}
				variant={showJustIcon ? 'text' : 'outlined'}
				disableElevation
				disabled={shift === 'all' && !allSessions}
				onClick={(event) => {
					!shiftMenuAnchorEl && setShiftMenuAnchorEl(event.currentTarget);
				}}
				endIcon={<ScheduleIcon />}
				sx={{
					textTransform: 'capitalize',
					fontSize: '16px',
					fontWeight: 600,
					mr: 2
				}}
			>
				{showJustIcon ? '' : shift === 'all' ? 'All Shift' : shift}
			</Button>

			<StyledMenu
				MenuListProps={{
					'aria-labelledby': 'customized-button'
				}}
				anchorEl={shiftMenuAnchorEl}
				open={isShiftMenuOpened}
				onClose={() => setShiftMenuAnchorEl(null)}
			>
				<MenuItem
					sx={{ fontWeight: 600, py: 0.5 }}
					value={'morning'}
					onClick={() => {
						handleShiftChange(Session.morning);
						setShiftMenuAnchorEl(null);
					}}
				>
					<Box>Morning Shift</Box>
				</MenuItem>
				<Divider sx={{ my: 0.5 }} />
				<MenuItem
					sx={{ fontWeight: 600, py: 0.5 }}
					value={'evening'}
					onClick={() => {
						handleShiftChange(Session.evening);
						setShiftMenuAnchorEl(null);
					}}
				>
					<Box>Evening Shift</Box>
				</MenuItem>
				<Divider sx={{ my: 0.5, display: `${allSessions ? '' : 'none'}` }} />
				<MenuItem
					sx={{ fontWeight: 600, py: 0.5, display: `${allSessions ? '' : 'none'}` }}
					value={'all'}
					onClick={() => {
						handleShiftChange(Session.all);
						setShiftMenuAnchorEl(null);
					}}
				>
					<Box>All Shifts</Box>
				</MenuItem>
			</StyledMenu>
		</>
	);
};
export default ShiftSelector;

import { useContext, useEffect, useRef } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Drawer, Typography, useTheme } from '@mui/material';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { FoodComponentDataPortionPage, FoodPortioningFoodType, Kitchen, MobileFiltersPages } from '../../../libs';
import FilterPopup from '../../MealsStatus/MealStatusMobileList/FilterPopup';
import DownloadPopup from '../DownloadPopup';
import PortioningListRowMobile from './PortionListRowMobile';

interface PortionListMobile {
	kitchen: Kitchen;
	data: FoodComponentDataPortionPage;
	filters: MobileFiltersPages;
	setFilters: (value: MobileFiltersPages) => void;
	date: string;
}

const PortionListMobile = ({ filters, setFilters, data, kitchen, date }: PortionListMobile) => {
	const appContext = useContext(AppContext);
	const exportPotioningStatusModalRef = useRef<any>();
	const filtersRef = useRef<any>();

	const theme = useTheme();

	useEffect(() => {
		if (appContext.isFilterOpen) {
			filtersRef.current?.open();
		} else {
			filtersRef.current?.close();
		}
	}, [filtersRef.current]);

	useEffect(() => {
		setFilters({
			showCustom: true,
			shift: filters.shift
		});
		appContext.setIsFilterOpen!(false);
	}, [kitchen]);

	return (
		<Box sx={{ width: '100%' }}>
			<Box>
				<Box display={'flex'} justifyContent={'end'} width={'100%'}>
					<LoadingButton
						id="export-button"
						disableElevation
						onClick={() => exportPotioningStatusModalRef.current?.open()}
						variant="text"
						sx={{
							color: 'black',
							width: '5%',
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							m: 0,
							mr: -3,
							p: 0
						}}
						startIcon={<FileDownloadOutlinedIcon />}
					/>
				</Box>
				<DownloadPopup
					exportPotioningStatusModalRef={exportPotioningStatusModalRef}
					theme={theme}
					kitchen={kitchen}
					spacing={2}
					width="100%"
					stackDirection="column"
				/>
				{Object.values(data).some((d) => d.length > 0) ? (
					<Box display={'flex'} flexDirection={'column'} sx={{ my: 2, mx: 1 }}>
						{Object.entries(data).map(([key, value]) => (
							<>
								{value.map((meal: any) => (
									<PortioningListRowMobile
										key={meal.id}
										food={meal}
										shift={filters.shift}
										foodType={key as FoodPortioningFoodType}
										date={date}
										kitchen={kitchen}
									/>
								))}
							</>
						))}
					</Box>
				) : (
					<Typography
						sx={{
							minWidth: 150,
							p: 1,
							fontFamily: caloTheme.typography.fontFamily,
							fontSize: '28px',
							textTransform: 'uppercase',
							color: theme.palette.neutral600,
							textAlign: 'center',
							border: 'none'
						}}
					>
						No meals
					</Typography>
				)}
			</Box>
			<Drawer anchor="bottom" open={appContext.isFilterOpen} onClose={() => appContext.setIsFilterOpen!(false)}>
				<FilterPopup
					filters={filters}
					setFilters={(v) => {
						setFilters(v);
						appContext.setIsFilterOpen(false);
					}}
					filtersRef={filtersRef}
					kitchen={kitchen}
				/>
			</Drawer>
		</Box>
	);
};
export default PortionListMobile;

import { subDays } from 'date-fns/fp';
import { identity, invert, pickBy } from 'lodash-es';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { DateRange } from '@mui/lab/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { Complaint, ComplaintReason, ComplaintsFiltersInput, Kitchen } from '../../../libs';
import { EXPORT_COMPLAINT_LIST_MUTATION } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, getAllComplaintTypesMap, getDate } from '../../../libs/utils/helperFunctions';
import CustomerComplaintsTableRow from './CustomerComplaintsTableRow';
import FilterItems from './FilterItems';

interface CustomerComplaintsTableProps {
	kitchen: Kitchen;
	complaints: Complaint[];
	date: string;
	filters: ComplaintsFiltersInput;
	setFilters: (data: ComplaintsFiltersInput) => void;
}

const CustomerComplaintsTable = ({ date, complaints, kitchen, filters, setFilters }: CustomerComplaintsTableProps) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const appContext = useContext(AppContext);
	const theme = useTheme();
	const [isExportButtonClicked, setIsExportButtonClicked] = useState<boolean>(false);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([new Date(subDays(1)(new Date(date))), new Date(date)]);
	const [category, setCategory] = useState<string>('All');
	const [type, setType] = useState<string>('All');
	const [categoryMenuAnchorEl, setCategoryMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isCategoryMenuOpened = Boolean(categoryMenuAnchorEl);
	const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isTypeMenuOpened = Boolean(typeMenuAnchorEl);

	const [downloadComplaintsList, { loading }] = useMutation(EXPORT_COMPLAINT_LIST_MUTATION, {
		onCompleted: (data) => {
			createAutoDownloadLinkFromFile(
				data.exportComplaintList.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				`Calo_Complaints_List_${kitchen}_${filters.date?.gte}_${filters.date?.lte}`
			);
			setIsExportButtonClicked(false);
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral100,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 16,
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const handleResultsButtonClick = () => {
		if (dateRange[0] && dateRange[1]) {
			setIsFilterOpened(false);
			setFilters({
				kitchen,
				date: {
					gte: getDate(dateRange[0]),
					lte: getDate(dateRange[1])
				},
				category: invert(ComplaintReason)[category],
				type: invert(ComplaintReason)[category] ? getAllComplaintTypesMap(true)[type] : undefined
			});
		} else {
			toast.error('Please select a valid date range');
		}
	};

	return (
		<Box sx={{ width: '100%', minHeight: '100vh' }}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 2, mb: 0, width: '100%' }}>
				<Stack display="flex" flexDirection={'row'} justifyContent={'end'}>
					<Stack display="flex" flexDirection={'row'} justifyContent={'start'}>
						<Typography sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px' }}>
							Complaints Raised on - {filters.date?.gte} - {filters.date?.lte}
						</Typography>
					</Stack>
					<LoadingButton
						id="export-button"
						loading={loading}
						disableElevation
						disabled={loading || appContext.isOffline}
						onClick={() =>
							downloadComplaintsList({
								variables: {
									filters: pickBy(filters, identity)
								}
							})
						}
						variant="text"
						color="primary"
						sx={{
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							m: 2,
							position: 'absolute',
							right: '200px'
						}}
						endIcon={
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									// eslint-disable-next-line max-len
									d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5"
									stroke={isExportButtonClicked || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.66675 7.16663L8.00008 10.5L11.3334 7.16663"
									stroke={isExportButtonClicked || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8 10.5V2.5"
									stroke={isExportButtonClicked || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					>
						Export
					</LoadingButton>
					<Button
						variant="text"
						color={'primary'}
						disabled={appContext.isOffline}
						sx={{
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							m: 2,
							position: 'absolute',
							right: '15px',
							px: 'inherits',
							py: 'inherits',
							backgroundColor: 'inherits',
							':hover': {
								backgroundColor: 'inherits',
								border: 'inherits'
							}
						}}
						onClick={() => setIsFilterOpened(true)}
						endIcon={
							<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.6666 1.5H1.33325L6.66659 7.80667V12.1667L9.33325 13.5V7.80667L14.6666 1.5Z"
									stroke={appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					>
						Filter
					</Button>
					<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
						<FilterItems
							toggleFilterDrawer={setIsFilterOpened}
							handleResultsButtonClick={handleResultsButtonClick}
							dateRange={dateRange}
							setDateRange={setDateRange}
							category={category}
							setCategory={setCategory}
							type={type}
							setType={setType}
							isCategoryMenuOpened={isCategoryMenuOpened}
							categoryMenuAnchorEl={categoryMenuAnchorEl}
							handleCategorySelectButtonClick={(event) => setCategoryMenuAnchorEl(event.currentTarget)}
							handleCategoryCloseSelectMenu={() => setCategoryMenuAnchorEl(null)}
							isTypeMenuOpened={isTypeMenuOpened}
							typeMenuAnchorEl={typeMenuAnchorEl}
							handleTypeSelectButtonClick={(event) => setTypeMenuAnchorEl(event.currentTarget)}
							handleTypeCloseSelectMenu={() => setTypeMenuAnchorEl(null)}
						/>
					</Drawer>
				</Stack>
			</Grid>
			{complaints && (
				<Table sx={{ m: 2, width: '100%' }}>
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ p: 1, pl: 2, minWidth: '254px' }}>User</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '40px', textAlign: 'center' }}>Complaint Type</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '40px', textAlign: 'center' }}>Complaint</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '30px', textAlign: 'center' }}>Reported By</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '30px', textAlign: 'center' }}>Resolved By</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Delivery Day</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Delivery Time</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Driver</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Complaint ID</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Meal</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '24px', textAlign: 'center' }}>Invoice</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '24px', textAlign: 'center' }}>Comment</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '24px', textAlign: 'center' }}>Attachment </StyledTableCell>
							<StyledTableCell sx={{ minWidth: '40px', textAlign: 'center' }}>Status</StyledTableCell>
						</TableRow>
					</TableHead>
					{complaints && complaints.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={12}
									sx={{
										minWidth: 150,
										p: 1,
										fontFamily: caloTheme.typography.fontFamily,
										fontSize: '28px',
										textTransform: 'uppercase',
										color: theme.palette.neutral600,
										textAlign: 'center',
										border: 'none'
									}}
								>
									No Complaints
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{complaints.map((complaint) => (
								<CustomerComplaintsTableRow key={`${complaint.id}-${complaint.createdAt}`} complaint={complaint} />
							))}
						</TableBody>
					)}
				</Table>
			)}
		</Box>
	);
};
export default CustomerComplaintsTable;

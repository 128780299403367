import Typography from '@mui/material/Typography';

import { FoodPortioningStatus, PortioningStatusAttributes } from '../../../libs';

interface PortionStatusItemProps {
	attributes: PortioningStatusAttributes;
}

const PortionStatusItem = ({ attributes }: PortionStatusItemProps) => {
	const { time, status } = attributes;

	const text =
		status === FoodPortioningStatus.resumePortioning || status === FoodPortioningStatus.pausePortioning
			? status.split(' ')[1]
			: status;

	if (time) {
		return <Typography fontSize="20px">{status === FoodPortioningStatus.completed ? text + ' in ' : text + ' for '}</Typography>;
	} else {
		return (
			<Typography fontSize="20px" textTransform={'capitalize'}>
				{status}
			</Typography>
		);
	}
};

export default PortionStatusItem;

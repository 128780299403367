import { isFuture } from 'date-fns';
import { addDays, differenceInDays, subDays } from 'date-fns/fp';
import enLocale from 'date-fns/locale/en-GB';
import { useState } from 'react';

import { DateRange } from '@mui/lab/DateRangePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import DatePicker from '../DatePicker';

interface DateRangePickerProps {
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
	dayRangeLimit?: number;
	startLabel?: string;
	endLabel?: string;
	stackDirection?: 'row' | 'column';
	spacing?: number;
	margin?: number;
	disableFutureDates?: boolean;
	showCalendarIcon?: boolean;
}
const DateRangePicker = ({
	dateRange,
	setDateRange,
	dayRangeLimit,
	startLabel,
	endLabel,
	stackDirection,
	spacing,
	margin,
	disableFutureDates,
	showCalendarIcon
}: DateRangePickerProps) => {
	const [isEndDateChanged, setIsEndDateChanged] = useState(false);

	const yesterday = subDays(1)(new Date());
	const tomorrow = addDays(1)(new Date());
	const direction = stackDirection || 'column';

	const handleStartDateChange = (date: Date | null) => {
		if (date && dayRangeLimit && dateRange[1] && differenceInDays(new Date(date), new Date(dateRange[1])) > dayRangeLimit) {
			setDateRange([date, date]);
		} else {
			setDateRange([date, dateRange[1]]);
		}
	};

	const handleEndDateChange = (date: Date | null) => {
		if (date && dayRangeLimit && dateRange[0] && differenceInDays(new Date(dateRange[0]), new Date(date)) > dayRangeLimit) {
			setDateRange([date, date]);
		} else {
			setDateRange([dateRange[0], date]);
		}
		setIsEndDateChanged(true);
	};
	const potentialMaxDate = dateRange[0] && dayRangeLimit ? addDays(dayRangeLimit)(new Date(dateRange[0])) : undefined;
	let actualMaxDate;
	if (disableFutureDates) {
		actualMaxDate = potentialMaxDate && isFuture(potentialMaxDate) ? tomorrow : potentialMaxDate;
	} else {
		actualMaxDate = potentialMaxDate;
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
			<Stack
				direction={direction}
				justifyContent="space-between"
				alignItems="center"
				spacing={spacing || 2}
				sx={{ mx: margin ? margin : 3 }}
			>
				<DatePicker
					date={dateRange[0]}
					minDate={
						dayRangeLimit && isEndDateChanged && !!dateRange[1] ? subDays(dayRangeLimit)(new Date(dateRange[1])) : undefined
					}
					maxDate={dateRange[1] || yesterday}
					setDate={(date: Date | null) => handleStartDateChange(date)}
					label={startLabel ?? 'Start Date'}
					showCalendarIcon={showCalendarIcon}
				/>
				<DatePicker
					date={dateRange[1]}
					minDate={dateRange[0] || undefined}
					maxDate={actualMaxDate}
					setDate={(date: Date | null) => handleEndDateChange(date)}
					label={endLabel ?? 'End Date'}
					showCalendarIcon={showCalendarIcon}
				/>
			</Stack>
		</LocalizationProvider>
	);
};
export default DateRangePicker;

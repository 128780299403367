import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { Delivery } from '../../../../libs';
import { formatDeliveryTime } from '../../../../libs/utils/helperFunctions';

interface DriverDispatchTableRowProps {
	delivery: Delivery;
}
const DriverDispatchTableRow = ({ delivery }: DriverDispatchTableRowProps) => {
	return (
		<TableRow>
			<TableCell sx={{ color: caloTheme.palette.neutral900, minWidth: '94px', border: 'none', p: 1 }}>
				<Typography padding="6px" fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
					{delivery.name}
				</Typography>
			</TableCell>
			<TableCell sx={styles.tableCell}>{delivery.shortId ? `#${delivery.shortId}` : '---'}</TableCell>
			<TableCell sx={styles.tableCell}>{delivery.pickupTime || '---'}</TableCell>
			<TableCell sx={styles.tableCell}>{formatDeliveryTime(delivery.customDeliveryTime)}</TableCell>
		</TableRow>
	);
};

export default DriverDispatchTableRow;

const styles = {
	tableCell: {
		color: caloTheme.palette.neutral900,
		minWidth: '94px',
		fontSize: 16,
		fontWeight: '600',
		lineHeight: '19px',
		border: 'none',
		p: 1,
		textAlign: 'center'
	}
};

import { startCase } from 'lodash-es';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { AddressServiceClass } from '@calo/services';
import { NewDeliveryAddress } from '@calo/types';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import ConfirmationMessage from '../../../components/ConfirmationMessage';
import Icon from '../../../components/Icon/Icon';
import { Brand, Country, DeliveryTicketData, DeliveryTicketStatus, DeliveryTicketType, Kitchen, User } from '../../../libs';
import { APPROVE_DELIVERY_TICKET_MUTATION, REJECT_DELIVERY_TICKET_MUTATION } from '../../../libs/graphQL';
import { getUserAttributes } from '../../../libs/utils/cognitoUserHelpers';
import { formatDate } from '../../../libs/utils/helperFunctions';
import ColumnAttr from '../ColumnAttr/ColumnAttr';

type SwapCardProps = {
	id: string;
	data?: DeliveryTicketData;
	date?: string;
	createdBy?: User;
	resolvedBy?: User;
	resolvedAt?: string;
	downloadedAt?: string;
	kitchen: Kitchen;
	country: Country;
	brand: Brand;
	status?: DeliveryTicketStatus;
	createdAt?: string;
	index?: number;
	zone?: string;
	isMutationRunning: boolean;
	setIsMutationRunning: (data: boolean) => void;
} & (
	| {
			tabValue: 1;
			numberOfTickets: number;
			isCardOpened: boolean;
			isLastItem: boolean;
			setOpenedCardId: (id: string | null) => void;
	  }
	| { tabValue: 0 }
);

const cancelClickEvent = (event: React.MouseEvent) => event.stopPropagation();

const SwapCard = ({
	id,
	status,
	kitchen,
	data,
	createdBy,
	resolvedBy,
	index,
	zone,
	resolvedAt,
	createdAt,
	isMutationRunning,
	downloadedAt,
	setIsMutationRunning,
	...props
}: SwapCardProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const [isTicketOpened, setIsTicketOpened] = useState(props.tabValue === 0 ? true : false);
	const [isPreviousAddressHidden, setIsPreviousAddressHidden] = useState(data?.type === DeliveryTicketType.address);
	const [action, setAction] = useState(true);
	const [confirmationMessage, setConfirmationMessage] = useState('Are you sure you want to approve this change?');
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [approveDeliveryTicket, { loading: approveLoading }] = useMutation(APPROVE_DELIVERY_TICKET_MUTATION);
	const [rejectDeliveryTicket, { loading: rejectLoading }] = useMutation(REJECT_DELIVERY_TICKET_MUTATION);

	const isSecondTab = props.tabValue === 1;

	const AddressService = new AddressServiceClass();
	const oldAddress = data?.addressTicketData?.oldAddress
		? AddressService.display(data?.addressTicketData?.oldAddress as unknown as NewDeliveryAddress)
		: '';
	const newAddress = data?.addressTicketData?.newAddress
		? AddressService.display(data?.addressTicketData?.newAddress as unknown as NewDeliveryAddress)
		: '';

	const oldAddressMapLinkClickHandler = () => {
		if (data?.addressTicketData?.newAddress?.lat && data?.addressTicketData?.newAddress?.lng)
			window.open(
				'https://maps.google.com?q=' + data?.addressTicketData?.newAddress?.lat + ',' + data?.addressTicketData?.newAddress?.lng
			);
	};

	const newAddressMapLinkClickHandler = () => {
		if (data?.addressTicketData?.oldAddress?.lat && data?.addressTicketData?.oldAddress?.lng)
			window.open(
				'https://maps.google.com?q=' + data?.addressTicketData?.oldAddress?.lat + ',' + data?.addressTicketData?.oldAddress?.lng
			);
	};

	const handleApproveDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await approveDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data?.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket approved successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const handleRejectDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await rejectDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data?.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket rejected successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const cardClickHandler = () => {
		if (isSecondTab && props.isCardOpened) {
			props.setOpenedCardId(null);
		} else if (isSecondTab && !props.isCardOpened) {
			props.setOpenedCardId(id);
		} else {
			setIsTicketOpened(!isTicketOpened);
		}
	};

	return (
		<Card
			sx={{
				minWidth: '100%',
				boxShadow: 0,
				maxHeight: 'auto',
				border: isSecondTab ? '0px' : '2px solid' + theme.palette.neutral100,
				borderRadius: isSecondTab ? '0px' : '8px',
				mb: isSecondTab ? 0 : 2,
				borderColor:
					status === DeliveryTicketStatus.approved
						? theme.palette.primary500
						: status === DeliveryTicketStatus.rejected
							? theme.palette.red
							: theme.palette.neutral100,
				borderBottom:
					isSecondTab && (props.numberOfTickets === 1 || props.isLastItem) ? '0px solid' : '2px solid' + theme.palette.neutral100
			}}
			onClick={cardClickHandler}
		>
			<CardContent>
				<Stack direction={'row'} justifyContent={'space-between'} alignItems="center">
					<Typography
						component="div"
						sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '21px', fontWeight: 400, width: '18%' }}
					>
						#{index} {data?.type === DeliveryTicketType.cancel ? 'Cancel Delivery' : 'Logistics Change'}
					</Typography>
					<ColumnAttr sx={{ width: '25%', justifyContent: 'center' }}>{data?.userName}</ColumnAttr>
					<ColumnAttr sx={{ width: '15%', justifyContent: 'center' }}>{data?.phoneNumber} </ColumnAttr>
					<ColumnAttr sx={{ width: '15%', justifyContent: 'center' }}>{data?.deliveryOldShortId || 'xxx-xxx'}</ColumnAttr>
					<ColumnAttr sx={{ width: '15%', justifyContent: 'center' }}>{data?.deliveryShortId || 'xxx-xxx'}</ColumnAttr>
					<ColumnAttr sx={{ width: '15%', justifyContent: 'center' }}>{zone}</ColumnAttr>
					<ColumnAttr sx={{ width: '5%', justifyContent: 'end' }}>
						<Stack flexDirection={'row'} justifyContent={'flex-end'} textAlign={'end'}>
							{data?.autoApprove ? (
								<Icon name={'userRequest'} width={'32px'} style={{ minWidth: '24px' }} />
							) : (
								<Icon name={'CXRequest'} width={'28px'} style={{ minWidth: '24px' }} />
							)}
							{downloadedAt && (
								<Icon name="downloaded" width={'22px'} style={{ maxWidth: '22px', marginRight: '8px', marginTop: '-6px' }} />
							)}
							{isSecondTab &&
								(props.isCardOpened ? (
									<ExpandLessIcon onClick={() => props.setOpenedCardId(null)} style={{ marginTop: '4px' }} />
								) : (
									<ExpandMoreIcon onClick={() => props.setOpenedCardId(id)} style={{ marginTop: '4px' }} />
								))}
							{!isSecondTab &&
								(isTicketOpened ? (
									<ExpandLessIcon onClick={() => setIsTicketOpened(!isTicketOpened)} style={{ marginTop: '4px' }} />
								) : (
									<ExpandMoreIcon onClick={() => setIsTicketOpened(!isTicketOpened)} style={{ marginTop: '4px' }} />
								))}
						</Stack>
					</ColumnAttr>
				</Stack>
				{(isSecondTab ? props.isCardOpened : isTicketOpened) && (
					<>
						<Divider sx={{ my: 1, mb: 2 }} />
						<Stack
							direction={'column'}
							justifyContent="space-between"
							alignItems="start"
							sx={{
								my: '1px',
								display: data?.type === DeliveryTicketType.cancel || data?.addressTicketData?.newAddress ? 'initial' : 'none'
							}}
							className="stop-click"
							onClick={cancelClickEvent}
						>
							{data?.type === DeliveryTicketType.address && (
								<Box sx={{ p: 1, backgroundColor: theme.palette.secondaryBlue50, my: 1 }}>
									<Typography component="div" sx={{ fontSize: 12, fontWeight: 600, lineHeight: '14px' }}>
										New Location:
									</Typography>
									<Stack direction={'row'} justifyContent="space-between" alignItems="center">
										<Typography component="div" sx={{ fontSize: 19, fontWeight: 600, lineHeight: '23px' }}>
											{newAddress}
										</Typography>
										<IconButton style={{ marginTop: '-12px', marginRight: 8 }} onClick={newAddressMapLinkClickHandler}>
											<LocationOnOutlinedIcon style={{ color: caloTheme.palette.primary500 }} />
										</IconButton>
									</Stack>
								</Box>
							)}
							{isPreviousAddressHidden ? (
								<>
									{data?.type === DeliveryTicketType.address && (
										<Stack direction={'row'} justifyContent="center" alignItems="center" sx={{ pt: 2 }}>
											<Button
												variant="text"
												onClick={() => setIsPreviousAddressHidden(false)}
												sx={{ fontSize: 16, fontWeight: 600, lineHeight: '19px', color: theme.palette.primary500 }}
											>
												Show Old Address
											</Button>
										</Stack>
									)}
								</>
							) : (
								<>
									<Stack
										direction={'column'}
										justifyContent="space-between"
										alignItems="start"
										spacing={1}
										sx={{ mb: 1, p: 1, backgroundColor: theme.palette.secondaryBlue50 }}
									>
										<Typography component="div" sx={{ fontSize: 12, fontWeight: 600, lineHeight: '14px' }}>
											{data?.type === DeliveryTicketType.address ? 'Old Location' : 'Location'}:
										</Typography>
										<Stack direction={'row'} justifyContent="space-between" alignItems="center" width="100%">
											<Typography component="div" sx={{ fontSize: 19, fontWeight: 600, lineHeight: '23px' }}>
												{oldAddress}
											</Typography>
											<IconButton style={{ marginTop: '-12px', marginRight: 8 }} onClick={oldAddressMapLinkClickHandler}>
												<LocationOnOutlinedIcon style={{ color: caloTheme.palette.primary500 }} />
											</IconButton>
										</Stack>
									</Stack>
									{data?.type === DeliveryTicketType.address && (
										<Stack direction={'row'} justifyContent="center" alignItems="center">
											<Button
												variant="text"
												onClick={() => setIsPreviousAddressHidden(true)}
												sx={{ fontSize: 16, fontWeight: 600, lineHeight: '19px', color: theme.palette.primary500 }}
											>
												Hide Old Address
											</Button>
										</Stack>
									)}
								</>
							)}
						</Stack>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems="center"
							sx={{ py: 2 }}
							onClick={cancelClickEvent}
						>
							<Stack
								direction={'row'}
								justifyContent={'space-between'}
								alignItems="start"
								width={'47%'}
								sx={{ backgroundColor: theme.palette.secondaryYellow50 }}
							>
								{data?.addressTicketData?.newDriver ? (
									<>
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" sx={{ p: 1 }}>
											{data?.addressTicketData?.oldDriver && (
												<>
													<Typography
														component="div"
														display={'flex'}
														flexDirection="row"
														alignItems="center"
														sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
													>
														Old Driver
													</Typography>
													<Typography
														component="div"
														sx={{
															marginLeft: '4px',
															mt: 1,
															textTransform: 'capitalize',
															fontSize: 19,
															fontWeight: 600,
															lineHeight: '23px',
															marginRight: '8px'
														}}
													>
														{`${data?.addressTicketData?.oldDriver.name} ${data?.addressTicketData?.oldDriver?.code ? `(#${data?.addressTicketData?.oldDriver.code})` : ''}` ||
															'---'}
													</Typography>
												</>
											)}
										</Stack>
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
											<Typography
												component="div"
												display={'flex'}
												flexDirection="row"
												alignItems="center"
												sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
											>
												New Driver
											</Typography>
											<Typography
												component="div"
												sx={{
													marginLeft: '4px',
													mt: 1,
													textTransform: 'capitalize',
													fontSize: 19,
													fontWeight: 600,
													lineHeight: '23px',
													marginRight: '8px'
												}}
											>
												{`${data?.addressTicketData?.newDriver.name} ${data?.addressTicketData?.newDriver?.code ? `(#${data?.addressTicketData?.newDriver.code})` : ''}` ||
													'---'}
											</Typography>
										</Stack>
									</>
								) : (
									<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" sx={{ p: 1 }}>
										<Typography
											component="div"
											display={'flex'}
											flexDirection="row"
											alignItems="center"
											sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
										>
											Driver
										</Typography>
										<Typography
											component="div"
											sx={{
												marginLeft: '4px',
												mt: 1,
												textTransform: 'capitalize',
												fontSize: 19,
												fontWeight: 600,
												lineHeight: '23px',
												marginRight: '8px'
											}}
										>
											{`${data?.addressTicketData?.oldDriver?.name} ${data?.addressTicketData?.oldDriver?.code ? `(#${data?.addressTicketData?.oldDriver.code})` : ''}` ||
												'---'}
										</Typography>
									</Stack>
								)}
							</Stack>

							<Stack
								direction={'row'}
								justifyContent={'space-between'}
								alignItems="center"
								width={'51%'}
								sx={{ backgroundColor: theme.palette.secondaryPurple50 }}
							>
								{data?.addressTicketData?.newDeliveryTime ? (
									<>
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
											{data?.deliveryTime && (
												<>
													<Typography
														component="div"
														display={'flex'}
														flexDirection="row"
														alignItems="center"
														sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
													>
														Old Shift
													</Typography>
													<Typography
														component="div"
														sx={{
															marginLeft: '4px',
															textTransform: 'capitalize',
															fontSize: 19,
															fontWeight: 600,
															lineHeight: '23px',
															marginRight: '8px',
															mt: 1
														}}
													>
														{data?.deliveryTime || '---'}
													</Typography>
												</>
											)}
										</Stack>
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'}>
											<Typography
												component="div"
												display={'flex'}
												flexDirection="row"
												alignItems="center"
												sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
											>
												New Shift
											</Typography>
											<Typography
												component="div"
												sx={{
													marginLeft: '4px',
													textTransform: 'capitalize',
													fontSize: 19,
													fontWeight: 600,
													lineHeight: '23px',
													marginRight: '8px',
													mt: 1
												}}
											>
												{data?.addressTicketData?.newDeliveryTime || '--'}
											</Typography>
										</Stack>
									</>
								) : (
									<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
										{data?.deliveryTime && (
											<>
												<Typography
													component="div"
													display={'flex'}
													flexDirection="row"
													alignItems="center"
													sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
												>
													Shift
												</Typography>
												<Typography
													component="div"
													sx={{
														marginLeft: '4px',
														textTransform: 'capitalize',
														fontSize: 19,
														fontWeight: 600,
														lineHeight: '23px',
														marginRight: '8px',
														mt: 1
													}}
												>
													{data?.deliveryTime || '---'}
												</Typography>
											</>
										)}
									</Stack>
								)}
							</Stack>
						</Stack>
						<Divider sx={{ my: 1 }} />

						<Stack
							justifyContent={'space-between'}
							display="flex"
							flexDirection={'row'}
							alignItems="start"
							onClick={cancelClickEvent}
						>
							<Stack justifyContent={'space-between'} display="flex" flexDirection={'column'} alignItems="start" sx={{ mt: 1 }}>
								<Stack direction={'row'} justifyContent={'space-between'} alignItems="center" sx={{ mb: 1 }}>
									<Typography
										component="div"
										display={'flex'}
										flexDirection="row"
										sx={{ fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
									>
										Created By:
									</Typography>
									<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 600, lineHeight: '17px' }}>
										{startCase(createdBy?.name)} on {formatDate(createdAt ?? '', 'HH:mm dd/MM/yyyy')}
									</Typography>
								</Stack>
								{resolvedBy && resolvedAt && (
									<Stack direction={'row'} justifyContent={'space-between'} alignItems="center">
										<Typography
											component="div"
											display={'flex'}
											flexDirection="row"
											sx={{ fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
										>
											Resolved By:
										</Typography>
										<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 600, lineHeight: '17px' }}>
											{startCase(resolvedBy.name)} on {formatDate(resolvedAt, 'HH:mm dd/MM/yyyy')}
										</Typography>
									</Stack>
								)}
							</Stack>
							<Stack direction={'row'} justifyContent="end" alignItems="left">
								{(status === DeliveryTicketStatus.pending || status === DeliveryTicketStatus.approved) && (
									<LoadingButton
										loading={rejectLoading}
										variant="text"
										onClick={() => {
											if (status === DeliveryTicketStatus.pending) {
												setAction(false);
												setConfirmationMessage('Are you sure you want to reject this change?');
												setIsOpened(true);
											}
										}}
										disabled={status === DeliveryTicketStatus.approved || appContext.isOffline || isMutationRunning}
										sx={{
											textTransform: 'capitalize',
											fontSize: '19px',
											width: '150px',
											fontWeight: 600,
											color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.red,
											':hover': {
												color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.red,
												background: status === DeliveryTicketStatus.approved ? 'white' : '#FAEEEE'
											},
											':disabled': {
												color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.neutral600
											}
										}}
									>
										{status === DeliveryTicketStatus.approved ? 'Approved' : 'Reject'}
									</LoadingButton>
								)}
								{(status === DeliveryTicketStatus.pending || status === DeliveryTicketStatus.rejected) && (
									<LoadingButton
										loading={approveLoading}
										variant={status === DeliveryTicketStatus.rejected ? 'text' : undefined}
										onClick={() => {
											if (status === DeliveryTicketStatus.pending) {
												setAction(true);
												setConfirmationMessage('Are you sure you want to approve this change?');
												setIsOpened(true);
											}
										}}
										disabled={status === DeliveryTicketStatus.rejected || appContext.isOffline || isMutationRunning}
										sx={{
											px: 2,
											textTransform: 'none',
											mb: '10px',
											fontSize: '19px',
											fontWeight: status === DeliveryTicketStatus.rejected ? 600 : 'inherit',
											m: 2,
											color: status === DeliveryTicketStatus.rejected ? theme.palette.red : 'white',
											backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.primary500,
											':hover': {
												backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.primary600
											},
											':disabled': {
												color: status === DeliveryTicketStatus.rejected ? theme.palette.red : theme.palette.neutral600,
												backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.neutral100
											}
										}}
									>
										{status === DeliveryTicketStatus.rejected
											? 'Rejected'
											: data?.type === DeliveryTicketType.address
												? 'Confirm Change'
												: 'Cancel Delivery'}
									</LoadingButton>
								)}
							</Stack>
						</Stack>
					</>
				)}
			</CardContent>
			<ConfirmationMessage
				isOpened={isOpened}
				message={confirmationMessage}
				accept={'Yes'}
				reject={'No'}
				handleAccept={action ? handleApproveDeliveryTicket : handleRejectDeliveryTicket}
				handleClose={() => setIsOpened(false)}
			/>
		</Card>
	);
};

export default SwapCard;

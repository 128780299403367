import { keyBy } from 'lodash-es';

import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { Food, FoodComponent, Kitchen, Session } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import MealsStatusTable from './MealsStatusTable';
import MealStatusMobileList from './MealStatusMobileList';

interface MealsStatusProps {
	kitchen: Kitchen;
	foods: Food[];
	foodComponents: FoodComponent[];
	shift: Session;
}

const MealsStatus = ({ shift, kitchen, foods, foodComponents }: MealsStatusProps) => {
	const { isMobile } = useDocumentMedia();

	return isMobile ? (
		<Box sx={{ width: '95%', my: 2, display: 'flex', flexDirection: 'column', ml: 2.5 }}>
			{foods.length > 0 ? (
				<MealStatusMobileList shift={shift} kitchen={kitchen} foods={foods} foodComponents={keyBy(foodComponents, 'id')} />
			) : (
				<EmptyMessage label={'no data'} />
			)}
		</Box>
	) : (
		<div style={{ minHeight: '100vh', width: '99%' }}>
			{foods.length > 0 ? (
				<MealsStatusTable shift={shift} kitchen={kitchen} foods={foods} foodComponents={keyBy(foodComponents, 'id')} />
			) : (
				<EmptyMessage label={'no data'} />
			)}
		</div>
	);
};
export default MealsStatus;

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

import { QualityCheckStatus } from '../../../../libs';
import { useDocumentMedia } from '../../../../libs/utils/document';
import { Styles } from './styles';

interface ReviewButtonProps {
	setIsReviewDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>;
	showJustIcon?: boolean;
	status?: QualityCheckStatus | undefined;
	isLoading: boolean;
}

const ReviewButton = ({ setIsReviewDrawerOpened, showJustIcon, status, isLoading }: ReviewButtonProps) => {
	const { isMobile } = useDocumentMedia();
	if (status === QualityCheckStatus.Accepted) {
		return (
			<LoadingButton
				loading={isLoading}
				onClick={() => setIsReviewDrawerOpened(true)}
				variant={showJustIcon ? 'contained' : 'text'}
				color={'primary'}
				aria-label="review-quality-track-button"
				endIcon={showJustIcon ? <CheckIcon sx={{ color: 'white' }} /> : <></>}
				sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullApprovedButtonDesktop}
			>
				{showJustIcon ? '' : 'Approved'}
			</LoadingButton>
		);
	} else if (status === QualityCheckStatus.Rejected) {
		return (
			<LoadingButton
				loading={isLoading}
				onClick={() => setIsReviewDrawerOpened(true)}
				variant={showJustIcon ? 'contained' : 'text'}
				color={'error'}
				aria-label="review-quality-track-button"
				endIcon={showJustIcon ? <CloseIcon /> : <></>}
				sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullRejectedButtonDesktop}
			>
				{showJustIcon ? '' : 'Rejected'}
			</LoadingButton>
		);
	}
	return (
		<LoadingButton
			loading={isLoading}
			onClick={() => setIsReviewDrawerOpened(true)}
			variant={showJustIcon ? 'contained' : 'outlined'}
			color={'primary'}
			aria-label="review-quality-track-button"
			sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullButtonDesktop}
		>
			{showJustIcon ? '' : 'Review'}
		</LoadingButton>
	);
};

export default ReviewButton;

import { gql } from '@apollo/client';
import {
	BAGGING_DISPATCH_FIELDS,
	DRIVER_DISPATCH_FIELDS,
	EXPORT_FILE_RESPONSE_FIELDS,
	LIST_CHEFS_FIELDS,
	LIST_COMPLAINTS_RESPONSE_FIELDS,
	LIST_COMPONENT_BATCHES_RESPONSE_FIELDS,
	LIST_DELIVERIES_RESPONSE_FIELDS,
	LIST_DELIVERY_TICKETS_RESPONSE_FIELDS,
	LIST_FOODS_BY_FILTERS_RESPONSE_FIELDS,
	LIST_FOODS_RESPONSE_FIELDS,
	LIST_FOOD_COMPONENTS_RESPONSE_FIELDS,
	LIST_FOOD_COMPONENT_BY_RANGE_RESPONSE_FIELDS,
	LIST_GIFTS_RESPONSE_FIELDS,
	LIST_KITCHENS_RESPONSE_FIELDS,
	LIST_PRODUCTIVITY_METRICS_RESPONSE_FIELDS,
	LIST_QUALITY_METRICS_RESPONSE_FIELDS,
	SESSION_INFO_FIELDS,
	SESSION_LIST_RESPONSE_FIELDS,
	SHOPPING_MENU_FIELDS,
	SINGLE_FOOD_COMPONENT_FIELDS
} from './fragments';

export const BAGGING_STATUS_QUERY = gql`
	${SESSION_INFO_FIELDS}
	query BaggingStatus($kitchen: Kitchen, $date: String) {
		baggingStatus(kitchen: $kitchen, date: $date) {
			...SessionInfoFields
		}
	}
`;

export const EXPORT_BAGGING_SESSION_QUERY = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	query ExportBaggingSession(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$shift: String
		$actions: [String]
		$fileType: String
	) {
		exportBaggingSession(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			shift: $shift
			actions: $actions
			fileType: $fileType
		) {
			...ExportFileResponseFields
		}
	}
`;

export const GET_FOOD_COMPONENT_QUERY = gql`
	${SINGLE_FOOD_COMPONENT_FIELDS}
	query GetFoodComponent($id: String) {
		getFoodComponent(id: $id) {
			...SingleFoodComponentFields
		}
	}
`;

export const GET_SHOPPING_MENU_QUERY = gql`
	${SHOPPING_MENU_FIELDS}
	query GetShoppingMenu($id: String, $kitchen: Kitchen) {
		getShoppingMenu(id: $id, kitchen: $kitchen) {
			...ShoppingMenuFields
		}
	}
`;

export const LIST_BAGGING_DISPATCH_QUERY = gql`
	${BAGGING_DISPATCH_FIELDS}
	query ListBaggingDispatch($kitchen: Kitchen, $date: String, $session: String) {
		listBaggingDispatch(kitchen: $kitchen, date: $date, session: $session) {
			...BaggingDispatchFields
		}
	}
`;

export const LIST_BAGGING_SESSION_QUERY = gql`
	${SESSION_LIST_RESPONSE_FIELDS}
	query ListBaggingSession(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$shift: String
		$actions: [String]
		$limit: Int
		$next: String
	) {
		listBaggingSession(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			shift: $shift
			actions: $actions
			limit: $limit
			next: $next
		) {
			...SessionListResponseFields
		}
	}
`;

export const LIST_CHEFS_QUERY = gql`
	${LIST_CHEFS_FIELDS}
	query ListChefs($kitchen: Kitchen) {
		listChefs(kitchen: $kitchen) {
			...ListChefsFields
		}
	}
`;

export const LIST_COMPLAINTS_QUERY = gql`
	${LIST_COMPLAINTS_RESPONSE_FIELDS}
	query ListComplaints($query: ListComplaintInput) {
		listComplaints(query: $query) {
			...ListComplaintsResponseFields
		}
	}
`;

export const LIST_COMPONENT_BATCHES_QUERY = gql`
	${LIST_COMPONENT_BATCHES_RESPONSE_FIELDS}
	query ListComponentBatches($kitchen: Kitchen, $date: RangeInput, $shift: String) {
		listComponentBatches(kitchen: $kitchen, date: $date, shift: $shift) {
			...ListComponentBatchesResponseFields
		}
	}
`;

export const LIST_DELIVERIES_QUERY = gql`
	${LIST_DELIVERIES_RESPONSE_FIELDS}
	query ListDeliveries($query: ListDeliveriesInput) {
		listDeliveries(query: $query) {
			...ListDeliveriesResponseFields
		}
	}
`;

export const LIST_DELIVERY_TICKETS_QUERY = gql`
	${LIST_DELIVERY_TICKETS_RESPONSE_FIELDS}
	query ListDeliveryTickets($query: listDeliveryTicketsInput) {
		listDeliveryTickets(query: $query) {
			...ListDeliveryTicketsResponseFields
		}
	}
`;

export const LIST_DRIVER_DISPATCHES_QUERY = gql`
	${DRIVER_DISPATCH_FIELDS}
	query ListDriverDispatches($kitchen: Kitchen, $date: String, $deliveryTime: String) {
		listDriverDispatches(kitchen: $kitchen, date: $date, deliveryTime: $deliveryTime) {
			...DriverDispatchFields
		}
	}
`;

export const LIST_FOOD_COMPONENT_BY_RANGE_QUERY = gql`
	${LIST_FOOD_COMPONENT_BY_RANGE_RESPONSE_FIELDS}
	query ListFoodComponentByRange(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$limit: Int
		$next: String
		$filters: ListFoodComponentByRangeFilters
	) {
		listFoodComponentByRange(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			limit: $limit
			next: $next
			filters: $filters
		) {
			...ListFoodComponentByRangeResponseFields
		}
	}
`;

export const LIST_FOOD_COMPONENTS_BY_NAME_QUERY = gql`
	${LIST_FOOD_COMPONENTS_RESPONSE_FIELDS}
	query ListFoodComponentsByName($kitchen: Kitchen, $brand: String, $name: String) {
		listFoodComponentsByName(kitchen: $kitchen, brand: $brand, name: $name) {
			...ListFoodComponentsResponseFields
		}
	}
`;

export const LIST_FOOD_COMPONENTS_QUERY = gql`
	${LIST_FOOD_COMPONENTS_RESPONSE_FIELDS}
	query ListFoodComponents(
		$kitchen: Kitchen
		$date: String
		$station: String
		$shift: String
		$brand: String
		$withShortages: Boolean
	) {
		listFoodComponents(
			kitchen: $kitchen
			date: $date
			station: $station
			shift: $shift
			brand: $brand
			withShortages: $withShortages
		) {
			...ListFoodComponentsResponseFields
		}
	}
`;

export const LIST_FOODS_BY_FILTERS_QUERY = gql`
	${LIST_FOODS_BY_FILTERS_RESPONSE_FIELDS}
	query ListFoodsByFilters($filters: FoodFiltersInput, $page: Int, $limit: Int) {
		listFoodsByFilters(filters: $filters, page: $page, limit: $limit) {
			...ListFoodsByFiltersResponseFields
		}
	}
`;

export const LIST_FOODS_QUERY = gql`
	${LIST_FOODS_RESPONSE_FIELDS}
	query ListFoods($kitchen: Kitchen, $date: String, $shift: String, $brand: String) {
		listFoods(kitchen: $kitchen, date: $date, shift: $shift, brand: $brand) {
			...ListFoodsResponseFields
		}
	}
`;

export const LIST_GIFTS_QUERY = gql`
	${LIST_GIFTS_RESPONSE_FIELDS}
	query ListGifts($query: ListGiftsReqInput) {
		listGifts(query: $query) {
			...ListGiftsResponseFields
		}
	}
`;

export const LIST_KITCHENS_QUERY = gql`
	${LIST_KITCHENS_RESPONSE_FIELDS}
	query ListKitchens($kitchen: Kitchen) {
		listKitchens(kitchen: $kitchen) {
			...ListKitchensResponseFields
		}
	}
`;

export const LIST_PRODUCTIVITY_METRICS_QUERY = gql`
	${LIST_PRODUCTIVITY_METRICS_RESPONSE_FIELDS}
	query ListProductivityMetrics($startDate: String, $endDate: String, $kitchen: Kitchen) {
		listProductivityMetrics(startDate: $startDate, endDate: $endDate, kitchen: $kitchen) {
			...ListProductivityMetricsResponseFields
		}
	}
`;

export const LIST_QUALITY_METRICS_QUERY = gql`
	${LIST_QUALITY_METRICS_RESPONSE_FIELDS}
	query ListQualityMetrics($startDate: String, $endDate: String, $kitchen: Kitchen, $shift: Session) {
		listQualityMetrics(startDate: $startDate, endDate: $endDate, kitchen: $kitchen, shift: $shift) {
			...ListQualityMetricsResponseFields
		}
	}
`;

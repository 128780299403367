import { keyBy } from 'lodash-es';

import {
	AllCombinedShortages,
	CombinedShortages,
	ComponentShortage,
	FCStatus,
	FoodComponent,
	FoodComponentStationTitle
} from '../../libs';
import { getFoodComponentStatusAttributes } from '../../libs/utils/helperFunctions';

export const getCombinedShortages = (
	shortages: ComponentShortage[] | undefined,
	tableName: string
): AllCombinedShortages | undefined => {
	if (shortages && shortages.length > 0) {
		const completedShortages = shortages.filter((s) =>
			s.cookingStations?.some((station) => station.name === tableName && station.status === FCStatus.completed)
		);
		const unCompletedShortages = shortages.filter(
			(s) =>
				getFoodComponentStatusAttributes(s.cookingStations ?? [], tableName).isCheckable &&
				s.cookingStations?.some((station) => station.name === tableName && station.status === FCStatus.pending)
		);
		const unCheckableShortages = shortages.filter(
			(s) =>
				!getFoodComponentStatusAttributes(s.cookingStations ?? [], tableName).isCheckable &&
				s.cookingStations?.some((station) => station.name === tableName && station.status === FCStatus.pending)
		);

		const unCompleted = {
			ids: unCompletedShortages.map((component) => component.id),
			totalWeight: unCompletedShortages.reduce((accumulator, currentValue) => accumulator + (currentValue.weight ?? 0), 0),
			cookingStations:
				unCompletedShortages.length > 0 ? unCompletedShortages[unCompletedShortages.length - 1].cookingStations : []
		} as CombinedShortages;
		const completed = {
			ids: completedShortages.map((component) => component.id),
			totalWeight: completedShortages.reduce((accumulator, currentValue) => accumulator + (currentValue.weight ?? 0), 0),
			cookingStations: completedShortages.length > 0 ? completedShortages[completedShortages.length - 1].cookingStations : []
		} as CombinedShortages;
		const unCheckable = {
			ids: unCheckableShortages.map((component) => component.id),
			totalWeight: unCheckableShortages.reduce((accumulator, currentValue) => accumulator + (currentValue.weight ?? 0), 0),
			cookingStations:
				unCheckableShortages.length > 0 ? unCheckableShortages[unCheckableShortages.length - 1].cookingStations : []
		} as CombinedShortages;

		return { completed, unCompleted, unCheckable };
	}
};

export const getActiveShoragesCountForAllSections = (foodComponents: FoodComponent[]) => {
	const totalValues = Object.keys(FoodComponentStationTitle).map((station) => {
		let total = 0;
		for (const fc of foodComponents) {
			if (fc.shortages && fc.shortages.length > 0 && fc.cookingStations?.some((s) => s.name === station)) {
				const combinedShortages = getCombinedShortages(fc.shortages, station);
				total += (combinedShortages?.unCheckable?.ids.length || 0) + (combinedShortages?.unCompleted?.ids.length || 0);
			}
		}
		return {
			station,
			total
		};
	});

	return keyBy(totalValues, 'station');
};

import { useContext } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../../App';
import { FoodComponentStationTitle } from '../../../../libs';

interface StationFilterProps {
	selectedStation: string;
	setSelectedStation: React.Dispatch<React.SetStateAction<string>>;
	disabled: boolean;
}

const StationFilter = ({ selectedStation, setSelectedStation, disabled }: StationFilterProps) => {
	const theme = useTheme();
	const appContext = useContext(AppContext);

	return (
		<Stack direction={'row'} alignItems="center" justifyContent={'stretch'} sx={{ mx: '30px', mt: '5px', mb: 2 }}>
			{Object.entries(FoodComponentStationTitle).map(([key, value]) => (
				<Button
					disabled={disabled}
					key={key}
					value={key}
					onClick={() => {
						!disabled && setSelectedStation(key);
					}}
					sx={{
						textTransform: 'capitalize',
						fontSize: appContext.isSidebarOpened ? '14px' : '16px',
						fontWeight: 600,
						flexShrink: 0,
						flexGrow: 1,
						backgroundColor: theme.palette.white,
						px: 0,
						borderBottom: `2px solid ${!disabled && selectedStation === key ? theme.palette.primary500 : theme.palette.neutral100}`,
						borderRadius: 0,
						color: selectedStation === key ? theme.palette.primary500 : theme.palette.neutral500,
						'&:hover': {
							backgroundColor: 'transparent'
						}
					}}
				>
					{value}
				</Button>
			))}
		</Stack>
	);
};

export default StationFilter;

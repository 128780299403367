import { useContext } from 'react';

import { Icon as Iconify } from '@iconify/react';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { InputField } from '../../../../components';
import { MealCheckBox } from '../../../../libs';
import DownloadMealPopUpFooter from './DownloadMealPopUpFooter';

interface DownloadMealPopUpProps {
	date: string;
	mealCheckboxes: MealCheckBox;
	setMealCheckboxes: React.Dispatch<React.SetStateAction<MealCheckBox>>;
}

const DownloadMealPopUp = ({ mealCheckboxes, setMealCheckboxes, date }: DownloadMealPopUpProps) => {
	const appContext = useContext(AppContext);

	const handleSpecificMealAmountChange = (event: number, specificMealId: string) => {
		const newAmount = +event;
		if (Object.prototype.hasOwnProperty.call(mealCheckboxes, specificMealId)) {
			setMealCheckboxes({
				...mealCheckboxes,
				[specificMealId]: {
					...mealCheckboxes[specificMealId],
					amount: newAmount
				}
			});
		}
	};

	const StyledTableCell = styled(TableCell)(() => ({
		[`&.${tableCellClasses.head}`]: {
			color: caloTheme.palette.neutral900,
			border: 'none',
			fontSize: 14,
			fontWeight: 600,
			lineHeight: '20px',
			backgroundColor: caloTheme.palette.neutral50
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16
		}
	}));

	return (
		<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'}>
			<Box style={{ maxHeight: '400px', overflow: 'auto' }}>
				<Table stickyHeader sx={{ width: '100%' }}>
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ width: '50%', pl: 1 }} style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
								Meal Name
							</StyledTableCell>
							<StyledTableCell sx={{ width: '10%', pl: 1, textAlign: 'center' }}>Size</StyledTableCell>
							<StyledTableCell sx={{ width: '10%', textAlign: 'center', pl: 1 }}>Amount</StyledTableCell>
							<StyledTableCell
								sx={{ width: '5%', pl: 0, textAlign: 'center' }}
								style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
							>
								Action
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(mealCheckboxes).map(([key, meal]) => (
							<TableRow key={key}>
								<StyledTableCell sx={{ minWidth: '100%', pl: 1 }}>{meal.meal.name?.en}</StyledTableCell>
								<StyledTableCell sx={{ minWidth: '100%', textAlign: 'center', pl: 1 }}>{meal.size}</StyledTableCell>
								<StyledTableCell>
									<InputField
										key={key}
										type="number"
										value={meal.amount}
										disabled={appContext.isOffline}
										onChange={(event) => handleSpecificMealAmountChange(+event, meal.meal.id)}
										debounce
										fullWidth={true}
										inputMode="numeric"
										sx={{
											fontSize: '16px',
											fontWeight: 600,
											border: '1px solid' + caloTheme.palette.neutral900,
											borderRadius: '8px',
											width: '136px',
											height: '38px',
											textAlign: 'center',
											p: '10px',
											input: { textAlign: 'center' },
											color: appContext.isOffline ? caloTheme.palette.neutral600 : caloTheme.palette.neutral900,
											':disabled': { color: caloTheme.palette.neutral600, backgroundColor: caloTheme.palette.neutral100 }
										}}
									/>
								</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'start' }}>
									<Iconify
										icon="mi:delete"
										width={21}
										onClick={() => {
											const newMealCheckboxes = { ...mealCheckboxes };
											delete newMealCheckboxes[key];
											setMealCheckboxes(newMealCheckboxes);
										}}
									/>
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
			<DownloadMealPopUpFooter date={date} mealCheckboxes={mealCheckboxes} />
		</Box>
	);
};
export default DownloadMealPopUp;

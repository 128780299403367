import { Brand } from '@calo/types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { caloTheme } from '../../assets/themes/calo';

interface BrandIndicatorProps {
	brand: Brand;
}

const BrandIndicator = ({ brand }: BrandIndicatorProps) => {
	const theme = useTheme();

	return (
		<Box
			component={'span'}
			sx={{
				fontSize: '23px',
				fontFamily: caloTheme.typography.fontFamily,
				textTransform: 'uppercase',
				color: brand === Brand.CALO ? theme.palette.primary500 : theme.palette.mealo,
				fontWeight: 400
			}}
		>
			{brand}
		</Box>
	);
};
export default BrandIndicator;

import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Modal from '../../../components/Modal';
import PortionActionButton from '../../../components/PortionActionButton/PortionActionButton';
import { useExportDocuments } from '../../../hooks';
import { DocsType, Food, FoodPortioningFoodType, Page, ShoppingMenu, UpdateFoodPortioningStatusOperation } from '../../../libs';
import { EXPORT_MEAL_INVOICES_MUTATION, UPDATE_FOOD_PORTIONING_STATUS_MUTATION } from '../../../libs/graphQL';
import {
	createAutoDownloadLinkFromArrayOfUrls,
	getFoodPortioningStatusAttributes,
	isValidURL
} from '../../../libs/utils/helperFunctions';
import PortionStatusItem from '../PortionStatusItem/PortionStatusItem';
import ProcessTime from '../ProcessTime';

interface PortionTableRowProps {
	food: Food;
	foodType: FoodPortioningFoodType;
	shift: string;
	shoppingMenu: ShoppingMenu;
	kitchen: string;
	date: string;
}

const PortionTableRow = ({ food, foodType, shift, shoppingMenu, kitchen, date }: PortionTableRowProps) => {
	const theme = useTheme();

	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);

	const [mutateFoodPortioningStatus, { loading }] = useMutation(UPDATE_FOOD_PORTIONING_STATUS_MUTATION);
	const [exportMealInvoices, { loading: exportLoading }] = useMutation(EXPORT_MEAL_INVOICES_MUTATION);
	const { handleExportDocuments, loading: exportDocumentsLoading } = useExportDocuments();
	const portioningStatus = food.portioningStatus?.find((s) => s.session === shift);
	const attributes = getFoodPortioningStatusAttributes(portioningStatus!, foodType, Page.portioningDisplay, theme);

	const shoppingMenuFood = useMemo(() => {
		const index = (shoppingMenu?.food || []).findIndex(
			(f) => shoppingMenu.kitchen === food.kitchen && f.name.en === food.name.en
		);
		return shoppingMenu?.food?.[index];
	}, [shoppingMenu]);

	const handleUpdateFoodPortioningStatus = async (operation: UpdateFoodPortioningStatusOperation) => {
		if (!loading) {
			await mutateFoodPortioningStatus({
				variables: {
					id: food.id,
					session: shift,
					kitchen: food.kitchen,
					operation: operation
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	const handleExportInvoices = async () => {
		const index = (shoppingMenu?.food || []).findIndex(
			(f) => shoppingMenu.kitchen === food.kitchen && f.name.en === food.name.en
		);
		if (index === -1) {
			toast.error('Something went wrong. Food slug is missing');
			return;
		}
		const selectedFood = shoppingMenu?.food?.[index];
		const shoppingMenuFoodSlug = selectedFood?.isCustom
			? `custom-${shoppingMenu.country}-${shoppingMenu.kitchen}`
			: selectedFood?.id;
		await exportMealInvoices({
			variables: { id: shoppingMenu.id, kitchen: shoppingMenu.kitchen, shoppingMenuFoodSlug },
			onCompleted: (data) => {
				if (data?.exportMealInvoices.data && data?.exportMealInvoices.data.length > 0) {
					createAutoDownloadLinkFromArrayOfUrls(data.exportMealInvoices.data);
				} else {
					toast.error('Something went wrong');
				}
			},
			onError: (error) => {
				toast.error(error.message);
			}
		});
	};

	const handleExportPortioningGuidelines = async () => {
		const docsTypes = [DocsType.portioningGuidelines];
		await handleExportDocuments({ ids: [food.name.en], docTypes: docsTypes, kitchen, day: date, shift });
	};

	return (
		<TableRow>
			<TableCell sx={{ width: 300, border: 'none', p: 1 }}>
				<Typography padding="6px" fontWeight="600" fontSize="19px" textTransform="capitalize">
					{food.name.en}
				</Typography>
			</TableCell>
			<TableCell sx={{ width: 50, border: 'none', p: 1 }}>
				<IconButton
					onClick={() => {
						food.portioningURL && window.open(food.portioningURL, '_blank');
					}}
					disabled={!food.portioningURL || !isValidURL(food.portioningURL)}
					sx={{ color: '#367bf5' }}
				>
					<Icon icon="ph:file-text-duotone" width="1.2em" height="1.3em" />
				</IconButton>
			</TableCell>
			<TableCell sx={{ width: 50, border: 'none', p: 1 }}>
				<IconButton disabled={exportDocumentsLoading} onClick={handleExportPortioningGuidelines} sx={{ color: '#367bf5' }}>
					<Icon icon="lucide:external-link" width="1.2em" height="1.3em" />
				</IconButton>
			</TableCell>
			<TableCell sx={{ minWidth: 450, border: 'none', p: 1 }}>
				<Stack flexDirection="row" gap="15px">
					<PortionStatusItem attributes={attributes} />
					{attributes.time && <ProcessTime attributes={attributes} portioningStatus={portioningStatus} time={attributes.time!} />}
				</Stack>
			</TableCell>
			<TableCell sx={{ border: 'none', p: 1 }}>
				<PortionActionButton
					attributes={attributes}
					loading={loading}
					handleUpdateFoodPortioningStatus={handleUpdateFoodPortioningStatus}
				/>
			</TableCell>
			<TableCell sx={{ border: 'none', p: 1, textAlign: 'center' }}>
				<Stack alignItems="center" borderRadius="5px" textTransform={'capitalize'}>
					{shoppingMenuFood?.modifiedDeliveryIds && (shoppingMenuFood?.modifiedDeliveryIds?.deliveryIds?.length ?? 0) > 0 ? (
						<Button
							onClick={handleExportInvoices}
							sx={{
								width: '120px',
								color: theme.palette.primary500,
								backgroundColor: theme.palette.white,
								textTransform: 'capitalize',
								py: 2,
								'&:disabled': {
									color: theme.palette.neutral600,
									backgroundColor: theme.palette.white
								},
								'&:hover': {
									color: theme.palette.white,
									backgroundColor: theme.palette.primary500
								}
							}}
						>
							<Typography fontWeight="600" fontSize="16px">
								{exportLoading
									? 'Downloading...'
									: shoppingMenuFood?.modifiedDeliveryIds?.downloadSequence
										? 'Downloaded'
										: 'Download'}
							</Typography>
						</Button>
					) : (
						<Typography fontWeight="600" fontSize="16px">
							No invoices
						</Typography>
					)}
				</Stack>
			</TableCell>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.notEnded)}
				title={'Uncheck ' + food.name.en}
				message={'Are you sure you want to uncheck ' + food.name.en + '?'}
			/>
		</TableRow>
	);
};

export default PortionTableRow;

import { KDSExtraMeals } from '../../../libs';
import SizeRow from './SizeRow';

interface MealsTableRowProps {
	food: KDSExtraMeals;
	isLastRow: boolean;
	isEditing: boolean;
	setLoading: (value: boolean) => void;
}

const MealsTableRow = ({ food, isLastRow, isEditing, setLoading }: MealsTableRowProps) => (
	<SizeRow food={food} isEditing={isEditing} isLastRow={isLastRow} setLoading={setLoading} key={`${food.id}#${food.size}`} />
);
export default MealsTableRow;

import { caloTheme } from '../../../../assets/themes/calo';

export const Styles = {
	justIconStyle: {
		height: '40px',
		minWidth: '40px',
		padding: 0,
		paddingRight: 1.5,
		borderRadius: '8px',
		justifySelf: 'center',
		color: caloTheme.palette.primary500,
		borderColor: caloTheme.palette.primary500,
		'&:hover': {
			borderColor: caloTheme.palette.neutral900,
			backgroundColor: caloTheme.palette.neutral100,
			color: caloTheme.palette.black
		}
	},

	buttonWithText: {
		width: '124px',
		height: '45px',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '17px',
		borderRadius: '8px',
		textTransform: 'none',
		color: caloTheme.palette.black,
		borderColor: caloTheme.palette.neutral900,
		'&:hover': {
			borderColor: caloTheme.palette.neutral900,
			backgroundColor: caloTheme.palette.neutral100,
			color: caloTheme.palette.black
		}
	},

	greenButtonWithText: {
		width: '124px',
		height: '45px',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '17px',
		borderRadius: '8px',
		textTransform: 'none',
		color: caloTheme.palette.white,
		borderColor: caloTheme.palette.primary500,
		backgroundColor: caloTheme.palette.primary500
	}
};

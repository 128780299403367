import { orderBy } from 'lodash-es';

import { Chef, Session, SingleFoodComponentWithBatches } from '../../../../libs';
import { removeTypename } from '../../../../libs/utils/helperFunctions';
import QualityTableBodyRow from '../QualityTableBodyRow';

interface QualityTableProps {
	foodComponent: SingleFoodComponentWithBatches;
	kitchen: string;
	chefs: Chef[];
	shift: Session;
	date: string;
}

const QualityTableBody = ({ chefs, foodComponent, shift, date }: QualityTableProps) => (
	<>
		{foodComponent.batches && foodComponent.batches.some((batch) => batch.session === shift) ? (
			orderBy(foodComponent.batches, 'id')
				.filter((batch) => batch.session === shift)
				.map((foodComponentBatch) => (
					<QualityTableBodyRow
						chefs={chefs || []}
						date={date}
						shift={shift}
						foodComponent={foodComponent}
						key={foodComponentBatch.id}
						foodComponentName={foodComponent.name.en}
						foodComponentBatch={removeTypename(foodComponentBatch)}
						batchesListLength={
							foodComponent.batches
								? foodComponent.batches.filter((b) => b.session === shift)[
										foodComponent.batches.filter((b) => b.session === shift).length - 1
									].batchNumber
								: 0
						}
					/>
				))
		) : (
			<QualityTableBodyRow
				chefs={chefs || []}
				key={foodComponent.id}
				batchesListLength={0}
				shift={shift}
				date={date}
				foodComponent={foodComponent}
			/>
		)}
	</>
);

export default QualityTableBody;

import { compact } from 'lodash';

import { MeasurementUnit } from '@calo/dashboard-types';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { ComponentBatch, QualityMetricsFoodComponent, QualityRating } from '../../../../libs';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { renderDotColor } from '../../../QualityTracking/utils';
import { BatchDrawerData } from '../../Section/Section';

const calculateBatchStandardsScore = (batch: ComponentBatch): number => {
	const standardsScores: number[] = compact(
		batch.quality?.[0]?.standards?.map((standard) => {
			switch (standard.rating) {
				case QualityRating.bad:
					return 0;
				case QualityRating.neutral:
					return 0.5;
				case QualityRating.good:
					return 1;
				default:
					return 0;
			}
		})
	);

	const batchScore = standardsScores?.reduce((acc, score) => acc + score, 0) / (standardsScores?.length ?? 1);
	return batchScore;
};

const getBatchWeightPerGram = (batch: ComponentBatch, cookedWeight: number) =>
	batch.measurementUnit === MeasurementUnit.piece ? (batch.weight ?? 0) * (cookedWeight ?? 1) : batch.weight;

interface props {
	foodComponentBatch: ComponentBatch;
	qualityMetricsFoodComponent: QualityMetricsFoodComponent;
	setBatchDrawerData: (data: BatchDrawerData) => void;
}

const SectionTableRowFolded = ({ foodComponentBatch, qualityMetricsFoodComponent, setBatchDrawerData }: props) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			backgroundColor: caloTheme.palette.green[50],
			color: theme.palette.text.primary,
			fontSize: '14px',
			width: `${92 / 6}%`
		}
	}));

	return (
		<TableRow>
			<StyledTableCell
				onClick={() => setBatchDrawerData({ foodComponentBatch, qualityMetricsFoodComponent, isReviewDrawerOpened: true })}
				sx={{
					textAlign: 'left !important',
					cursor: 'pointer',
					fontWeight: 500,
					fontSize: '13px',
					textTransform: 'uppercase',
					letterSpacing: '0.46px',
					color: caloTheme.palette.primary.main + ' !important'
				}}
			>
				<CircleIcon
					sx={{
						color: renderDotColor(foodComponentBatch.quality?.[0]?.status),
						fontSize: '16px',
						verticalAlign: 'middle',
						marginRight: '4px'
					}}
				/>
				Batch {foodComponentBatch.batchNumber}
			</StyledTableCell>
			<StyledTableCell>{foodComponentBatch.session}</StyledTableCell>
			<StyledTableCell>{foodComponentBatch.chef}</StyledTableCell>
			<StyledTableCell>
				{roundToTwoDecimals(foodComponentBatch.weight ?? 0)} {foodComponentBatch.measurementUnit}
			</StyledTableCell>
			<StyledTableCell>{getPercentage(calculateBatchStandardsScore(foodComponentBatch) || 0)}</StyledTableCell>
			<StyledTableCell>
				{getCurrencyFromKitchen(foodComponentBatch.kitchen)}{' '}
				{roundToTwoDecimals(
					(qualityMetricsFoodComponent.cost ?? 0) *
						(getBatchWeightPerGram(foodComponentBatch, qualityMetricsFoodComponent.cookedWeight ?? 0) ?? 0)
				)}
			</StyledTableCell>
			<StyledTableCell sx={{ width: '8% !important' }}></StyledTableCell>
		</TableRow>
	);
};

export default SectionTableRowFolded;

import Push from 'push.js';

import { NotificationType } from '../enums';

export const sendNotification = (pendingReq: number, type: NotificationType) => {
	Push.create(`${type} Request`, {
		body:
			type === NotificationType.shortage
				? `There has been ${pendingReq} shortage/s reported`
				: type === NotificationType.lockTimeChange
					? `There has been ${pendingReq} request/s to swap a meal`
					: `There has been ${pendingReq} request/s to modify a delivery`,
		icon: '/favicon.ico',
		vibrate: true,
		requireInteraction: true
	});
};

import { format } from 'date-fns/fp';
import { compact, isNil, keyBy, sortBy } from 'lodash-es';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { QualityMetric } from '../../../../libs';
import SectionTableRow from '../SectionTableRow';

interface Props {
	qualityMetric: QualityMetric;
	name?: string;
	filterText?: string;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
}

const SectionTable = ({ qualityMetric, name, filterText, isExpanded, setIsExpanded }: Props) => {
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.grey[100],
			color: theme.palette.text.primary,
			fontSize: '14px',
			fontWeight: 500,
			width: `${92 / 5}%`
		}
	}));
	const filteredQualityMetricFood = compact(
		qualityMetric.food?.filter((f) =>
			!isNil(name) && name?.length > 0 ? f.name.en.toLowerCase().includes(name.toLowerCase()) : true
		)
	);
	const keyedQualityMetricsFoodComponent = keyBy(qualityMetric.components, 'id');
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 1 }}>
				<Typography sx={{ color: theme.palette.neutral900, fontSize: '24px' }}>
					{format('dd-MM-yyy')(new Date(qualityMetric.day ?? ''))}
				</Typography>
				{filterText && <Typography sx={{ fontSize: '24px', color: caloTheme.palette.neutral900 }}>{filterText}</Typography>}
			</Stack>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ textAlign: 'left !important' }}>Name</StyledTableCell>
						<StyledTableCell>Shift</StyledTableCell>
						<StyledTableCell>Weight</StyledTableCell>
						<StyledTableCell>Consistency Score</StyledTableCell>
						<StyledTableCell>Cost</StyledTableCell>
						<StyledTableCell onClick={() => setIsExpanded(!isExpanded)} sx={{ width: '8% !important', py: 0 }}>
							{isExpanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredQualityMetricFood.length > 0 ? (
						sortBy(filteredQualityMetricFood, 'name.en').map((food) => (
							<SectionTableRow
								key={`${food.id}#${Math.random()}`}
								qualityMetric={qualityMetric}
								qualityMetricsFood={food}
								isExpanded={isExpanded}
								keyedQualityMetricsFoodComponent={keyedQualityMetricsFoodComponent}
							/>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={9}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No Data
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	);
};

export default SectionTable;

import { format } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';

import { Button, ButtonGroup } from '@mui/material';
import Stack from '@mui/material/Stack';

import { caloTheme } from '../../assets/themes/calo';
import { useListQualityMetricsQuery } from '../../hooks';
import { CookingStationName, ExportMetricsTypeEnum, Kitchen, Session } from '../../libs';
import Filter from './Filter';
import Header from './Header';
import Section from './Section';

export interface Filters {
	startDate: string;
	endDate: string;
	shift: Session;
	kitchen: string;
	date: string;
	availableShifts: Session[];
	name?: string;
	type: ExportMetricsTypeEnum;
	isExpanded: boolean;
	stations: CookingStationName[];
}

interface QualityMetricsProps {
	kitchen: Kitchen;
	date: string;
	shift: Session;
}

const QualityMetrics = ({ kitchen, date, shift }: QualityMetricsProps) => {
	const [tab, setTab] = useState<0 | 1>(0);
	const defaultFilters: Filters = {
		startDate: format('yyyy-MM-dd')(Date.parse(date)),
		endDate: format('yyyy-MM-dd')(Date.parse(date)),
		shift: shift ?? Session.all,
		kitchen,
		date,
		availableShifts: shift === Session.all ? [Session.all] : [Session.all, Session.morning, Session.evening],
		type: tab === 0 ? ExportMetricsTypeEnum.meals : ExportMetricsTypeEnum.components,
		isExpanded: false,
		stations: [],
		name: undefined
	};
	const [filters, setFilters] = useState<Filters>({ ...defaultFilters });

	const { qualityMetrics, qualityMetricsLoading } = useListQualityMetricsQuery({
		startDate: filters.startDate,
		endDate: filters.endDate,
		kitchen,
		shift: shift !== Session.all && filters.shift === Session.all ? undefined : filters.shift
	});

	const handleTabChange = (tab: 0 | 1) => {
		setTab(tab);
		setFilters((old) => ({
			...old,
			type: tab === 0 ? ExportMetricsTypeEnum.meals : ExportMetricsTypeEnum.components
		}));
	};

	const clearFilters = () => {
		setFilters((old) => ({
			...old,
			...defaultFilters
		}));
	};

	useEffect(() => {
		setFilters((old) => ({
			...old,
			...defaultFilters
		}));
	}, [kitchen, date, shift]);
	const filteredQualityMetrics = qualityMetrics.filter((qualityMetric) => (qualityMetric.food?.length ?? 0) > 0);
	const tabsList: (0 | 1)[] = [0, 1];

	return (
		<Stack direction="column" justifyContent="space-between" alignItems="start" spacing={2} sx={{ width: '100%', mx: 2 }}>
			<Header qualityMetrics={filteredQualityMetrics} kitchen={kitchen} stations={filters.stations} />
			<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ width: '100%', mx: 2, mb: 0 }}>
				<ButtonGroup variant="outlined" aria-label="Basic button group">
					{tabsList.map((tabValue) => (
						<Button
							key={tabValue}
							variant="outlined"
							sx={{
								px: tabValue === 0 ? 4 : 2,
								color: caloTheme.palette.text.primary,
								borderColor: caloTheme.palette.text.primary,
								backgroundColor: tab === tabValue ? caloTheme.palette.grey[300] : 'inherit'
							}}
							onClick={() => {
								handleTabChange(tabValue);
								setFilters((old) => ({ ...old, name: undefined, isExpanded: false, stations: [] }));
							}}
						>
							{tabValue === 0 ? 'Meals' : 'Components'}
						</Button>
					))}
				</ButtonGroup>
				<Filter filters={filters} setFilters={setFilters} shift={shift} tab={tab} clearFilters={clearFilters} />
			</Stack>
			<Section
				qualityMetrics={filteredQualityMetrics}
				tab={tab}
				kitchen={kitchen}
				shift={shift !== Session.all && filters.shift === Session.all ? undefined : filters.shift}
				date={date}
				name={filters.name}
				loading={qualityMetricsLoading}
				isExpanded={filters.isExpanded}
				setIsExpanded={() => setFilters((old) => ({ ...old, isExpanded: !old.isExpanded }))}
				filterText={`${format('dd-MM-yyy')(new Date(filters.startDate))} to ${format('dd-MM-yyy')(new Date(filters.endDate))}, ${startCase(filters.shift)}`}
				stations={filters.stations}
			/>
		</Stack>
	);
};

export default QualityMetrics;

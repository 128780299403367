import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { QualityMetric, QualityMetricsFoodComponent } from '../../../../libs';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';

interface props {
	qualityMetricsFoodComponent: QualityMetricsFoodComponent;
	qualityMetric: QualityMetric;
}

const SectionTableRowFolded = ({ qualityMetricsFoodComponent, qualityMetric }: props) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			backgroundColor: caloTheme.palette.green[50],
			color: theme.palette.text.primary,
			fontSize: '14px',
			width: `${92 / 5}%`
		}
	}));

	return (
		<TableRow>
			<StyledTableCell sx={{ textAlign: 'left !important' }}>{qualityMetricsFoodComponent.name?.en}</StyledTableCell>
			<StyledTableCell>{qualityMetric.shift}</StyledTableCell>
			<StyledTableCell>{roundToTwoDecimals(qualityMetricsFoodComponent.weight ?? 0)} g</StyledTableCell>
			<StyledTableCell>{getPercentage(qualityMetricsFoodComponent.consistencyScore ?? 0)}</StyledTableCell>
			<StyledTableCell>
				{getCurrencyFromKitchen(qualityMetric.kitchen)}{' '}
				{roundToTwoDecimals((qualityMetricsFoodComponent.cost ?? 0) * (qualityMetricsFoodComponent.weight ?? 0))}
			</StyledTableCell>
			<StyledTableCell sx={{ width: '8% !important' }}></StyledTableCell>
		</TableRow>
	);
};

export default SectionTableRowFolded;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { Chef } from '../libs';
import { LIST_CHEFS_QUERY, SUBSCRIBE_TO_CHEF_CHANGES_SUBSCRIPTION } from '../libs/graphQL';

const useListChefsQuery = (kitchen: string) => {
	const { loading, refetch, subscribeToMore } = useQuery(LIST_CHEFS_QUERY, {
		variables: { kitchen },
		onCompleted(data) {
			if (data?.listChefs?.data) {
				setChefs(data.listChefs.data);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const [chefs, setChefs] = useState<Chef[]>([]);

	useSubscription(SUBSCRIBE_TO_CHEF_CHANGES_SUBSCRIPTION);

	const moreChefs = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_CHEF_CHANGES_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToComponentChefs = subscriptionData.data.subscribeToChefChanges;
				const prevData = Array.isArray(prev?.listChefs?.data) ? prev.listChefs.data : [];

				return {
					...prev,
					listChefs: {
						...prev.listChefs,
						data: [subscribeToComponentChefs, ...prevData]
					}
				};
			}
		});

	useEffect(() => {
		moreChefs();
	}, []);

	return {
		chefsLoading: loading,
		chefs,
		refetchChefs: refetch
	};
};

export default useListChefsQuery;

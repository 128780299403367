import { useState, useEffect } from 'react';

const getMediaData = () => ({
	isMobile: window.innerWidth <= 640,
	isTablet: window.innerWidth > 640 && window.innerWidth <= 1024,
	isDesktop: window.innerWidth > 1024
});

export const useDocumentMedia = () => {
	const [mediaData, setMediaData] = useState(getMediaData());

	useEffect(() => {
		const handleResize = () => setMediaData(getMediaData());
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return mediaData;
};

export default async function compressToWebp(file: File): Promise<Blob | null> {
	const image = new Image();
	image.src = URL.createObjectURL(file);
	image.style.cssText = `
				visibility: 'none';
				position: 'fixed';
				zIndex: '-999';
			`;

	await new Promise((resolve) => {
		image.addEventListener('load', function () {
			resolve(null);
		});
	});

	document.body.appendChild(image);

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	canvas.height = image.naturalHeight;
	canvas.width = image.naturalWidth;

	context?.drawImage(image, 0, 0);
	document.body.removeChild(image);

	return new Promise<Blob | null>((resolve) => {
		canvas.toBlob(
			(blob) => {
				resolve(blob);
			},
			'image/webp',
			0.5
		);
	});
}

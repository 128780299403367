import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { MeasurementUnit } from '@calo/dashboard-types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import { Box, Menu, MenuItem } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { Popup } from '../../../../components';
import { ComponentBatch, Session, SingleFoodComponentWithBatches } from '../../../../libs';
import {
	CREATE_COMPONENT_BATCH_MUTATION,
	DELETE_COMPONENT_BATCH_MUTATION,
	LIST_COMPONENT_BATCHES_QUERY
} from '../../../../libs/graphQL';
import { useDocumentMedia } from '../../../../libs/utils/document';
import NewBatchForm from '../NewBatchForm';
import { Styles } from './style';

interface AddBatchButtonProps {
	foodComponentBatch?: ComponentBatch;
	addBatchModalRef: any;
	deliveryActionMenuAnchorEl: null | HTMLElement;
	setDeliveryActionMenuAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
	handleUpdateBatch: (value: any) => Promise<void>;
	foodComponentName?: string;
	batchesListLength?: number;
	showJustIcon?: boolean;
	date: string;
	shift: Session;
	foodComponent: SingleFoodComponentWithBatches;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddBatchButton = ({
	addBatchModalRef,
	foodComponentBatch,
	deliveryActionMenuAnchorEl,
	setDeliveryActionMenuAnchorEl,
	batchesListLength,
	foodComponentName,
	handleUpdateBatch,
	date,
	shift,
	foodComponent,
	isLoading,
	setIsLoading
}: AddBatchButtonProps) => {
	const isActionMenuOpen = Boolean(deliveryActionMenuAnchorEl);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { isMobile } = useDocumentMedia();

	const [createComponentBatch, { loading: createLoading }] = useMutation(CREATE_COMPONENT_BATCH_MUTATION);
	const [deleteComponentBatch, { loading: deleteLoading }] = useMutation(DELETE_COMPONENT_BATCH_MUTATION, {
		refetchQueries: [{ query: LIST_COMPONENT_BATCHES_QUERY }, 'getListComponentBatches']
	});

	const handleCreateBatch = async (values?: any) => {
		await createComponentBatch({
			variables: {
				data: {
					foodComponentId: foodComponentBatch?.foodComponentId ?? foodComponent.id,
					brand: foodComponentBatch?.brand ?? foodComponent.brand,
					country: foodComponentBatch?.country ?? foodComponent.country,
					kitchen: foodComponentBatch?.kitchen ?? foodComponent.kitchen,
					session: foodComponentBatch?.session ?? shift,
					date: foodComponentBatch?.date ?? date,
					batchNumber: (batchesListLength || 0) + 1,
					quality: [],
					...values
				}
			},
			onCompleted: () => {
				setIsEditing(false);
				setDeliveryActionMenuAnchorEl(null);
				addBatchModalRef.current?.close();
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleDeleteBatch = async (batchData: ComponentBatch) => {
		await deleteComponentBatch({
			variables: {
				id: batchData!.id,
				kitchen: batchData.kitchen
			},
			onCompleted: (data) => {
				if (data.deleteComponentBatch.message) {
					setDeliveryActionMenuAnchorEl(null);
					toast.success(data.deleteComponentBatch.message);
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleAddBatchButtonClick = (event: any) => {
		if (!deliveryActionMenuAnchorEl) {
			if (isMobile || foodComponentBatch) {
				return setDeliveryActionMenuAnchorEl(event.currentTarget);
			}
			return addBatchModalRef.current?.open();
		}
	};

	useEffect(() => {
		setIsLoading(createLoading || deleteLoading);
	}, [createLoading, deleteLoading]);

	return (
		<>
			<LoadingButton
				loading={isLoading}
				id="delivery-action-button"
				aria-haspopup="true"
				disableElevation
				onClick={handleAddBatchButtonClick}
				variant="outlined"
				aria-label="meal-actions"
				sx={isMobile ? Styles.justIconStyle : foodComponentBatch ? Styles.buttonWithText : Styles.greenButtonWithText}
				endIcon={!isMobile && !foodComponentBatch ? <></> : <ArrowDownIcon />}
			>
				{isMobile ? '' : foodComponentBatch ? `Batch ${foodComponentBatch?.batchNumber}` : 'ADD BATCH'}
			</LoadingButton>
			<Menu
				MenuListProps={{
					'aria-labelledby': 'export-button'
				}}
				anchorEl={deliveryActionMenuAnchorEl}
				open={isActionMenuOpen}
				onClose={() => setDeliveryActionMenuAnchorEl(null)}
			>
				{foodComponentBatch && (
					<MenuItem
						sx={{ fontWeight: 600 }}
						onClick={() => handleDeleteBatch(foodComponentBatch)}
						disabled={(foodComponentBatch?.quality?.length ?? 0) > 0}
					>
						<Box
							sx={{
								fontVariant: 'h5',
								fontSize: '23px',
								lineHeight: '28px',
								fontWeight: 600,
								width: '96px',
								textAlign: 'center',
								color: caloTheme.palette.red
							}}
						>
							Delete
						</Box>
					</MenuItem>
				)}
				{foodComponentBatch && (
					<MenuItem
						onClick={() => {
							setIsEditing(true);
							addBatchModalRef.current?.open();
						}}
						sx={{ fontWeight: 600, py: 1 }}
					>
						<Box
							sx={{
								fontVariant: 'h5',
								fontSize: '23px',
								lineHeight: '28px',
								fontWeight: 600,
								width: '96px',
								textAlign: 'center'
							}}
						>
							Edit
						</Box>
					</MenuItem>
				)}
				<MenuItem onClick={() => addBatchModalRef.current?.open()} sx={{ fontWeight: 600, py: 1 }}>
					<Box
						sx={{
							fontVariant: 'h5',
							fontSize: '23px',
							lineHeight: '28px',
							fontWeight: 600,
							width: '96px',
							color: caloTheme.palette.primary500,
							borderColor: caloTheme.palette.primary500,
							p: 1,
							borderRadius: '8px',
							border: 1,
							textAlign: 'center'
						}}
					>
						Add
					</Box>
				</MenuItem>
			</Menu>
			<Popup
				ref={addBatchModalRef}
				onClose={() => {
					addBatchModalRef.current?.close();
					setIsEditing(false);
				}}
				title={isEditing ? 'Edit Batch' : 'ADD BATCH'}
				style={{
					fullWidth: true,
					maxWidth: 'md'
				}}
			>
				<NewBatchForm
					setIsEditing={setIsEditing}
					componentName={foodComponentName || ''}
					addBatchModalRef={addBatchModalRef}
					weight={isEditing && foodComponentBatch ? +(foodComponentBatch?.weight ?? 0).toString() : 0}
					measurementUnit={isEditing ? (foodComponentBatch?.measurementUnit as MeasurementUnit) : MeasurementUnit.g}
					handleAddBatch={(v) => (isEditing && foodComponentBatch ? handleUpdateBatch({ ...v }) : handleCreateBatch(v))}
				/>
			</Popup>
		</>
	);
};

export default AddBatchButton;

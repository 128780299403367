import ReactHtmlParser from 'html-react-parser';
import { useState } from 'react';

import { Box, Stack, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import Image from '../../../components/Image';
import ImageModal from '../../../components/ImageModal';
import { FoodComponentMethodStep } from '../../../libs';
import { decodeHtmlSymbols, getTableAttributes } from '../../../libs/utils/helperFunctions';

interface MethodsTableRowProps {
	method: FoodComponentMethodStep;
	index: number;
}

const MethodsTableRow = ({ method, index }: MethodsTableRowProps) => {
	const theme = useTheme();
	const [isModalOpened, setIsModalOpened] = useState(false);

	const StyledStep = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.common.black,
			fontSize: 19,
			fontWeight: 600,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase',
			border: 'none'
		}
	}));

	const StyledMethod = styled(TableCell)(() => ({
		[`&.${tableCellClasses.head}`]: {
			fontSize: 33,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase',
			border: 'none',
			paddingLeft: '30px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 20,
			fontWeight: 400
		}
	}));

	const stationAttributes = method.station && getTableAttributes(method.station, caloTheme);
	const StyledTag = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.common.black,
			backgroundColor: stationAttributes ? stationAttributes.color : caloTheme.palette.white,
			fontSize: 19,
			textTransform: 'capitalize',
			borderRadius: '8px',
			width: '200px',
			textAlign: 'center',
			border: 'none'
		}
	}));

	return (
		<TableRow key={method.description}>
			<TableCell sx={{ border: 'none' }}>
				{stationAttributes && <StyledTag>{stationAttributes ? stationAttributes.name : ''}</StyledTag>}
				<StyledStep> Step {index + 1}: </StyledStep>
				<Stack direction={'row'} justifyContent="start" alignItems="flex-start" sx={{ width: '100%' }}>
					<StyledMethod
						className="tableCenter"
						sx={{ border: 'none', width: method.attachment ? '95%' : '100%', textTransform: 'capitalize' }}
					>
						{ReactHtmlParser(decodeHtmlSymbols(method.description ?? ''))}
					</StyledMethod>
					{method.attachment && (
						<Image url={method.attachment || ''} alt={'Method image'} width={140} height={140} onClick={setIsModalOpened} />
					)}
				</Stack>
				<Box sx={{ borderBottom: 2, borderColor: theme.palette.neutral100, py: '5px', ml: '10px', mr: '-25px' }} />
			</TableCell>
			<ImageModal
				isOpened={isModalOpened}
				handleClose={() => setIsModalOpened(false)}
				title={`Step ${index + 1}:`}
				url={method.attachment?.replace('square@1x.jpg', 'square@3x.jpg') || ''}
				width={550}
			/>
		</TableRow>
	);
};

export default MethodsTableRow;

import { addDays, addMonths, differenceInMonths, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box, Button, CircularProgress, IconButton, Stack, Theme, Typography } from '@mui/material';

import { Popup } from '../../../components';
import DateRangePicker from '../../../components/DateRangePicker';
import { EXPORT_FOOD_COMPONENT_STATUS_MUTATION } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, getDate } from '../../../libs/utils/helperFunctions';

interface ExportComponentStatusPopupProps {
	exportComponentStatusModalRef: React.RefObject<any>;
	theme: Theme;
	kitchen: string;
	mt?: string;
	spacing?: number;
	width?: string;
	stackDirection?: 'row' | 'column';
}

const ExportComponentStatusPopup: React.FC<ExportComponentStatusPopupProps> = ({
	exportComponentStatusModalRef,
	theme,
	kitchen,
	mt,
	spacing,
	width,
	stackDirection
}) => {
	const today = startOfDay(new Date());
	const tomorrow = addDays(today, 1);

	const [exportFileName, setExportFileName] = useState(`Food_Components_Status_${kitchen}`);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([today, tomorrow]);

	const [exportFoodComponentsStatus, { loading }] = useMutation(EXPORT_FOOD_COMPONENT_STATUS_MUTATION, {
		onCompleted: (data) => {
			createAutoDownloadLinkFromFile(
				data.exportFoodComponentStatus.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				exportFileName
			);
			toast.success('File downloaded successfully!');
		},
		onError: (error) => {
			console.error(error);
			toast.error('Error while downloading file');
		}
	});

	const handleDateRangeChange = (newDateRange: DateRange<Date>) => {
		if (newDateRange[1] && newDateRange[1] >= tomorrow) {
			newDateRange[1] = tomorrow;
		}
		if (newDateRange[0] && newDateRange[0] >= tomorrow) {
			newDateRange[0] = tomorrow;
		}

		if (newDateRange[0] && newDateRange[1] && differenceInMonths(newDateRange[1], newDateRange[0]) > 3) {
			newDateRange[1] = addMonths(newDateRange[0], 3);
		}

		setDateRange(newDateRange);
	};

	const handleDownloadButtonClick = () => {
		if (dateRange[0] && dateRange[1]) {
			exportFoodComponentsStatus({
				variables: {
					kitchen,
					startDate: getDate(dateRange[0]),
					endDate: getDate(dateRange[1])
				}
			});
			exportComponentStatusModalRef.current?.close();
		} else {
			toast.error('Please select a valid date range');
		}
	};

	useEffect(() => {
		if (dateRange[0] && dateRange[1]) {
			setExportFileName(`Food_Components_Status_${kitchen}_${getDate(dateRange[0])}_${getDate(dateRange[1])}`);
		}
	}, [dateRange, kitchen]);

	return (
		<Popup
			ref={exportComponentStatusModalRef}
			onClose={() => exportComponentStatusModalRef.current?.close()}
			title={'Download'}
			style={{
				fullWidth: true,
				maxWidth: 'md'
			}}
		>
			<IconButton
				aria-label="close"
				onClick={() => exportComponentStatusModalRef.current?.close()}
				sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
			>
				<CloseIcon />
			</IconButton>
			<Stack>
				<Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', mb: '20px', mt: mt || '0px' }}>
					Please Choose the period for which you want to download the data
				</Typography>
				<DateRangePicker
					dateRange={dateRange}
					dayRangeLimit={100}
					setDateRange={handleDateRangeChange}
					stackDirection={stackDirection || 'row'}
					startLabel="Start Date"
					endLabel="End Date"
					spacing={spacing || 4}
					margin={0.0000001}
					disableFutureDates
				/>
				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						sx={{
							fontSize: '18px',
							padding: '10px 20px',
							color: theme.palette.white,
							borderRadius: '10px',
							width: width || '20%',
							mt: '30px',
							textTransform: 'none'
						}}
						onClick={handleDownloadButtonClick}
						disabled={loading}
					>
						{loading ? <CircularProgress size={24} color="inherit" /> : 'Download'}
					</Button>
				</Box>
			</Stack>
		</Popup>
	);
};

export default ExportComponentStatusPopup;

import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../assets/themes/calo';
import { RoutePaths } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import { formatDate, KitchenShortCut } from '../../libs/utils/helperFunctions';

interface TopBarProps {
	setIsOpen: (isOpen: boolean) => void;
	isOpen: boolean;
	kitchen: string;
	date: string;
	pageName?: string;
	openFilters: boolean;
	setOpenFilters: (value: boolean) => void;
}

const TopBar = ({ openFilters, setOpenFilters, setIsOpen, isOpen, kitchen, date }: TopBarProps) => {
	const location = useLocation();

	const { isMobile, isTablet } = useDocumentMedia();
	const pageName = useMemo(() => {
		if (matchPath(location.pathname, RoutePaths.componentsCheck)) {
			return 'Components Check';
		}
		if (matchPath(location.pathname, RoutePaths.portioningDisplay)) {
			return 'Portioning Display';
		}
		if (matchPath(location.pathname, RoutePaths.mealsStatus)) {
			return 'Meals Status';
		}
		if (matchPath(location.pathname, RoutePaths.wastageTracking)) {
			return 'Wastage tracking';
		}
		if (matchPath(location.pathname, RoutePaths.shortageReporting)) {
			return 'Shortage Reporting';
		}
		if (matchPath(location.pathname, RoutePaths.shortageDisplay)) {
			return 'Shortage Display';
		}
		if (matchPath(location.pathname, RoutePaths.assemblyCheck)) {
			return 'Assembly Check';
		}
		if (matchPath(location.pathname, RoutePaths.qualityTracking)) {
			return 'Quality Tracking';
		}
		if (matchPath(location.pathname, RoutePaths.wastageArchive)) {
			return 'Wastage Archive';
		}
		if (matchPath(location.pathname, RoutePaths.preDispatchCheck)) {
			return 'Food Bagging & Dispatch Record';
		}
		if (matchPath(location.pathname, RoutePaths.preDispatchArchive)) {
			return 'Food Bagging & Dispatch Archive';
		}
		if (matchPath(location.pathname, RoutePaths.recipeGuideBook)) {
			return 'Recipe GuideBook';
		}
		if (matchPath(location.pathname, RoutePaths.lockTimeChanges)) {
			return 'Kitchen Requests';
		}
		if (matchPath(location.pathname, RoutePaths.logisticsChanges)) {
			return 'Logistics Requests';
		}
		if (matchPath(location.pathname, RoutePaths.driverDispatch)) {
			return 'Driver Dispatch';
		}
		if (matchPath(location.pathname, RoutePaths.fileManagement)) {
			return 'Files Requests';
		}
		if (matchPath(location.pathname, RoutePaths.specialRequest)) {
			return 'Special Requests';
		}
		if (matchPath(location.pathname, RoutePaths.qualityMetrics)) {
			return 'Quality Metrics';
		}
		if (matchPath(location.pathname, RoutePaths.productivityMetrics)) {
			return 'Productivity Metrics';
		}
		return '';
	}, [location.pathname]);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				color="inherit"
				sx={{
					width: { sm: isOpen ? `calc(100% - ${260}px)` : '100%' },
					ml: { sm: isOpen ? '260px' : '0' },
					boxShadow: 0
				}}
			>
				<Toolbar>
					<IconButton size="medium" edge="start" color="inherit" aria-label="menu" onClick={() => setIsOpen(!isOpen)}>
						<SvgIcon
							sx={{ fill: 'none', width: '32px', height: '32px', ml: isMobile ? '-8px' : '' }}
							viewBox="0 0 36 36"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M4 24H28" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M4 16H28" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M4 8H28" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</SvgIcon>
					</IconButton>
					<Stack display="flex" flexDirection={'row'} sx={{ width: '100%' }}>
						{isMobile || isTablet ? (
							<Stack display="flex" flexDirection={'row'} justifyContent={'space-between'} width="100%">
								<Typography
									variant="h1"
									sx={{
										flexGrow: 0,
										ml: '-4px',
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '14px',
										fontWeight: 600,
										color: caloTheme.palette.neutral900,
										fontSize: '12px',
										textTransform: 'uppercase'
									}}
								>
									{pageName}
								</Typography>
								<Typography
									variant="h1"
									sx={{
										flexGrow: 0,
										justifyContent: 'end',
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '14px',
										fontWeight: 600,
										color: caloTheme.palette.neutral900,
										fontSize: '12px',
										textTransform: 'uppercase'
									}}
								>
									{KitchenShortCut(kitchen)} Kitchen, {formatDate(date, 'dd-MM-yyyy')}
								</Typography>
							</Stack>
						) : (
							<>
								<Typography
									variant="h1"
									component="div"
									sx={{
										flexGrow: 1,
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '24px',
										fontWeight: 400,
										color: caloTheme.palette.neutral900,
										fontSize: '24px',
										textTransform: 'capitalize'
									}}
								>
									{pageName}
								</Typography>
								<Typography
									variant="h1"
									component="div"
									sx={{
										flexGrow: 0,
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '24px',
										fontWeight: 400,
										color: caloTheme.palette.neutral900,
										fontSize: '24px',
										textTransform: 'capitalize'
									}}
								>
									{kitchen.toUpperCase()} Kitchen, {formatDate(date, 'dd-MM-yyyy')}
								</Typography>
							</>
						)}
					</Stack>
					{isMobile &&
						(matchPath(location.pathname, RoutePaths.mealsStatus) ||
							matchPath(location.pathname, RoutePaths.assemblyCheck) ||
							matchPath(location.pathname, RoutePaths.portioningDisplay)) && (
							<IconButton
								sx={{
									backgroundColor: caloTheme.palette.primary100,
									width: '50px',
									height: '36px',
									mr: '-10px',
									ml: '2px',
									borderRadius: '8px',
									':hover': {
										backgroundColor: caloTheme.palette.primary100
									},
									':focus': {
										backgroundColor: caloTheme.palette.primary100
									}
								}}
								size="small"
								aria-label="menu"
								onClick={() => setOpenFilters(!openFilters)}
							>
								<SettingsOutlinedIcon style={{ width: '20px', stroke: caloTheme.palette.primary400 }} color={'primary'} />
							</IconButton>
						)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default TopBar;

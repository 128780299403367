import { compact, isNil, sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';

import { Dictionary } from '@calo/types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, Table, TableBody } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { QualityMetric, QualityMetricFood, QualityMetricsFoodComponent } from '../../../../libs';
import { getPercentage } from '../../../../libs/utils/helperFunctions';
import SectionTableRowFolded from '../SectionTableRowFolded';

interface Props {
	qualityMetric: QualityMetric;
	qualityMetricsFood: QualityMetricFood;
	isExpanded: boolean;
	keyedQualityMetricsFoodComponent: Dictionary<QualityMetricsFoodComponent>;
}

const SectionTableRow = ({ qualityMetric, qualityMetricsFood, isExpanded, keyedQualityMetricsFoodComponent }: Props) => {
	const qualityMetricsFoodComponent = compact(
		qualityMetricsFood.componentsIds?.map((id) => keyedQualityMetricsFoodComponent[id])
	);
	const [open, setOpen] = useState(isExpanded);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.text.primary,
			fontSize: '14px',
			width: `${92 / 5}%`
		}
	}));
	const getFoodConsistencyScore = () => {
		if (qualityMetricsFood) {
			const isAllComponentsReviewed =
				qualityMetricsFoodComponent.filter((component) => !isNil(component.consistencyScore)).length ===
				qualityMetricsFoodComponent.length;
			if (qualityMetricsFoodComponent.length === 0 || !isAllComponentsReviewed) {
				return '-';
			}
			return getPercentage(
				qualityMetricsFoodComponent.reduce((accumulator, currentValue) => accumulator + (currentValue.consistencyScore ?? 0), 0) /
					qualityMetricsFoodComponent.length
			);
		}
		return '-';
	};

	useEffect(() => {
		setOpen(isExpanded);
	}, [isExpanded]);

	return (
		<>
			<TableRow>
				<StyledTableCell sx={{ textAlign: 'left !important' }}>{qualityMetricsFood.name.en}</StyledTableCell>
				<StyledTableCell>{qualityMetric.shift}</StyledTableCell>
				<StyledTableCell>-</StyledTableCell>
				<StyledTableCell>{getFoodConsistencyScore()}</StyledTableCell>
				<StyledTableCell>-</StyledTableCell>
				<StyledTableCell sx={{ width: '8% !important', py: 0 }} onClick={() => setOpen(!open)}>
					{qualityMetricsFoodComponent.length > 0 ? open ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" /> : ''}
				</StyledTableCell>
			</TableRow>
			{qualityMetricsFoodComponent.length > 0 && (
				<TableRow>
					<StyledTableCell sx={{ p: 0, backgroundColor: caloTheme.palette.neutral50 }} colSpan={6}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table>
								<TableBody>
									{sortBy(qualityMetricsFoodComponent, 'name.en').map((component) => (
										<SectionTableRowFolded
											key={component.id}
											qualityMetricsFoodComponent={component}
											qualityMetric={qualityMetric}
										/>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</StyledTableCell>
				</TableRow>
			)}
		</>
	);
};

export default SectionTableRow;

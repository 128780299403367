import { Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { SingleFoodComponent } from '../../../libs';
import { addStepsToMethods } from '../helpers';
import MethodsTableRow from './MethodsTableRow';

interface MethodsTableProps {
	selectedFoodComponent: SingleFoodComponent;
}

const MethodsTable = ({ selectedFoodComponent }: MethodsTableProps) => {
	const StyledTableCell = styled(TableCell)(() => ({
		[`&.${tableCellClasses.head}`]: {
			fontSize: 33,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase',
			border: 'none',
			paddingLeft: '30px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 20,
			fontWeight: 400
		}
	}));

	return (
		<Table sx={{ width: '100%' }}>
			<TableHead>
				<TableRow>
					<StyledTableCell sx={{ pt: 2 }}>Cooking Method</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{selectedFoodComponent.method ? (
					addStepsToMethods(selectedFoodComponent.method)?.map((f) => (
						<MethodsTableRow key={f.description} method={f} index={f.step} />
					))
				) : (
					<EmptyMessage style={{ marginLeft: '30px' }} label={'no cooking method'} />
				)}
			</TableBody>
		</Table>
	);
};

export default MethodsTable;

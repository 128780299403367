import { FCStatus, FoodComponent, FoodComponentStationTitle } from '../../../../libs';
import { getFoodComponentStatusAttributes } from '../../../../libs/utils/helperFunctions';
import { getCombinedShortages } from '../../ComponentsCheckHelpers';
import { MobileSectionTableRow } from '../SectionTableRow';

interface SectionProps {
	foodComponent: FoodComponent;
	selectedComponentStation: FoodComponentStationTitle;
	setSelectedComponentStation: (value: FoodComponentStationTitle) => void;
}

const Section = ({ foodComponent, selectedComponentStation, setSelectedComponentStation }: SectionProps) => {
	const combinedShortages = getCombinedShortages(foodComponent.shortages, selectedComponentStation);

	return (
		<>
			<MobileSectionTableRow
				key={foodComponent.id}
				foodComponent={foodComponent}
				statusAttributes={getFoodComponentStatusAttributes(foodComponent.cookingStations ?? [], selectedComponentStation)}
				selectedComponentStation={selectedComponentStation}
				setSelectedComponentStation={setSelectedComponentStation}
				isChecked={Boolean(
					foodComponent.cookingStations?.some(
						(station) =>
							FoodComponentStationTitle[station.name as keyof typeof FoodComponentStationTitle] === selectedComponentStation &&
							station.status === FCStatus.completed
					)
				)}
			/>
			{combinedShortages
				? Object.entries(combinedShortages).map(
						([type, shortages]) =>
							shortages.ids.length > 0 && (
								<MobileSectionTableRow
									key={shortages.ids.join(';')}
									foodComponent={foodComponent}
									isChecked={type === 'completed' ? true : false}
									statusAttributes={getFoodComponentStatusAttributes(shortages.cookingStations, selectedComponentStation)}
									selectedComponentStation={selectedComponentStation}
									setSelectedComponentStation={setSelectedComponentStation}
									combinedShortages={shortages}
								/>
							)
					)
				: ''}
		</>
	);
};

export default Section;

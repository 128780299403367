import { startCase } from 'lodash-es';
import { useRef, useState } from 'react';

import { Icon as Iconify } from '@iconify/react';
import { Box, Button, Chip, Grid, IconButton, Stack } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../../../assets/themes/calo';
import { Image, Popup } from '../../../../components';
import { Delivery, Gift } from '../../../../libs';
import { formatDate } from '../../../../libs/utils/helperFunctions';

interface SpecialRequestTableRowProps {
	request: Gift;
	delivery: Delivery | undefined;
}

const SpecialRequestTableRow = ({ request, delivery }: SpecialRequestTableRowProps) => {
	const viewImageRef = useRef<any>();
	const moreRequestRef = useRef<any>();
	const commentModalRef = useRef<any>();
	const attachmentModalRef = useRef<any>();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [selectedComment, setSelectedComment] = useState<any>(null);
	const [selectedAttachment, setSelectedAttachment] = useState<string>('');
	const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);

	const handleViewComment = (comment: string) => {
		setSelectedComment(comment);
	};

	const handleChangeImage = (selectedAttachments: string[], viewNext: string, index: number) => {
		if (index < selectedAttachments.length || (index >= selectedAttachments.length && index !== -1)) {
			setCurrentIndex(index);
			if (index === selectedAttachments.length) {
				setCurrentIndex(0);
			} else if (viewNext === 'Right' && selectedAttachments.length >= index) {
				setCurrentIndex(index + 1);
				setSelectedAttachment(selectedAttachments[index + 1]);
			} else if (viewNext === 'Left' && selectedAttachments.length > index && index >= 0) {
				setCurrentIndex(index - 1);
				setSelectedAttachment(selectedAttachments[index - 1]);
			}
		}
	};

	const handleRenderTypeChips = (row: Gift, showType: string) => {
		if (!row.items || row.items.length === 0) {
			return [];
		}

		const createChip = (key: string, label: string, more = false) => (
			<Chip
				key={key}
				label={label}
				size={'medium'}
				variant="outlined"
				sx={{
					color: caloTheme.palette.primary700,
					backgroundColor: caloTheme.palette.primary100,
					cursor: more ? 'pointer' : 'default',
					padding: '8px 6px 8px 6px',
					borderWidth: 0,
					mr: 1,
					my: 1,
					fontSize: '14px',
					fontWeight: 600,
					lineHeight: '18px',
					textTransform: 'capitalize'
				}}
				onClick={() => (more ? moreRequestRef.current?.open() : null)}
			/>
		);

		let chips = [];
		let totalCount = 0;
		for (const request of row.items) {
			if (request.item) {
				const meal = request.item;
				chips.push(createChip(meal.id, `${meal.name?.en} ${' - ' + meal.size} (${request.amount})`));
				totalCount++;
			} else {
				chips.push(createChip(request.id, request.type ?? ''));
				totalCount++;
			}

			if (showType !== 'all' && totalCount >= 3) break;
		}

		if (showType === 'short' && row.items.length > 2) {
			chips = chips.slice(0, 2);
			chips.push(createChip(`${row.id} more`, `${row.items.length - 2} more`, true));
		}
		return chips;
	};

	return (
		<>
			<TableRow key={`${request.id}-${request.updatedAt}`}>
				<TableCell sx={{ border: 'none' }}>
					<Typography padding="6px" fontWeight={400} fontSize="14px" lineHeight={'18px'} textTransform={'capitalize'}>
						{request.userName}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography textTransform={'none'} sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
						{request.phoneNumber}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography textTransform={'none'} sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
						{startCase(delivery?.driver?.name) || '--'}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
						{delivery?.shortId ? `#${delivery?.shortId}` : '--'}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
						{`${startCase(request.createdBy?.name)}`}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography
						textTransform={'capitalize'}
						sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}
					>
						{`${formatDate(request.updatedAt ?? request.createdAt ?? '', 'HH:mm a - dd/MM/yyyy')}`}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none' }}>
					<Typography textTransform={'none'} sx={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
						{request.zone ? request.zone : '-'}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none', p: 1 }}>
					<Typography
						textTransform={'capitalize'}
						sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px', flexWrap: 'wrap' }}
					>
						{handleRenderTypeChips(request, 'short')}
					</Typography>
				</TableCell>
				<TableCell sx={{ border: 'none', textAlign: 'center' }} key={request.id}>
					<Button
						variant="text"
						sx={{
							color: request.comment ? caloTheme.palette.neutral900 : caloTheme.palette.neutral400,
							fontFamily: 'Roboto',
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							height: '34px',
							textTransform: 'capitalize',
							textAlign: 'center',
							borderRadius: 4
						}}
						disabled={!request.comment}
						onClick={() => {
							const lastComment = request.comment ? request.comment : '';
							handleViewComment(lastComment ? lastComment : 'text');
							commentModalRef.current?.open();
						}}
					>
						<Iconify icon="octicon:comment-24" width={21} />
					</Button>
				</TableCell>
				<TableCell sx={{ border: 'none', textAlign: 'center' }}>
					<Button
						variant="text"
						sx={{
							color: caloTheme.palette.neutral900,
							fontFamily: 'Roboto',
							textAlign: 'center',
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							textTransform: 'capitalize',
							height: '34px',
							borderRadius: 4,
							boxShadow: 'none',
							':hover': {
								boxShadow: 'none',
								color: caloTheme.palette.neutral900
							}
						}}
						disabled={!request.attachments || request.attachments.length === 0}
						onClick={() => {
							setSelectedAttachments(request.attachments || []);
							attachmentModalRef.current?.open();
						}}
					>
						<Iconify icon="mingcute:attachment-line" rotate={1} width={21} />
					</Button>
				</TableCell>
			</TableRow>

			<Popup
				ref={commentModalRef}
				onClose={() => {
					setSelectedComment(null);
					commentModalRef.current?.close();
				}}
				title={'Comments'}
				style={{
					fullWidth: true,
					maxWidth: 'md'
				}}
			>
				<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'}>
					<Typography
						variant="h2"
						sx={{
							color: caloTheme.palette.neutral900,
							pl: 1,
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '20px'
						}}
					>
						{selectedComment}
					</Typography>
					<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} sx={{ paddingTop: 2 }}>
						<Button
							variant="text"
							sx={{
								fontSize: '16px',
								color: caloTheme.palette.primary500,
								width: '90px',
								height: '50px',
								fontWeight: 600
							}}
							onClick={() => {
								setSelectedComment(null);
								commentModalRef.current?.close();
							}}
						>
							Done
						</Button>
					</Stack>
				</Box>
			</Popup>

			<Popup
				ref={attachmentModalRef}
				onClose={() => {
					attachmentModalRef.current?.close();
				}}
				title={'Attachments'}
				style={{
					fullWidth: true,
					maxWidth: 'xl'
				}}
			>
				<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
					<Grid container spacing={5}>
						{request.attachments?.map((image, index) => (
							<Grid item key={image}>
								<Grid container alignItems="center">
									<Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
										<Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
											<Stack
												key={image}
												display={'flex'}
												flexDirection={'column'}
												justifyContent={'start'}
												onClick={() => {
													setSelectedAttachment(image);
													setCurrentIndex(index);
													viewImageRef.current.open();
												}}
											>
												<Image
													key={image}
													url={`${process.env.REACT_APP_BUCKET_URL}/gifts/${request.id.split('#')[1]}/${image}`}
													alt={'Attachments'}
													height={220}
												/>
											</Stack>
											<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
												<Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
													Attachment {index + 1}
												</Typography>
											</Stack>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Box>
			</Popup>

			<Popup
				ref={viewImageRef}
				onClose={() => {
					viewImageRef.current?.close();
					setSelectedAttachment('');
					setCurrentIndex(0);
				}}
				title={'Attachments'}
				style={{
					fullWidth: true,
					maxWidth: 'xl'
				}}
			>
				{selectedAttachments.length > 0 && (
					<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ width: '100%' }}>
						<Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
							<Stack justifyContent={'center'} display={selectedAttachments.length > 1 ? 'flex' : 'none'}>
								<IconButton
									onClick={() =>
										handleChangeImage(request.attachments || [], 'Left', selectedAttachments.indexOf(selectedAttachment))
									}
									sx={{
										backgroundColor: caloTheme.palette.primary200,
										cursor: 'pointer',
										display: currentIndex <= 0 ? 'none' : 'flex'
									}}
								>
									<Iconify icon="formkit:arrowleft" color={caloTheme.palette.primary500} width={32} height={'32px'} />
								</IconButton>
							</Stack>
							<Stack key={selectedAttachment} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
								<Image
									key={selectedAttachment}
									url={`${process.env.REACT_APP_BUCKET_URL}/gifts/${request.id.split('#')[1]}/${selectedAttachment}`}
									alt={'Attachments'}
									height={612}
									width={1024}
								/>
							</Stack>
							<Stack justifyContent={'center'} display={selectedAttachments.length > 1 ? 'flex' : 'none'}>
								<IconButton
									onClick={() =>
										handleChangeImage(request.attachments || [], 'Right', selectedAttachments.indexOf(selectedAttachment))
									}
									sx={{
										backgroundColor: caloTheme.palette.primary200,
										cursor: 'pointer',
										display: currentIndex + 1 >= selectedAttachments.length ? 'none' : 'flex'
									}}
								>
									<Iconify icon="formkit:arrowright" color={caloTheme.palette.primary500} width={32} height={'32px'} />
								</IconButton>
							</Stack>
						</Box>
						<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} sx={{ paddingTop: 2 }}>
							<Button
								variant="text"
								sx={{
									fontSize: '16px',
									color: caloTheme.palette.primary500,
									width: '90px',
									height: '50px',
									fontWeight: 600
								}}
								onClick={() => {
									setSelectedAttachment('');
									viewImageRef.current?.close();
								}}
							>
								Close
							</Button>
						</Stack>
					</Box>
				)}
			</Popup>
			<Popup
				style={{ fullWidth: true }}
				title="Request Type"
				ref={moreRequestRef}
				onClose={() => moreRequestRef.current?.close()}
			>
				<Box flexDirection={'column'}>
					<Stack flexDirection={'row'} sx={{ flexWrap: 'wrap' }}>
						{handleRenderTypeChips(request, 'all').map((chip, index) => (
							<Stack key={index} sx={{ mb: 1 }}>
								{chip}
							</Stack>
						))}
					</Stack>
					<Stack>
						<Button
							variant="contained"
							sx={{
								textTransform: 'none',
								height: '45px',
								mx: 'auto',
								width: '113px',
								lineHeight: '24px',
								fontWeight: 600,
								fontSize: '20px',
								borderRadius: '8px',
								padding: '14px 20px 14px 20px',
								color: caloTheme.palette.white,
								backgroundColor: caloTheme.palette.primary500,
								[caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
									justifyItems: 'center',
									margin: 'auto',
									marginTop: 4,
									width: 'auto'
								}
							}}
							onClick={() => moreRequestRef.current?.close()}
						>
							Confirm
						</Button>
					</Stack>
				</Box>
			</Popup>
		</>
	);
};

export default SpecialRequestTableRow;

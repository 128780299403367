import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Options } from '../../libs';

interface onSearch<V> {
	(searchText: V, selected: true): void;
	(searchText: string, selected: false): void;
}

interface SearchBarProps<T, V> {
	onSearch: onSearch<V>;
	getOptionValue: (value: Options<T> | null) => V;
	placeholder?: string;
	options: Options<T>[];
	searchLoading: boolean;
	debounce: boolean;
}

function SearchBar<T, V>({
	searchLoading,
	placeholder = 'Search...',
	onSearch,
	getOptionValue,
	options,
	debounce
}: SearchBarProps<T, V>) {
	const [selectedOption, setSelectedOption] = useState<Options<T> | null>(null);
	const [searchTerm, setSearchTerm] = useState('');

	const debounced = useDebouncedCallback(
		async (value) => {
			await handleOnInputChange(value);
		},
		debounce ? 600 : 0
	);

	const handleOnInputChange = async (value: string) => {
		onSearch(value, false);
	};

	return (
		<Autocomplete
			id="search-id"
			value={selectedOption}
			inputValue={searchTerm}
			loading={searchLoading}
			options={!searchLoading && searchTerm ? options : []}
			getOptionLabel={(option) => option.label || ''}
			isOptionEqualToValue={(option, value) => {
				return isEqual(option, value);
			}}
			onInputChange={(event, val) => {
				if (event?.type === 'change') {
					setSearchTerm(val);
					debounced(val);
				}
			}}
			onChange={(_, val) => {
				setSelectedOption(val);
				setSearchTerm(val?.label ?? '');

				onSearch(getOptionValue(val), true);
			}}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						size="small"
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							style: {
								fontSize: '17px',
								fontWeight: 400,
								borderRadius: '8px'
							},
							inputProps: {
								...params.inputProps,
								className: '',
								style: {
									fontSize: '17px',
									fontWeight: 400
								}
							}
						}}
					/>
				);
			}}
			renderOption={(props, option) => <li {...props}>{option.label}</li>}
		/>
	);
}

export default SearchBar;

import { compact } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../App';
import EmptyMessage from '../../components/EmptyMessage';
import TemperatureSelectModal from '../../components/TemperatureSelectModal';
import { BaggingSessionInput, FoodType, Kitchen, PreDispatchCheckHeader, Session, SessionInfo, SessionStatus } from '../../libs';
import {
	BAGGING_STATUS_QUERY,
	MODIFY_BAGGING_SESSION_MUTATION,
	SUBSCRIBE_TO_BAGGING_DISPATCH_SUBSCRIPTION,
	SUBSCRIBE_TO_BAGGING_SESSION_SUBSCRIPTION
} from '../../libs/graphQL';
import { getTableAttributes, makeCustomMealRepresentative } from '../../libs/utils/helperFunctions';
import SectionHeader from './SectionHeader';
import SectionTable from './SectionTable';

interface PreDispatchCheckProps {
	kitchen: Kitchen;
	date: string;
	shift: Session;
}

const PreDispatchCheck = ({ kitchen, date, shift }: PreDispatchCheckProps) => {
	const [sessionInfo, setSessionInfo] = useState<SessionInfo>({
		sessions: [],
		dispatches: [],
		kitchen: '',
		date: ''
	});
	const [isTemperatureModalOpened, setIsTemperatureModalOpened] = useState(false);
	const [roomTemperature, setRoomTemperature] = useState('');
	const [isStarted, setIsStarted] = useState(false);
	const [isEnded, setIsEnded] = useState(false);
	const [modifyBaggingSession] = useMutation(MODIFY_BAGGING_SESSION_MUTATION);
	const [sessionValue, setSessionValue] = useState(Session.morning);
	const [activeSessionID, setActiveSessionID] = useState('');
	const [mainMealTemperatureValue, setMainMealTemperatureValue] = useState(0);
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const { loading: Loading, refetch } = useQuery(BAGGING_STATUS_QUERY, {
		variables: { kitchen, date },
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setSessionInfo(data.baggingStatus ? { ...data.baggingStatus } : {});
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	useSubscription(SUBSCRIBE_TO_BAGGING_DISPATCH_SUBSCRIPTION);

	const { data: sessionNewData } = useSubscription(SUBSCRIBE_TO_BAGGING_SESSION_SUBSCRIPTION);

	const handleCloseModals = () => {
		setIsTemperatureModalOpened(false);
	};

	const handleRoomTemperatureState = (newTemperature: string) => {
		setRoomTemperature(newTemperature);
	};

	const handleUpdateRoomTemperature = (newTemperature: string) => {
		handleRoomTemperatureState(newTemperature);
		handleUpdateBaggingSessionTemperature(newTemperature);
	};

	const handleUpdateSessionValue = (newSession: Session) => {
		setSessionValue(newSession);
	};

	const handleUpdateActiveSessionID = (newID: string) => {
		setActiveSessionID(newID);
	};

	const handleUpdateBaggingSessionTemperature = async (temperature: string) => {
		if (activeSessionID && isStarted) {
			const input: BaggingSessionInput = {
				kitchen,
				date,
				roomTemperature: parseFloat(temperature),
				session: sessionValue,
				status: SessionStatus.started
			};
			await modifyBaggingSession({
				variables: { id: activeSessionID, input },
				onCompleted: () => {
					toast.success('The room temperature updated successfully');
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	const handleUpdateMainTemperatureValue = (newTemperature: number) => {
		setMainMealTemperatureValue(newTemperature);
	};

	useEffect(() => {
		refetch({ kitchen, date });
	}, [sessionValue]);

	if (Loading && !appContext.isOffline) {
		return (
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={Loading} onClick={handleCloseModals}>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	const getFilteredDispatchesForSection = (sessionInfo: SessionInfo, tableName: string) => {
		const tableAttributes = getTableAttributes(tableName, theme);
		if ((sessionInfo.dispatches?.length ?? 0) > 0) {
			const dispatches = sessionInfo.dispatches?.filter(
				(f) =>
					f.session === sessionValue &&
					f.foodType?.includes((tableAttributes.condition as FoodType) || (tableAttributes.condition2 as FoodType))
			);
			const custom = compact(dispatches?.filter((f) => f.isCustomFood));
			const nonCustom = compact(dispatches?.filter((f) => !f.isCustomFood));
			return custom.length > 0 ? [...nonCustom, makeCustomMealRepresentative(custom)] : nonCustom;
		}

		return [];
	};

	const isPreview = shift === Session.all && sessionValue !== Session.all;

	return (
		<Box sx={{ width: '96%' }}>
			{sessionInfo.dispatches || !appContext.isOffline ? (
				<Stack direction="column" sx={{ ml: '20px', my: '5px', width: '100%' }}>
					<SectionHeader
						isStarted={isStarted}
						setIsStarted={setIsStarted}
						isEnded={isEnded}
						setIsEnded={setIsEnded}
						roomTemperature={roomTemperature}
						setOpenTemperatureModal={setIsTemperatureModalOpened}
						handleUpdateSessionValue={handleUpdateSessionValue}
						handleUpdateActiveSessionID={handleUpdateActiveSessionID}
						kitchen={kitchen}
						date={date}
						sessions={sessionInfo.sessions ?? []}
						handleRoomTemperatureState={handleRoomTemperatureState}
						sessionNewData={sessionNewData?.subscribeToBaggingSession}
						refetch={refetch}
						isPreview={isPreview}
						shift={shift}
					/>
					{Object.keys(PreDispatchCheckHeader).map((tableName, index) => (
						<Box sx={{ width: '100%' }} key={index}>
							{getFilteredDispatchesForSection(sessionInfo, tableName).length > 0 ? (
								<SectionTable
									tableName={tableName}
									dispatches={getFilteredDispatchesForSection(sessionInfo, tableName)}
									kitchen={kitchen}
									date={date}
									mainMealTemperatureValue={mainMealTemperatureValue}
									handleUpdateMainTemperatureValue={handleUpdateMainTemperatureValue}
									isPreview={isPreview}
								/>
							) : (
								''
							)}
						</Box>
					))}
				</Stack>
			) : (
				<Box sx={{ height: '500px' }}>
					<EmptyMessage label={'no data'} />
				</Box>
			)}
			<TemperatureSelectModal
				open={isTemperatureModalOpened}
				handleClose={handleCloseModals}
				handleAccept={handleUpdateRoomTemperature}
				type={'room'}
				startTemp={roomTemperature === '' ? '0.0' : roomTemperature}
			/>
		</Box>
	);
};

export default PreDispatchCheck;

import enLocale from 'date-fns/locale/en-GB';

import { Dictionary } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Divider, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { caloTheme } from '../../../assets/themes/calo';
import DatePicker from '../../../components/DatePicker';
import { getComplainsOptionsForReason, getSelectableOptions } from '../../../libs/utils/helperFunctions';

interface FilterItemsProps {
	toggleFilterDrawer: (data: boolean) => void;
	handleResultsButtonClick: () => void;
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
	category: string;
	type: string;
	setCategory: (category: string) => void;
	setType: (type: string) => void;
	isCategoryMenuOpened: boolean;
	categoryMenuAnchorEl: HTMLElement | null;
	handleCategorySelectButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	handleCategoryCloseSelectMenu: () => void;
	isTypeMenuOpened: boolean;
	typeMenuAnchorEl: HTMLElement | null;
	handleTypeSelectButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	handleTypeCloseSelectMenu: () => void;
}

const FilterItems = ({
	toggleFilterDrawer,
	handleResultsButtonClick,
	dateRange,
	category,
	type,
	setCategory,
	setType,
	setDateRange,
	isCategoryMenuOpened,
	categoryMenuAnchorEl,
	handleCategorySelectButtonClick,
	handleCategoryCloseSelectMenu,
	isTypeMenuOpened,
	typeMenuAnchorEl,
	handleTypeSelectButtonClick,
	handleTypeCloseSelectMenu
}: FilterItemsProps) => {
	const complaintsCategoriesAndTypes: Dictionary<
		{
			value: string;
			label: string;
		}[]
	> = getSelectableOptions();
	const theme = useTheme();
	const StyledMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 200,
			color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0'
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5)
				},
				'&:active': {
					backgroundColor: alpha(theme.palette.primary500, theme.palette.action.selectedOpacity)
				}
			}
		}
	}));

	return (
		<Box sx={{ width: 290 }} role="presentation">
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', my: 1, mx: 3 }}>Filter</Box>
			<IconButton
				aria-label="close"
				onClick={() => toggleFilterDrawer(false)}
				sx={{
					position: 'absolute',
					right: 10,
					top: 10,
					fontWeight: 600,
					color: 'black'
				}}
			>
				<CloseIcon />
			</IconButton>

			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Category</Box>
			<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3, my: 1 }}>
				<Button
					id="menu-button"
					aria-controls={isCategoryMenuOpened ? 'menu-button' : undefined}
					aria-haspopup="true"
					aria-expanded={isCategoryMenuOpened ? 'true' : undefined}
					variant="outlined"
					disableElevation
					onClick={(event) => {
						!categoryMenuAnchorEl && handleCategorySelectButtonClick(event);
					}}
					endIcon={<KeyboardArrowDownIcon />}
					sx={{
						textTransform: 'capitalize',
						fontSize: '12px',
						fontWeight: 600,
						width: '100%',
						border: '2px solid ' + theme.palette.primary500,
						borderRadius: '8px',
						height: '40px',
						'&:hover': { border: '2px solid ' + theme.palette.primary500 }
					}}
				>
					{category}
				</Button>
				<StyledMenu
					MenuListProps={{
						'aria-labelledby': 'customized-button'
					}}
					anchorEl={categoryMenuAnchorEl}
					open={isCategoryMenuOpened}
					onClose={handleCategoryCloseSelectMenu}
				>
					<MenuItem
						sx={{ fontWeight: 600, py: 0.5 }}
						value={category}
						onClick={() => {
							setCategory('All');
							setType('All');
							handleCategoryCloseSelectMenu();
						}}
					>
						<Box>All</Box>
					</MenuItem>
					<Divider sx={{ my: 0.5 }} />
					{Object.keys(complaintsCategoriesAndTypes).map((categoryLabel, index) => (
						// const categoryKey = invert(ComplaintReason)[categoryLabel] || 'All';

						<Box key={index}>
							<MenuItem
								sx={{ fontWeight: 600, py: 0.5 }}
								value={categoryLabel}
								onClick={() => {
									setCategory(categoryLabel);
									setType('All');
									handleCategoryCloseSelectMenu();
								}}
							>
								<Box>{categoryLabel}</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
						</Box>
					))}
				</StyledMenu>
			</Stack>

			{category !== 'All' && (
				<>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Complaint</Box>
					<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3, my: 1 }}>
						<Button
							id="menu-button"
							aria-controls={isTypeMenuOpened ? 'menu-button' : undefined}
							aria-haspopup="true"
							aria-expanded={isTypeMenuOpened ? 'true' : undefined}
							variant="outlined"
							disableElevation
							onClick={(event) => {
								!typeMenuAnchorEl && handleTypeSelectButtonClick(event);
							}}
							endIcon={<KeyboardArrowDownIcon />}
							sx={{
								textTransform: 'capitalize',
								fontSize: '14px',
								fontWeight: 600,
								width: '100%',
								border: '2px solid ' + theme.palette.primary500,
								borderRadius: '8px',
								height: '40px',
								'&:hover': { border: '2px solid ' + theme.palette.primary500 }
							}}
						>
							{type}
						</Button>
						<StyledMenu
							MenuListProps={{
								'aria-labelledby': 'customized-button'
							}}
							anchorEl={typeMenuAnchorEl}
							open={isTypeMenuOpened}
							onClose={handleTypeCloseSelectMenu}
						>
							<MenuItem
								sx={{ fontWeight: 600, py: 0.5 }}
								value={type}
								onClick={() => {
									setType('All');
									handleTypeCloseSelectMenu();
								}}
							>
								<Box>All</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							{
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore

								getComplainsOptionsForReason(category).map((type, index) => (
									<Box key={index}>
										<MenuItem
											sx={{ fontWeight: 600, py: 0.5 }}
											value={type.label}
											onClick={() => {
												setType(type.label);
												handleTypeCloseSelectMenu();
											}}
										>
											<Box>{type.label}</Box>
										</MenuItem>
										<Divider sx={{ my: 0.5 }} />
									</Box>
								))
							}
						</StyledMenu>
					</Stack>
				</>
			)}

			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Date</Box>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
				<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3 }}>
					<DatePicker
						date={dateRange[0]}
						setDate={(date: Date | null) => setDateRange([date, dateRange[1]])}
						label="Start Date"
					/>
					<Box sx={{ mx: 2 }} />
					<DatePicker date={dateRange[1]} setDate={(date: Date | null) => setDateRange([dateRange[0], date])} label="End Date" />
				</Stack>
			</LocalizationProvider>
			<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3, my: 1 }}>
				<Button
					variant="contained"
					onClick={() => handleResultsButtonClick()}
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						fontWeight: 600,
						width: '100%',
						color: 'white'
					}}
				>
					Show Results
				</Button>
			</Stack>
		</Box>
	);
};
export default FilterItems;

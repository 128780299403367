import { Link } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material/styles';

import { NotificationType, RoutePaths } from '../../libs';
import { sendNotification } from '../../libs/utils/notification';

interface OfflineAlertProps {
	type: NotificationType;
	pendingReq: number;
}
const NotificationAlert = ({ type, pendingReq }: OfflineAlertProps) => {
	const theme = useTheme();
	const audio = new Audio(type === NotificationType.shortage ? '/notification.mp3' : '/sound.mp3');
	audio.play();
	sendNotification(pendingReq, type);
	const toLink =
		type === NotificationType.shortage
			? RoutePaths.shortageDisplay
			: type === NotificationType.lockTimeChange
				? RoutePaths.lockTimeChanges
				: RoutePaths.logisticsChanges;

	return (
		<Link className="link" to={toLink}>
			<Alert
				variant="outlined"
				severity={'warning'}
				icon={false}
				sx={{
					fontFamily: 'Roboto',
					fontSize: '12px',
					fontWeight: 400,
					border: 'none',
					color: theme.palette.neutral900,
					p: 0,
					m: 0,
					lineHeight: '14px',
					backgroundColor: theme.palette.primary50
				}}
			>
				<AlertTitle
					sx={{
						fontSize: '16px',
						fontWeight: 600,
						lineHeight: '19px',
						mb: 2
					}}
				>
					<Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
						<Typography
							sx={{
								backgroundColor: theme.palette.primary500,
								borderRadius: '50%',
								color: 'white',
								mr: 1,
								textAlign: 'center',
								pt: '4px',
								width: '25px',
								height: '25px',
								fontSize: '14px',
								fontWeight: 400,
								lineHeight: '17px'
							}}
						>
							{pendingReq}
						</Typography>
						{type === NotificationType.shortage && 'Pending'} {type} Request
					</Stack>
				</AlertTitle>
				{type === NotificationType.shortage
					? 'There has been a new component shortage request'
					: type === NotificationType.lockTimeChange
						? 'There has been a request to swap a meal'
						: 'There has been a request to modify a delivery'}
				, press here to view the request
			</Alert>
		</Link>
	);
};
export default NotificationAlert;

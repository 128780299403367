import { addDays, format, subDays } from 'date-fns/fp';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { DateRange } from '@mui/lab/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { EXPORT_FOOD_COMPONENT_WASTAGE_MUTATION } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, formatDate, getDate } from '../../../libs/utils/helperFunctions';
import FilterItems from './FilterItems';

interface Filters {
	startDate: string;
	endDate: string;
}

interface FilterProps {
	filters: Filters;
	setFilters: (data: Filters) => void;
	setIsFilterEnabled: (data: boolean) => void;
	isFilterEnabled: boolean;
	date: string;
	kitchen: string;
	isDownloadDisabled: boolean;
	lastDayDate: string;
}

const Filter = ({
	filters,
	setFilters,
	setIsFilterEnabled,
	isFilterEnabled,
	date,
	kitchen,
	isDownloadDisabled,
	lastDayDate
}: FilterProps) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isExportMenuOpened = Boolean(exportMenuAnchorEl);
	const [exportFileName, setExportFileName] = useState<string>(
		`Calo_Food_Components_Wastage_${kitchen}_${isFilterEnabled ? filters.startDate : filters.startDate}_${isFilterEnabled ? filters.endDate : ''}`
	);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const appContext = useContext(AppContext);
	const theme = useTheme();
	const [downloadFoodComponentWastage, { loading }] = useMutation(EXPORT_FOOD_COMPONENT_WASTAGE_MUTATION, {
		onCompleted: (data) => {
			createAutoDownloadLinkFromFile(
				data.exportFoodComponentWastage.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				exportFileName
			);
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	useEffect(() => {
		setDateRange([new Date(filters.startDate), new Date(filters.endDate)]);
	}, [filters]);

	const handleResultsButtonClick = () => {
		if (dateRange[0] && dateRange[1]) {
			setIsFilterEnabled(true);
			setIsFilterOpened(false);
			setFilters({
				startDate: dateRange[0] ? getDate(dateRange[0]) : '',
				endDate: dateRange[1] ? getDate(dateRange[1]) : ''
			});
		} else {
			toast.error('Please select a valid date range');
		}
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const handleExportChange = () => {
		setExportMenuAnchorEl(null);
		setExportFileName(
			`Calo_Food_Components_Wastage_${kitchen}_${isFilterEnabled ? filters.startDate : filters.startDate}_${isFilterEnabled ? filters.endDate : ''}`
		);
		downloadFoodComponentWastage({
			variables: {
				kitchen,
				startDate: isFilterEnabled ? filters.startDate : lastDayDate,
				endDate: isFilterEnabled ? filters.endDate : lastDayDate
			}
		});
	};

	const handleClearButtonClick = () => {
		setIsFilterOpened(false);
		setIsFilterEnabled(false);
		setDateRange([null, null]);
		setFilters({
			startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
			endDate: format('yyyy-MM-dd')(addDays(1)(Date.parse(date)))
		});
	};

	return (
		<div>
			<React.Fragment>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					{isFilterEnabled && (
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mr: 3 }}>
							<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>Filter:</Box>
							<Box sx={{ fontSize: '20px' }}>
								{formatDate(filters.startDate, 'dd-MM-yyyy')}{' '}
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M3.33301 8H12.6663" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>{' '}
								{formatDate(filters.endDate, 'dd-MM-yyyy')}
							</Box>
						</Stack>
					)}
					<LoadingButton
						loading={loading}
						id="export-button"
						aria-controls={isExportMenuOpened ? 'export-button' : undefined}
						aria-haspopup="true"
						aria-expanded={isExportMenuOpened ? 'true' : undefined}
						disableElevation
						disabled={isDownloadDisabled || appContext.isOffline}
						onClick={(event) => {
							!exportMenuAnchorEl && setExportMenuAnchorEl(event.currentTarget);
						}}
						variant="text"
						color="primary"
						sx={{
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							m: 2,
							position: 'absolute',
							right: '200px'
						}}
						endIcon={
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5"
									stroke={isDownloadDisabled || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.66675 7.16663L8.00008 10.5L11.3334 7.16663"
									stroke={isDownloadDisabled || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8 10.5V2.5"
									stroke={isDownloadDisabled || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					>
						Export
					</LoadingButton>
					<Menu
						MenuListProps={{
							'aria-labelledby': 'export-button'
						}}
						anchorEl={exportMenuAnchorEl}
						open={isExportMenuOpened}
						onClose={() => setExportMenuAnchorEl(null)}
					>
						<MenuItem sx={{ fontWeight: 600, py: 0.5 }} value={'CSV'} onClick={handleExportChange}>
							<Box>Export as CSV</Box>
						</MenuItem>
					</Menu>
					<Button
						variant="text"
						color={isFilterEnabled ? undefined : 'primary'}
						disabled={appContext.isOffline}
						sx={{
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							m: 2,
							position: 'absolute',
							right: `${isFilterEnabled ? '20px' : '15px'}`,
							px: `${isFilterEnabled ? 2 : 'inherits'}`,
							py: `${isFilterEnabled ? 0.5 : 'inherits'}`,
							backgroundColor: `${isFilterEnabled ? theme.palette.primary50 : 'inherits'}`,
							':hover': {
								backgroundColor: `${isFilterEnabled ? 'white' : 'inherits'}`,
								border: `${isFilterEnabled ? '2px solid ' + theme.palette.primary500 : 'inherits'}`
							}
						}}
						onClick={() => (isFilterEnabled ? handleClearButtonClick() : setIsFilterOpened(true))}
						endIcon={
							isFilterEnabled ? (
								<CloseIcon />
							) : (
								<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M14.6666 1.5H1.33325L6.66659 7.80667V12.1667L9.33325 13.5V7.80667L14.6666 1.5Z"
										stroke={appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)
						}
					>
						{isFilterEnabled ? 'Clear Filters' : 'Filter'}
					</Button>
				</Grid>
				<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
					<FilterItems
						toggleFilterDrawer={setIsFilterOpened}
						handleResultsButtonClick={handleResultsButtonClick}
						dateRange={dateRange}
						setDateRange={setDateRange}
					/>
				</Drawer>
			</React.Fragment>
		</div>
	);
};
export default Filter;

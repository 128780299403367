import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { FoodComponentQualityStandard } from '@calo/dashboard-types';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Typography } from '@mui/material';

import { caloTheme } from '../../../../../assets/themes/calo';
import { Image } from '../../../../../components';
import { QualityRating } from '../../../../../libs';
import { renderDotColor } from '../../../utils';

interface QualityStandardBodyProps {
	standard: FoodComponentQualityStandard;
	standardRate: QualityRating | null;
	handleRateStandard: (rating: QualityRating, standardId: string) => void;
}

const QualityStandardBody = ({ standard, standardRate, handleRateStandard }: QualityStandardBodyProps) => {
	const [isExtended, setIsExtended] = useState(false);
	const handleRateStandardPress = (rating: QualityRating, standardId: string) => {
		handleRateStandard(rating, standardId);
		setIsExtended(false);
	};

	return (
		<Box sx={{ width: '100%', height: 'auto', borderBottom: 1, borderBottomColor: 'lightgrey' }}>
			<Button
				onClick={() => setIsExtended(!isExtended)}
				sx={{
					color: 'black',
					width: '100%',
					height: 50,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Typography variant="body1" fontWeight={600} sx={{ textTransform: 'none' }}>
					{standard.name}
				</Typography>
				<Box display="flex" flexDirection="row" alignItems="center">
					<CircleIcon sx={{ color: renderDotColor(standardRate) }} />
					{isExtended ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</Box>
			</Button>
			{isExtended && (
				<Box sx={{ width: '100%', height: 'auto' }}>
					{standard.imageURLs && (
						<Carousel showArrows={false} showStatus={false}>
							{standard.imageURLs.map((image, index) => (
								<Box
									key={index}
									sx={{
										borderRadius: 3,
										width: '100%',
										marginBottom: 4,
										minHeight: 200,
										height: '200px',
										overflow: 'hidden',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<Image
										key={index}
										alt={`${standard.name} Image ${index}`}
										style={{ width: 290, height: 'auto' }}
										url={`${image.replace('/original', '')}/square@1x.jpg`}
									/>
								</Box>
							))}
						</Carousel>
					)}
					<Typography variant="body1">{standard.description ?? ''}</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingY: 3 }}>
						<Button
							key={`${standardRate}#${QualityRating.bad}`}
							variant="outlined"
							color="error"
							onClick={() => handleRateStandardPress(QualityRating.bad, standard.id)}
							sx={{
								height: 50,
								borderRadius: 2,
								backgroundColor: standardRate === QualityRating.bad ? '#FCE6E6' : caloTheme.palette.white
							}}
						>
							<CloseIcon />
						</Button>
						<Button
							key={`${standardRate}#${QualityRating.neutral}`}
							variant="outlined"
							color="warning"
							onClick={() => handleRateStandardPress(QualityRating.neutral, standard.id)}
							sx={{
								height: 50,
								borderRadius: 2,
								backgroundColor: standardRate === QualityRating.neutral ? '#FFF6E6' : caloTheme.palette.white
							}}
						>
							<ErrorOutlineIcon />
						</Button>
						<Button
							key={`${standardRate}#${QualityRating.good}`}
							variant="outlined"
							color="primary"
							onClick={() => handleRateStandardPress(QualityRating.good, standard.id)}
							sx={{
								height: 50,
								borderRadius: 2,
								backgroundColor: standardRate === QualityRating.good ? '#E6F3EC' : caloTheme.palette.white
							}}
						>
							<CheckIcon />
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default QualityStandardBody;

import { sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, Table, TableBody } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { ComponentBatch, QualityMetric, QualityMetricsFoodComponent } from '../../../../libs';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { BatchDrawerData } from '../../Section/Section';
import SectionTableRowFolded from '../SectionTableRowFolded';

interface Props {
	qualityMetric: QualityMetric;
	qualityMetricsFoodComponent: QualityMetricsFoodComponent;
	batches: ComponentBatch[];
	isExpanded: boolean;
	setBatchDrawerData: (data: BatchDrawerData) => void;
}

const SectionTableRow = ({ qualityMetric, qualityMetricsFoodComponent, batches, isExpanded, setBatchDrawerData }: Props) => {
	const [open, setOpen] = useState(isExpanded);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.text.primary,
			fontSize: '14px',
			width: `${92 / 6}%`
		}
	}));

	useEffect(() => {
		setOpen(isExpanded);
	}, [isExpanded]);

	return (
		<>
			<TableRow>
				<StyledTableCell sx={{ textAlign: 'left !important' }}>{qualityMetricsFoodComponent.name?.en}</StyledTableCell>
				<StyledTableCell>{qualityMetric.shift}</StyledTableCell>
				<StyledTableCell>-</StyledTableCell>
				<StyledTableCell>{roundToTwoDecimals(qualityMetricsFoodComponent.weight ?? 0)} g</StyledTableCell>
				<StyledTableCell>{getPercentage(qualityMetricsFoodComponent.consistencyScore ?? 0)}</StyledTableCell>
				<StyledTableCell>
					{getCurrencyFromKitchen(qualityMetric.kitchen)}{' '}
					{roundToTwoDecimals((qualityMetricsFoodComponent.weight ?? 0) * (qualityMetricsFoodComponent.cost ?? 0))}
				</StyledTableCell>
				<StyledTableCell sx={{ width: '8% !important', py: 0 }} onClick={() => setOpen(!open)}>
					{batches.length > 0 ? open ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" /> : ''}
				</StyledTableCell>
			</TableRow>
			{batches.length > 0 && (
				<TableRow>
					<StyledTableCell sx={{ p: 0, backgroundColor: caloTheme.palette.neutral50 }} colSpan={7}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table>
								<TableBody>
									{sortBy(batches, 'batchNumber').map((batch) => (
										<SectionTableRowFolded
											key={`${batch.id}#${batch.date}#${batch.session}`}
											qualityMetricsFoodComponent={qualityMetricsFoodComponent}
											foodComponentBatch={batch}
											setBatchDrawerData={setBatchDrawerData}
										/>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</StyledTableCell>
				</TableRow>
			)}
		</>
	);
};

export default SectionTableRow;

import { format } from 'date-fns/fp';
import { compact, isNil, sortBy } from 'lodash-es';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { ComponentBatch, CookingStationName, QualityMetric } from '../../../../libs';
import { BatchDrawerData } from '../../Section/Section';
import SectionTableRow from '../SectionTableRow';

interface Props {
	qualityMetric: QualityMetric;
	batches: ComponentBatch[];
	name?: string;
	filterText?: string;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
	stations: CookingStationName[];
	setBatchDrawerData: (data: BatchDrawerData) => void;
}

const SectionTable = ({
	qualityMetric,
	batches,
	name,
	filterText,
	isExpanded,
	setIsExpanded,
	stations,
	setBatchDrawerData
}: Props) => {
	const theme = useTheme();
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.grey[100],
			color: theme.palette.text.primary,
			fontSize: '14px',
			fontWeight: 500,
			width: `${92 / 6}%`
		}
	}));

	const filteredComponents = compact(
		qualityMetric.components?.filter((f) =>
			!isNil(name) && name?.length > 0
				? f.name.en.toLowerCase().includes(name.toLowerCase())
				: true && stations.length > 0 && f.lastCookingStation
					? stations.includes(f.lastCookingStation)
					: true
		)
	);

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography sx={{ color: theme.palette.neutral900, fontSize: '24px' }}>
					{format('dd-MM-yyy')(new Date(qualityMetric.day ?? ''))}
				</Typography>
				{filterText && <Typography sx={{ fontSize: '24px', color: caloTheme.palette.neutral900 }}>{filterText}</Typography>}
			</Stack>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ textAlign: 'left !important' }}>Name</StyledTableCell>
						<StyledTableCell>Shift</StyledTableCell>
						<StyledTableCell>Cook</StyledTableCell>
						<StyledTableCell>Weight</StyledTableCell>
						<StyledTableCell>Consistency Score</StyledTableCell>
						<StyledTableCell>Cost</StyledTableCell>
						<StyledTableCell onClick={() => setIsExpanded(!isExpanded)} sx={{ width: '8% !important', py: 0 }}>
							{isExpanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredComponents.length > 0 ? (
						sortBy(filteredComponents, 'name.en').map((component) => (
							<SectionTableRow
								key={`${component.id}#${component.name.en.trim()}`}
								qualityMetricsFoodComponent={component}
								qualityMetric={qualityMetric}
								batches={batches?.filter(
									(b) => b.foodComponentId.split('#')[1] === component.id && b.session === qualityMetric.shift
								)}
								isExpanded={isExpanded}
								setBatchDrawerData={setBatchDrawerData}
							/>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={7}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No Data
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	);
};

export default SectionTable;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { caloTheme } from '../../../assets/themes/calo';
import { BaggingSession } from '../../../libs';
import { formatDate, getTimeAndDateInTimeZone } from '../../../libs/utils/helperFunctions';

interface SectionHeaderProps {
	session: BaggingSession;
}

const SectionHeader = ({ session }: SectionHeaderProps) => (
	<Grid container sx={{ width: '80%', mb: 2, mt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
		{session.date !== '' && (
			<Grid container sx={{ width: '100%' }} direction="row" justifyContent="flex-start" alignItems="center">
				<Box sx={{ mr: '8px', fontFamily: caloTheme.typography.fontFamily, fontSize: '28px' }}>
					{session.date && formatDate(session.date, 'dd-MM-yyyy')}
				</Box>
				<Stack direction="row" alignItems="center" sx={{ mx: '5px', mr: '20px' }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>Bagging time start:</Box>
					<Box sx={{ ml: '4px', fontSize: '20px' }}>
						{session.startTime ? getTimeAndDateInTimeZone(session.startTime, session.kitchen) : ''}
					</Box>
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ mx: '5px', mr: '20px' }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>
						{session.endTime ? 'Bagging time end:' : ''}
					</Box>
					<Box sx={{ ml: '4px', fontSize: '20px' }}>
						{session.endTime ? getTimeAndDateInTimeZone(session.endTime, session.kitchen) : ''}
					</Box>
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ mx: '5px', mr: '20px' }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>
						{session.roomTemperature ? 'Portioning Room Temperature:' : ''}
					</Box>
					<Box sx={{ ml: '4px', fontSize: '16px', fontWeight: 600 }}>
						{session.roomTemperature ? session.roomTemperature + '°C' : ''}
					</Box>
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ mx: '5px', mr: '20px' }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>{session.session ? 'Shift:' : ''}</Box>
					<Box sx={{ ml: '4px', fontSize: '16px', fontWeight: 600, textTransform: 'capitalize' }}>
						{session.session ? session.session : ''}
					</Box>
				</Stack>
			</Grid>
		)}
	</Grid>
);

export default SectionHeader;

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

import { caloTheme } from '../../../../assets/themes/calo';

interface HeaderRowItemProps {
	sIngredient: {
		type: 'header';
		header: string;
	};
}

const HeaderRowItem = ({ sIngredient }: HeaderRowItemProps) => {
	const theme = useTheme();

	const StyledIngredientCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			color: theme.palette.common.black,
			border: 'bottom',
			fontSize: 28,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 19,
			fontWeight: 600,
			color: theme.palette.common.black,
			border: '0',
			fontFamily: 'Roboto',
			lineHeight: '28px',
			height: 'auto',
			minHeight: '50px'
		}
	}));

	return (
		<TableRow>
			<TableCell
				sx={{
					border: 'none',
					p: 1,
					flexDirection: 'row',
					display: 'flex',
					mr: 2,
					width: '100%',
					borderCollapse: 'collapse',
					borderSpacing: '0px 4px'
				}}
			>
				<StyledIngredientCell sx={{ p: 1, pl: 2, width: '100%', backgroundColor: theme.palette.neutral50, borderRadius: '8px' }}>
					<Typography sx={{ fontSize: '23px', fontWeight: 600, color: theme.palette.neutral900 }}>
						{sIngredient.header}
					</Typography>
				</StyledIngredientCell>
			</TableCell>
		</TableRow>
	);
};

export default HeaderRowItem;

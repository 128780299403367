import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Chef } from '../../../../../libs';
import { DELETE_CHEF_MUTATION } from '../../../../../libs/graphQL';

interface CookItemProps {
	chef: Chef;
	setEditableCook: React.Dispatch<React.SetStateAction<Chef | null | undefined>>;
}

const CookItem = ({ chef, setEditableCook }: CookItemProps) => {
	const theme = useTheme();
	const [isDelete, setIsDelete] = useState(false);
	const [deleteChefUser] = useMutation(DELETE_CHEF_MUTATION);

	const handleDeleteCook = async () => {
		await deleteChefUser({
			variables: { id: chef.id, kitchen: chef.kitchen },
			onCompleted: () => {
				toast.success('Chef deleted successfully');
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	return (
		<ListItem sx={{ height: '53px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			{isDelete ? (
				<>
					<Typography sx={{ fontSize: '12px', color: '#000000' }}>Are you sure you want to delete cook?</Typography>
					<Stack sx={{ flexDirection: 'row', gap: '32px', marginRight: '12px' }}>
						<Button
							sx={{ color: theme.palette.neutral600, fontSize: '16px', textTransform: 'none', fontWeight: 600 }}
							onClick={() => setIsDelete(false)}
						>
							Cancel
						</Button>
						<Button
							sx={{ color: '#DC2626', fontSize: '16px', textTransform: 'none', fontWeight: 600 }}
							onClick={handleDeleteCook}
						>
							Delete
						</Button>
					</Stack>
				</>
			) : (
				<>
					<Stack>
						<Typography sx={{ fontSize: '11px' }}>Cook Name</Typography>
						<Typography sx={{ fontSize: '20px', color: '#000000', fontWeight: '500', lineHeight: '1' }}>{chef.name}</Typography>
					</Stack>
					<Stack sx={{ flexDirection: 'row', gap: '16px' }}>
						<IconButton onClick={() => setEditableCook(chef)}>
							<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M13.8603 2.21026C14.0855 1.98508 14.3528 1.80646 14.647 1.68459C14.9413 1.56272 15.2566 1.5 15.575 1.5C15.8935 1.5 16.2088 1.56272 16.503 1.68459C16.7972 1.80646 17.0646 1.98508 17.2897 2.21026C17.5149 2.43544 17.6935 2.70276 17.8154 2.99697C17.9373 3.29118 18 3.60651 18 3.92497C18 4.24342 17.9373 4.55875 17.8154 4.85296C17.6935 5.14717 17.5149 5.4145 17.2897 5.63968L5.71545 17.214L1 18.5L2.28603 13.7845L13.8603 2.21026Z"
									stroke="#343434"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</IconButton>
						<IconButton onClick={() => setIsDelete(true)}>
							<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 6.5H5H21" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path
									d="M8 6.5V4.5C8 3.96957 8.21071 3.46086 8.58579 3.08579C8.96086 2.71071 9.46957 2.5 10 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V6.5M19 6.5V20.5C19 21.0304 18.7893 21.5391 18.4142 21.9142C18.0391 22.2893 17.5304 22.5 17 22.5H7C6.46957 22.5 5.96086 22.2893 5.58579 21.9142C5.21071 21.5391 5 21.0304 5 20.5V6.5H19Z"
									stroke="#343434"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path d="M14 11.5V17.5" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M10 11.5V17.5" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</IconButton>
					</Stack>
				</>
			)}
		</ListItem>
	);
};

export default CookItem;

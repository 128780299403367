import { uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';

import { ComponentBatch } from '../libs';
import { GraphQLClient, LIST_COMPONENT_BATCHES_QUERY } from '../libs/graphQL';

const useListComponentBatchesWithPaginationQuery = (kitchen: string, dates: string[], shift?: string) => {
	const filters = { kitchen, shift };
	const [batches, setBatches] = useState<ComponentBatch[]>([]);
	const [page, setPage] = useState(0);
	const hasNext = page + 1 < dates.length;
	const { loading, refetch, fetchMore } = useQuery(LIST_COMPONENT_BATCHES_QUERY, {
		variables: {
			...filters,
			date: { gte: dates[page], lte: dates[page] }
		},
		skip: dates.length === 0,
		onCompleted(data) {
			if (data?.listComponentBatches?.data) {
				setBatches(data.listComponentBatches.data);
			}
		},
		onError(error) {
			toast.error(error.message);
		}
	});

	const clearData = () => {
		setBatches([]);
		GraphQLClient.clearStore();
	};

	const handleFetchMore = () => {
		if (hasNext) {
			const pageNum = page + 1;
			fetchMore({
				variables: {
					...filters,
					date: { gte: dates[pageNum], lte: dates[pageNum] }
				},
				updateQuery: (prev: any, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setPage(pageNum + 1);
						const prevData = Array.isArray(prev?.listComponentBatches?.data) ? prev.listComponentBatches.data : [];
						const moreData = Array.isArray(fetchMoreResult?.listComponentBatches?.data)
							? fetchMoreResult.listComponentBatches.data
							: [];
						const newComponentBatches = uniqBy([...prevData, ...moreData], 'id');

						return {
							...prev,
							listComponentBatches: {
								...prev.listComponentBatches,
								data: newComponentBatches
							}
						};
					}

					return prev;
				}
			});
		}
	};

	useEffect(() => {
		setPage(0);
	}, [dates, kitchen, shift]);

	return {
		componentBatchesLoading: loading,
		batches,
		refetchBatches: refetch,
		clearBatchesData: clearData,
		handleFetchMoreBatches: handleFetchMore,
		hasNext
	};
};

export default useListComponentBatchesWithPaginationQuery;

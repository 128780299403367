import { Stack } from '@mui/material';

import CaloLogo from '../../assets/caloLogo';
import ImageModal from '../../components/Image';

const Home = () => {
	return (
		<Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '90vh' }}>
			<ImageModal alt="Calo logo" url={`data:image/png;base64, ${CaloLogo}`} />
		</Stack>
	);
};

export default Home;

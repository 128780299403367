import { caloTheme } from '../../../../assets/themes/calo';

export const Styles = {
	fullRejectedButtonDesktop: {
		margin: 'auto',
		width: '90%',
		height: '40px',
		fontWeight: 600,
		fontSize: '18px',
		textTransform: 'none',
		color: caloTheme.palette.secondaryAlert500,
		backgroundColor: 'white',
		'&:hover': {
			color: caloTheme.palette.white,
			backgroundColor: caloTheme.palette.error.main
		}
	},
	fullApprovedButtonDesktop: {
		margin: 'auto',
		width: '90%',
		height: '40px',
		fontWeight: 600,
		fontSize: '18px',
		textTransform: 'none',
		color: caloTheme.palette.primary500,
		backgroundColor: 'white',
		'&:hover': {
			color: caloTheme.palette.white,
			backgroundColor: caloTheme.palette.primary500
		}
	},
	fullButtonMobile: {
		margin: 'auto',
		width: '90%',
		height: '40px',
		fontWeight: 600,
		fontSize: '16px',
		textTransform: 'none',
		borderRadius: '7px',
		color: caloTheme.palette.white,
		borderColor: caloTheme.palette.primary500,
		backgroundColor: caloTheme.palette.primary500,
		'&:hover': {
			color: caloTheme.palette.white,
			backgroundColor: caloTheme.palette.primary500
		}
	},
	fullButtonDesktop: {
		margin: 'auto',
		width: '90%',
		height: '40px',
		fontWeight: 600,
		fontSize: '16px',
		textTransform: 'none',
		borderRadius: '7px',
		color: 'black',
		borderColor: 'black',
		backgroundColor: 'white',
		'&:hover': {
			color: caloTheme.palette.white,
			backgroundColor: caloTheme.palette.primary500
		}
	},
	justIconStyle: {
		height: '40px',
		minWidth: '40px',
		padding: 0,
		paddingRight: 1.5,
		borderRadius: '8px',
		justifySelf: 'center'
	}
};

import { format } from 'date-fns/fp';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { capitalize, compact } from 'lodash-es';
import { useCallback, useContext, useState } from 'react';

import { Icon as Iconify } from '@iconify/react';
import Button from '@mui/material/Button';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { Delivery, Gift, GiftItemType, Session } from '../../../libs';

interface ExportProps {
	isDownloadDisabled: boolean;
	gifts: Gift[];
	kitchen: string;
	shift: Session;
	date: string;
	deliveryList: Delivery[];
}

const Export = ({ isDownloadDisabled, gifts, date, kitchen, shift, deliveryList }: ExportProps) => {
	const appContext = useContext(AppContext);
	const [isExportButtonClicked, setIsExportButtonClicked] = useState<boolean>(false);

	const onExport = useCallback(async () => {
		if (!gifts || gifts.length === 0) {
			console.log('No gifts data available to export.');
			return;
		}
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(`Gifts ${kitchen}-${shift}-${date}`, {
			pageSetup: { fitToPage: true, orientation: 'portrait' }
		});

		const headers = [
			'Username',
			'Phone Number',
			'Driver Name',
			'Delivery Time',
			'Zone',
			'Invoice',
			'Requested By',
			'Request Type',
			'Request',
			'Amount',
			'Requested On',
			'Comment'
		];

		const headerRow = worksheet.getRow(2);
		headerRow.values = headers;
		headerRow.eachCell((cell) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'F7F7F7' }
			};
			cell.alignment = { horizontal: 'left', vertical: 'middle' };
			cell.border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' }
			};
		});
		worksheet.columns = headers.map((header) => ({ header, key: header, width: 20 }));
		const dateRow = worksheet.getRow(1);
		dateRow.getCell(1).value = date;
		dateRow.getCell(1).alignment = { horizontal: 'left', vertical: 'middle' };

		for (let i = 2; i <= 11; i++) {
			dateRow.getCell(i).value = '';
			dateRow.getCell(i).alignment = { horizontal: 'center', vertical: 'middle' };
		}
		worksheet.getColumn('D').alignment = { horizontal: 'center' };
		worksheet.getColumn('E').alignment = { horizontal: 'center' };
		worksheet.getColumn('F').alignment = { horizontal: 'center' };
		worksheet.getColumn('G').alignment = { horizontal: 'center' };
		worksheet.getColumn('H').alignment = { horizontal: 'center' };
		worksheet.getColumn('I').alignment = { horizontal: 'center' };

		const transformedGifts = compact(
			gifts.flatMap((gift) =>
				gift.items?.map((item) => ({
					...gift,
					items: item.type === GiftItemType.meal ? `${item.item?.name?.en} (${item.item?.size})` : '-',
					type: item.type,
					amount: item.amount
				}))
			)
		);

		for (const row of transformedGifts) {
			worksheet.addRow({
				Username: row.userName,
				'Phone Number': row.phoneNumber,
				'Driver Name': deliveryList.find((delivery) => delivery.id === row.deliveryId)?.driver?.name || '-',
				'Delivery Time': capitalize(row.deliveryTime) || '',
				Zone: row.zone || '-',
				Invoice: deliveryList.find((delivery) => delivery.id === row.deliveryId)?.shortId
					? '#' + deliveryList.find((delivery) => delivery.id === row.deliveryId)?.shortId
					: '-',
				'Requested By': row.createdBy?.name || '-',
				'Request Type': row.type,
				Request: row.items,
				Amount: row.amount || '-',
				'Requested On': `${format('HH:mm a')(new Date(row.updatedAt ?? row.createdAt ?? ''))} - ${format('dd/MM/yyyy')(new Date(row.updatedAt ?? row.createdAt ?? ''))}`,
				Comment: row.comment || '-'
			});
		}

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';
		const blob = new Blob([buffer], { type: fileType });
		saveAs(blob, `Gifts ${kitchen}-${shift}-${date}` + fileExtension);
	}, [gifts, deliveryList, kitchen, shift, date]);

	return (
		<>
			<Button
				id="export-button"
				onClick={() => {
					setIsExportButtonClicked(true);
					onExport();
					setIsExportButtonClicked(false);
				}}
				disabled={isExportButtonClicked || appContext.isOffline || isDownloadDisabled}
				variant="outlined"
				color="primary"
				sx={{
					width: '130px',
					height: '47px',
					textTransform: 'none',
					fontWeight: 600,
					fontSize: '16px',
					borderRadius: '8px',
					lineHeight: '20px',
					color: 'black',
					boxShadow: 'none',
					borderColor: 'black'
				}}
				startIcon={
					<Iconify
						width={22}
						icon="material-symbols-light:download"
						color={isExportButtonClicked || appContext.isOffline || isDownloadDisabled ? caloTheme.palette.neutral200 : 'black'}
					/>
				}
			>
				Download
			</Button>
		</>
	);
};
export default Export;

import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.neutral50,
		color: theme.palette.common.black,
		py: 1,
		pl: 2,
		pr: 0,
		fontSize: '16px',
		fontWeight: 600,
		border: 0
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}));

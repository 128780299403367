import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../assets/themes/calo';

export interface EmptyMessageProps {
	label: string;
	style?: any;
}

const EmptyMessage = ({ label, ...style }: EmptyMessageProps) => {
	const theme = useTheme();
	return (
		<Typography
			variant="h1"
			gutterBottom
			sx={{
				my: 2,
				ml: 3,
				fontFamily: caloTheme.typography.fontFamily,
				fontSize: '28px',
				textTransform: 'uppercase',
				color: theme.palette.neutral600
			}}
			{...style}
		>
			{label}
		</Typography>
	);
};
export default EmptyMessage;

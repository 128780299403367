import CloseIcon from '@mui/icons-material/Close';
import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { caloTheme } from '../../../assets/themes/calo';
import DateRangePicker from '../../../components/DateRangePicker';

interface FilterItemsProps {
	toggleFilterDrawer: (data: boolean) => void;
	handleResultsButtonClick: () => void;
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
}

const FilterItems = ({ toggleFilterDrawer, handleResultsButtonClick, dateRange, setDateRange }: FilterItemsProps) => (
	<Box sx={{ width: 290, height: '100%', position: 'relative' }} role="presentation">
		<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', my: 1, mx: 3 }}>Filter</Box>
		<IconButton
			aria-label="close"
			onClick={() => toggleFilterDrawer(false)}
			sx={{
				position: 'absolute',
				right: 10,
				top: 10,
				fontWeight: 600,
				color: 'black'
			}}
		>
			<CloseIcon />
		</IconButton>
		<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Date</Box>
		<DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
		<Stack
			direction="column"
			justifyContent="space-evenly"
			alignItems="start"
			spacing={1}
			sx={{ mx: 3, my: 1, position: 'absolute', bottom: 0, width: '85%' }}
		>
			<Button
				variant="contained"
				onClick={handleResultsButtonClick}
				sx={{
					textTransform: 'capitalize',
					fontSize: '19px',
					fontWeight: 600,
					color: 'white',
					width: '100%'
				}}
			>
				Show Results
			</Button>
		</Stack>
	</Box>
);
export default FilterItems;

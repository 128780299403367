import { CardMedia, Dialog, DialogContent } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';

interface PopupProps {
	previewImage: string | null;
	onClose?: () => void;
}

const PreviewPopUp = forwardRef(({ onClose, previewImage }: PopupProps, ref) => {
	const [openDialog, setOpenDialog] = useState(false);

	useImperativeHandle(ref, () => ({
		open: () => {
			setOpenDialog(true);
		},
		close: () => {
			setOpenDialog(false);
		}
	}));

	const handleClose = () => {
		setOpenDialog(false);
		onClose && onClose();
	};

	return (
		<Dialog
			maxWidth={'xs'}
			open={openDialog}
			fullWidth={true}
			onClose={handleClose}
			sx={{ backgroundColor: '#343434E6' }}
			PaperProps={{
				style: {
					width: '100%',
					margin: 0
				}
			}}
		>
			<DialogContent sx={{ width: '100%', height: 'auto', overflow: 'auto', padding: 0 }}>
				<CardMedia
					component="img"
					image={`${process.env.REACT_APP_BUCKET_URL}/${previewImage}/original`}
					alt="placeholder"
					sx={{ objectFit: 'cover' }}
				/>
			</DialogContent>
		</Dialog>
	);
});
export default PreviewPopUp;

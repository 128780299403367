import { keyBy, sortBy } from 'lodash-es';

import { Box } from '@mui/material';

import EmptyMessage from '../../components/EmptyMessage';
import { Food, FoodComponent } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import ShortageReportingTable from './Table';

interface ShortageReportingProps {
	foods: Food[];
	foodComponents: FoodComponent[];
}

const ShortageReporting = ({ foods, foodComponents }: ShortageReportingProps) => {
	const { isMobile } = useDocumentMedia();
	const mobileStyles = {
		box: {
			width: '100%'
		}
	};

	const desktopStyles = {
		box: {
			margin: 10,
			width: '98%',
			padding: 5
		}
	};
	const keyedComponents = keyBy(foodComponents, 'id');
	const sortedFoods = sortBy(foods, 'name.en');

	return (
		<Box style={{ ...(isMobile ? mobileStyles.box : desktopStyles.box) }}>
			{sortedFoods.length > 0 ? (
				<ShortageReportingTable foods={sortedFoods} keyedFoodComponents={keyedComponents} />
			) : (
				<EmptyMessage label={'No data'} style={{ margin: 0 }} />
			)}
		</Box>
	);
};
export default ShortageReporting;

import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ComponentShortage, FCStatus, FoodComponent, Kitchen } from '../../../libs';
import { getTimeAndDateInTimeZone } from '../../../libs/utils/helperFunctions';

interface ShortageTableProps {
	shortage: ComponentShortage;
	foodComponent: FoodComponent;
	index: number;
	length: number;
}

const ShortageDisplayRow = ({ shortage, foodComponent, index, length }: ShortageTableProps) => {
	const theme = useTheme();
	const isCompleted = shortage.cookingStations?.every((c) => c.status === FCStatus.completed);
	const completeTime = shortage.cookingStations?.at(shortage.cookingStations.length - 1)?.endTime;
	return (
		<TableRow>
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{foodComponent.date}
			</TableCell>
			{index === 0 && (
				<TableCell
					rowSpan={index === 0 ? length : 0}
					sx={{
						border: 1,
						borderColor: theme.palette.neutral100,
						borderTop: 0,
						textAlign: 'center',
						fontWeight: 600,
						fontSize: '14px'
					}}
				>
					{foodComponent.name.en}
				</TableCell>
			)}
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{shortage.weight} g
			</TableCell>
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{shortage.foodName?.en || ''}
			</TableCell>
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{shortage.foodSize}
			</TableCell>
			<TableCell
				sx={{
					color: isCompleted ? theme.palette.primary500 : theme.palette.secondaryOrange500,
					textTransform: 'capitalize',
					borderBottom: 1,
					borderBottomColor: theme.palette.neutral100,
					textAlign: 'center',
					fontSize: '14px',
					fontWeight: 600
				}}
			>
				{isCompleted ? FCStatus.completed : FCStatus.pending}
			</TableCell>
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{getTimeAndDateInTimeZone(shortage.createdAt ?? '', foodComponent.kitchen ?? Kitchen.BH001)}
			</TableCell>
			<TableCell sx={{ borderBottom: 1, borderBottomColor: theme.palette.neutral100, textAlign: 'center', fontSize: '14px' }}>
				{completeTime ? getTimeAndDateInTimeZone(completeTime, foodComponent.kitchen ?? Kitchen.BH001) : '---'}
			</TableCell>
		</TableRow>
	);
};

export default ShortageDisplayRow;

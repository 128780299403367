import { useState } from 'react';

import Box from '@mui/material/Box';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DateView } from '@mui/x-date-pickers/models';
import {
	pickersLayoutClasses,
	PickersLayoutContentWrapper,
	PickersLayoutProps,
	PickersLayoutRoot,
	usePickerLayout
} from '@mui/x-date-pickers/PickersLayout';

const LayoutWithKeyboardView = (props: PickersLayoutProps<Date | null, Date, DateView>) => {
	const { value, onChange } = props;
	const [showKeyboardView, setShowKeyboardView] = useState(false);

	const { toolbar, tabs, content, actionBar } = usePickerLayout({
		...props,
		slotProps: {
			...props.slotProps,
			toolbar: {
				...props.slotProps?.toolbar,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				showKeyboardViewSwitch: props.wrapperVariant === 'mobile',
				showKeyboardView,
				setShowKeyboardView
			}
		}
	});

	return (
		<PickersLayoutRoot ownerState={props}>
			{toolbar}
			{actionBar}
			<PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
				{tabs}
				{showKeyboardView ? (
					<Box sx={{ mx: 3, my: 2, width: 272 }}>
						<DateField value={value} onChange={onChange} sx={{ width: '100%' }} />
					</Box>
				) : (
					content
				)}
			</PickersLayoutContentWrapper>
		</PickersLayoutRoot>
	);
};

export default LayoutWithKeyboardView;

import { omit } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { Chef, ComponentBatch, Session, SingleFoodComponentWithBatches } from '../../../../libs';
import { UPDATE_COMPONENT_BATCH_MUTATION } from '../../../../libs/graphQL';
import AddBatchButton from '../../Common/AddBatchButton';
import ReviewButton from '../../Common/ReviewButton';
import ReviewQualityDrawer from '../../Common/ReviewDrawer/ReviewQualityDrawer';
import SelectCookButton from '../../Common/SelectCookButton';
import { QualityTrackingContext } from '../../QualityTrackingContext';
import { applyStatusFilterOnQualityTracking } from '../../utils';

interface QualityTableRowProps {
	chefs: Chef[];
	foodComponent: SingleFoodComponentWithBatches;
	foodComponentBatch?: ComponentBatch;
	batchesListLength?: number;
	foodComponentName?: string;
	date: string;
	shift: Session;
}

const QualityTableBodyRow = ({
	batchesListLength,
	foodComponentName,
	foodComponentBatch,
	chefs,
	foodComponent,
	date,
	shift
}: QualityTableRowProps) => {
	const addBatchModalRef = useRef<any>();
	const selectCookModalRef = useRef<any>();

	const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [isReviewDrawerOpened, setIsReviewDrawerOpened] = useState(false);
	const [selectedCook, setSelectedCook] = useState(foodComponentBatch?.chef ?? '');
	const [isLoading, setIsLoading] = useState(false);

	const [updateComponentBatch, { loading: updateLoading }] = useMutation(UPDATE_COMPONENT_BATCH_MUTATION);
	const { selectedCheckStatuses } = useContext(QualityTrackingContext);

	const handleUpdateBatch = async (value: any) => {
		await updateComponentBatch({
			variables: {
				id: foodComponentBatch!.id,
				input: {
					...omit(foodComponentBatch, 'id'),
					...value
				}
			},
			onCompleted: () => {
				setDeliveryActionMenuAnchorEl(null);
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleSelectChef = (chef: string) => {
		if (foodComponentBatch) {
			handleUpdateBatch({ chef });
		}
		setSelectedCook(chef);
		selectCookModalRef.current?.close();
	};

	useEffect(() => {
		setIsLoading(updateLoading);
	}, [updateLoading]);

	if (applyStatusFilterOnQualityTracking(selectedCheckStatuses, foodComponentBatch)) {
		return (
			<>
				<TableRow key={`${foodComponentBatch?.id}-${foodComponentBatch?.batchNumber}`} sx={{ border: 'none' }}>
					<TableCell sx={{ fontSize: '19px', fontWeight: 600, minWidth: '200px', border: 'none' }}>
						{' '}
						{foodComponentName ?? foodComponent.name.en}
					</TableCell>
					<TableCell sx={{ minWidth: '120px', border: 'none' }}>
						<AddBatchButton
							deliveryActionMenuAnchorEl={deliveryActionMenuAnchorEl}
							addBatchModalRef={addBatchModalRef}
							foodComponentBatch={foodComponentBatch}
							setDeliveryActionMenuAnchorEl={setDeliveryActionMenuAnchorEl}
							foodComponentName={foodComponentName}
							batchesListLength={batchesListLength}
							handleUpdateBatch={handleUpdateBatch}
							date={date}
							shift={shift}
							foodComponent={foodComponent}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
						/>
					</TableCell>
					<TableCell sx={{ minWidth: '124px', border: 'none' }}>
						<Typography
							component="span"
							sx={{
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '19px',
								fontFamily: caloTheme.typography.fontFamily
							}}
						>
							{foodComponentBatch ? `${foodComponentBatch.weight} ${foodComponentBatch.measurementUnit}` : ''}
						</Typography>
					</TableCell>
					<TableCell sx={{ minWidth: '124px', border: 'none' }}>
						<SelectCookButton
							chefs={chefs}
							foodComponentBatch={foodComponentBatch}
							handleSelectChef={handleSelectChef}
							selectCookModalRef={selectCookModalRef}
							selectedCook={selectedCook}
							isLoading={isLoading}
						/>
					</TableCell>
					<TableCell sx={{ minWidth: '124px', textOverflow: 'ellipsis', border: 'none' }}>
						<Typography
							component="span"
							sx={{
								width: '123px',
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '19px',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								display: 'inline-block',
								textOverflow: 'ellipsis',
								fontFamily: caloTheme.typography.fontFamily
							}}
						>
							{foodComponentBatch?.quality?.[foodComponentBatch?.quality?.length - 1]?.actor?.name}
						</Typography>
					</TableCell>
					<TableCell sx={{ minWidth: '124px', border: 'none' }}>
						<ReviewButton
							setIsReviewDrawerOpened={setIsReviewDrawerOpened}
							status={foodComponentBatch?.quality?.[0]?.status}
							isLoading={isLoading}
						/>
					</TableCell>
				</TableRow>

				<ReviewQualityDrawer
					isReviewDrawerOpened={isReviewDrawerOpened}
					setIsReviewDrawerOpened={setIsReviewDrawerOpened}
					foodComponent={foodComponent}
					foodComponentBatch={foodComponentBatch}
					selectedCook={selectedCook}
					date={date}
					shift={shift}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			</>
		);
	}
	return <></>;
};
export default QualityTableBodyRow;

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { format } from 'date-fns/fp';
import { orderBy } from 'lodash-es';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import CircleIcon from '@mui/icons-material/Circle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Card, CardActions, CardContent, Collapse, ImageList, ImageListItem, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { ComponentBatch } from '../../../../libs';
import { renderDotColor } from '../../../QualityTracking/utils';

interface PreviousQualitiesProps {
	foodComponentBatch?: ComponentBatch;
	openImagePreview: (imageUrl: string) => void;
}

const PreviousQualities = ({ foodComponentBatch, openImagePreview }: PreviousQualitiesProps) => {
	const [isExtended, setIsExtended] = useState(true);
	if (!foodComponentBatch || foodComponentBatch.quality?.length === 0) {
		return <></>;
	}
	return (
		<Box sx={{ width: '100%', height: 'auto', borderBottom: 1, borderBottomColor: caloTheme.palette.separatorLine }}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
				sx={{ my: 2 }}
				onClick={() => setIsExtended(!isExtended)}
			>
				<Typography sx={{ textTransform: 'none', fontSize: '18px' }}>Previous Comments</Typography>
				{isExtended ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
			</Stack>
			<Collapse in={isExtended} timeout="auto">
				<Carousel autoPlay={false} animation={'slide'} cycleNavigation={false}>
					{orderBy(foodComponentBatch.quality, 'createdAt', 'desc').map((quality, index) => (
						<Card
							key={index}
							variant="outlined"
							sx={{
								border: 0,
								borderRadius: 2,
								backgroundColor: caloTheme.palette.neutral50
							}}
						>
							<CardContent>
								<Stack spacing={{ xs: 1, sm: 1, md: 1 }} direction="row" useFlexGap flexWrap="wrap">
									{quality.standards?.map((standard, index) => (
										<Typography key={index} sx={{ fontSize: '16px', fontWeight: 600 }}>
											<CircleIcon sx={{ color: renderDotColor(standard.rating), fontSize: '10px', marginRight: '2px' }} />
											{standard.name}
										</Typography>
									))}
								</Stack>
								<Typography textAlign={'start'} sx={{ fontSize: '16px', mt: 2 }}>
									{quality.comment}
								</Typography>
								{quality.commentImageURLs && quality.commentImageURLs.length > 0 && (
									<ImageList sx={{ width: '100%' }} cols={4} gap={8}>
										{quality.commentImageURLs.map((image) => (
											<ImageListItem key={image} sx={{ width: '80px', height: '80px' }} onClick={() => openImagePreview(image)}>
												<img
													srcSet={`${process.env.REACT_APP_BUCKET_URL}/${image}/square@1x.jpg`}
													src={`${process.env.REACT_APP_BUCKET_URL}/${image}/square@1x.jpg`}
													alt="placeholder"
													loading="lazy"
													width={80}
													height={80}
												/>
											</ImageListItem>
										))}
									</ImageList>
								)}
							</CardContent>
							<CardActions sx={{ m: 1, mt: 0 }}>
								<Typography marginRight={1} sx={{ fontSize: '14px', fontWeight: 600 }}>
									{quality.actor?.name}
								</Typography>
								<Typography sx={{ fontSize: '14px', color: caloTheme.palette.neutral600 }}>
									{format('dd/MM/yyyy, hh:mm a ')(new Date(quality.createdAt ?? ''))}
								</Typography>
							</CardActions>
						</Card>
					))}
				</Carousel>
			</Collapse>
		</Box>
	);
};

export default PreviousQualities;

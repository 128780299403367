import { forwardRef, useImperativeHandle, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../assets/themes/calo';

interface StyleProps {
	divider?: boolean;
	fullWidth?: boolean;
	maxWidth?: Breakpoint;
	borderRadius?: string;
}
interface PopupProps {
	title?: React.ReactNode;
	onClose?: () => void;
	children: React.ReactNode;
	subTitle?: React.ReactNode;
	style?: StyleProps;
	showCloseButton?: boolean;
	showDivider?: boolean;
}

const Popup = forwardRef(({ subTitle, title, children, onClose, style, showCloseButton, showDivider }: PopupProps, ref) => {
	const [openDialog, setOpenDialog] = useState(false);

	useImperativeHandle(ref, () => ({
		open: () => {
			setOpenDialog(true);
		},
		close: () => {
			setOpenDialog(false);
		}
	}));

	const handleClose = () => {
		setOpenDialog(false);
		onClose && onClose();
	};

	return (
		<Dialog
			maxWidth={style?.maxWidth}
			open={openDialog}
			fullWidth={style?.fullWidth}
			onClose={handleClose}
			PaperProps={{
				style: {
					borderRadius: style?.borderRadius || '8px'
				}
			}}
		>
			{title && (
				<DialogTitle display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
						<Typography
							component="div"
							sx={{ margin: 'auto', width: '100%', fontSize: '26px', lineHeight: '47px', fontWeight: 400, mr: 'full' }}
						>
							{title}
						</Typography>
						{showCloseButton && (
							<IconButton
								aria-label="close"
								onClick={handleClose}
								sx={{
									color: caloTheme.palette.text.primary
								}}
							>
								<CloseIcon sx={{ fontSize: '24px', fontWeight: 700 }} />
							</IconButton>
						)}
					</Stack>
					{subTitle && (
						<Typography
							component="div"
							sx={{
								fontSize: '26px',
								lineHeight: '47px',
								fontWeight: 400,
								width: '100%',
								justifyContent: 'end',
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							{subTitle}
						</Typography>
					)}
				</DialogTitle>
			)}
			{showDivider && <Divider sx={{ backgroundColor: caloTheme.palette.divider }} />}
			<DialogContent
				dividers={style?.divider}
				sx={{ width: '100%', height: 'auto', overflow: style?.maxWidth ? 'hidden' : 'auto' }}
			>
				<Stack>{children}</Stack>
			</DialogContent>
		</Dialog>
	);
});
export default Popup;

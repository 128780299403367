import { orderBy } from 'lodash-es';
import { useContext, useState } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '../../../App';
import { KDSExtraMeals } from '../../../libs';
import MealsTableRow from '../MealsTableRow';

interface MealsTableProps {
	foods: KDSExtraMeals[];
	tableName: string;
	isEditing: boolean;
	isFirstTable: boolean;
	setIsEditing: (isEditing: boolean) => void;
}

const MealsTable = ({ foods, tableName, isEditing, isFirstTable, setIsEditing }: MealsTableProps) => {
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [loading, setLoading] = useState(false);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: '1px solid ' + theme.palette.neutral50,
			fontSize: 16,
			fontWeight: 600,
			lineHeight: '19px',
			textTransform: 'capitalize',
			padding: '8px'
		}
	}));

	return (
		<Table stickyHeader sx={{ display: foods.length > 0 ? 'table' : 'none', zIndex: 0 }}>
			<TableHead>
				<TableRow>
					<StyledTableCell colSpan={3} sx={{ fontSize: '16px', fontWeight: 600, borderRadius: '4px' }}>
						{tableName}
					</StyledTableCell>
					<StyledTableCell>
						<Stack direction="row" justifyContent="end" alignItems="center">
							<Button
								disabled={foods.length === 0 || appContext.isOffline || loading}
								onClick={() => setIsEditing(!isEditing)}
								variant="outlined"
								sx={{
									textTransform: 'capitalize',
									fontSize: '16px',
									borderWidth: 2,
									width: '80px',
									borderRadius: '8px',
									borderColor: theme.palette.primary500,
									':hover': { borderWidth: 2, borderColor: theme.palette.primary400 },
									visibility: isFirstTable ? 'visible' : 'hidden'
								}}
							>
								{isEditing ? 'Done' : 'Edit'}
							</Button>
						</Stack>
					</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{orderBy(foods, (f) => f.extraMeals || 0, ['desc']).map((food, index) => (
					<MealsTableRow
						setLoading={setLoading}
						key={food.id}
						food={food}
						isEditing={isEditing}
						isLastRow={foods.length === index + 1}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default MealsTable;

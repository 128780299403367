import { useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { DeliveryTicketWithIndex } from '../../../libs';
import SwapCard from '../SwapCard';

interface TabPanelProps {
	deliveryTickets: DeliveryTicketWithIndex[];
	index: number;
	value: number;
	loading: boolean;
}

const SwapTab = ({ deliveryTickets, value, index, loading, ...other }: TabPanelProps) => {
	const [isMutationRunning, setIsMutationRunning] = useState(false);
	const theme = useTheme();
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			sx={{ width: '100%' }}
			{...other}
		>
			{loading ? (
				<Box
					sx={{
						fontFamily: caloTheme.typography.fontFamily,
						fontSize: '28px',
						textTransform: 'uppercase',
						color: theme.palette.neutral600,
						mt: '10px'
					}}
				>
					Loading ...
				</Box>
			) : deliveryTickets.length > 0 && value === index ? (
				<Box sx={{ py: 2 }}>
					{deliveryTickets.map((d) => (
						<SwapCard
							key={d.id}
							{...d}
							tabValue={value}
							index={d.index}
							isMutationRunning={isMutationRunning}
							setIsMutationRunning={setIsMutationRunning}
						/>
					))}
				</Box>
			) : (
				<EmptyMessage label={'No Requests'} style={{ display: 'flex', justifyContent: 'center' }} />
			)}
		</Box>
	);
};

export default SwapTab;

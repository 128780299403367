import { Box, styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import { DriverDispatch } from '../../../libs';
import DriverDispatchTableRow from './DriverDispatchTableRow';

interface CaloDriverDispatchTableProps {
	sortedDriversByName: DriverDispatch[];
}

const CaloDriverDispatchTable = ({ sortedDriversByName }: CaloDriverDispatchTableProps) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: caloTheme.palette.neutral900,
			border: 'none',
			fontSize: 14,
			fontWeight: 600,
			height: '40px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	return (
		<Box sx={{ width: '100%' }}>
			{sortedDriversByName && (
				<Table sx={{ m: 2, width: '100%' }}>
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} sx={{ minWidth: '254px' }}>
								Driver Name
							</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '20px', textAlign: 'center' }}>Calo Bags</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '40px', textAlign: 'center' }}>Van Temperature </StyledTableCell>
							<StyledTableCell
								style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
								sx={{ minWidth: '40px', textAlign: 'center' }}
							>
								Departure Time{' '}
							</StyledTableCell>
						</TableRow>
					</TableHead>
					{sortedDriversByName && sortedDriversByName.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={5}
									sx={{
										minWidth: 150,
										p: 1,
										fontFamily: caloTheme.typography.fontFamily,
										fontSize: '28px',
										textTransform: 'uppercase',
										color: caloTheme.palette.neutral600,
										textAlign: 'center',
										border: 'none'
									}}
								>
									No Drivers
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{sortedDriversByName.map((driver) => (
								<DriverDispatchTableRow key={driver.id} driver={driver} />
							))}
						</TableBody>
					)}
				</Table>
			)}
		</Box>
	);
};
export default CaloDriverDispatchTable;

import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import { DocsType } from '../libs';
import { EXPORT_DOCUMENTS_MUTATION } from '../libs/graphQL';
import { createAutoDownloadLinkFromArrayOfUrls } from '../libs/utils/helperFunctions';

const useExportDocuments = () => {
	const [exportDocuments, { loading }] = useMutation(EXPORT_DOCUMENTS_MUTATION);

	const handleExportDocuments = async ({
		ids,
		docTypes,
		kitchen,
		day,
		shift
	}: {
		ids: string[];
		docTypes: DocsType[];
		kitchen: string;
		day: string;
		shift: string;
	}) => {
		await exportDocuments({
			variables: { ids, docTypes, kitchen, day, shift },
			onCompleted: (data) => {
				if (data?.exportDocuments.data && data?.exportDocuments.data.length > 0) {
					createAutoDownloadLinkFromArrayOfUrls(data.exportDocuments.data);
				} else {
					toast.error('Something went wrong');
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	return { handleExportDocuments, loading };
};

export default useExportDocuments;

import { Suspense } from 'react';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { GraphQLClient } from './libs/graphQL';
import Amplify from 'aws-amplify';
import amplifyConfig from './configs/amplifyConfig';
import { createRoot } from 'react-dom/client';

Amplify.configure(amplifyConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<ApolloProvider client={GraphQLClient}>
		<Suspense fallback={<section />}>
			<App />
		</Suspense>
	</ApolloProvider>
);

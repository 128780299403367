import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { caloTheme } from '../../assets/themes/calo';
import Image from '../Image';

interface ImageModalProps {
	isOpened: boolean;
	handleClose: () => void;
	title: string;
	url: string;
	width?: number;
}
const ImageModal = ({ isOpened, handleClose, title, url, width = 120 }: ImageModalProps) => (
	<Dialog open={isOpened} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
		<DialogTitle
			id="alert-dialog-title"
			sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', minWidth: '480px' }}
		>
			{title}
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
		<DialogContent>
			<Image url={url} alt={title} width={width} />
		</DialogContent>
	</Dialog>
);
export default ImageModal;

import React, { useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, CardMedia, Drawer, IconButton, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { Image as ImageComponent, Popup } from '../../../../components';
import { ComponentBatch, QualityMetricsFoodComponent } from '../../../../libs';
import QualityCard from './QualityCard';

interface ReviewQualityDrawerProps {
	isReviewDrawerOpened: boolean;
	setIsReviewDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>;
	foodComponent?: QualityMetricsFoodComponent;
	foodComponentBatch?: ComponentBatch;
}

const ReviewQualityDrawer = ({
	foodComponentBatch,
	isReviewDrawerOpened,
	setIsReviewDrawerOpened,
	foodComponent
}: ReviewQualityDrawerProps) => {
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const previewImagePopUpRef = useRef<any>();

	const openImagePreview = (imageURL: string) => {
		setPreviewImage(imageURL);
		previewImagePopUpRef.current?.open();
	};
	if (!foodComponent) return null;

	return (
		<Drawer anchor={'right'} open={isReviewDrawerOpened} onClose={() => setIsReviewDrawerOpened(false)}>
			<Stack direction="column" justifyContent="start" alignItems="start" spacing={2} sx={{ m: 2, width: '420px' }}>
				<IconButton
					aria-label="close"
					onClick={() => setIsReviewDrawerOpened(false)}
					sx={{
						position: 'absolute',
						right: 10,
						top: 0,
						fontWeight: 600,
						color: 'black'
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box
					sx={{
						width: '420px',
						minHeight: '300px',
						height: '300px',
						overflow: 'hidden',
						display: 'flex',
						alignSelf: 'center',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '8px'
					}}
				>
					<ImageComponent
						alt={`${foodComponent.name?.en} Image`}
						style={{ width: 420, height: 'auto', borderRadius: '8px' }}
						key={foodComponent.id}
						url={`${process.env.REACT_APP_BUCKET_URL}/food-component/${foodComponent.id}/square@3x.jpg`}
					/>
				</Box>
				<Stack direction="column" justifyContent="start" alignItems="start" spacing={0}>
					<Stack direction="row" justifyContent="start" alignItems="start" spacing={1}>
						<Typography sx={{ fontSize: '23px', fontWeight: 600, color: caloTheme.palette.neutral900 }}>
							{foodComponent.name.en}
						</Typography>
						<Typography sx={{ fontSize: '23px', color: caloTheme.palette.neutral600 }}>
							(Batch {foodComponentBatch?.batchNumber})
						</Typography>
					</Stack>
					<Typography sx={{ fontSize: '16px', color: caloTheme.palette.neutral600 }}>{foodComponentBatch?.chef}</Typography>
				</Stack>
				<QualityCard foodComponentBatch={foodComponentBatch} openImagePreview={openImagePreview} />
				<Popup ref={previewImagePopUpRef} onClose={() => previewImagePopUpRef.current?.close()}>
					<CardMedia
						component="img"
						image={`${process.env.REACT_APP_BUCKET_URL}/${previewImage}/square@3x.jpg`}
						alt={foodComponent.name.en}
						sx={{ objectFit: 'cover' }}
					/>
				</Popup>
			</Stack>
		</Drawer>
	);
};

export default ReviewQualityDrawer;

import { Heading, Image, Text, View } from '@aws-amplify/ui-react';

/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/naming-convention */
import logo from '../../assets/caloLogo';
import { caloTheme } from '../../assets/themes/calo';

const SigninHeader = {
	Header() {
		return (
			<View textAlign="center" padding={'20px'}>
				<Image alt="Calo logo" src={`data:image/png;base64, ${logo}`} />
			</View>
		);
	},
	Footer() {
		return (
			<View textAlign="center" padding={'20px'}>
				<Text color={'#57AE7F'}>&copy; 2022 Calo Inc.</Text>
			</View>
		);
	},
	SignIn: {
		Header() {
			return (
				<View textAlign="center" padding={'20px'} paddingBottom={'0px'}>
					<Heading padding={'20px'} level={3} fontFamily={caloTheme.typography.fontFamily} textAlign="center" fontSize={'33px'}>
						Sign in to your account
					</Heading>
					<Text color={'#9D9D9D'} paddingBottom={'0px'}>
						Phone number must start with the country code and follow the format +xxxxxxxxxx
					</Text>
				</View>
			);
		}
	}
};

export default SigninHeader;

import { Box, Stack, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

import { caloTheme } from '../../../../assets/themes/calo';
import { Ingredient, SingleFoodComponent } from '../../../../libs';
import { roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { calculateFractionWeight, formatWeightText } from '../../helpers';

interface IngredientRowItemProps {
	ingredient: Ingredient;
	foodComponent: SingleFoodComponent;
	newCookedWeight: number;
}

const IngredientRowItem = ({ ingredient, foodComponent, newCookedWeight }: IngredientRowItemProps) => {
	const StyledIngredientCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			color: theme.palette.common.black,
			border: 'bottom',
			fontSize: 28,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 19,
			fontWeight: 600,
			color: theme.palette.common.black,
			border: '0',
			fontFamily: 'Roboto',
			lineHeight: '28px',
			height: 'auto',
			minHeight: '50px'
		}
	}));

	const fractionWeight = calculateFractionWeight(foodComponent, ingredient.quantity ?? 0, newCookedWeight, ingredient.weight);
	const weightBeforePrep =
		fractionWeight * (ingredient.wastage ?? 1) < 1
			? roundToTwoDecimals(fractionWeight * (ingredient.wastage ?? 0))
			: Math.ceil(Math.round(fractionWeight * (ingredient.wastage ?? 0)));
	const weightAfterPrep = fractionWeight < 1 ? roundToTwoDecimals(fractionWeight) : Math.ceil(Math.round(fractionWeight));

	return (
		<TableRow>
			<TableCell
				sx={{
					p: 1,
					mr: 2,
					width: '100%',
					border: 'none',
					display: 'flex',
					flexDirection: 'row',
					borderSpacing: '0px 4px',
					borderCollapse: 'collapse'
				}}
			>
				<StyledIngredientCell sx={{ p: 1, pl: 2, width: '100%' }}>
					<Stack sx={{ width: '100%', fontWeight: 400 }}>{`${ingredient.name.en} (${ingredient.internalName})`}</Stack>
					<Box
						sx={{
							mt: 1,
							mx: 1,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<Stack sx={{ direction: 'column', width: '50%' }}>
							<Typography sx={{ fontSize: '20px', fontWeight: 600, lineHeight: '28px', mt: '2px' }}>
								{formatWeightText(weightBeforePrep, ingredient.weight || 1, ingredient.measurementUnit)}
							</Typography>
							<Typography sx={{ borderBottom: 2, borderColor: caloTheme.palette.primary500, width: '70%', mt: '4px' }} />
						</Stack>

						<Stack
							sx={{
								direction: 'column',
								width: '50%'
							}}
						>
							<Typography sx={{ fontSize: '20px', fontWeight: 600, lineHeight: '28px', mt: '2px' }}>
								{formatWeightText(weightAfterPrep, ingredient.weight || 1, ingredient.measurementUnit)}
							</Typography>
							<Typography sx={{ borderBottom: 2, borderColor: caloTheme.palette.secondaryYellow500, width: '70%', mt: '4px' }} />
						</Stack>
					</Box>
				</StyledIngredientCell>
			</TableCell>
		</TableRow>
	);
};

export default IngredientRowItem;

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { caloTheme } from '../../assets/themes/calo';

interface ConfirmationMessageProps {
	isOpened: boolean;
	handleClose: () => void;
	handleAccept: () => void;
	message: string;
	accept?: string;
	reject?: string;
}
const ConfirmationMessage = ({ isOpened, handleClose, handleAccept, message, accept, reject }: ConfirmationMessageProps) => {
	const theme = useTheme();

	const handleAction = () => {
		handleAccept();
		handleClose();
	};

	return (
		<Dialog
			open={isOpened}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '39px', lineHeight: '47px', textAlign: 'center', m: 4 }}
			>
				{message}
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500]
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mb: 4 }}>
				<Button
					variant="contained"
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						borderRadius: '8px',
						width: '153px',
						lineHeight: '23px',
						color: 'white',
						fontWeight: 600,
						px: '20px',
						py: '14px'
					}}
					onClick={handleAction}
				>
					{accept || 'Accept'}
				</Button>
				<Button
					variant="text"
					sx={{
						color: theme.palette.neutral600,
						textTransform: 'capitalize',
						width: '153px',
						fontSize: '19px',
						lineHeight: '23px',
						fontWeight: 600,
						px: '20px',
						py: '14px'
					}}
					onClick={handleClose}
				>
					{reject || 'Reject'}
				</Button>
			</Stack>
		</Dialog>
	);
};
export default ConfirmationMessage;

import { format } from 'date-fns/fp';
import { map, uniq } from 'lodash-es';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Brand, DeliveryStatus } from '@calo/types';
import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';

import { useListSpecialRequest } from '../../hooks';
import useListDeliveryQuery from '../../hooks/useListDeliveryQuery';
import { DeliveriesFiltersInput, FileManagementType, Kitchen, Session, SpecialRequestFilterProps } from '../../libs';
import { getCountry } from '../../libs/utils/helperFunctions';
import SpecialRequestTable from './SpecialRequestTable.tsx/SpecialRequestTable';

interface SpecialRequestProps {
	kitchen: string;
	date: string;
}

const SpecialRequest = ({ kitchen, date }: SpecialRequestProps) => {
	const initialSpecialRequestFilters: SpecialRequestFilterProps = {
		kitchen: (kitchen as Kitchen) || Kitchen.BH001,
		date: {
			gte: date,
			lte: date
		},
		session: Session.morning
	};
	const initialDeliveriesFilters: DeliveriesFiltersInput = {
		kitchen: kitchen as Kitchen,
		day: {
			gte: format('yyyy-MM-dd')(new Date(date)),
			lte: format('yyyy-MM-dd')(new Date(date))
		},
		brand: Brand.CALO,
		country: getCountry(kitchen as Kitchen),
		skipped: false,
		status: [
			DeliveryStatus.upcoming,
			DeliveryStatus.paymentRequired,
			DeliveryStatus.suspended,
			DeliveryStatus.cancelled,
			DeliveryStatus.paused
		]
	};
	const [filters, setFilters] = useState<SpecialRequestFilterProps>(initialSpecialRequestFilters);
	const [filtersDeliveries, setFiltersDeliveries] = useState<DeliveriesFiltersInput>(initialDeliveriesFilters);
	const { deliveries, deliveriesLoading } = useListDeliveryQuery(
		filtersDeliveries,
		initialDeliveriesFilters,
		FileManagementType.customer,
		false,
		filtersDeliveries.userIds?.length ?? 50
	);

	const { specialRequestList, isLoading, handleFetchMoreRequest, hasNext } = useListSpecialRequest(filters);

	useEffect(() => {
		setFilters(initialSpecialRequestFilters);
		setFiltersDeliveries(initialDeliveriesFilters);
	}, [kitchen, date]);

	useEffect(() => {
		if (specialRequestList) {
			const specialRequestUserIds = specialRequestList.map((req) => req.userId);
			setFiltersDeliveries({ ...filtersDeliveries, userIds: specialRequestUserIds || [] });
		}
	}, [specialRequestList]);

	const filteredSpecialRequestList = specialRequestList.filter((req: any) => req.deliveryTime === filters.session);
	const uniqueZones = uniq(map(filteredSpecialRequestList, 'zone'));

	return (
		<Box sx={{ width: '98%' }}>
			<InfiniteScroll
				dataLength={specialRequestList.length}
				next={handleFetchMoreRequest}
				hasMore={hasNext}
				loader={
					hasNext ? (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					) : null
				}
				style={{ overflowY: 'hidden' }}
			>
				<SpecialRequestTable
					date={date}
					kitchen={kitchen}
					filters={filters}
					setFilters={setFilters}
					key={`${kitchen}-${date}`}
					deliveryList={deliveries || []}
					zones={uniqueZones}
					specialRequestList={
						specialRequestList.filter(
							(req: any) => req.deliveryTime === filters.session && (!filters.zone || req.zone === filters.zone)
						) || []
					}
				/>
				<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || deliveriesLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</InfiniteScroll>
		</Box>
	);
};
export default SpecialRequest;

import cx from 'classnames';
import React from 'react';

import { Icon } from '../../libs/utils/icons';

interface IconProps extends React.SVGProps<SVGSVGElement> {
	name: Icon;
	color?: string;
	size?: number;
	flip?: boolean;
}

export default ({ name, className, color, flip, size = 4, ...rest }: IconProps) => (
	<svg
		viewBox="0 0 128 128"
		className={cx(
			'inline-block',
			color,
			`w-${size}`,
			`h-${size}`,
			{
				'fill-current': !color,
				'flip-img': flip
			},
			className
		)}
		xmlns="http://www.w3.org/2000/svg"
		// onClick={onClick}
		preserveAspectRatio="xMidYMid meet"
		{...rest}
	>
		{renderIcon(name, color ?? '')}
	</svg>
);
const renderIcon = (name: Icon, color: string) => {
	switch (name) {
		case 'CXRequest':
			return (
				<g>
					<path
						fill="#2b2b2b"
						stroke="none"
						fillRule="evenodd"
						d="M3 7.5a5 5 0 1 1 10 0c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1a.822.822 0 0 1-.926.816l-2.125-.265A1.999 1.999 0 0 0 6 13.5a1.999 1.999 0 0 0 3.923.554l2.027.254C13.04 14.445 14 13.598 14 12.5c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1 0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1Zm6 5.996v.012a1 1 0 1 1 0-.012ZM2 8.5h1v3H2Zm11 0h1v3h-1Zm0 0"
						transform="scale(6.27778 6.25)"
					/>
				</g>
			);
		case 'userRequest':
			return (
				<g>
					<path
						fill="#2b2b2b"
						stroke="none"
						fillRule="evenodd"
						d="M2.5 4a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0ZM6 1.5A2.502 2.502 0 0 0 3.5 4c0 1.379 1.121 2.5 2.5 2.5S8.5 5.379 8.5 4 7.379 1.5 6 1.5Zm0 8a4.502 4.502 0 0 0-4.473 4c-.03.273-.25.5-.527.5s-.504-.223-.477-.5c.25-2.805 2.606-5 5.477-5a5.498 5.498 0 0 1 5.477 5c.027.277-.2.5-.477.5-.277 0-.496-.227-.527-.5-.25-2.25-2.157-4-4.473-4Zm0 0"
						transform="scale(6.27778 6.25)"
					/>
				</g>
			);
		case 'filter':
			return (
				<g>
					<path
						fill="none"
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M14.668 2.5H1.332l5.336 6.309v4.359L9.332 14.5V8.809Zm0 0"
						transform="scale(6.27778 6.25)"
					/>
				</g>
			);
		case 'notificationCX':
			return (
				<g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="scale(6.27778 6.25)">
					<path d="M2 12V8a6.002 6.002 0 0 1 6-6 6.002 6.002 0 0 1 6 6v4" />
					<path d="M14 12.668A1.334 1.334 0 0 1 12.668 14H12a1.334 1.334 0 0 1-1.332-1.332v-2A1.336 1.336 0 0 1 12 9.332h2Zm-12 0A1.334 1.334 0 0 0 3.332 14H4a1.334 1.334 0 0 0 1.332-1.332v-2A1.336 1.336 0 0 0 4 9.332H2Zm0 0" />
				</g>
			);
		case 'notificationCustomer':
			return (
				<g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
					<path
						d="M13.336 14v-1.332A2.67 2.67 0 0 0 10.668 10H5.336a2.67 2.67 0 0 0-2.668 2.668V14M8 7.332a2.666 2.666 0 1 0-.003-5.333A2.666 2.666 0 0 0 8 7.332Zm0 0"
						transform="scale(6.27778 6.25)"
					/>
				</g>
			);
		case 'downloaded':
			return (
				<g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="scale(6.27778 6.25)">
					<path fill="#f7fbf9" d="M12 0c6.629 0 12 5.371 12 12s-5.371 12-12 12S0 18.629 0 12 5.371 0 12 0Zm0 0" />
					<path
						fill="none"
						stroke="#57ae7f"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M18.668 11.387V12a6.67 6.67 0 0 1-4.777 6.39 6.676 6.676 0 0 1-7.484-2.766 6.665 6.665 0 0 1 .527-7.96 6.655 6.655 0 0 1 3.597-2.172 6.67 6.67 0 0 1 4.184.414"
					/>
					<path fill="none" stroke="#57ae7f" strokeLinecap="round" strokeLinejoin="round" d="M18.668 6.668 12 13.34l-2-2" />
				</g>
			);
		case 'meal':
			return (
				<g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="scale(6.27778 6.25)">
					<path
						d="M5.25 18v-5.508c0-.816-.191-1.62-.555-2.351l-.703-1.403a3.083 3.083 0 1 1 5.516 0l-.703 1.403a5.282 5.282 0 0 0-.555 2.351V18a1.5 1.5 0 0 1-3 0Zm0 0"
						fill="none"
						strokeWidth="1.5"
						stroke={color}
						strokeLinecap="butt"
						strokeLinejoin="miter"
						strokeOpacity="1"
						strokeMiterlimit="4"
					/>
					<path
						d="M13.5 4v5.688c0 .68.27 1.332.75 1.812s.75 1.133.75 1.813v4.937a1.5 1.5 0 0 0 3 0v-4.941c0-.68.27-1.329.75-1.809s.75-1.133.75-1.813V4m-3 0v6"
						fill="none"
						strokeWidth="1.5"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeOpacity="1"
						strokeMiterlimit="4"
					/>
				</g>
			);
	}
};

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { DatePickerToolbar, DatePickerToolbarProps } from '@mui/x-date-pickers/DatePicker';

const ToolbarWithKeyboardViewSwitch = (
	props: DatePickerToolbarProps<any> & {
		showKeyboardViewSwitch?: boolean;
		showKeyboardView?: boolean;
		setShowKeyboardView?: React.Dispatch<React.SetStateAction<boolean>>;
	}
) => {
	const { showKeyboardViewSwitch, showKeyboardView, setShowKeyboardView, ...other } = props;

	if (showKeyboardViewSwitch) {
		return (
			<Stack
				spacing={2}
				direction={other.isLandscape ? 'column' : 'row'}
				alignItems="center"
				sx={
					other.isLandscape
						? {
								gridColumn: 1,
								gridRow: '1 / 3'
							}
						: { gridColumn: '1 / 4', gridRow: 1, mr: 1 }
				}
			>
				<DatePickerToolbar {...other} sx={{ flex: '1 1 100%' }} />
				<IconButton color="inherit" onClick={() => setShowKeyboardView!((prev) => !prev)}>
					{showKeyboardView ? <CalendarMonthIcon /> : <ModeEditIcon />}
				</IconButton>
			</Stack>
		);
	}

	return <DatePickerToolbar {...other} />;
};

export default ToolbarWithKeyboardViewSwitch;

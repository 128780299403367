import { useContext } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { QualityTrackingContext } from '../QualityTrackingContext';
import Filter from './Filter';
import MobileQualityTable from './MobileQualityTable';

const MobileQualityTracking = () => {
	const { date, shifts, chefs, foodComponentsWithBatches } = useContext(QualityTrackingContext);
	return (
		<Box sx={{ width: '100%', px: 1, display: 'flex', flexDirection: 'column' }}>
			<Filter />
			{foodComponentsWithBatches.length > 0 ? (
				<MobileQualityTable chefs={chefs} foodComponents={foodComponentsWithBatches} shift={shifts} date={date} />
			) : (
				<Typography>No data</Typography>
			)}
		</Box>
	);
};

export default MobileQualityTracking;

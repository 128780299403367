import { orderBy } from 'lodash-es';

import { FoodComponent } from '../../../libs';
import ShortageDisplayRow from '../ShortageDisplayRow';

interface ComponentsShortageTableProps {
	foodComponent: FoodComponent;
}

const ShortageDisplayTable = ({ foodComponent }: ComponentsShortageTableProps) => (
	<>
		{foodComponent.shortages &&
			foodComponent.shortages.length > 0 &&
			orderBy(foodComponent.shortages, [(sh) => new Date(sh.createdAt ?? '')], ['desc']).map((shortage, index) => (
				<ShortageDisplayRow
					key={shortage.id}
					shortage={shortage}
					foodComponent={foodComponent}
					index={index}
					length={foodComponent?.shortages?.length || 0}
				/>
			))}
	</>
);

export default ShortageDisplayTable;

import { format } from 'date-fns/fp';
import { isNull, omit } from 'lodash-es';
import { useContext } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button, Stack } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { InputField } from '../../../../components';
import { DriverDispatch } from '../../../../libs';
import { UPDATE_DRIVER_DISPATCH_MUTATION } from '../../../../libs/graphQL';
import { isHoursMinFormat } from '../../../../libs/utils/helperFunctions';

interface DriverDispatchTableRowProps {
	driver: DriverDispatch;
}

const DriverDispatchTableRow = ({ driver }: DriverDispatchTableRowProps) => {
	const appContext = useContext(AppContext);

	const [mutateDriverDispatches] = useMutation(UPDATE_DRIVER_DISPATCH_MUTATION);

	const handleDriverDispatch = async (name: string, value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | Date) => {
		await mutateDriverDispatches({
			variables: {
				id: driver.id,
				input: {
					...omit(driver, ['driver', 'id', '__typename']),
					[name]: value
				}
			},
			onCompleted: () => {
				toast.success('Updated successfully');
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleDepartureChange = (date: any) => {
		const dateWithout = date.slice(0, 6);
		if (isHoursMinFormat(date)) {
			const [hours, minutes] = dateWithout.split(':');
			const dateObject = new Date();
			dateObject.setHours(hours);
			dateObject.setMinutes(minutes);
			handleDriverDispatch('departureTime', dateObject);
		} else {
			toast.error('Wrong in time format');
		}
	};

	return (
		<TableRow>
			<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
				<Typography padding="6px" fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
					{driver.driver.driverName}
				</Typography>
			</TableCell>
			<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
				<Typography
					textTransform={'capitalize'}
					sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}
				>
					{driver.caloBags}
				</Typography>
			</TableCell>
			<TableCell sx={{ minWidth: '196px', border: 'none', textAlign: 'center' }}>
				<InputField
					type="number"
					value={driver.vanTemperature}
					placeholder="Temp."
					disabled={appContext.isOffline}
					onChange={(event) => handleDriverDispatch('vanTemperature', event)}
					debounce
					fullWidth={true}
					inputMode="numeric"
					sx={{
						textTransform: 'none',
						fontSize: '16px',
						fontWeight: 600,
						border: '1px solid transparent',
						borderRadius: '4px',
						width: '176px',
						height: '39px',
						textAlign: 'center',
						p: '10px',
						input: { textAlign: 'center' },
						color:
							appContext.isOffline || driver.vanTemperature === 0 || isNull(driver.vanTemperature)
								? caloTheme.palette.neutral600
								: caloTheme.palette.primary500,
						backgroundColor:
							appContext.isOffline || driver.vanTemperature === 0 || isNull(driver.vanTemperature)
								? caloTheme.palette.neutral100
								: caloTheme.palette.primary100,
						':disabled': { color: caloTheme.palette.neutral600, backgroundColor: caloTheme.palette.neutral100 }
					}}
				/>
			</TableCell>
			<TableCell sx={{ border: 'none', p: 1, textAlign: 'center', minWidth: '156px', width: '246px' }}>
				<Stack display={'flex'} flexDirection={'row'} justifyContent="space-between">
					<InputField
						value={driver.departureTime ? format('HH:mm')(new Date(driver.departureTime)) : ''}
						placeholder="Time"
						disabled={appContext.isOffline}
						debounce
						onChange={(event: any) => handleDepartureChange(event)}
						fullWidth={true}
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							mr: 1,
							fontWeight: 600,
							border: '1px solid transparent',
							borderRadius: '4px',
							width: '146px',
							height: '39px',
							textAlign: 'center',
							p: '10px',
							input: { textAlign: 'center' },
							color:
								appContext.isOffline || isNull(driver.departureTime)
									? caloTheme.palette.neutral600
									: caloTheme.palette.primary500,
							backgroundColor:
								appContext.isOffline || isNull(driver.departureTime)
									? caloTheme.palette.neutral100
									: caloTheme.palette.primary100,
							':disabled': { color: caloTheme.palette.neutral600, backgroundColor: caloTheme.palette.neutral100 }
						}}
						endAdornment={driver.departureTime ? format('a')(new Date(driver.departureTime)) : null}
					/>

					<Button
						variant="contained"
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							color: 'white',
							borderRadius: '8px',
							fontWeight: 600,
							px: 4,
							boxShadow: 'none',
							':hover': {
								boxShadow: 'none',
								backgroundColor: caloTheme.palette.primary600
							}
						}}
						onClick={() => handleDriverDispatch('departureTime', new Date())}
					>
						Now
					</Button>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

export default DriverDispatchTableRow;

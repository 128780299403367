import { format } from 'date-fns/fp';
import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';

import { AppContext } from '../../App';
import useListDeliveryQuery from '../../hooks/useListDeliveryQuery';
import useListFoodQuery from '../../hooks/useListFoodQuery';
import {
	Brand,
	CheckboxesState,
	DeliveriesFiltersInput,
	Delivery,
	DeliveryStatus,
	FileManagementFoodFilter,
	FileManagementType,
	FilterSizeState,
	FilterTimeState,
	FilterTypeState,
	Food,
	Kitchen,
	MealCheckBox
} from '../../libs';
import { LIST_DRIVER_DISPATCHES_QUERY } from '../../libs/graphQL';
import {
	getCountry,
	initialFilterSizeState,
	initialFilterTimeState,
	initialFilterTypeState
} from '../../libs/utils/helperFunctions';
import FileManagementHeader from './FileManagementHeader';
import FileManagementTable from './FileManagementTable/FileManagementTable';

interface FileManagementProps {
	kitchen: string;
	date: string;
}

const FileManagement = ({ kitchen, date }: FileManagementProps) => {
	const initialFilters: DeliveriesFiltersInput = {
		kitchen: kitchen as Kitchen,
		day: {
			gte: format('yyyy-MM-dd')(new Date(date)),
			lte: format('yyyy-MM-dd')(new Date(date))
		},
		brand: Brand.CALO,
		country: getCountry(kitchen as Kitchen),
		skipped: false,
		status: [DeliveryStatus.upcoming, DeliveryStatus.paymentRequired]
	};

	const initialFoodFilters: FileManagementFoodFilter = {
		date: null,
		size: [],
		type: [],
		name: undefined,
		kitchen: kitchen,
		ids: []
	};

	const appContext = useContext(AppContext);
	const [selectedDataType, setSelectedDataType] = useState<FileManagementType>(FileManagementType.meal);
	const [filters, setFilters] = useState<DeliveriesFiltersInput>(initialFilters);
	const [filterMeals, setFilterMeals] = useState<FileManagementFoodFilter>(initialFoodFilters);
	const { deliveries, filteredDeliveries, deliveriesLoading, hasNext, handleFetchMoreDeliveries, refetchDeliveries } =
		useListDeliveryQuery(filters, initialFilters, selectedDataType, false);
	const { food, filteredFood, foodLoading, hasNextFood, handleFetchMoreFood } = useListFoodQuery(
		filterMeals,
		initialFoodFilters,
		selectedDataType
	);
	const [foodSearchResults, setFoodSearchResults] = useState<Food[]>(food);
	const [searchResults, setSearchResults] = useState<Delivery[]>(deliveries);
	const [checkboxes, setCheckboxes] = useState<CheckboxesState>({ invoice: [], backLabel: [], frontLabel: [] });
	const [mealCheckboxes, setMealCheckboxes] = useState<MealCheckBox>({});
	const [filtersSizeAndType, setFiltersSizeAndType] = useState<{ sizes: FilterSizeState; type: FilterTypeState }>({
		sizes: initialFilterSizeState(),
		type: initialFilterTypeState()
	});
	const [filterDeliveryTime, setFilterDeliveryTime] = useState<FilterTimeState>(initialFilterTimeState());

	useEffect(() => {
		if (selectedDataType === FileManagementType.meal) {
			setFilterMeals(initialFoodFilters);
			setMealCheckboxes({});
			setFiltersSizeAndType({ sizes: initialFilterSizeState(), type: initialFilterTypeState() });
		} else {
			setFilters(initialFilters);
			setCheckboxes({ invoice: [], backLabel: [], frontLabel: [] });
			setFilterDeliveryTime(initialFilterTimeState());
		}
	}, [kitchen, date, selectedDataType]);

	useEffect(() => {
		const { name, ids, size, type } = filterMeals;
		if (name && ids.length === 0 && (size.length > 0 || type.length > 0)) {
			setFilterMeals((prevMeals) => ({
				...prevMeals,
				name: undefined
			}));
		}
	}, [filterMeals.name, filterMeals.ids, filterMeals.size, filterMeals.type]);

	useEffect(() => {
		const { name, userIds, deliveryTime, driverId } = filters;
		if (name && !userIds && (deliveryTime || driverId)) {
			setFilters((prevFilters) => ({
				...prevFilters,
				name: undefined
			}));
		}
	}, [filters.name, filters.userIds, filters.deliveryTime, filters.driverId]);

	const { loading: driverDispatchLoading, data: driverData } = useQuery(LIST_DRIVER_DISPATCHES_QUERY, {
		variables: { date, kitchen },
		onError: () => {
			toast.error('Error while loading Drivers');
		}
	});

	return (
		<Box sx={{ width: '100%', mx: 2 }} id="scrollableDiv">
			<FileManagementHeader
				date={date}
				foodData={food}
				filters={filters}
				checkboxes={checkboxes}
				setFilters={setFilters}
				foodLoading={foodLoading}
				filterMeals={filterMeals}
				key={`${kitchen}-${date}`}
				deliveriesData={deliveries}
				setCheckboxes={setCheckboxes}
				mealCheckboxes={mealCheckboxes}
				setFilterMeals={setFilterMeals}
				searchLoading={deliveriesLoading}
				selectedDataType={selectedDataType}
				setSearchResults={setSearchResults}
				optionsFoodData={filteredFood || []}
				setMealCheckboxes={setMealCheckboxes}
				refetchDeliveries={refetchDeliveries}
				filtersSizeAndType={filtersSizeAndType}
				filterDeliveryTime={filterDeliveryTime}
				setSelectedDataType={setSelectedDataType}
				setFoodSearchResults={setFoodSearchResults}
				setFiltersSizeAndType={setFiltersSizeAndType}
				setFilterDeliveryTime={setFilterDeliveryTime}
				optionsDeliveryData={filteredDeliveries || []}
				handleClearData={() => setFilters(initialFilters)}
				driverData={driverData?.listDriverDispatches || []}
				driverDataLoading={driverDispatchLoading}
			/>
			<InfiniteScroll
				dataLength={selectedDataType === FileManagementType.meal ? food.length : deliveries.length}
				next={selectedDataType === FileManagementType.meal ? handleFetchMoreFood : handleFetchMoreDeliveries}
				hasMore={selectedDataType === FileManagementType.meal ? hasNextFood : hasNext}
				loader={
					(selectedDataType === FileManagementType.meal ? foodLoading : deliveriesLoading) ? (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					) : null
				}
				style={{ overflowY: 'hidden', width: '100%' }}
			>
				<FileManagementTable
					kitchen={kitchen}
					checkboxes={checkboxes}
					key={`${kitchen}-${date}`}
					setCheckboxes={setCheckboxes}
					searchResults={searchResults}
					foodSearchLoading={foodLoading}
					mealCheckboxes={mealCheckboxes}
					searchLoading={deliveriesLoading}
					selectedDataType={selectedDataType}
					foodSearchResults={foodSearchResults}
					setMealCheckboxes={setMealCheckboxes}
				/>
			</InfiniteScroll>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={(selectedDataType === FileManagementType.customer ? deliveriesLoading : foodLoading) && !appContext.isOffline}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};

export default FileManagement;

import { startCase } from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { DateRange } from '@mui/lab/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Chip, FormControl, InputAdornment, InputLabel, ListItemText, Select, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { InputField } from '../../../components';
import DateRangePicker from '../../../components/DateRangePicker';
import { useExportQualityMetricsMutation } from '../../../hooks';
import { CookingStationName, FoodComponentStationTitle, Session } from '../../../libs';
import { getDate } from '../../../libs/utils/helperFunctions';
import { Filters } from '../QualityMetrics';

interface FilterProps {
	filters: Filters;
	setFilters: (data: Filters) => void;
	shift: Session;
	tab: number;
	clearFilters: () => void;
}

const Filter = ({ filters, setFilters, shift, tab, clearFilters }: FilterProps) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [isFilterApplied, setIsFilterApplied] = useState(false);
	const [name, setName] = useState<string | undefined>(filters.name);
	const [shiftState, setShiftState] = useState<Session>(filters.shift);
	const [stationsState, setStationsState] = useState<CookingStationName[]>(filters.stations);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const appContext = useContext(AppContext);
	const { handleExportQualityMetrics, loading: exportQualityMetricsLoading } = useExportQualityMetricsMutation();

	useEffect(() => {
		setShiftState(filters.shift);
		setDateRange([new Date(filters.startDate), new Date(filters.endDate)]);
		setName(filters.name);
		setStationsState(filters.stations);
	}, [filters]);

	const handleResultsButtonClick = () => {
		setIsFilterApplied(true);
		if (dateRange[0] && dateRange[1]) {
			setIsFilterOpened(false);
			setFilters({
				...filters,
				startDate: dateRange[0] ? getDate(dateRange[0]) : '',
				endDate: dateRange[1] ? getDate(dateRange[1]) : '',
				shift: shiftState,
				name: name,
				isExpanded: false,
				stations: stationsState
			});
		} else {
			toast.error('Please select a valid date range');
		}
	};

	return (
		<React.Fragment>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
				<Button
					variant="outlined"
					disabled={appContext.isOffline}
					sx={{
						color: isFilterApplied ? caloTheme.palette.primary.main : caloTheme.palette.text.primary,
						borderColor: isFilterApplied ? caloTheme.palette.primary.main : caloTheme.palette.text.primary
					}}
					onClick={() => setIsFilterOpened(true)}
					startIcon={<FilterAltRoundedIcon />}
				>
					Filter
				</Button>
				<LoadingButton
					loading={exportQualityMetricsLoading}
					disableElevation
					disabled={exportQualityMetricsLoading || appContext.isOffline}
					onClick={() =>
						handleExportQualityMetrics({
							startDate: filters.startDate,
							endDate: filters.endDate,
							shift: shift !== Session.all && shiftState === Session.all ? undefined : shiftState,
							kitchen: filters.kitchen,
							type: filters.type,
							stations: filters.stations
						})
					}
					variant="outlined"
					startIcon={<DownloadRoundedIcon />}
					sx={{ color: caloTheme.palette.text.primary, borderColor: caloTheme.palette.text.primary }}
				>
					Download
				</LoadingButton>
			</Stack>
			<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{ m: 2, width: 300, height: '100%', my: 4 }}
				>
					<Stack direction="column" justifyContent="space-between" alignItems="start" spacing={4} sx={{ mx: 2, width: '100%' }}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
							<Typography sx={{ fontSize: '24px', fontWeight: 700, color: caloTheme.palette.text.primary }}>Filter</Typography>
							<IconButton
								aria-label="close"
								onClick={() => setIsFilterOpened(false)}
								sx={{
									color: caloTheme.palette.text.primary
								}}
							>
								<CloseIcon sx={{ fontSize: '24px', fontWeight: 700 }} />
							</IconButton>
						</Stack>
						<FormControl fullWidth>
							<InputLabel>Delivery shift</InputLabel>
							<Select label="Delivery shift" value={shiftState} onChange={(e) => setShiftState(e.target.value as Session)}>
								{filters.availableShifts.map((shiftValue) => (
									<MenuItem key={shiftValue} value={shiftValue}>
										{startCase(shiftValue)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<DateRangePicker
							dateRange={dateRange}
							setDateRange={setDateRange}
							dayRangeLimit={30}
							stackDirection="row"
							startLabel="From"
							endLabel="To"
							disableFutureDates={true}
							showCalendarIcon={true}
						/>
						<FormControl fullWidth>
							<InputLabel>{tab === 0 ? 'Meal' : 'Component'} Name</InputLabel>
							<InputField
								isOutlined={true}
								id="Name"
								value={name}
								onChange={(event: any) => setName(event)}
								label={tab === 0 ? 'Meal Name' : 'Component Name'}
								placeholder={`Enter ${tab === 0 ? 'Meal' : 'Component'} name`}
								onFocus={(event) => event.target.select()}
								endAdornment={
									<InputAdornment position="end">
										<IconButton aria-label="toggle password visibility" onClick={() => setName('')}>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						{tab === 1 && (
							<FormControl fullWidth>
								<InputLabel>Station</InputLabel>
								<Select
									label={'Station'}
									value={stationsState}
									multiple
									onChange={(e) => setStationsState(e.target.value as CookingStationName[])}
									renderValue={(selected) => (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((value) => (
												<Chip
													key={value}
													label={CookingStationName[value]}
													onMouseDown={(event) => event.stopPropagation()}
													onDelete={() => setStationsState((old) => old.filter((station) => station !== value))}
												/>
											))}
										</Box>
									)}
								>
									{Object.entries(FoodComponentStationTitle).map(([key, value]) => (
										<MenuItem key={key} value={key}>
											<Checkbox checked={stationsState.includes(key as CookingStationName)} />
											<ListItemText primary={startCase(value)} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					</Stack>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
						<Button
							variant="outlined"
							sx={{ width: '50%' }}
							onClick={() => {
								clearFilters();
								setIsFilterApplied(false);
								setIsFilterOpened(false);
							}}
						>
							clear filter
						</Button>
						<Button
							disabled={appContext.isOffline}
							variant="contained"
							sx={{ color: caloTheme.palette.white, width: '50%' }}
							onClick={handleResultsButtonClick}
						>
							apply
						</Button>
					</Stack>
				</Stack>
			</Drawer>
		</React.Fragment>
	);
};
export default Filter;

import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Input, InputBaseProps, OutlinedInput } from '@mui/material';

interface InputProps extends InputBaseProps {
	type?: string;
	debounce?: boolean;
	isOutlined?: boolean;
	label?: string;
}

const InputField = ({ type, debounce, onChange, value, isOutlined, label, ...rest }: InputProps) => {
	const [localValue, setLocalValue] = useState(value);

	const debounced = useDebouncedCallback((e: any) => {
		if (onChange) {
			onChange(e);
		}
	}, 600);

	const handleOnchange = (e: any) => {
		const callback = debounce ? debounced : onChange;
		setLocalValue(e);
		if (callback) {
			callback(e);
		}
	};

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	return (
		<>
			{isOutlined ? (
				<OutlinedInput
					type={type}
					value={debounce ? localValue : value}
					label={label}
					fullWidth
					onChange={(data: any) => handleOnchange(data.target.value)}
					{...rest}
				/>
			) : (
				<Input
					type={type}
					value={debounce ? localValue : value}
					fullWidth
					disableUnderline
					onChange={(data: any) => handleOnchange(data.target.value)}
					{...rest}
				/>
			)}
		</>
	);
};

export default InputField;

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Brand } from '@calo/types';
import UndoIcon from '@mui/icons-material/Undo';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Button, Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';

import { AppContext } from '../../../../App';
import BrandIndicator from '../../../../components/BrandIndicator';
import Modal from '../../../../components/Modal';
import { CombinedShortages, CookingStationName, FoodComponent, FoodComponentStationTitle } from '../../../../libs';
import { VERIFY_COMPONENT_MUTATION } from '../../../../libs/graphQL';
import { getTableAttributes, getUserAttributes } from '../../../../libs/utils';
import ComponentProcessTime from '../../ComponentProcessTime/ComponentProcessTime';
import ActionButtons from './ActionButtons';

interface SectionTableRowProps {
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
	tableName: CookingStationName;
	isChecked: boolean;
	foodComponent: FoodComponent;
	setSelectedTable: (value: string) => void;
	combinedShortages?: CombinedShortages;
}

const SectionTableRow = ({
	statusAttributes,
	foodComponent,
	tableName,
	combinedShortages,
	isChecked,
	setSelectedTable
}: SectionTableRowProps) => {
	const [isCheckedState, setIsCheckedState] = useState(isChecked);
	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);

	const [mutateFoodComponent, { loading }] = useMutation(VERIFY_COMPONENT_MUTATION);
	const theme = useTheme();
	const tableAttributes = getTableAttributes(statusAttributes.lastUncheckedStation, theme);

	const currentStation = foodComponent.cookingStations?.find((station) => station.name === tableName);
	const isLastStation = foodComponent.cookingStations?.[foodComponent.cookingStations?.length - 1] === currentStation;
	const appContext = useContext(AppContext);

	const handleFoodComponentVerify = async (isVerified: boolean) => {
		const user = getUserAttributes(appContext.user);
		await mutateFoodComponent({
			variables: {
				input: {
					id: foodComponent.id,
					isVerified,
					verifiedBy: user
				},
				kitchen: foodComponent.kitchen
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const actionButtons = () => {
		if (combinedShortages) {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={tableName}
					statusAttributes={statusAttributes}
					combinedShortages={combinedShortages}
					isChecked={isCheckedState}
					setIsCheckedState={setIsCheckedState}
				/>
			);
		} else {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={tableName}
					statusAttributes={statusAttributes}
					combinedShortages={undefined}
				/>
			);
		}
	};

	return (
		<TableRow hover sx={{ width: '100%', height: '62px' }}>
			<TableCell colSpan={currentStation && currentStation.processTime ? 4 : 3} sx={{ px: '15px', pr: 0, width: '200px' }}>
				<Stack display={'flex'} flexDirection={'column'}>
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							color: statusAttributes.isCheckable || currentStation?.endTime ? '' : theme.palette.neutral600
						}}
					>
						{foodComponent.name.en}
					</Typography>
					{foodComponent.isVerified && foodComponent.verifiedBy && isLastStation && (
						<Typography
							sx={{
								fontWeight: 400,
								fontSize: '14px',
								color: theme.palette.grey[500]
							}}
						>
							Verified by {foodComponent.verifiedBy.name}
						</Typography>
					)}

					{combinedShortages && (
						<Typography
							sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '19px', mt: '14px', color: theme.palette.neutral900 }}
						>
							Quantity: {combinedShortages ? combinedShortages.totalWeight : 0} g
						</Typography>
					)}
				</Stack>
			</TableCell>
			{foodComponent.brand !== Brand.CALO && (
				<TableCell padding="none" sx={{ pr: 3, pb: 1, fontSize: '16px' }}>
					<BrandIndicator brand={foodComponent.brand || Brand.CALO} />
				</TableCell>
			)}
			{!combinedShortages && currentStation && currentStation.processTime ? (
				<TableCell align="right" colSpan={1} sx={{ width: '80px', px: 0 }}>
					<ComponentProcessTime
						combinedShortages={combinedShortages}
						foodComponent={foodComponent}
						processTime={currentStation.processTime}
						endTime={currentStation.endTime}
					/>
				</TableCell>
			) : (
				<TableCell align="right" sx={{ px: 0, py: 0 }}></TableCell>
			)}

			{!currentStation?.processTime && !combinedShortages && (
				<TableCell
					padding="none"
					align="right"
					colSpan={2}
					sx={{ minWidth: '120px', fontWeight: 600, fontSize: '16px', textTransform: 'capitalize' }}
				>
					<Typography
						sx={{
							borderRadius: '16px',
							padding: '8px',
							textAlign: 'center',
							cursor: 'pointer',
							bgcolor: tableAttributes.color,
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '17px',
							color: theme.palette.neutral900,
							width: '120px',
							marginLeft: 'auto',
							flexGrow: '0',
							display: statusAttributes.lastUncheckedStation === tableName ? 'none' : ''
						}}
						onClick={() => setSelectedTable(statusAttributes.lastUncheckedStation)}
					>
						{FoodComponentStationTitle[statusAttributes.lastUncheckedStation as keyof typeof FoodComponentStationTitle]}
					</Typography>
				</TableCell>
			)}
			<TableCell
				colSpan={
					(currentStation && !currentStation.endTime && currentStation.processTime?.startedAt) || combinedShortages ? 3 : 2
				}
				padding="none"
				align="right"
			>
				{statusAttributes.isCheckable || currentStation?.endTime ? (
					(currentStation && !currentStation.endTime) || combinedShortages ? (
						actionButtons()
					) : (
						<Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
							{isLastStation && (
								<>
									{foodComponent.isVerified ? (
										<Box display="flex" flexDirection="row" alignItems="center" marginRight="20px">
											<VerifiedIcon />
											<Typography fontWeight="500" fontSize="13px" marginLeft="5px">
												VERIFIED
											</Typography>
										</Box>
									) : (
										<Button
											variant="outlined"
											disabled={loading}
											onClick={() => handleFoodComponentVerify(true)}
											startIcon={<VerifiedIcon />}
											sx={{
												color: theme.palette.black,
												backgroundColor: theme.palette.white,
												borderColor: theme.palette.black,
												borderRadius: '5px',
												textTransform: 'capitalize'
											}}
										>
											<Typography fontWeight="500" fontSize="13px">
												VERIFY
											</Typography>
										</Button>
									)}
								</>
							)}
							{foodComponent.isVerified && isLastStation ? (
								<Button
									variant="outlined"
									disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
									onClick={() => setIsConfirmationModalOpened(true)}
									style={{ width: '32px', minWidth: '32px' }}
									sx={{
										backgroundColor: theme.palette.white,
										borderColor: theme.palette.black,
										borderRadius: '5px',
										padding: 0,
										height: '32px',
										'&:disabled': {
											backgroundColor: theme.palette.white
										}
									}}
								>
									<UndoIcon sx={{ color: theme.palette.black }} />
								</Button>
							) : (
								<Typography sx={{ color: theme.palette.black, mr: '20px', fontWeight: 600, fontSize: '13px', ml: '20px' }}>
									COMPLETED
								</Typography>
							)}
						</Box>
					)
				) : (
					<Typography sx={{ color: theme.palette.neutral600, fontWeight: 600, mr: 4, fontSize: '16px' }}>Pending</Typography>
				)}
				{combinedShortages && (
					<Typography sx={{ color: theme.palette.red, fontWeight: 400, fontSize: '14px', lineHeight: '17px', mr: 3, mt: '14px' }}>
						Shortage
					</Typography>
				)}
			</TableCell>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleFoodComponentVerify(false)}
				title={'Undo confirmation '}
				message={'Are you sure you want to undo this action?'}
			/>
		</TableRow>
	);
};

export default SectionTableRow;

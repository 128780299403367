import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ModalProps {
	open: boolean;
	handleClose: () => void;
	handleAccept: () => void;
	setOpen: (open: boolean) => void;
	loading: boolean;
	title: string;
	message: string;
}
const Modal = ({ open, handleClose, handleAccept, setOpen, loading, title, message }: ModalProps) => {
	const handleAction = () => {
		setOpen(false);
		handleAccept();
	};
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button disabled={loading} onClick={() => handleAction()} autoFocus>
					Yes
				</Button>
				<Button disabled={loading} onClick={handleClose}>
					No
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default Modal;

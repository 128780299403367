import { useContext, useState } from 'react';

import { MeasurementUnit } from '@calo/dashboard-types';
import { MenuItem, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { useDocumentMedia } from '../../../../libs/utils/document';

interface NewBatchFormProps {
	weight?: number;
	componentName: string;
	addBatchModalRef: any;
	measurementUnit?: MeasurementUnit;
	handleAddBatch: (values: any) => void;
	setIsEditing: (value: boolean) => void;
}

const NewBatchForm = ({
	setIsEditing,
	addBatchModalRef,
	componentName,
	handleAddBatch,
	weight,
	measurementUnit
}: NewBatchFormProps) => {
	const [newBatch, setNewBatch] = useState({ weight: weight || '', measurementUnit: measurementUnit || '' });
	const appContext = useContext(AppContext);
	const { isMobile } = useDocumentMedia();

	const handleClearNewBatch = () => {
		setNewBatch({ weight: '', measurementUnit: '' });
		addBatchModalRef.current?.close();
		setIsEditing(false);
	};
	return (
		<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'}>
			<Stack display={'flex'} flexDirection={isMobile ? 'column' : 'row'} sx={{ gap: isMobile ? 2 : 0 }}>
				<Stack style={{ width: '100%' }}>
					<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '4px' }}>Component Name</label>
					<Typography sx={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.9px' }}>{componentName}</Typography>
				</Stack>
				<Stack display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
					<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px' }}>Batch Weight</label>
					<TextField
						type="number"
						value={newBatch.weight}
						placeholder="0"
						disabled={appContext.isOffline}
						onChange={(event) => setNewBatch({ ...newBatch, weight: event.target.value.toString() })}
						fullWidth={true}
						inputMode="numeric"
						inputProps={{ inputProps: { style: { min: 0, fontWeight: 600, fontSize: '12px', lineHeight: '14px' } } }}
						sx={{
							mr: 2,
							mt: '4px',
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 600,
							borderRadius: '8px',
							width: isMobile ? '100%' : '246px',
							height: '51px',
							textAlign: 'center',
							input: {
								textAlign: 'center',
								fontWeight: 600,
								fontSize: '16px',
								lineHeight: '16px'
							}
						}}
					/>
				</Stack>
				<Stack display={'flex'} flexDirection={'column'}>
					<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '6px' }}>Measurment Unit</label>
					<TextField
						select
						placeholder={'Select Unit'}
						value={newBatch.measurementUnit}
						sx={{ width: '150px' }}
						onChange={(event) => setNewBatch({ ...newBatch, measurementUnit: event.target.value })}
						InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
					>
						{[MeasurementUnit.g, MeasurementUnit.piece, MeasurementUnit.ml].map((row) => (
							<MenuItem key={row} value={row}>
								{row}
							</MenuItem>
						))}
					</TextField>
				</Stack>
			</Stack>
			<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
				<Button
					variant="contained"
					sx={{
						width: 'auto',
						height: '51px',
						mr: 2,
						boxShadow: 'none',
						backgroundColor: caloTheme.palette.primary500,
						color: caloTheme.palette.white,
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						':hover': {
							boxShadow: 'none',
							backgroundColor: caloTheme.palette.primary500,
							color: caloTheme.palette.white
						}
					}}
					disabled={!newBatch.weight || newBatch.measurementUnit.length === 0 || +newBatch.weight === 0}
					onClick={() => {
						handleAddBatch(newBatch);
						handleClearNewBatch();
					}}
				>
					Confirm Batch
				</Button>
				<Button
					variant="text"
					sx={{
						width: '159px',
						height: '51px',
						color: caloTheme.palette.neutral500,
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						':hover': {
							backgroundColor: caloTheme.palette.neutral100,
							color: caloTheme.palette.neutral500
						}
					}}
					onClick={() => handleClearNewBatch()}
				>
					Close
				</Button>
			</Stack>
		</Box>
	);
};
export default NewBatchForm;

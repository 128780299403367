import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { KDSPermission } from '@calo/dashboard-types';

import { AppContext } from '../../App';
import { getDefaultRoute } from '../../libs/utils/getDefaultRoute';

interface ProtectedRouteProps {
	component: React.ComponentType<any>;
	permission?: KDSPermission;
	componentProps?: any;
}

const ProtectedRoute = ({ component: Component, permission, componentProps = {} }: ProtectedRouteProps) => {
	const { user } = useContext(AppContext);

	if (!permission || !user.permissions?.includes(permission)) {
		const defaultRoute = getDefaultRoute(user.permissions ?? []);
		return <Navigate to={defaultRoute} />;
	}

	return <Component {...componentProps} />;
};

export default ProtectedRoute;

import { format } from 'date-fns/fp';
import { orderBy } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '../../App';
import { caloTheme } from '../../assets/themes/calo';
import EmptyMessage from '../../components/EmptyMessage';
import { useListComponentByRangeQuery } from '../../hooks';
import { Food } from '../../libs';
import Filter from './Filter';
import ShortageDisplayTable from './ShortageDisplayTable';

interface ShortageDisplayProps {
	kitchen: string;
	date: string;
	foods: Food[];
}

interface Filters {
	startDate: string;
	endDate: string;
}

const ShortageDisplay = ({ kitchen, date, foods }: ShortageDisplayProps) => {
	const appContext = useContext(AppContext);
	const theme = useTheme();
	const [isFilterEnabled, setIsFilterEnabled] = useState(false);
	const [filters, setFilters] = useState<Filters>({
		startDate: format('yyyy-MM-dd')(Date.parse(date)),
		endDate: format('yyyy-MM-dd')(Date.parse(date))
	});
	const lastDayFoodComponents = format('yyyy-MM-dd')(Date.parse(date));
	const queryVariables = {
		kitchen,
		startDate: filters.startDate,
		endDate: filters.endDate
	};

	const { componentsLoading, foodComponents, refetchComponents, hasNext } = useListComponentByRangeQuery({
		kitchen,
		startDate: filters.startDate,
		endDate: filters.endDate,
		filters: { shortages: true }
	});

	useEffect(() => {
		refetchComponents({ ...queryVariables, filters: { shortages: true } });
	}, [filters]);

	useEffect(() => {
		setFilters({
			startDate: format('yyyy-MM-dd')(Date.parse(date)),
			endDate: format('yyyy-MM-dd')(Date.parse(date))
		});
		setIsFilterEnabled(false);
	}, [kitchen, date]);

	useEffect(() => {
		refetchComponents();
	}, [kitchen, date, foods]);

	const sortedFoodComponents = orderBy(
		foodComponents,
		[(fc) => new Date(fc.shortages ? fc.shortages[fc.shortages.length - 1].createdAt! : '')],
		['desc']
	);

	return (
		<Box sx={{ width: '97%' }}>
			<Stack direction="column" sx={{ ml: '20px', mt: '15px', mb: '10px', width: '100%' }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Filter
						filters={filters}
						setFilters={setFilters}
						setIsFilterEnabled={setIsFilterEnabled}
						isFilterEnabled={isFilterEnabled}
						date={date}
						kitchen={kitchen}
						lastDayDate={lastDayFoodComponents}
					/>
				</Grid>
				<Table stickyHeader sx={{ m: 2 }}>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Date
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Component name
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Shortage weight
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Meal name
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Meal size
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Status
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Created at
							</TableCell>
							<TableCell
								sx={{
									backgroundColor: theme.palette.neutral100,
									border: 0,
									fontWeight: 600,
									fontSize: '16px',
									textAlign: 'center'
								}}
							>
								Completed at
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedFoodComponents.length > 0 ? (
							sortedFoodComponents.map((fc) => <ShortageDisplayTable key={fc.id} foodComponent={fc} />)
						) : (
							<EmptyMessage label={'no data'} style={{ margin: 20 }} />
						)}
					</TableBody>
				</Table>
				{!hasNext && isFilterEnabled && !componentsLoading ? (
					<Box
						sx={{
							mt: 2,
							fontFamily: caloTheme.typography.fontFamily,
							fontSize: '28px',
							textTransform: 'uppercase',
							color: theme.palette.neutral600,
							textAlign: 'center'
						}}
					>
						The End
					</Box>
				) : (
					isFilterEnabled &&
					!componentsLoading && (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					)
				)}
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={componentsLoading && !appContext.isOffline}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Stack>
		</Box>
	);
};

export default ShortageDisplay;

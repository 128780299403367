import { caloTheme } from '../../assets/themes/calo';
import { ComponentBatch, QualityCheckStatus, QualityRating } from '../../libs';

export const applyStatusFilterOnQualityTracking = (
	selectedCheckStatuses: QualityCheckStatus[],
	foodComponentBatch?: ComponentBatch
) => {
	return (
		(foodComponentBatch?.quality?.[0]?.status && selectedCheckStatuses.includes(foodComponentBatch?.quality?.[0]?.status)) ||
		(selectedCheckStatuses.includes(QualityCheckStatus.Pending) && (!foodComponentBatch || !foodComponentBatch.quality?.[0]))
	);
};

export const renderDotColor = (standardRating: QualityRating | null | undefined | QualityCheckStatus) => {
	switch (standardRating) {
		case QualityRating.bad:
		case QualityCheckStatus.Rejected:
			return caloTheme.palette.secondaryAlert500;
		case QualityRating.neutral:
		case QualityCheckStatus.Pending:
			return caloTheme.palette.secondaryYellow500;
		case QualityRating.good:
		case QualityCheckStatus.Accepted:
			return caloTheme.palette.primary.main;
		default:
			return caloTheme.palette.white;
	}
};

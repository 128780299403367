import { format, subDays } from 'date-fns/fp';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';

import { useListCustomerComplaints } from '../../hooks';
import { ComplaintsFiltersInput, Kitchen } from '../../libs';
import CustomerComplaintsTable from './CustomerComplaintsTable.tsx/CustomerComplaintsTable';

interface CustomerComplaintsProps {
	kitchen: Kitchen;
	date: string;
}

const CustomerComplaints = ({ kitchen, date }: CustomerComplaintsProps) => {
	const initialFilters: ComplaintsFiltersInput = {
		kitchen: kitchen || Kitchen.BH001,
		date: {
			gte: format('yyyy-MM-dd')(subDays(1)(new Date(date))),
			lte: format('yyyy-MM-dd')(new Date(date))
		}
	};

	const [filters, setFilters] = useState<ComplaintsFiltersInput>(initialFilters);

	const { complaintsLoading, handleFetchMoreComplaints, hasNext, complaintsList } = useListCustomerComplaints(filters);

	useEffect(() => {
		setFilters(initialFilters);
	}, [kitchen, date]);

	return (
		<Box sx={{ width: '98%', minHeight: '100vh' }}>
			<InfiniteScroll
				dataLength={complaintsList.length}
				next={handleFetchMoreComplaints}
				hasMore={hasNext}
				loader={
					hasNext ? (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					) : null
				}
				style={{ overflowY: 'hidden' }}
			>
				<CustomerComplaintsTable
					key={`${kitchen}-${date}`}
					kitchen={kitchen}
					date={date}
					filters={filters}
					setFilters={setFilters}
					complaints={complaintsList || []}
				/>
				<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={complaintsLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</InfiniteScroll>
		</Box>
	);
};
export default CustomerComplaints;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';

import { Kitchen, QualityMetric, Session } from '../libs';
import { GraphQLClient, LIST_QUALITY_METRICS_QUERY } from '../libs/graphQL';

interface Props {
	startDate: string;
	endDate: string;
	kitchen: Kitchen;
	shift?: Session;
}

const useListQualityMetricsQuery = ({ startDate, endDate, kitchen, shift }: Props) => {
	const [qualityMetrics, setQualityMetrics] = useState<QualityMetric[]>([]);

	const { loading, refetch } = useQuery(LIST_QUALITY_METRICS_QUERY, {
		variables: { startDate, endDate, kitchen, shift },
		onCompleted: (data) => {
			if (data?.listQualityMetrics?.data) {
				setQualityMetrics(data.listQualityMetrics.data);
			}
		},
		onError: (error) => {
			toast.error(`Error fetching Quality Metrics data: ${error.message}`);
		}
	});

	const clearData = () => {
		setQualityMetrics([]);
		GraphQLClient.clearStore();
	};

	useEffect(() => {
		setQualityMetrics([]);
		refetch();
	}, [startDate, endDate, kitchen, shift]);

	return {
		qualityMetricsLoading: loading,
		qualityMetrics,
		clearQualityMetricsData: clearData
	};
};

export default useListQualityMetricsQuery;

import { createContext } from 'react';

import { IQualityTrackingContext, Kitchen, Session } from '../../libs';

export const QualityTrackingContext = createContext<IQualityTrackingContext>({
	chefs: [],
	date: '',
	kitchen: '' as Kitchen,
	shifts: Session.all,
	setShifts: () => '',
	clearData: () => undefined,
	batches: [],
	selectedStation: '',
	setSelectedStation: () => '',
	isLoading: false,
	foodComponentsWithBatches: [],
	selectedCheckStatuses: [],
	setSelectedCheckStatuses: () => [],
	searchText: '',
	setSearchText: () => ''
});

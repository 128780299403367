import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

import { caloTheme } from '../../assets/themes/calo';

interface OfflineAlertProps {
	isOffline: boolean;
}
const OfflineAlert = ({ isOffline }: OfflineAlertProps) => {
	const theme = useTheme();

	return (
		<Alert
			variant="outlined"
			severity={isOffline ? 'error' : 'success'}
			icon={isOffline ? <WifiOffIcon fontSize="inherit" /> : <WifiIcon fontSize="inherit" />}
			sx={{
				fontFamily: caloTheme.typography.fontFamily,
				fontSize: '19px',
				textTransform: 'uppercase',
				borderWidth: '2px',
				color: isOffline ? theme.palette.red : theme.palette.primary500,
				py: 0,
				px: 2,
				lineHeight: 'inherit',
				backgroundColor: 'white'
			}}
		>
			You are {isOffline ? 'offline' : 'online'}
		</Alert>
	);
};
export default OfflineAlert;

import { capitalize, startCase, upperFirst } from 'lodash-es';
import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { TicketStatus } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Box, Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { Image, Popup } from '../../../../components';
import { Complaint, ComplaintStatus, DocsType } from '../../../../libs';
import { EXPORT_DOCUMENTS_MUTATION, RESOLVE_COMPLAINT_MUTATION } from '../../../../libs/graphQL';
import { getUserAttributes } from '../../../../libs/utils/cognitoUserHelpers';
import { createAutoDownloadLinkFromArrayOfUrls, formatDate } from '../../../../libs/utils/helperFunctions';

interface CustomerComplaintsTableRowProps {
	complaint: Complaint;
}

const CustomerComplaintsTableRow = ({ complaint }: CustomerComplaintsTableRowProps) => {
	const viewImageRef = useRef<any>();
	const commentModalRef = useRef<any>();
	const attachmentModalRef = useRef<any>();
	const appContext = useContext(AppContext);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [selectedComment, setSelectedComment] = useState<any>(null);
	const [selectedAttachment, setSelectedAttachment] = useState<string>('');
	const [updateCustomerComplaint] = useMutation(RESOLVE_COMPLAINT_MUTATION);
	const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);
	const [exportDocuments, { loading }] = useMutation(EXPORT_DOCUMENTS_MUTATION);

	const handleUpdateComplaint = async (selectedComplaint: Complaint, newStatus: TicketStatus) => {
		await updateCustomerComplaint({
			variables: {
				id: selectedComplaint!.id,
				status: newStatus,
				kitchen: selectedComplaint.kitchen,
				user: getUserAttributes(appContext.user)
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleViewComment = (comment: string) => {
		setSelectedComment(comment);
	};

	const exportDocument = async (deliveryId: string, docType: DocsType) => {
		if (deliveryId) {
			const { data } = await exportDocuments({
				variables: { ids: [deliveryId], docTypes: [docType], day: complaint.date, kitchen: complaint.kitchen },
				onError: (e) => {
					toast.error(`Error while exporting ${docType}, ${e.message} `);
					return;
				}
			});
			if (data?.exportDocuments.data && data.exportDocuments.data.length > 0) {
				return createAutoDownloadLinkFromArrayOfUrls(
					data.exportDocuments.data.filter((exportDoc: any) => exportDoc.includes('pdf'))
				);
			}
		}
	};

	const handleExportDoc = async (selectedComplaintData: Complaint) => {
		if (selectedComplaintData.deliveryId) {
			await exportDocument(selectedComplaintData.deliveryId, DocsType.invoice);
		}
	};

	const handleComplaintsStatus = (status: ComplaintStatus) => {
		switch (status) {
			case 'approved':
				return (
					<IconButton
						style={{
							backgroundColor: caloTheme.palette.primary100,
							cursor: 'auto',
							margin: 'auto'
						}}
					>
						<Iconify
							icon="ic:baseline-check"
							style={{
								color: caloTheme.palette.primary700
							}}
						/>
					</IconButton>
				);
			case 'rejected':
				return (
					<IconButton
						style={{
							backgroundColor: caloTheme.palette.secondaryRed100,
							cursor: 'auto',
							margin: 'auto'
						}}
					>
						<Iconify
							icon="ph:x-bold"
							style={{
								color: caloTheme.palette.red
							}}
						/>
					</IconButton>
				);
			case 'pending':
				return (
					<Button
						variant="outlined"
						sx={{
							textTransform: 'none',
							borderColor: caloTheme.palette.neutral900,
							fontSize: '16px',
							color: caloTheme.palette.neutral900,
							borderRadius: '8px',
							width: '40px',
							height: '40px',
							fontWeight: 600,
							margin: 'auto',
							boxShadow: 'none',
							':hover': {
								boxShadow: 'none',
								borderColor: caloTheme.palette.neutral900,
								color: caloTheme.palette.neutral900
							}
						}}
						onClick={() => {
							handleUpdateComplaint(complaint, TicketStatus.review);
						}}
					>
						Review
					</Button>
				);
			case 'review':
				return (
					<>
						<Button
							variant="contained"
							sx={{
								fontSize: '16px',
								color: caloTheme.palette.red,
								backgroundColor: caloTheme.palette.secondaryPink100,
								borderRadius: '8px',
								width: '40px',
								height: '40px',
								fontWeight: 600,
								px: 4,
								boxShadow: 'none',
								':hover': {
									boxShadow: 'none',
									color: caloTheme.palette.red,
									backgroundColor: caloTheme.palette.secondaryPink100
								}
							}}
							onClick={() => {
								handleUpdateComplaint(complaint, TicketStatus.rejected);
							}}
						>
							<ClearRoundedIcon sx={{ margin: '20%' }} style={{ justifyContent: 'center' }} />
						</Button>
						<Button
							variant="contained"
							sx={{
								textTransform: 'none',
								fontSize: '16px',
								color: caloTheme.palette.primary500,
								backgroundColor: caloTheme.palette.primary100,
								borderRadius: '8px',
								width: '40px',
								height: '40px',
								fontWeight: 600,
								px: 4,
								boxShadow: 'none',
								':hover': {
									boxShadow: 'none',
									color: caloTheme.palette.primary500,
									backgroundColor: caloTheme.palette.primary100
								}
							}}
							onClick={() => {
								handleUpdateComplaint(complaint, TicketStatus.approved);
							}}
						>
							<CheckOutlinedIcon sx={{ margin: '20%' }} style={{ justifyContent: 'center' }} />
						</Button>
					</>
				);
			default:
				return <Typography>Something Wrong</Typography>;
		}
	};

	const handleChangeImage = (selectedAttachments: string[], viewNext: string, index: number) => {
		if (index < selectedAttachments.length || (index >= selectedAttachments.length && index !== -1)) {
			setCurrentIndex(index);
			if (index === selectedAttachments.length) {
				setCurrentIndex(0);
			} else if (viewNext === 'Right' && selectedAttachments.length >= index) {
				setCurrentIndex(index + 1);
				setSelectedAttachment(selectedAttachments[index + 1]);
			} else if (viewNext === 'Left' && selectedAttachments.length > index && index >= 0) {
				setCurrentIndex(index - 1);
				setSelectedAttachment(selectedAttachments[index - 1]);
			}
		}
	};

	return (
		<>
			<TableRow key={`${complaint.id}-${complaint.createdAt}`}>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography padding="6px" fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
						{complaint.user.name}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '194px', border: 'none', p: 1 }}>
					<Typography textTransform={'none'} sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{upperFirst(startCase(complaint.category).toLowerCase())}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '194px', border: 'none', p: 1 }}>
					<Typography textTransform={'none'} sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{capitalize(startCase(complaint.type).toLowerCase())}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{`${startCase(complaint.createdBy?.name)} on ${formatDate(complaint.createdAt ?? '', 'h:mm a dd-MM-yyyy')}`}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{complaint.resolvedAt && complaint.resolvedBy
							? `${startCase(complaint.resolvedBy?.name)} on ${formatDate(complaint.resolvedAt, 'hh:mm a dd-MM-yyyy')}`
							: '----'}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography
						textTransform={'capitalize'}
						sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}
					>
						{complaint.date}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{startCase(complaint.deliveryTime)}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{startCase(complaint.driver?.name)}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
						{complaint.operationsId}
					</Typography>
				</TableCell>
				<TableCell sx={{ minWidth: '94px', border: 'none', p: 1 }}>
					<Typography
						textTransform={'capitalize'}
						sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}
					>
						{startCase(complaint.meal?.name) || ''}
					</Typography>
				</TableCell>
				<TableCell sx={{ width: '196px', border: 'none', textAlign: 'center' }} key={complaint.id}>
					<Button
						variant="text"
						sx={{
							width: '48px',
							color: caloTheme.palette.neutral900,
							fontFamily: 'Roboto',
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							height: '36px',
							textTransform: 'capitalize',
							textAlign: 'center',
							backgroundColor: caloTheme.palette.neutral50,
							borderRadius: 4
						}}
						disabled={!complaint.deliveryId || loading}
						onClick={() => handleExportDoc(complaint)}
					>
						<Iconify icon="lucide:download" />
					</Button>
				</TableCell>
				<TableCell sx={{ width: '196px', border: 'none', textAlign: 'center' }} key={complaint.id}>
					<Button
						variant="text"
						sx={{
							width: '48px',
							color: caloTheme.palette.neutral900,
							fontFamily: 'Roboto',
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							height: '36px',
							textTransform: 'capitalize',
							textAlign: 'center',
							backgroundColor: caloTheme.palette.neutral50,
							borderRadius: 4
						}}
						disabled={!complaint.comments || complaint.comments?.length === 0}
						onClick={() => {
							const lastComment = complaint.comments ? complaint.comments[complaint.comments.length - 1] : '';
							handleViewComment(lastComment && lastComment.text ? lastComment.text : 'text');
							commentModalRef.current?.open();
						}}
					>
						<Iconify icon="octicon:comment-24" />
					</Button>
				</TableCell>
				<TableCell sx={{ width: '196px', border: 'none', textAlign: 'center' }}>
					<Button
						variant="text"
						sx={{
							color: caloTheme.palette.neutral900,
							fontFamily: 'Roboto',
							textAlign: 'center',
							fontWeight: 600,
							fontSize: '16px',
							width: '48px',
							lineHeight: '19px',
							textTransform: 'capitalize',
							backgroundColor: caloTheme.palette.neutral50,
							height: '36px',
							borderRadius: 4,
							boxShadow: 'none',
							':hover': {
								boxShadow: 'none',
								color: caloTheme.palette.primary500
							}
						}}
						disabled={!complaint.attachments || complaint.attachments.length === 0}
						onClick={() => {
							setSelectedAttachments(complaint.attachments || []);
							attachmentModalRef.current?.open();
						}}
					>
						<Iconify icon="mingcute:attachment-line" rotate={1} />
					</Button>
				</TableCell>
				<TableCell sx={{ border: 'none', p: 1, textAlign: 'center', minWidth: '166px', width: '166px' }}>
					<Stack display={'flex'} flexDirection={'row'} justifyContent="space-between">
						<Tooltip title={complaint.status === 'pending' ? '' : startCase(complaint.status)} arrow>
							{handleComplaintsStatus(complaint.status ?? ComplaintStatus.pending)}
						</Tooltip>
					</Stack>
				</TableCell>
			</TableRow>

			<Popup
				ref={commentModalRef}
				onClose={() => {
					setSelectedComment(null);
					commentModalRef.current?.close();
				}}
				title={'Comments'}
				style={{
					fullWidth: true,
					maxWidth: 'md'
				}}
			>
				<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'}>
					<Typography
						variant="h2"
						sx={{
							color: caloTheme.palette.neutral900,
							pl: 1,
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '20px'
						}}
					>
						{selectedComment}
					</Typography>
					<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} sx={{ paddingTop: 2 }}>
						<Button
							variant="text"
							sx={{
								fontSize: '16px',
								color: caloTheme.palette.primary500,
								width: '90px',
								height: '50px',
								fontWeight: 600
							}}
							onClick={() => {
								setSelectedComment(null);
								commentModalRef.current?.close();
							}}
						>
							Done
						</Button>
					</Stack>
				</Box>
			</Popup>

			<Popup
				ref={attachmentModalRef}
				onClose={() => {
					attachmentModalRef.current?.close();
				}}
				title={'Attachments'}
				style={{
					fullWidth: true,
					maxWidth: 'xl'
				}}
			>
				<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
					<Grid container spacing={5}>
						{complaint.attachments?.map((image, index) => (
							<Grid item key={image}>
								<Grid container alignItems="center">
									<Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
										<Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
											<Stack
												key={image}
												display={'flex'}
												flexDirection={'column'}
												justifyContent={'start'}
												onClick={() => {
													setSelectedAttachment(image);
													setCurrentIndex(index);
													viewImageRef.current.open();
												}}
											>
												<Image
													key={image}
													url={`${process.env.REACT_APP_BUCKET_URL}/complaints/${complaint.id.split('#')[1]}/${image}`}
													alt={'Attachments'}
													height={220}
												/>
											</Stack>
											<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
												<Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
													Attachment {index + 1}
												</Typography>
											</Stack>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Box>
			</Popup>

			<Popup
				ref={viewImageRef}
				onClose={() => {
					viewImageRef.current?.close();
					setSelectedAttachment('');
					setCurrentIndex(0);
				}}
				title={'Attachments'}
				style={{
					fullWidth: true,
					maxWidth: 'xl'
				}}
			>
				{selectedAttachments.length > 0 && (
					<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ width: '100%' }}>
						<Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
							<Stack justifyContent={'center'} display={selectedAttachments.length > 1 ? 'flex' : 'none'}>
								<IconButton
									onClick={() =>
										handleChangeImage(complaint.attachments || [], 'Left', selectedAttachments.indexOf(selectedAttachment))
									}
									sx={{
										backgroundColor: caloTheme.palette.primary200,
										cursor: 'pointer',
										display: currentIndex <= 0 ? 'none' : 'flex'
									}}
								>
									<Iconify icon="formkit:arrowleft" color={caloTheme.palette.primary500} width={32} height={'32px'} />
								</IconButton>
							</Stack>
							<Stack key={selectedAttachment} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
								<Image
									key={selectedAttachment}
									url={`${process.env.REACT_APP_BUCKET_URL}/complaints/${complaint.id.split('#')[1]}/${selectedAttachment}`}
									alt={'Attachments'}
									height={612}
									width={1024}
								/>
							</Stack>
							<Stack justifyContent={'center'} display={selectedAttachments.length > 1 ? 'flex' : 'none'}>
								<IconButton
									onClick={() =>
										handleChangeImage(complaint.attachments || [], 'Right', selectedAttachments.indexOf(selectedAttachment))
									}
									sx={{
										backgroundColor: caloTheme.palette.primary200,
										cursor: 'pointer',
										display: currentIndex + 1 >= selectedAttachments.length ? 'none' : 'flex'
									}}
								>
									<Iconify icon="formkit:arrowright" color={caloTheme.palette.primary500} width={32} height={'32px'} />
								</IconButton>
							</Stack>
						</Box>
						<Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} sx={{ paddingTop: 2 }}>
							<Button
								variant="text"
								sx={{
									fontSize: '16px',
									color: caloTheme.palette.primary500,
									width: '90px',
									height: '50px',
									fontWeight: 600
								}}
								onClick={() => {
									setSelectedAttachment('');
									viewImageRef.current?.close();
								}}
							>
								Close
							</Button>
						</Stack>
					</Box>
				)}
			</Popup>
		</>
	);
};

export default CustomerComplaintsTableRow;

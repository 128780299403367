import { compact, uniq } from 'lodash-es';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, CircularProgress, Stack } from '@mui/material';

import EmptyMessage from '../../../components/EmptyMessage';
import { useListComponentBatchesWithPaginationQuery } from '../../../hooks';
import { ComponentBatch, CookingStationName, Kitchen, QualityMetric, QualityMetricsFoodComponent, Session } from '../../../libs';
import ComponentsSectionTable from '../ComponentsTable/SectionTable';
import MealsSectionTable from '../MealsTable/SectionTable';
import BatchDrawer from './BatchDrawer';

interface Props {
	qualityMetrics: QualityMetric[];
	tab: 0 | 1;
	kitchen: Kitchen;
	shift?: Session;
	date: string;
	loading: boolean;
	name?: string;
	filterText: string;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
	stations: CookingStationName[];
}
export interface BatchDrawerData {
	foodComponentBatch?: ComponentBatch;
	qualityMetricsFoodComponent?: QualityMetricsFoodComponent;
	isReviewDrawerOpened: boolean;
}

const Section = ({
	qualityMetrics,
	tab,
	kitchen,
	shift,
	date,
	name,
	loading,
	filterText,
	isExpanded,
	setIsExpanded,
	stations
}: Props) => {
	const dates = qualityMetrics.length ? compact(uniq(qualityMetrics?.map((qualityMetric) => qualityMetric.day))) : [date];
	const { batches, handleFetchMoreBatches, hasNext } = useListComponentBatchesWithPaginationQuery(kitchen, dates, shift);
	const [batchDrawerData, setBatchDrawerData] = useState<BatchDrawerData>({
		isReviewDrawerOpened: false,
		foodComponentBatch: undefined,
		qualityMetricsFoodComponent: undefined
	});

	return (
		<Box sx={{ width: '100%', mx: 2 }}>
			{qualityMetrics.length > 0 ? (
				tab === 0 ? (
					qualityMetrics.map((qualityMetric, index) => (
						<MealsSectionTable
							key={qualityMetric.id}
							qualityMetric={qualityMetric}
							name={name}
							filterText={index === 0 ? filterText : undefined}
							isExpanded={isExpanded}
							setIsExpanded={setIsExpanded}
						/>
					))
				) : (
					<InfiniteScroll
						dataLength={qualityMetrics.length}
						next={handleFetchMoreBatches}
						hasMore={hasNext}
						loader={
							hasNext ? (
								<Stack direction="column" alignItems="center">
									<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
								</Stack>
							) : null
						}
						style={{ overflow: 'hidden', width: '100%' }}
					>
						{qualityMetrics.map((qualityMetric, index) => (
							<ComponentsSectionTable
								key={qualityMetric.id}
								qualityMetric={qualityMetric}
								batches={batches.filter((batch) => batch.date === qualityMetric.day && batch.session === qualityMetric.shift)}
								name={name}
								filterText={index === 0 ? filterText : undefined}
								isExpanded={isExpanded}
								setIsExpanded={setIsExpanded}
								stations={stations}
								setBatchDrawerData={setBatchDrawerData}
							/>
						))}
					</InfiniteScroll>
				)
			) : (
				<EmptyMessage label={loading ? 'Loading' : 'No data'} style={{ marginTop: '10', textAlign: 'center' }} />
			)}
			<BatchDrawer
				isReviewDrawerOpened={batchDrawerData.isReviewDrawerOpened}
				setIsReviewDrawerOpened={() => setBatchDrawerData((old) => ({ ...old, isReviewDrawerOpened: !old.isReviewDrawerOpened }))}
				foodComponent={batchDrawerData.qualityMetricsFoodComponent}
				foodComponentBatch={batchDrawerData.foodComponentBatch}
			/>
		</Box>
	);
};

export default Section;

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';

interface ImageModalProps {
	alt: string;
	url: string;
	width?: number;
	height?: number;
	onClick?: (value: boolean) => void;
	style?: any;
}
const ImageModal = ({ alt, url, width, height, onClick, ...style }: ImageModalProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [naturalSize, setNaturalSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		setIsError(false);
		setIsLoading(true);
		const image = new Image();
		image.addEventListener('load', () => {
			setNaturalSize({ width: image.naturalWidth, height: image.naturalHeight });
			setIsLoading(false);
		});
		image.addEventListener('error', () => {
			setIsError(true);
			setIsLoading(false);
		});
		image.src = url;
	}, [url, alt]);

	return (
		<Stack direction="column" alignItems="center" textAlign="center">
			<Stack
				direction="column"
				alignItems="center"
				textAlign="center"
				sx={{ display: isLoading ? 'block' : 'none', height: height || '100%' }}
			>
				<CircularProgress sx={{ position: 'relative', top: '30%' }} />
			</Stack>
			<Box sx={{ display: isLoading ? 'none' : 'block', cursor: !isError && onClick ? 'pointer' : 'default' }}>
				<img
					width={width || 'auto'}
					height={height || 'auto'}
					style={{
						...style,
						maxHeight: Math.min(naturalSize.height, height || naturalSize.height),
						maxWidth: Math.min(naturalSize.width, width || naturalSize.width),
						objectFit: 'contain'
					}}
					onClick={() => !isError && onClick && onClick(true)}
					onLoad={() => setIsLoading(false)}
					onError={(e: any) => {
						e.target.src = `https://via.placeholder.com/${width || 240}`;
						setIsError(true);
					}}
					src={url}
					alt={alt}
					{...style}
				/>
			</Box>
		</Stack>
	);
};
export default ImageModal;

import { compact } from 'lodash';

import { Card, CardContent, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import { CookingStationName, Kitchen, QualityMetric } from '../../../libs';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../libs/utils/helperFunctions';

interface Props {
	qualityMetrics: QualityMetric[];
	kitchen: Kitchen;
	stations: CookingStationName[];
}

const Header = ({ qualityMetrics, kitchen, stations }: Props) => {
	const allComponents = compact(qualityMetrics.flatMap((q) => q.components));
	const filteredComponents = allComponents.filter((f) =>
		stations.length > 0 && f.lastCookingStation ? stations.includes(f.lastCookingStation) : true
	);
	const numberOfRejected = filteredComponents.reduce((acc, curr) => acc + (curr?.numberOfRejected ?? 0), 0);
	const totalReviewed = filteredComponents.reduce((acc, curr) => acc + (curr?.totalReviewed ?? 0), 0);
	const consistencyScore = filteredComponents.reduce((acc, curr) => acc + (curr?.consistencyScore ?? 0), 0);
	const costOfPoorQuality = filteredComponents.reduce((acc, curr) => acc + (curr?.weightOfRejected ?? 0) * (curr?.cost ?? 0), 0);
	const ratedComponents = filteredComponents.filter((curr) => (curr?.totalReviewed ?? 0) > 0);

	const metrics = [
		{
			label: 'REJECTED',
			value: getPercentage(numberOfRejected / (totalReviewed || 1)),
			detail: `${numberOfRejected} / ${totalReviewed}`
		},
		{
			label: 'REVIEW RATE',
			value: getPercentage(ratedComponents.length / (filteredComponents.length || 1)),
			detail: `${ratedComponents.length} / ${filteredComponents.length}`
		},
		{ label: 'CONSISTENCY SCORE', value: getPercentage(consistencyScore / (ratedComponents.length || 1)), detail: '' },
		{
			label: 'COST OF POOR QUALITY',
			value: getCurrencyFromKitchen(kitchen) + ' ' + roundToTwoDecimals(costOfPoorQuality),
			detail: ''
		}
	];

	return (
		<Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
			{metrics.map((metric, index) => (
				<Card
					key={index}
					sx={{
						backgroundColor: caloTheme.palette.grey[100],
						boxShadow: 'none',
						borderRadius: '12px',
						width: 'calc(25% - 8px)'
					}}
				>
					<CardContent sx={{ '&:last-child': { paddingBottom: 1 } }}>
						<Typography
							sx={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.17px', color: caloTheme.palette.text.secondary }}
						>
							{metric.label}
						</Typography>
						<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
							<Typography sx={{ fontSize: '24px', color: caloTheme.palette.text.primary, mt: 1 }}>{metric.value}</Typography>
							{metric.detail && (
								<Typography sx={{ fontSize: '16px', color: caloTheme.palette.text.secondary }}>{metric.detail}</Typography>
							)}
						</Stack>
					</CardContent>
				</Card>
			))}
		</Stack>
	);
};

export default Header;

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Brand } from '@calo/types';
import UndoIcon from '@mui/icons-material/Undo';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Button, Stack, TableRow, Typography, useTheme } from '@mui/material';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import Modal from '../../../../components/Modal';
import { CookingStations, FoodComponent, FoodComponentStationTitle } from '../../../../libs';
import { VERIFY_COMPONENT_MUTATION } from '../../../../libs/graphQL';
import { getTableAttributes, getUserAttributes } from '../../../../libs/utils';
import ComponentProcessTime from '../../ComponentProcessTime/ComponentProcessTime';
import ActionButtons from './ActionButtons';

interface CombinedShortages {
	ids: string[];
	totalWeight: number;
	cookingStations: CookingStations[];
}

interface SectionTableRowProps {
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
	isChecked: boolean;
	foodComponent: FoodComponent;
	selectedComponentStation: FoodComponentStationTitle;
	setSelectedComponentStation: (value: FoodComponentStationTitle) => void;
	combinedShortages?: CombinedShortages;
}

const MobileSectionTableRow = ({
	statusAttributes,
	foodComponent,
	isChecked,
	selectedComponentStation,
	setSelectedComponentStation,
	combinedShortages
}: SectionTableRowProps) => {
	const [isCheckedState, setIsCheckedState] = useState(isChecked);
	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);

	const [mutateFoodComponent, { loading }] = useMutation(VERIFY_COMPONENT_MUTATION);

	const theme = useTheme();
	const tableAttributes = getTableAttributes(statusAttributes.lastUncheckedStation, theme);

	const currentStation = foodComponent.cookingStations?.find(
		(station) => FoodComponentStationTitle[station.name as keyof typeof FoodComponentStationTitle] === selectedComponentStation
	);

	const isLastStation = foodComponent.cookingStations?.[foodComponent.cookingStations?.length - 1] === currentStation;
	const appContext = useContext(AppContext);
	const handleFoodComponentVerify = async (isVerified: boolean) => {
		const user = getUserAttributes(appContext.user);
		await mutateFoodComponent({
			variables: {
				input: {
					id: foodComponent.id,
					isVerified,
					verifiedBy: user
				},
				kitchen: foodComponent.kitchen
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const actionButtons = () => {
		if (combinedShortages) {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					statusAttributes={statusAttributes}
					tableName={selectedComponentStation}
					combinedShortages={combinedShortages}
					isChecked={isCheckedState}
					setIsCheckedState={setIsCheckedState}
				/>
			);
		} else {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={selectedComponentStation}
					statusAttributes={statusAttributes}
					combinedShortages={undefined}
				/>
			);
		}
	};

	return (
		<TableRow>
			<Stack
				sx={{
					borderBottom: '1px solid ' + theme.palette.neutral200,
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
					px: '8px',
					py: '16px',
					width: '100%'
				}}
			>
				<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography
						variant="h2"
						sx={{ fontWeight: 400, fontFamily: caloTheme.typography.fontFamily, lineHeight: '19px', fontSize: '16px', ml: '2px' }}
					>
						{foodComponent.name.en}
					</Typography>
					{foodComponent.isVerified && foodComponent.verifiedBy && isLastStation && (
						<Typography
							variant="body2"
							sx={{
								fontWeight: 400,
								fontFamily: caloTheme.typography.fontFamily,
								lineHeight: '19px',
								fontSize: '14px',
								ml: '2px',
								color: theme.palette.grey[500]
							}}
						>
							Verified by {foodComponent.verifiedBy.name}
						</Typography>
					)}
					{!currentStation?.processTime && (
						<Typography
							sx={{
								borderRadius: '16px',
								padding: '8px',
								width: '120px',
								textAlign: 'center',
								bgcolor: tableAttributes.color,
								display: statusAttributes.lastUncheckedStation === selectedComponentStation ? 'none' : '',
								fontWeight: 400,
								fontSize: '14px',
								lineHeight: '17px',
								color: caloTheme.palette.neutral900
							}}
							onClick={() => setSelectedComponentStation(statusAttributes.lastUncheckedStation as FoodComponentStationTitle)}
						>
							{FoodComponentStationTitle[statusAttributes.lastUncheckedStation as keyof typeof FoodComponentStationTitle]}
						</Typography>
					)}
				</Stack>
				{foodComponent.brand !== Brand.CALO && (
					<Typography variant="h4" sx={{ mr: 1, fontWeight: 400, fontSize: '23px', lineHeight: '28px', color: '#FF9900' }}>
						M
					</Typography>
				)}

				<Stack
					sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
				>
					{currentStation && currentStation.processTime && !combinedShortages && (
						<ComponentProcessTime
							combinedShortages={combinedShortages}
							foodComponent={foodComponent}
							processTime={currentStation.processTime}
							endTime={currentStation.endTime ?? ''}
						/>
					)}
					{statusAttributes.isCheckable || currentStation?.endTime ? (
						(currentStation && !currentStation.endTime) || combinedShortages ? (
							actionButtons()
						) : (
							<Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
								{isLastStation && (
									<>
										{foodComponent.isVerified ? (
											<Box display="flex" flexDirection="row" alignItems="center" marginRight="20px">
												<VerifiedIcon />
												<Typography fontWeight="500" fontSize="13px" marginLeft="5px">
													VERIFIED
												</Typography>
											</Box>
										) : (
											<Button
												variant="outlined"
												disabled={loading}
												onClick={() => handleFoodComponentVerify(true)}
												startIcon={<VerifiedIcon />}
												sx={{
													color: theme.palette.black,
													backgroundColor: theme.palette.white,
													borderColor: theme.palette.black,
													borderRadius: '5px',
													textTransform: 'capitalize'
												}}
											>
												<Typography fontWeight="500" fontSize="13px">
													VERIFY
												</Typography>
											</Button>
										)}
									</>
								)}
								{foodComponent.isVerified && isLastStation ? (
									<Button
										variant="outlined"
										disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
										onClick={() => setIsConfirmationModalOpened(true)}
										style={{ width: '32px', minWidth: '32px' }}
										sx={{
											backgroundColor: theme.palette.white,
											borderColor: theme.palette.black,
											borderRadius: '5px',
											padding: 0,
											height: '32px',
											'&:disabled': {
												backgroundColor: theme.palette.white
											}
										}}
									>
										<UndoIcon sx={{ color: theme.palette.black }} />
									</Button>
								) : (
									<Typography sx={{ color: theme.palette.black, mr: '20px', fontWeight: 600, fontSize: '13px', ml: '20px' }}>
										COMPLETED
									</Typography>
								)}
							</Box>
						)
					) : (
						<Typography sx={{ color: theme.palette.neutral600, fontWeight: 600, mr: 3, width: '100%', textAlign: 'center' }}>
							Pending
						</Typography>
					)}
				</Stack>
				{combinedShortages && (
					<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<Typography sx={{ fontWeight: 400, fontSize: '14px', color: caloTheme.palette.neutral900 }}>
							Quantity: {combinedShortages?.totalWeight || 0} g
						</Typography>

						<Typography sx={{ fontWeight: 400, fontSize: '10px', color: caloTheme.palette.red }}>Shortage</Typography>
					</Stack>
				)}
			</Stack>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleFoodComponentVerify(false)}
				title={'Undo confirmation '}
				message={'Are you sure you want to undo this action?'}
			/>
		</TableRow>
	);
};

export default MobileSectionTableRow;

import { Alignment, Borders, Column, Fill, PageSetup, Worksheet } from 'exceljs';
import { chunk, compact } from 'lodash-es';
import striptags from 'striptags';

import { ComponentService } from '@calo/services';
import { Brand } from '@calo/types';

import { FoodComponent, SingleFoodComponent } from '../../../libs';
import { weightCalculator } from '../../../libs/utils/foodComponentHelper';
import { decodeHtmlSymbols } from '../../../libs/utils/helperFunctions';
import { calculateFractionWeight } from '../helpers';

export const pageSetup: Partial<PageSetup> = {
	paperSize: 9,
	orientation: 'landscape',
	margins: {
		left: 0.5,
		right: 0.5,
		top: 1,
		bottom: 0.5,
		header: 0.3,
		footer: 0.1
	}
};

export const handleWidthAndHeight = (worksheet: Worksheet, columns: string[], i: number, width = 13, height = 30) => {
	for (const letter of columns) {
		worksheet.getColumn(letter).width = width;
	}
	const rows: any = worksheet.getRows(0, i + 99);
	for (const row of rows) {
		row.height = height;
	}
};

export const handlePageLayouts = (worksheet: Worksheet, i: number, component: FoodComponent, newCookedWeight: number | null) => {
	const header = getHeader(component, newCookedWeight);
	worksheet.headerFooter.oddHeader = worksheet.headerFooter.evenHeader = header;
	worksheet.headerFooter.oddFooter = worksheet.headerFooter.evenFooter = footer;
	worksheet.columns = columns;
	worksheet.getRow(i + 1).alignment = alignment;
	worksheet.getRow(i + 1).values = ['Ingredient', 'Quantity Before Prep', 'Quantity After Prep', 'Unit', '', 'Method'];
	worksheet.mergeCells(`F${i + 1}`, `J${i + 1}`);
	worksheet.getCell(`F${i + 1}`).style = { border, fill, alignment };
	worksheet.getCell(`A${i + 1}`).style = { border, fill, alignment };
	worksheet.getCell(`B${i + 1}`).style = { border, fill, alignment };
	worksheet.getCell(`C${i + 1}`).style = { border, fill, alignment };
};

export const handleIngredients = (worksheet: Worksheet, component: SingleFoodComponent, newCookedWeight: number) => {
	for (const row of component.ingredients || []) {
		const ingredientQuantity = calculateFractionWeight(component, row.quantity ?? 0, newCookedWeight);
		worksheet.addRow(
			{
				ingredient: row?.internalName || row?.name.en,
				beforeQuantity: Math.ceil(ingredientQuantity * (row.wastage ?? 1)),
				afterQuantity: Math.ceil(ingredientQuantity),
				unit: row?.measurementUnit || 'g'
			},
			''
		).font = rowFont;
	}
};

export const handleChildComponents = (worksheet: Worksheet, component: SingleFoodComponent, newCookedWeight: number) => {
	for (const row of component.childComponents || []) {
		const childComponentWeight = calculateFractionWeight(
			component,
			ComponentService.calculateComponentWeight(row.cups, row.measurementUnit, row.weight ?? 0, row.quantity || 0),
			newCookedWeight
		);
		worksheet.addRow(
			{
				ingredient: row?.name.en,
				beforeQuantity: childComponentWeight,
				unit: 'g'
			},
			''
		).font = rowFont;
	}
};

export const generateOvenSettingsTableData = (description: string) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(description, 'text/html');

	const rowData = [...doc.querySelectorAll('tr')].map((row) => {
		const cells = [...row.querySelectorAll('td')].map((cell) => cell.textContent || '');
		return cells;
	});

	const tableData: Record<string, string | null> = {};

	for (const row of rowData) {
		if (row.length === 2) {
			const attributeName = row[0].trim();
			const attributeValue = row[1].trim() || '';
			tableData[attributeName] = attributeValue;
		}
	}

	return tableData;
};

export const handleMethods = (worksheet: Worksheet, component: FoodComponent, i: number) => {
	for (const row of component.method || []) {
		const decodedDescription = decodeHtmlSymbols(row.description ?? '');
		const isTable = decodedDescription.includes('<table');
		const isOvenSetting = decodedDescription.includes('Steam %');

		if (isTable) {
			const removeTableHtml = decodedDescription.split('<tr');
			let index = isOvenSetting ? i + 3 : 6;
			let k = 5;
			for (const rows of removeTableHtml) {
				const tableData = getTableData(rows);
				for (const [j, rowCol] of tableData.entries()) {
					index = getUpdatedIndexForHandlingLastRow(index);
					const activeCell = isOvenSetting ? worksheet.getCell(index + j, k) : worksheet.getCell(i + 2, index);
					activeCell.value = rowCol;
					activeCell.alignment = isOvenSetting
						? { wrapText: false, horizontal: 'center', vertical: 'middle' }
						: { wrapText: true };
					activeCell.border = border;
					index = isOvenSetting ? index : index + 1;
				}
				k = k + 1;
				i = isOvenSetting ? i : i + 1;
				index = isOvenSetting ? index : 0;
			}
			i = index;
		} else {
			const description = striptags(decodedDescription);
			const maxColumnLength = getMaxColumnLength(description);
			let index = 6;
			i = getUpdatedIForHandlingLastRow(i, maxColumnLength);
			worksheet.mergeCells(`F${i + 2}`, `J${i + 2 + maxColumnLength}`);
			worksheet.getCell(`F${i + 2}`).alignment = { wrapText: true, vertical: 'middle', indent: 1 };
			worksheet.getCell(`F${i + 2}`).font = { bold: true, size: 12 };
			worksheet.getCell(i + 2, index).value = description;
			index = index + 1;
			i = i + maxColumnLength + 1;
		}
	}
};

const font = { size: 10, name: 'Arial' };
const rowFont = { size: 11, bold: true, name: 'Arial' };
const fill: Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'efefef' } };
const alignment: Partial<Alignment> = { horizontal: 'center', vertical: 'middle' };
const footer = '&R &12 Page (&P) of (&N)';
const border: Partial<Borders> = {
	top: { style: 'thin', color: { argb: 'b7b7b7' } },
	left: { style: 'thin', color: { argb: 'b7b7b7' } },
	bottom: { style: 'thin', color: { argb: 'b7b7b7' } },
	right: { style: 'thin', color: { argb: 'b7b7b7' } }
};
const columns: Array<Partial<Column>> = [
	{
		header: 'Ingredient',
		width: 45,
		key: 'ingredient',
		style: { border, alignment: { vertical: 'middle', indent: 1 }, font }
	},
	{ header: 'Quantity Before Prep', width: 25, key: 'beforeQuantity', style: { border, alignment, font } },
	{ header: 'Quantity After Prep', width: 25, key: 'afterQuantity', style: { border, alignment, font } },
	{ header: 'Unit', width: 10, key: 'unit', style: { border, alignment, font } },
	{ header: '', width: 1 },
	{ header: 'Method', width: 35, key: 'method', style: { border, alignment, font } }
];

const getHeader = (component: FoodComponent, newCookedWeight: number | null) => {
	const cookedWeight =
		newCookedWeight === null
			? weightCalculator(
					component,
					component.cookedWeight ?? 0,
					component.count ?? 1,
					component.cupsQuantity,
					component.quantities || []
				)
			: newCookedWeight;
	const rawWeight = weightCalculator(
		component,
		component.weight ?? 0,
		component.count ?? 1,
		component.cupsQuantity,
		component.quantities || [],
		(component.weight ?? 0) / (component.cookedWeight ?? 0)
	);
	return `&15 &B${component.name.en} &B  |  Raw Weight: &B${rawWeight} g &B |  Cooked Weight: &B${cookedWeight} g &B |  ${component.brand || Brand.CALO}  |  ${component.country}  |  ${component.kitchen}  |  ${component.date}`;
};

const getUpdatedIndexForHandlingLastRow = (index: number) => {
	const isLastRow = index !== 0 && index % 20 === 0;
	if (isLastRow) {
		return index + 1;
	}
	return index;
};

const getMaxColumnLength = (phraze: string) => {
	const listOfWords = phraze.split(/\s+/);
	const chuncksOfWords = chunk(listOfWords, 30);
	return chuncksOfWords.length;
};

const getUpdatedIForHandlingLastRow = (i: number, maxColLength: number) => {
	const g = i + 2;
	if (g !== 0 && g % 20 === 0 && (g + maxColLength) % 20 !== 0) {
		return i + maxColLength;
	}
	return i;
};

const getTableData = (rows: string) => compact(striptags(rows.replace('>', ''), ['\n']).split('\n'));

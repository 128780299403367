import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import {
	BackLabelConfigurationValues,
	BackLabelsConfigurationHeatingInstructions,
	BackLabelsConfigurationLanguage,
	BackLabelsConfigurationShelfLife,
	BackLabelsConfigurationTemplate,
	BackLabelsConfigurationType
} from '@calo/dashboard-types';
import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { MealCheckBox } from '../../../../libs';
import { EXPORT_FOOD_LABELS_MUTATION } from '../../../../libs/graphQL';
import { createAutoDownloadLinkFromArrayOfUrls } from '../../../../libs/utils/helperFunctions';

const defaultValue = 'Default';

interface DownloadMealPopUpFooterProps {
	date: string;
	mealCheckboxes: MealCheckBox;
}

const DownloadMealPopUpFooter = ({ mealCheckboxes, date }: DownloadMealPopUpFooterProps) => {
	const [exportMealBackLabels, { loading: loadingMeals }] = useMutation(EXPORT_FOOD_LABELS_MUTATION);
	const [isConfigurationVisible, setIsConfigurationVisible] = useState(false);
	const [configurationValues, setConfigurationValues] = useState<BackLabelConfigurationValues>({});

	const exportMeals = async () => {
		const payload = Object.values(mealCheckboxes).map((mcb) => ({
			id: mcb.meal.id,
			amount: mcb.amount,
			backLabels: !!mcb.isBackLabelSelected,
			frontLabels: !!mcb.isFrontLabelSelected
		}));

		const { data } = await exportMealBackLabels({
			variables: { input: { data: payload, day: date, configurations: configurationValues } },
			onError: (e) => {
				toast.error(`Error while exporting food back-labels, ${e.message} `);
				return;
			}
		});
		if (data?.exportFoodLabels.data && data.exportFoodLabels.data.length > 0) {
			return createAutoDownloadLinkFromArrayOfUrls(data.exportFoodLabels.data);
		}
	};

	const setFormValues = (key: string, value: any) => {
		setConfigurationValues({
			...configurationValues,
			[key]: value === defaultValue ? undefined : value
		});
	};

	return (
		<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'}>
			{isConfigurationVisible && (
				<>
					<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} sx={{ paddingTop: 2 }}>
						<Typography
							variant="h6"
							sx={{
								fontSize: '19px',
								fontWeight: 600,
								color: caloTheme.palette.neutral900
							}}
						>
							Back Label Configuration
						</Typography>
						<Button
							variant="outlined"
							onClick={() => {
								setIsConfigurationVisible(false);
							}}
							sx={{
								fontSize: '16px',
								fontWeight: 600,
								marginRight: 2.5,
								color: 'black',
								borderWidth: 0,
								textTransform: 'capitalize',
								':hover': {
									backgroundColor: 'white',
									borderWidth: 0
								}
							}}
						>
							Remove configuration
						</Button>
					</Stack>
					<Stack
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: 2
						}}
					>
						<TextField
							select
							label="Language"
							value={configurationValues.language || defaultValue}
							sx={{ my: 1, width: '100%' }}
							onChange={(data: any) => setFormValues('language', data.target.value)}
							InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
						>
							{[defaultValue, ...Object.values(BackLabelsConfigurationLanguage)].map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</TextField>
						<TextField
							select
							label="Heating Instructions"
							value={configurationValues.heatingInstructions || defaultValue}
							sx={{ my: 1, width: '100%' }}
							onChange={(data: any) => setFormValues('heatingInstructions', data.target.value)}
							InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
						>
							{[defaultValue, ...Object.values(BackLabelsConfigurationHeatingInstructions)].map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</TextField>
						<TextField
							select
							label="Shelf Life"
							value={configurationValues.shelfLifeType || defaultValue}
							sx={{ my: 1, width: '100%' }}
							onChange={(data: any) => setFormValues('shelfLifeType', data.target.value)}
							InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
						>
							{[defaultValue, ...Object.values(BackLabelsConfigurationShelfLife)].map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</TextField>
					</Stack>
					<Stack
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: 2
						}}
					>
						<TextField
							select
							label="Type"
							value={configurationValues.type || defaultValue}
							sx={{ my: 1, width: '100%' }}
							onChange={(data: any) => setFormValues('type', data.target.value)}
							InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
						>
							{[defaultValue, ...Object.values(BackLabelsConfigurationType)].map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</TextField>
						<TextField
							select
							label="Template"
							value={configurationValues.template || defaultValue}
							sx={{ my: 1, width: '100%' }}
							onChange={(data: any) => setFormValues('template', data.target.value)}
							InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
						>
							{[defaultValue, ...Object.values(BackLabelsConfigurationTemplate)].map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</TextField>

						{configurationValues.shelfLifeType === BackLabelsConfigurationShelfLife.Custom ? (
							<TextField
								type="number"
								inputMode="numeric"
								label="Custom Shelf Life"
								value={configurationValues.shelfLife}
								sx={{ my: 1, width: '100%' }}
								onChange={(data: any) =>
									setConfigurationValues({
										...configurationValues,
										shelfLife: data.target.value
									})
								}
								InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
							></TextField>
						) : (
							<div style={{ width: '100%' }} />
						)}
					</Stack>
				</>
			)}
			<Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} width={'100%'} sx={{ paddingTop: 2 }}>
				{!isConfigurationVisible && (
					<Button
						variant="outlined"
						onClick={() => {
							setIsConfigurationVisible(true);
						}}
						sx={{
							textTransform: 'capitalize',
							fontSize: '16px',
							fontWeight: 600,
							marginRight: 2.5,
							color: 'black',
							borderColor: 'black',
							borderRadius: 2
						}}
					>
						Configure
					</Button>
				)}
				<LoadingButton
					loading={loadingMeals}
					variant="contained"
					disabled={Object.values(mealCheckboxes).length === 0}
					sx={{
						textTransform: 'capitalize',
						fontSize: '16px',
						lineHeight: '23px',
						borderRadius: '8px',
						color: 'white',
						height: '50px',
						fontWeight: 600,
						boxShadow: 'none',
						p: '10.5px 20px',
						':hover': {
							boxShadow: 'none'
						}
					}}
					onClick={() => exportMeals()}
				>
					Download
				</LoadingButton>
			</Stack>
		</Box>
	);
};
export default DownloadMealPopUpFooter;

import { KDSPermission } from '@calo/dashboard-types';

import { RoutePaths } from '../enums';

export const getDefaultRoute = (permissions: KDSPermission[]): string => {
	if (permissions.length === 0) {
		return RoutePaths.home;
	}

	switch (permissions[0]) {
		case KDSPermission.VIEW_COMPONENTS_CHECK:
			return RoutePaths.componentsCheck;
		case KDSPermission.VIEW_PORTIONING_DISPLAY:
			return RoutePaths.portioningDisplay;
		case KDSPermission.VIEW_MEALS_STATUS:
			return RoutePaths.mealsStatus;
		case KDSPermission.VIEW_WASTAGE_TRACKING:
			return RoutePaths.wastageTracking;
		case KDSPermission.VIEW_QUALITY_TRACKING:
			return RoutePaths.qualityTracking;
		case KDSPermission.VIEW_SHORTAGE_REPORTING:
			return RoutePaths.shortageReporting;
		case KDSPermission.VIEW_SHORTAGE_DISPLAY:
			return RoutePaths.shortageDisplay;
		case KDSPermission.VIEW_ASSEMBLY_CHECK:
			return RoutePaths.assemblyCheck;
		case KDSPermission.VIEW_WASTAGE_ARCHIVE:
			return RoutePaths.wastageArchive;
		case KDSPermission.VIEW_PRE_DISPATCH_CHECK:
			return RoutePaths.preDispatchCheck;
		case KDSPermission.VIEW_PRE_DISPATCH_ARCHIVE:
			return RoutePaths.preDispatchArchive;
		case KDSPermission.VIEW_RECIPE_GUIDEBOOK:
			return RoutePaths.recipeGuideBook;
		case KDSPermission.VIEW_LOCK_TIME_CHANGES:
			return RoutePaths.lockTimeChanges;
		case KDSPermission.VIEW_LOGISTICS_CHANGES:
			return RoutePaths.logisticsChanges;
		case KDSPermission.VIEW_DRIVER_DISPATCH:
			return RoutePaths.driverDispatch;
		case KDSPermission.VIEW_CUSTOMER_COMPLAINTS:
			return RoutePaths.customerComplaints;
		case KDSPermission.VIEW_FILE_MANAGEMENT:
			return RoutePaths.fileManagement;
		case KDSPermission.VIEW_SPECIAL_REQUEST:
			return RoutePaths.specialRequest;
		case KDSPermission.VIEW_QUALITY_METRICS:
			return RoutePaths.qualityMetrics;

		default:
			return RoutePaths.home;
	}
};

import { uniqBy } from 'lodash-es';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';

import { Gift } from '../libs';
import { GraphQLClient, LIST_GIFTS_QUERY } from '../libs/graphQL';

const useListSpecialRequest = (filters: any, next?: string) => {
	const [specialRequestList, setSpecialRequest] = useState<any>([]);
	const [newNext, setNewNext] = useState<string | undefined>(next);
	const limit = 200;
	const { loading, refetch, fetchMore } = useQuery(LIST_GIFTS_QUERY, {
		variables: {
			query: {
				filters: {
					kitchen: filters.kitchen,
					date: filters.date
				},
				limit
			}
		},
		onCompleted: (data) => {
			if (data?.listGifts?.data) {
				setSpecialRequest(data?.listGifts?.data);
			}
			if (data?.listGifts?.next) {
				setNewNext(data?.listGifts?.next);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const handleFetchMore = () => {
		if (newNext) {
			fetchMore({
				variables: {
					query: {
						filters: {
							kitchen: filters.kitchen,
							date: filters.date
						},
						limit
					}
				},
				updateQuery: (prev: any | undefined, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setNewNext(fetchMoreResult?.listGifts?.next || null);
						const prevGifts = Array.isArray(prev?.listGifts?.data) ? prev.listGifts.data : [];
						const newGiftsData = Array.isArray(fetchMoreResult?.listGifts?.data) ? fetchMoreResult.listGifts.data : [];
						const newGifts = uniqBy([...prevGifts, ...newGiftsData], 'id');
						return {
							...prev,
							listGifts: {
								...prev.listGifts,
								data: newGifts,
								next: fetchMoreResult?.listGifts?.next
							}
						};
					}

					return prev;
				}
			});
		}
	};

	const clearData = () => {
		setSpecialRequest([]);
		// eslint-disable-next-line unicorn/no-useless-undefined
		setNewNext(undefined);
		GraphQLClient.clearStore();
	};

	return {
		isLoading: loading,
		specialRequestList: specialRequestList as Gift[],
		refetchRequest: refetch,
		hasNext: Boolean(newNext),
		clearRequestData: clearData,
		handleFetchMoreRequest: handleFetchMore
	};
};

export default useListSpecialRequest;

import { Fragment, useEffect, useRef, useState } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { ShiftSelector } from '../../../components';
import CustomFoodsFilter from '../../../components/CustomFoodsFilter';
import {
	Food,
	FoodComponentDataPortionPage,
	FoodPortioningFoodType,
	MobileFiltersPages,
	Page,
	Session,
	ShoppingMenu
} from '../../../libs';
import { handleShiftForKitchen, setShiftToLocalStorage } from '../../../libs/utils/helperFunctions';
import DownloadPopup from '../DownloadPopup';
import PortionTableRow from '../PortionTableRow';

interface PortioningTableProps {
	kitchen: string;
	filters: MobileFiltersPages;
	setFilters: (value: MobileFiltersPages) => void;
	data: FoodComponentDataPortionPage;
	shoppingMenu: ShoppingMenu;
	date: string;
}

const PortioningTable = ({ data, filters, setFilters, kitchen, shoppingMenu, date }: PortioningTableProps) => {
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const exportPotioningStatusModalRef = useRef<any>();
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const theme = useTheme();

	useEffect(() => {
		handleShiftForKitchen(Page.portioningDisplay, kitchen, filters.shift, handleShiftChange);
	}, [kitchen]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral100,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 16,
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const handleShiftChange = (shift: string) => {
		setFilters({ ...filters, shift: shift as Session });
		setShiftToLocalStorage(shift, Page.portioningDisplay, kitchen);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 2, mb: 0, width: '100%' }}>
				<Stack direction={'row'} alignItems="center" gap={4}>
					<CustomFoodsFilter
						isCustomFoodHidden={filters.showCustom}
						setIsCustomFoodHidden={(v) => setFilters({ ...filters, showCustom: v })}
					/>
				</Stack>
				<Stack direction="row" justifyContent="space-between" alignItems="center" width={'15%'}>
					<ShiftSelector
						kitchen={kitchen}
						allSessions={false}
						shift={filters.shift}
						key={'Portioning-Table'}
						handleShiftChange={handleShiftChange}
						isShiftMenuOpened={isShiftMenuOpened}
						shiftMenuAnchorEl={shiftMenuAnchorEl}
						setShiftMenuAnchorEl={setShiftMenuAnchorEl}
					/>
					<Button
						variant="outlined"
						sx={{
							ml: '5px',
							fontSize: '16px',
							padding: '10px',
							color: theme.palette.neutral900,
							borderColor: theme.palette.neutral700,
							borderRadius: '10px',
							textTransform: 'none',
							'&:hover': {
								backgroundColor: 'transparent',
								borderColor: theme.palette.neutral700
							},
							width: '40%'
						}}
						onClick={() => exportPotioningStatusModalRef.current?.open()}
					>
						Download
					</Button>
				</Stack>
			</Grid>
			<Table sx={{ m: 2, width: '100%' }}>
				<TableHead>
					<TableRow>
						<StyledTableCell colSpan={3} sx={{ width: '25%', p: 1, pl: 2 }}>
							Meals
						</StyledTableCell>
						<StyledTableCell sx={{ width: '25%', textAlign: 'start', p: 1 }}>Status</StyledTableCell>
						<StyledTableCell sx={{ width: '25%', textAlign: 'center' }}>Action</StyledTableCell>
						<StyledTableCell sx={{ width: '25%', textAlign: 'center' }}>On Demand Invoices</StyledTableCell>
					</TableRow>
				</TableHead>
				{Object.values(data).some((d: Food[]) => d.length > 0) ? (
					<TableBody>
						{Object.entries(data).map(([key, value]: [string, Food[]], i) => (
							<Fragment key={i}>
								{value.map((meal: Food) => (
									<PortionTableRow
										food={meal}
										key={meal.id}
										shift={filters.shift}
										shoppingMenu={shoppingMenu}
										foodType={key as FoodPortioningFoodType}
										date={date}
										kitchen={kitchen}
									/>
								))}
							</Fragment>
						))}
					</TableBody>
				) : (
					<TableBody>
						<TableRow>
							<TableCell
								colSpan={3}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No meals
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
			<DownloadPopup
				exportPotioningStatusModalRef={exportPotioningStatusModalRef}
				theme={theme}
				kitchen={kitchen}
				showCustom={filters.showCustom}
			/>
		</Box>
	);
};

export default PortioningTable;

import { Box, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled, useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import { InputField } from '../../../components';

interface CookedWeightTableProps {
	cookedWeight: number;
	setNewCookedWeight: (weight: number) => void;
}

const CookedWeightTable = ({ cookedWeight, setNewCookedWeight }: CookedWeightTableProps) => {
	const theme = useTheme();
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			color: theme.palette.common.black,
			fontSize: 28,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase',
			padding: '10px',
			border: 'none'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 19,
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: '2',
			fontFamily: 'Roboto',
			lineHeight: '23px'
		}
	}));

	return (
		<Table sx={{ mt: 2, width: '100%', minWidth: '100%' }}>
			<TableHead>
				<TableRow sx={{ pl: '5px' }}>
					<StyledTableCell sx={{ p: 1, pl: 2, minWidth: '100%' }}>COOKED WEIGHT</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell sx={{ fontSize: '23px', fontWeight: 600, textAlign: 'center', border: 'none' }}>
						<InputField
							type="number"
							value={cookedWeight}
							placeholder="0"
							onChange={(event) => setNewCookedWeight(Number(event))}
							debounce
							fullWidth={true}
							inputMode="numeric"
							endAdornment={
								<InputAdornment position="end">
									<Box
										sx={{
											fontSize: '23px',
											fontWeight: 600,
											color: theme.palette.black
										}}
									>
										{' '}
										g
									</Box>
								</InputAdornment>
							}
							sx={{
								textTransform: 'none',
								fontSize: '23px',
								fontWeight: 600,
								borderRadius: '4px',
								width: '100%',
								height: '70px',
								textAlign: 'start',
								px: '20px',
								input: { textAlign: 'start' },
								color: theme.palette.black,
								backgroundColor: theme.palette.primary50
							}}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default CookedWeightTable;

import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import { caloTheme } from '../../assets/themes/calo';

interface TemperatureSelectModalProps {
	open: boolean;
	handleClose: () => void;
	handleAccept: (Temp: string) => void;
	type: string;
	mealName?: string;
	startTemp: string;
}
const TemperatureSelectModal = ({ open, handleClose, handleAccept, type, mealName, startTemp }: TemperatureSelectModalProps) => {
	const [startTempState, setStartTempState] = useState(startTemp);
	const [tempA, setTempA] = useState(Number(startTempState.split('.')[0]));
	const [tempB, setTempB] = useState<number | string | Array<number | string>>(Number(startTempState.split('.')[1]) || 0);

	const handleAction = (temp: string) => {
		handleAccept(temp);
		handleClose();
	};
	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		setTempB(newValue);
	};
	const handleAdd = () => {
		setTempA(tempA + 1);
	};
	const handleSubtract = () => {
		setTempA(tempA - 1);
	};
	useEffect(() => {
		setStartTempState(startTemp);
		setTempA(Number(startTemp.split('.')[0]));
		setTempB(Number(startTemp.split('.')[1] || 0));
	}, [startTemp, open]);

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title" sx={{ width: '540px', height: '290px' }}>
				<Box
					sx={{
						position: 'absolute',
						left: 20,
						top: 15,
						color: (theme) => theme.palette.grey[900],
						fontSize: '19px',
						textTransform: 'capitalize',
						fontWeight: 600
					}}
				>
					Input {type} Temperature
				</Box>
				<Box sx={{ position: 'absolute', left: 20, top: 35, fontSize: '72px', fontFamily: caloTheme.typography.fontFamily }}>
					{tempA}.{tempB}°C
				</Box>
				<Box
					sx={{
						position: 'absolute',
						right: 130,
						top: 90,
						fontSize: '19px',
						textTransform: 'capitalize',
						fontWeight: 600
					}}
				>
					{mealName}
				</Box>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
				>
					<CloseIcon />
				</IconButton>
				<SvgIcon
					viewBox="0 0 512 72"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					sx={{ position: 'absolute', left: 15, top: 200, width: '512px', height: '72px' }}
				>
					<path d="M0 69L512 69" stroke="#575757" strokeWidth="6" />
					<path d="M356 33L356 57" stroke="#575757" strokeWidth="4" />
					<path d="M476 33L476 57" stroke="#575757" strokeWidth="4" />
					<path d="M236 33L236 57" stroke={tempB > 4 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M116 33L116 57" stroke="#575757" strokeWidth="4" />
					<path d="M380 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M500 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M260 45V57" stroke={tempB > 6 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M140 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M20 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M404 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M284 45V57" stroke={tempB > 8 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M164 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M44 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M332 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M452 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M212 45V57" stroke={tempB > 2 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M92 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M308 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M428 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M188 45V57" stroke={tempB > 0 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M68 45V57" stroke="#575757" strokeWidth="4" />
					<path d="M344 45L344 57" stroke="#575757" strokeWidth="4" />
					<path d="M464 45L464 57" stroke="#575757" strokeWidth="4" />
					<path d="M224 45L224 57" stroke={tempB > 3 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M104 45L104 57" stroke="#575757" strokeWidth="4" />
					<path d="M320 45L320 57" stroke="#575757" strokeWidth="4" />
					<path d="M440 45L440 57" stroke="#575757" strokeWidth="4" />
					<path d="M200 45L200 57" stroke={tempB > 1 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M80 45L80 57" stroke="#575757" strokeWidth="4" />
					<path d="M296 0L296 57" stroke="#575757" strokeWidth="4" />
					<path d="M176 0L176 57" stroke="#57AE7F" strokeWidth="4" />
					<path d="M56 0L56 57" stroke="#575757" strokeWidth="4" />
					<path d="M368 45L368 57" stroke="#575757" strokeWidth="4" />
					<path d="M488 45L488 57" stroke="#575757" strokeWidth="4" />
					<path d="M248 45L248 57" stroke={tempB > 5 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M128 45L128 57" stroke="#575757" strokeWidth="4" />
					<path d="M8 45L8 57" stroke="#575757" strokeWidth="4" />
					<path d="M392 45L392 57" stroke="#575757" strokeWidth="4" />
					<path d="M272 45L272 57" stroke={tempB > 7 ? '#57AE7F' : '#575757'} strokeWidth="4" />
					<path d="M152 45L152 57" stroke="#575757" strokeWidth="4" />
					<path d="M32 45L32 57" stroke="#575757" strokeWidth="4" />
					<path d="M416 0L416 57" stroke="#575757" strokeWidth="4" />
				</SvgIcon>
				<Box sx={{ position: 'absolute', left: 66, top: 150, fontSize: '28px', fontFamily: caloTheme.typography.fontFamily }}>
					{tempA - 1}
				</Box>
				<Box sx={{ position: 'absolute', left: 185, top: 150, fontSize: '28px', fontFamily: caloTheme.typography.fontFamily }}>
					{tempA}
				</Box>
				<Box sx={{ position: 'absolute', left: 305, top: 150, fontSize: '28px', fontFamily: caloTheme.typography.fontFamily }}>
					{tempA + 1}
				</Box>
				<Box sx={{ position: 'absolute', left: 426, top: 150, fontSize: '28px', fontFamily: caloTheme.typography.fontFamily }}>
					{tempA + 2}
				</Box>
				<Box sx={{ width: 109, position: 'absolute', left: 191, top: 253.5 }}>
					<Slider
						aria-labelledby="input-slider"
						defaultValue={typeof tempB === 'number' ? tempB : 0}
						step={1}
						valueLabelDisplay="auto"
						min={0}
						max={9}
						onChange={handleSliderChange}
					/>
				</Box>
			</DialogTitle>
			<Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{ mb: 2 }}>
				<IconButton aria-label="close" onClick={handleSubtract} sx={{ p: 3 }}>
					<RemoveIcon />
				</IconButton>
				<IconButton aria-label="close" onClick={handleAdd} sx={{ p: 3 }}>
					<AddIcon />
				</IconButton>
				<Button
					variant="contained"
					sx={{ textTransform: 'none', fontSize: '16px', color: 'white', fontWeight: 600, px: 4 }}
					onClick={() => {
						handleAction(`${tempA}.${tempB}`);
					}}
				>
					Done
				</Button>
			</Stack>
		</Dialog>
	);
};
export default TemperatureSelectModal;

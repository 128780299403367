import { omit } from 'lodash-es';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../../../assets/themes/calo';
import { Chef, ComponentBatch, Session, SingleFoodComponentWithBatches } from '../../../../libs';
import { UPDATE_COMPONENT_BATCH_MUTATION } from '../../../../libs/graphQL';
import AddBatchButton from '../../Common/AddBatchButton';
import ReviewButton from '../../Common/ReviewButton';
import ReviewQualityDrawer from '../../Common/ReviewDrawer/ReviewQualityDrawer';
import SelectCookButton from '../../Common/SelectCookButton';
import { QualityTrackingContext } from '../../QualityTrackingContext';
import { applyStatusFilterOnQualityTracking } from '../../utils';

interface MobileQualityTableRowProps {
	foodComponent: SingleFoodComponentWithBatches;
	chefs: Chef[];
	foodComponentBatch?: ComponentBatch;
	batchesListLength?: number;
	foodComponentName?: string;
	date: string;
	shift: Session;
}

const MobileQualityTableRow = ({
	foodComponent,
	chefs,
	batchesListLength,
	foodComponentBatch,
	foodComponentName,
	shift,
	date
}: MobileQualityTableRowProps) => {
	const theme = useTheme();
	const addBatchModalRef = useRef<any>();
	const selectCookModalRef = useRef<any>();
	const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [updateComponentBatch, { loading: updateLoading }] = useMutation(UPDATE_COMPONENT_BATCH_MUTATION);
	const [isReviewDrawerOpened, setIsReviewDrawerOpened] = useState(false);
	const [selectedCook, setSelectedCook] = useState(foodComponentBatch?.chef ?? '');
	const { selectedCheckStatuses } = useContext(QualityTrackingContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleSelectChef = (chef: string) => {
		if (foodComponentBatch) {
			handleUpdateBatch({ chef });
		}
		setSelectedCook(chef);
		selectCookModalRef.current?.close();
	};

	const handleUpdateBatch = async (value: any) => {
		await updateComponentBatch({
			variables: {
				id: foodComponentBatch?.id || foodComponent.id,
				input: {
					...omit(foodComponentBatch, 'id'),
					...value
				}
			},
			onCompleted: () => {
				setIsLoading(false);
				setDeliveryActionMenuAnchorEl(null);
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	useEffect(() => {
		setIsLoading(updateLoading);
	}, [updateLoading]);

	if (applyStatusFilterOnQualityTracking(selectedCheckStatuses, foodComponentBatch)) {
		return (
			<TableRow>
				<Stack
					sx={{
						border: '1px solid ' + theme.palette.neutral200,
						borderRadius: '5px',
						my: 1,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						px: '8px',
						py: '16px',
						width: '100%'
					}}
				>
					<Stack>
						<Typography variant="h5" sx={{ fontWeight: 600, fontFamily: caloTheme.typography.fontFamily, ml: '2px', mb: '10px' }}>
							{foodComponent.name.en}
						</Typography>
					</Stack>
					{foodComponentBatch && (
						<Stack>
							<Typography
								sx={{
									fontWeight: 300,
									color: 'grey',
									fontFamily: caloTheme.typography.fontFamily,
									lineHeight: '19px',
									fontSize: '12px',
									ml: '2px'
								}}
							>
								Quantity
							</Typography>
							<Typography
								sx={{
									fontWeight: 400,
									fontFamily: caloTheme.typography.fontFamily,
									lineHeight: '19px',
									fontSize: '16px',
									ml: '2px'
								}}
							>
								{`${foodComponentBatch.weight} ${foodComponentBatch.measurementUnit || 'g'}`}
							</Typography>
						</Stack>
					)}
					<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
						<Stack>
							{foodComponentBatch && (
								<Typography
									sx={{
										fontWeight: 300,
										color: 'grey',
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '19px',
										fontSize: '12px',
										ml: '2px'
									}}
								>
									Batch
								</Typography>
							)}
							<Typography
								sx={{
									fontWeight: 400,
									fontFamily: caloTheme.typography.fontFamily,
									lineHeight: '19px',
									fontSize: '16px',
									ml: '2px'
								}}
							>
								{foodComponentBatch?.batchNumber ? `Batch ${foodComponentBatch.batchNumber}` : 'Add Batch'}
							</Typography>
						</Stack>
						<AddBatchButton
							deliveryActionMenuAnchorEl={deliveryActionMenuAnchorEl}
							addBatchModalRef={addBatchModalRef}
							foodComponentBatch={foodComponentBatch}
							setDeliveryActionMenuAnchorEl={setDeliveryActionMenuAnchorEl}
							foodComponentName={foodComponentName}
							batchesListLength={batchesListLength}
							handleUpdateBatch={handleUpdateBatch}
							showJustIcon={true}
							shift={shift}
							date={date}
							foodComponent={foodComponent}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
						/>
					</Stack>
					<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
						<Stack>
							{(foodComponentBatch || selectedCook) && (
								<Typography
									sx={{
										fontWeight: 300,
										color: 'grey',
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '19px',
										fontSize: '12px',
										ml: '2px'
									}}
								>
									Cook
								</Typography>
							)}
							<Typography
								sx={{
									fontWeight: 400,
									fontFamily: caloTheme.typography.fontFamily,
									lineHeight: '19px',
									fontSize: '16px',
									ml: '2px',
									alignSelf: 'center'
								}}
							>
								{foodComponentBatch?.chef || selectedCook || 'Choose Cook'}
							</Typography>
						</Stack>
						<SelectCookButton
							showJustIcon={true}
							chefs={chefs}
							handleSelectChef={handleSelectChef}
							selectCookModalRef={selectCookModalRef}
							isLoading={isLoading}
						/>
					</Stack>
					{foodComponentBatch?.quality?.[0]?.actor ? (
						<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
							<Stack>
								<Typography
									sx={{
										fontWeight: 300,
										color: 'grey',
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '19px',
										fontSize: '12px',
										ml: '2px'
									}}
								>
									Reviewed By
								</Typography>
								<Typography
									sx={{
										fontWeight: 400,
										fontFamily: caloTheme.typography.fontFamily,
										lineHeight: '19px',
										fontSize: '16px',
										ml: '2px',
										alignSelf: 'start'
									}}
								>
									{foodComponentBatch?.quality?.[0]?.actor.name}
								</Typography>
							</Stack>
							<ReviewButton
								status={foodComponentBatch?.quality?.[0].status}
								showJustIcon={true}
								setIsReviewDrawerOpened={setIsReviewDrawerOpened}
								isLoading={isLoading}
							/>
						</Stack>
					) : (
						<ReviewButton setIsReviewDrawerOpened={setIsReviewDrawerOpened} isLoading={isLoading} />
					)}
				</Stack>
				<ReviewQualityDrawer
					isReviewDrawerOpened={isReviewDrawerOpened}
					setIsReviewDrawerOpened={setIsReviewDrawerOpened}
					foodComponent={foodComponent}
					foodComponentBatch={foodComponentBatch}
					selectedCook={selectedCook}
					date={date}
					shift={shift}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			</TableRow>
		);
	}
	return <></>;
};

export default MobileQualityTableRow;

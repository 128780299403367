import enLocale from 'date-fns/locale/en-GB';

import ClearIcon from '@mui/icons-material/Clear';
import EventFilledIcon from '@mui/icons-material/Event';
import { IconButton } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { caloTheme } from '../../assets/themes/calo';
import LayoutWithKeyboardView from './LayoutWithKeyboardView';
import ToolbarWithKeyboardViewSwitch from './ToolbarWithKeyboardViewSwitch';

interface DatePickerProps {
	date: Date | null;
	minDate?: Date | undefined;
	maxDate?: Date | undefined;
	setDate: (data: Date | null) => void;
	label: string;
	open?: boolean;
	disabled?: boolean;
	showClearIcon?: boolean;
	onClose?: () => void;
	color?: any;
	displayKeyboardView?: boolean;
	showCalendarIcon?: boolean;
}

const DatePickerComponent = ({
	onClose,
	open,
	date,
	minDate,
	maxDate,
	setDate,
	label,
	disabled = false,
	displayKeyboardView = false,
	showClearIcon = false,
	showCalendarIcon = false
}: DatePickerProps) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
			<MobileDatePicker
				open={open}
				onClose={() => (onClose ? onClose() : undefined)}
				label={label}
				value={date}
				minDate={minDate}
				maxDate={maxDate}
				disabled={disabled}
				onChange={(newValue: Date | null) => {
					setDate(newValue);
				}}
				sx={{ width: '100%' }}
				slots={
					displayKeyboardView
						? {
								layout: LayoutWithKeyboardView,
								toolbar: ToolbarWithKeyboardViewSwitch
							}
						: {}
				}
				slotProps={{
					textField: {
						InputProps: {
							endAdornment:
								showClearIcon && date ? (
									<IconButton
										onClick={(event) => {
											event.stopPropagation();
											setDate(null);
										}}
									>
										<ClearIcon />
									</IconButton>
								) : showCalendarIcon ? (
									<EventFilledIcon sx={{ color: caloTheme.palette.action.active }} />
								) : null
						}
					}
				}}
			/>
		</LocalizationProvider>
	);
};

export default DatePickerComponent;
